<app-header></app-header>

<div id="wrapper" style="text-align: center;">
    <app-sidebar-menu></app-sidebar-menu>

    <div id="page-content-wrapper">

        <div class="container-fluid view-container">
            <div class="row data-container">
                <div class="col-md-12 col-12">
                    <div class="rows">
                        <app-dealers-login *ngIf="!isLoggedIn"></app-dealers-login>

                        <div *ngIf="isLoggedIn">
                            <div style="text-align: -webkit-center;">
                                <div style="width: max-content;">
                                    <div style="text-align: right;">
                                        <!-- <h1>
                                            ברוך הבא רון!
                                        </h1> -->
                                    </div>
                                    <div class="search-form">
                                        <form class="form-inline">
                                            <input type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input" id="phone"
                                                name="phone" placeholder="טלפון ליד" [(ngModel)]="query.leadPhoneNumber"
                                                (keydown.enter)="search()" />

                                            <input type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom"
                                                style="margin-right: 25px;" id="keyword" name="keyword"
                                                placeholder="דגם / גרסה" [(ngModel)]="query.keyword"
                                                (keydown.enter)="search()" />

                                            <button type="button" class="header-button search-button point"
                                                (click)="search()">
                                                חיפוש
                                            </button>
                                        </form>

                                        <div *ngIf="hasErrors"
                                            style="text-align: right; padding-top: 20px; color: #ff2800;">
                                            <h6>{{errorText}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="scroll result-table">
                                <table class="table table-hover" style="direction: ltr; width: 100%;">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="value">#</th>
                                            <th scope="col" class="point value" (click)="sort(0)">Deal Id</th>
                                            <th scope="col" class="point value" (click)="sort(11)">Signed</th>
                                            <th scope="col" class="point value" (click)="sort(1)">Dealer</th>
                                            <th scope="col" class="point value" (click)="sort(11)">Deal Type</th>
                                            <th scope="col" class="point value" (click)="sort(2)">Name by Dealer
                                            </th>
                                            <th scope="col" class="point value" (click)="sort(3)">Name by CarFind
                                            </th>
                                            <th scope="col" class="point value" (click)="sort(4)">Liters</th>
                                            <th scope="col" class="point value" (click)="sort(5)">DSale</th>
                                            <th scope="col" class="point value" (click)="sort(6)">DList</th>
                                            <th scope="col" class="point value" (click)="sort(7)">List</th>
                                            <th scope="col" class="point value" (click)="sort(9)">Model</th>
                                            <th scope="col" class="point value" (click)="sort(10)">Color</th>
                                            <!-- <th scope="col">Similar Versions</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of result; let i=index;">
                                            <td class="value">{{i + 1}}</td>
                                            <td class="value">{{formatDealerId(item.id)}}</td>

                                            <td class="value"></td>
                                            <td class="value">{{item.dealerName}}</td>
                                            <td class="value">{{getDealType(item.dealType)}}</td>
                                            <td class="value" style="direction: rtl;">
                                                <a *ngIf="isValidDealerUrl(item.rawUrl)" target="_blank"
                                                    href="{{formatDealerUrl(item.rawUrl)}}">{{item.title}}</a>

                                                <span *ngIf="!isValidDealerUrl(item.rawUrl)">{{item.title}}</span>
                                            </td>
                                            <td class="value">
                                                <a target="_blank"
                                                    href="{{item.carFindMatchedIdUrl}}">{{item.carFindMatchedIdInfo}}</a>
                                                <div [innerHTML]="formatListValue(item.notExacRecommendationsInfo)">
                                                </div>
                                            </td>
                                            <td class="value">{{getValue(item.engineCapacity)}}</td>
                                            <td class="value">{{getValue(item.d01.toLocaleString())}}</td>
                                            <td class="value">{{getValue(item.d00.toLocaleString())}}</td>
                                            <td class="value">{{getValue(item.m00.toLocaleString())}}</td>
                                            <td class="value">{{item.model}}</td>
                                            <td class="value">{{item.availableColors}}</td>
                                            <!-- <td [innerHTML]="formatListValue(item.notExacRecommendationsInfo)"></td> -->
                                        </tr>
                                        <h4 *ngIf="similarVersions !== null && similarVersions.length > 0">Similar Versions:</h4>
                                        <tr *ngFor="let item of similarVersions; let i=index;">
                                            <td class="value">{{i + 1}}</td>
                                            <td class="value">{{formatDealerId(item.id)}}</td>

                                            <td class="value"></td>
                                            <td class="value">{{item.dealerName}}</td>
                                            <td class="value" style="direction: rtl;">
                                                <a *ngIf="isValidDealerUrl(item.rawUrl)" target="_blank"
                                                    href="{{formatDealerUrl(item.rawUrl)}}">{{item.title}}</a>

                                                <span *ngIf="!isValidDealerUrl(item.rawUrl)">{{item.title}}</span>
                                            </td>
                                            <td class="value">
                                                <a target="_blank"
                                                    href="{{item.carFindMatchedIdUrl}}">{{item.carFindMatchedIdInfo}}</a>
                                                <div [innerHTML]="formatListValue(item.notExacRecommendationsInfo)">
                                                </div>
                                            </td>
                                            <td class="value">{{item.engineCapacity}}</td>
                                            <td class="value">{{item.d01.toLocaleString()}}</td>
                                            <td class="value">{{item.d00.toLocaleString()}}</td>
                                            <td class="value">{{item.m00.toLocaleString()}}</td>
                                            <td class="value">{{item.model}}</td>
                                            <td class="value">{{item.availableColors}}</td>
                                            <!-- <td [innerHTML]="formatListValue(item.notExacRecommendationsInfo)"></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <span style="padding-top: 150px"></span>
                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>

</div>