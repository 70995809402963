<div class="container-fluid p-0">
  <div>
    <div class="col-md-12 col-12 p-0">
      <div class="row models-content">
        <span class="title">
          {{title}}
        </span>
      </div>
      <div class="row models-content">
        <div *ngFor="let model of ManufacturerModels; let i=index;"
          class="col-md-3 col-4 video-thumbnail-container p-0">
          <a href="{{getUrl(model)}}" style="position: relative; background-color:yellow">
            
            <!-- <img class="car-image point" style="position: absolute; bottom: 0px;" 
                    src="{{getImageUrl(model)}}" (error)="getDefaultImageUrl($event,model.bodyType)" alt="{{getImageAlt(model)}}" title="{{getImageTitle(model)}}" /> -->

            <div class="ytImgWrapper">
              <div id="videoWrapper" class="ytImgThumbBox">
                <!-- <iframe frameBorder="0" width="100%" height="130px" style="border-radius: 6px;"
                        [src]="getVideoUrl(model.videoUrl)">
                      </iframe> -->
                <img class="video-thumbnail ytImgThumbImg" alt="{{getImageAlt(model)}}" title="{{getImageTitle(model)}}"
                  [src]="getVideoThumbnail(model.videoUrl)" />
                <!-- <div id="videoOverlay"></div> -->
              </div>
            </div>

            <div class="model-name">
              <span>{{model.groupName}}</span><br />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>