<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper">
    <div class="container-fluid page-content-wrapper view-container">
      <div class="row wizard-wrapper features-container" style="padding-top: 150px;">
        <app-view-breadcrumbs [items]="generateBreadcrumbs()"></app-view-breadcrumbs>
      </div>
      <div class="row" style="padding-top: 25px;">
        <app-view-title [title]="viewTitle" [items]="generateBreadcrumbs()"></app-view-title>
      </div>
      <div class="row p-0">
        <span class="description-container">
          <span class="d-none d-xl-inline">
            קארפיינד מאפשר לכם לבצע השוואת רכבים בצורה פשוטה ונוחה. בחרו לפי יצרן ודגם את הרכבים ביניהם אתם מתלבטים ובצעו
            השוואת רכבים.
            קארפיינד מאפשר לכם לבצע השוואת רכבים בצורה פשוטה ונוחה. בחרו לפי יצרן ודגם את הרכבים ביניהם אתם מתלבטים
            ובצעו השוואת רכבים. לפי המפרט הטכני של הרכבים, מחירים, יתרונות וחסרונות, צריכת דלק, תוספי אבזור ועוד. בנוסף,
            מערכת השוואת הרכבים שלנו מאפשרת לכם להציג רק את ההבדלים בין הדגמים הנבחרים
          </span>
        </span>
      </div>
      <div class="row wizard-wrapper features-container">
        <div *ngFor="let car of carInfo; let i=index;" [ngClass]="getItemStyle()">
          <app-car-comparison-details [carModelInfo]="car" [carModelInfoIndex]="i"></app-car-comparison-details>
        </div>
        <div *ngFor="let car of blankCarModelsCount; let i=index;" [ngClass]="getItemStyle()">
          <app-car-comparison-details></app-car-comparison-details>
        </div>
      </div>
    </div> 
    <app-footer></app-footer>
  </div>