import { Component, OnInit, ElementRef, OnDestroy, Inject } from '@angular/core';
import { CarDetailsService } from '../../../services/car-details.service';
import { CarGroupInfo, SubModelInfo } from '../../../models/CarGroupInfo';
import { MessageService } from '../../../services/message.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { TagItem } from '../../../../app/models/tagItem';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';
import { HttpService } from '../../../../app/services/http.service';
import { ConfigurationInfo } from '../../../../app/models/configurationInfo';
declare let ga: Function;
declare var $: any;

@Component({
  selector: 'app-car-details-view',
  templateUrl: './car-details-view.component.html',
  styleUrls: ['./car-details-view.component.css']
})
export class CarDetailsViewComponent implements OnInit, OnDestroy {
  id: number;
  manufacturer: string;
  pmodel: string;
  model: string;
  bodyType: string;
  engine: string;
  doors: number;
  year: number;
  carInfo: CarGroupInfo;
  CarFindCarVersionInfo: SubModelInfo[];
  blankCarModelsCount: number[];
  private sub: any;
  showNext = false;
  showPrev = false;
  showDiffTitle: string;
  itemsPerPage: number;
  isScrolling: any = null;
  subscription: Subscription;
  messages: any[] = [];
  private eventsSubscriptionHandler = Subscription.EMPTY;
  isMobile: boolean;
  hasImages: boolean;
  hasVideo = true;

  constructor(
    public carDetailsService: CarDetailsService
    , private messageService: MessageService
    , private route: ActivatedRoute
    , private router: Router
    , private _elementRef: ElementRef
    , public utilsService: UtilsService
    , private meta: Meta
    , public googleAnalyticsService: GoogleAnalyticsService
    , private titleService: Title
    , private httpClient: HttpService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'conguration-page');
      this.isMobile = this.utilsService.IsMobile();
    });
  }

  ngOnInit() {
    this.utilsService.setSessionValue('page', 'config');

    this.meta.updateTag({ name: 'description', content: 'מציאת המחיר הזול בישראל לכל רכב חדש מיבואן / 0 ק"מ ליסינג' });
    this.utilsService.createCanonicalURL();

    this.itemsPerPage = this.isMobile ? 2 : 4;

    this.sub = this.route.params.subscribe(params => {
      this.manufacturer = encodeURIComponent(this.router.url.split('/')[1]);
      this.pmodel = encodeURIComponent(params.pmodel);
      this.model = encodeURIComponent(params.model);
      this.bodyType = encodeURIComponent(params.bodyType);
      this.engine = encodeURIComponent(params.engine);
      this.year = +encodeURIComponent(params.year);

      if (params.doors != undefined) {
        this.doors = +params.doors.replace('-doors', '');
      } else {
        this.doors = 0;
      }

      if (this.router.url.split('/')[1].includes('%20')) {
        this.utilsService.redirect(this.router.url.replace('%20', '-'), 301);
      }

      this.messageService.sendMessage('loading-start');
      this.carDetailsService.getCarDetailsData(this.manufacturer, this.pmodel, this.model, this.bodyType, this.engine, this.doors, this.year).subscribe((data: CarGroupInfo) => {
        this.carInfo = data;
        if (this.carInfo.subModels.length == 0) {
          const manf = this.manufacturer.replace('-', ' ');
          const model = this.pmodel.replace('-', ' ');
          this.utilsService.redirect(`/${manf}/${model}`, 301);
        } else {
          this.hasImages = this.carInfo.hasImages;
          this.hasVideo = this.carInfo.videourl != "";

          this.utilsService.setSessionValue('whatsappText', `${encodeURIComponent(this.carInfo.engManufacturerName === undefined ? this.carInfo.engManufacturerName : this.carInfo.manufacturerName)} ${encodeURIComponent(this.carInfo.engModelName === undefined ? this.carInfo.modelName : this.carInfo.engModelName)}`);

          const metaTitleValue = `${this.carInfo.manufacturerName} ${this.carInfo.modelName} ${this.carInfo.engManufacturerName} ${this.carInfo.engModelName} ${this.carInfo.bodyType}  ${this.carInfo.engine} ${this.year}`;
          const titleValue = `${this.carInfo.manufacturerName} ${this.carInfo.modelName} ${this.carInfo.engManufacturerName} ${this.carInfo.engModelName} ${this.carInfo.bodyType}  ${this.carInfo.engine} ${this.year}`;
          const description = `${metaTitleValue} הדגמים החדשים בעסקאות מימון מגוונות ביניהן: 0 ק"מ, מבצעים, מבצעי יבואן ועוד. לכל המידע על ${metaTitleValue} מחירון, צריכת דלק ועוד >>`;
          this.utilsService.addMetaDescriptionTag(description);

          this.utilsService.generatePageTitle(`${titleValue} במחיר המפורסם הזול בישראל`);

          this.id = this.carInfo.subModels[0].configurationId;
          this.setFacebookMeta();

          this.resetPage();

          const hebManufacturer = this.carInfo.manufacturerName;
          const hebModel = this.carInfo.modelName;
          const engine = this.carInfo.engine;
          const bodyType = this.carInfo.bodyType;
          const breadcrumbPath: BreadcrumbSchemeItem[] = [];
          breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
          breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(hebManufacturer, this.manufacturer));

          if (this.carInfo.groupName !== null) {
            breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(this.carInfo.groupName, this.carInfo.groupName));
          } else {
            breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(this.carInfo.engParentModelName, this.carInfo.engParentModelName));
          }

          breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(`${bodyType}, ${engine}`, '', true));
          this.utilsService.setBreadcrumbScheme(breadcrumbPath);
          //

          this.messageService.sendMessage('car-deailes-loading-data-done');
          this.messageService.sendMessage('loading-stop');
        }
      },
        (error) => {
          this.messageService.sendMessage('loading-stop');

          const manf = this.manufacturer.replace('-', ' ');
          const model = this.pmodel.replace('-', ' ');
          this.httpClient.getConfigurationInfoByModel(manf, model, 0).subscribe((data: ConfigurationInfo) => {
            if (data === null) {
              this.utilsService.redirect(`/${manf}`, 301);
            }
            else {
              this.utilsService.redirect(`/${manf}/${model}`, 301);
            }
          });
        });
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-show-prev') {
        this.getPrevPage();
      } else if (message.text === 'car-deailes-show-next') {
        this.getNextPage();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  setFacebookMeta() {
    const description = `${this.carInfo.name} - חוות דעת קארפיינד: רכב במחיר ${this.carInfo.priceIsAboveGroupAvg ? 'מעל' : 'מתחת'} לממוצע ${this.carInfo.categoryName}${this.carInfo.description === null ? '' : ', ' + this.carInfo.description}`;
    const image = '' //TODO;
    this.utilsService.setOGMeta(description, image, this.utilsService.getPageUrl(), 'אתר הרכב הגדול בישראל לעסקאות רכב חדש ו 0 ק"מ');
  }

  highlightFeatureRow(id, status) {
    const domElements = this._elementRef.nativeElement.querySelectorAll(`#${id}`);
    domElements.forEach(element => {
      element.style.backgroundColor = status ? '#F4F4F4' : 'white';
    });
  }

  colorValue(isHighest: boolean) {
    return "car-feature-value";
  }

  getFeatureTitle(key: string, car: SubModelInfo, carModelInfoIndex: number) {
    return carModelInfoIndex > 0 ? key : this.getFeature(key, car).title;
  }

  getFeatureValue(key: string, car: SubModelInfo) {
    const value = this.getFeature(key, car).value.toLocaleString();
    const rawValue = this.getFeature(key, car).rawValue;
    return value === '0' || value === '' || value === undefined || rawValue === 0 || rawValue === 0.0 || rawValue === '-' ? '' : value;
  }

  hasFeatureValue(key: string, car: SubModelInfo) {
    const value = this.getFeature(key, car).value;
    const rawValue = this.getFeature(key, car).rawValue;
    const isValid = !(value === '0' || value === '' || value === undefined || rawValue === 0 || rawValue === 0.0 || rawValue === '-');
    return isValid;
  }

  getDiffFeatureTitle(key: string, car: SubModelInfo) {
    return this.getDiffFeature(key, car).title;
  }

  getDiffFeatureValue(key: string, car: SubModelInfo) {
    return this.getDiffFeature(key, car).value.toLocaleString();
  }

  getFeature(key: string, car: SubModelInfo) {
    const feature = car.features.find(x => x.key === key);
    return feature;
  }

  getDiffFeature(key: string, car: SubModelInfo) {
    const feature = car.differentFeatures.find(x => x.key === key);
    return feature;
  }

  showNavigation() {
    this.messageService.sendMessage('car-deailes-navigation-refresh');
  }

  getPrevPage() {
    this.CarFindCarVersionInfo = this.carDetailsService.getPrevSubModels();
    this.calculateSpace();
    this.showNavigation();
  }

  resetPage() {
    this.CarFindCarVersionInfo = this.carDetailsService.resetSubModels();
    this.CarFindCarVersionInfo = this.CarFindCarVersionInfo.slice(0, this.itemsPerPage);
    this.calculateSpace();
    this.showNavigation();
  }

  getNextPage() {
    this.CarFindCarVersionInfo = this.carDetailsService.getNextSubModels();
    this.calculateSpace();
    this.showNavigation();
  }

  calculateSpace() {
    this.blankCarModelsCount = Array(this.itemsPerPage - this.CarFindCarVersionInfo.length).fill(0).map((x, i) => i);
  }

  onFilterTagRemove(tag: TagItem) {
    const params: string[] = [];

    if (tag.value !== this.manufacturer) {
      params.push(this.manufacturer);
    }
    if (tag.value !== this.model) {
      params.push(this.model);
    }
    if (tag.value !== this.engine) {
      params.push(this.engine);
    }
    if (tag.value !== this.bodyType) {
      params.push(this.bodyType);
    }

    const url = `/new-cars/${params.join('/')}`;
    this.router.navigateByUrl(url);
  }

  generateTags(): TagItem[] {
    const tags: TagItem[] = [];

    if (this.carInfo !== undefined) {
      const hebManufacturer = this.carInfo.manufacturerName;
      let hebModel = this.carInfo.groupName;
      if (hebModel === null) {
        hebModel = this.carInfo.engParentModelName;
      }

      const engModel = this.carInfo.engParentModelName;
      const engine = this.carInfo.engine;
      const bodyType = this.carInfo.bodyType;

      let modelName = hebModel;
      if (modelName == '') {
        modelName = this.carInfo.modelName;
      }

      this.manufacturer = this.manufacturer.replace(/ /g, '-').replace('%26', '&');

      tags.push(this.utilsService.generateNewTag(hebManufacturer, this.manufacturer, false));
      tags.push(this.utilsService.generateNewTag(hebModel, engModel.replace(/ /g, '-'), false));
      tags.push(this.utilsService.generateNewTag(`${hebModel} ${bodyType} ${engine}`, '', false));
    }

    return tags;
  }

  getDealerType(type: number) {
    // return this.utilsService.getDealerTypeValue(type);
    return 'מחירון';
  }
}
