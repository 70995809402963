import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent implements OnInit {

  showDialog = false;
  newUserCookie = 'new-user';

  constructor(private router: Router
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private cookieService: CookieService
    ) { }

  ngOnInit() {
    const data = this.cookieService.get(this.newUserCookie);

    if (isPlatformBrowser(this.platformId)) {
      if (data === '' || data === 'NaN') {
        this.showDialog = true;
        this.googleAnalyticsService.AddPageNavigation('welcome-dialog');
      }
      this.cookieService.set(this.newUserCookie, 'false', 60);
    }
  }

  confirm() {
    this.showDialog = false;
  }

  goToMoreDetails() {
    this.showDialog = false;
    if (isPlatformBrowser(this.platformId)) {
      window.open('/about', '_blank');
    }
  }
}
