<div (mouseenter)="itemMouseEnter()" (mouseleave)="itemMouseLeave()">

  <div id="mydiv" class="sticky-feature-list">
    <div class="comparison-item item-container">
      <div style="padding-bottom: 15px">
        <img data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="isPonterEnabled"
          [src]="getSelectionArrowStatus(isModelSelectionEnabled)" />
        <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          [ngClass]="getManufacturerClass()">{{modelName}}</span>
        <div class="dropdown-menu dropdown-menu-right" style="direction:rtl">
          <a *ngFor="let item of manufacturerList; let i=index;" class="dropdown-item"
            (click)="GetConfigurationList(item.title)">{{item.title}}</a>
        </div>
        <img *ngIf="carModelInfo !== null && isSelected" (click)="RemoveSubModel(carModelInfo.id)"
          style="float: left; padding-left: 13px" [ngClass]="isPonterEnabled" src="assets/images/svg/close-copy.svg" />
      </div>
      <div style="padding-bottom: 15px">
        <img [ngClass]="isPonterEnabled" [src]="getSelectionArrowStatus(isConfigurationSelectionEnabled)"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
        <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          [ngClass]="getTextClassStatus(isConfigurationSelectionEnabled)">{{configurationName}}</span>
        <div class="dropdown-menu">
          <a *ngFor="let item of modelList; let i=index;" class="dropdown-item"
            (click)="GetSubModelList(item.title, item.id)">{{item.title}}</a>
        </div>
      </div>
      <div>
        <img [ngClass]="isPonterEnabled" [src]="getSelectionArrowStatus(isSubModelSelectionEnabled)"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
        <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          [ngClass]="getTextClassStatus(isSubModelSelectionEnabled)">{{subModelName}}</span>
        <div class="dropdown-menu">
          <a *ngFor="let item of subModelList; let i=index;" (click)="SelectSubModel(item.title, item.id)"
            class="dropdown-item">{{item.title}}</a>
        </div>
        <img *ngIf="carModelInfo !== null && isSelected" style="float: left; padding-left: 14px" class="point"
          (click)="setFavorite(carModelInfo.subModels[0])"
          [src]="toggleFavoriteStyle(carModelInfo.subModels[0].subModelId)" />
      </div>
    </div>
    <div class="Mask d-none d-xl-block"></div>
  </div>

  <div
    *ngIf="(carModelInfo !== null && carModelInfo.subModels[0].hasMainImage) || (carModelInfo !== null && !carModelInfo.subModels[0].hasMainImage && carModelInfo.subModels[0].videoUrl == '')"
    class="car-image-container">
    <div>
      <a [routerLink]="getConfigurationUrl(carModelInfo)">
        <img class="car-image point" (error)="getDefaultImageUrl($event)" src="{{getImageUrl(carModelInfo, 'l')}}" />
      </a>
    </div>
  </div>

  <div
    *ngIf="carModelInfo !== null && !carModelInfo.subModels[0].hasMainImage && carModelInfo.subModels[0].videoUrl !== ''"
    id="videoWrapper" class="ytImgWrapper">
    <div class="ytImgThumbBox">
      <a [routerLink]="getConfigurationUrl(carModelInfo)" class="pointer">
        <img class="ytImgThumbImg" [src]="getVideoThumbnail(carModelInfo.subModels[0].videoUrl)" />
      </a>
    </div>
  </div>

  <div *ngIf="carModelInfo !== null" class="item-container">
    <div>
      <div style="padding-top: 5px">

        <ng-template #dealerPriceButton>
          <button class="button-price point" style="border-radius: 6px; margin-top: 27px;"
            (click)="openPriceDialog($event, carModelInfo.subModels[0].discountDealerPrice, carModelInfo.subModels[0].configurationId)">
            <div>
              <span class="car-price-text car-price-value-padding-right" style="float: right;">מבצע
                יבואן</span>
              <span class="car-price-value car-price-value-padding car-price-value-padding-left"
                style="float: left;">{{carModelInfo.subModels[0].discountDealerPrice.toLocaleString()}}
                <span class="car-price-symbol-value">₪</span></span>
            </div>
          </button>
        </ng-template>



        <button
          *ngIf="carModelInfo.subModels[0].discountDealerPrice == 0 || carModelInfo.subModels[0].discountDealerPrice >= carModelInfo.subModels[0].officalDealerPrice; else dealerPriceButton"
          class="button-price point" style="border-radius: 6px; margin-top: 27px;"
          (click)="openPriceDialog($event, carModelInfo.subModels[0].discountDealerPrice, carModelInfo.subModels[0].configurationId)">


          <div>
            <span class="car-price-text car-price-value-padding car-price-value-padding-right" style="float: right;">
              {{getDealerType(carModelInfo.subModels[0].dealerType)}}</span>
            <span class="car-price-value car-price-value-padding-left"
              style="float: left;">{{getPrice(carModelInfo.subModels[0].officalDealerPrice)}}
              <span class="car-price-symbol-value">₪</span></span>
          </div>
        </button>

        <div
          class="price-meta-info-container d-flex align-items-center justify-content-center base-price-padding-right">

          <!-- <img src="assets/images/svg/group-3.svg" data-toggle="popover" data-placement="top"
            style="padding-left: 5px; padding-bottom: 10px;"
            data-content="הסוכנויות שלנו הם יבואני רכב וחברות ליסינג כדוגמת אלבר, אוויס ואחרים. רכב 0 ק׳׳מ הינו:       רכב חדש שספק (למשל חברת ליסינג) קונה מיבואן, ומוכר לאדם פרטי - נקרא רכב 0 קילומטר, מאחר והרכב לא צבר קילומטרז. היתרון ברכישת רכב בעסקת 0 קילומטר (עם או בלי מימון): רכישת רכב חדש עם אחריות מהיבואן, במחיר הזול (עד עשרות אלפי ₪) ביחס לרכישת אותו הרכב ישירות מהיבואן)" /> -->


          <div *ngIf="hasDealerDiscount;" class="price-meta-info-container">
            <span class="car-price-text delaer-price-padding">
              {{getDealerType(carModelInfo.subModels[0].dealerType)}}
            </span>
            <span class="car-price-text car-model-price-title car-price-value base-price-padding-left"
              style="float: left;">
              {{carModelInfo.subModels[0].officalDealerPrice.toLocaleString()}}<span class="car-price-symbol-value">
                ₪</span>
            </span>
          </div>

        </div>


      </div>

      <div style="height: 70px;">
        <div *ngIf="(carModelInfo.subModels[0].zeroKMPriceCount > 0); else noZeroKMDealersTitle">
          <button class="button-price point" style="border-radius: 6px;"
            (click)="openPriceDialog($event, carModelInfo.subModels[0].lowerZeroKMPrice, carModelInfo.subModels[0].configurationId)">

            <div>
              <span style="float: right;"
                class="car-price-text car-price-value-padding-right">{{gerDealersCount()}}</span>
              <span class="car-price-value car-price-value-padding-left"
                style="float: left;">{{carModelInfo.subModels[0].lowerZeroKMPrice.toLocaleString()}}
                <span class="car-price-symbol-value">₪</span></span>
            </div>
          </button>
        </div>

        <ng-template #noZeroKMDealersTitle>
          <div *ngIf="carModelInfo.subModels[0].hasSpecialDeals">
            <button (click)="openCheaperDeals($event)" class="button-price point" style="border-radius: 6px;">
              <span class="button car-model-price point">
                מבצע מיוחד ל{{modelName}}
              </span>
            </button>
          </div>
        </ng-template>

        <div *ngIf="!carModelInfo.subModels[0].hasSpecialDeals && !carModelInfo.subModels[0].hasZeroKMPrice">
          <button (click)="openCheaperDeals($event)" class="button-price point" style="border-radius: 6px;">
            <span>
              רכבים דומים במבצע
            </span>
          </button>
        </div>
      </div>

      <div style="height: 70px;">
        <div>
          <button
            (click)="openMonthlyPriceDialog($event, carModelInfo.subModels[0].monthlyPayment, carModelInfo.subModels[0].configurationId)"
            class="button-price point" style="border-radius: 6px;">
            <div>
              <span class="car-price-text car-price-value-padding-right" style="float: right;">תשלום חודשי </span>
              <span class="car-price-value car-price-value-padding car-price-value-padding-left" style="float: left;">
                {{carModelInfo.subModels[0].monthlyPayment.toLocaleString()}}<span class="car-price-value"> ₪</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div style="padding-top: 15px">
      <span class="features-title">יתרונות ביחס לרכבי
        {{utilsService.bodyTypeCustomValueConverter(carModelInfo?.bodyType)}} {{carModelInfo?.engine}}</span>
    </div>
    <div class="features-section">
      <span class="features-text">
        {{getPositivevFeaturesSummary(carModelInfo.positiveFeatures)}}
      </span>
    </div>
    <div style="padding-top: 15px">
      <span class="features-title">חסרונות</span>
    </div>
    <div class="features-section">
      <span class="features-text">
        {{getNegativeFeaturesSummary(carModelInfo.negativeFeatures)}}
      </span>
    </div>
    <div *ngIf="carModelInfo.description !== null">
      <div style="padding-top: 15px">
        <span class="features-title">שורה תחתונה</span>
      </div>
      <div class="features-section">
        <span class="features-text">
          {{carModelInfo.description}}.
        </span>
      </div>
    </div>
    <div style="padding-top: 35px">
      <div class="diff-checkbox-text">
        <span *ngIf="carModelInfoIndex == 0; else showDiffTitle" class="float-left point" style="height: 50px">הצג רק
          הבדלים</span>
        <ng-template #showDiffTitle>
          <th scope="row" class="car-feature-main-title float-left diff-section-padding point">&nbsp;</th>
        </ng-template>
        <div class="diff-checkbox">
          <div *ngIf="(carModelInfoIndex == 0 && isMobile) || (carModelInfoIndex == 0 && !isMobile)"
            class="Checkbox point">
            <input type="checkbox" (change)="toggleDiff()" />
            <div class="Checkbox-visible"></div>
          </div>
        </div>

      </div>
      <div class="all-features-container">
        <table *ngIf="showDiff" class="table table-borderless">

          <tbody>
            <tr *ngFor="let feature of carModelInfo.subModels[0].differentFeatures;">
              <div>
                <span *ngIf="carModelInfoIndex == 0; else diffTitle" class="car-feature-title">{{feature.title}}</span>
                <ng-template #diffTitle>
                  <span class="car-feature-title">&nbsp;</span>
                </ng-template>
                <span class="car-feature-value">
                  {{feature.value}}
                </span>
              </div>
            </tr>
          </tbody>
        </table>

        <table *ngIf="showDiff === false" class="table table-borderless">

          <tbody>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('BodyType')"></span>
                <span id="f-EngineType" class="car-feature-value">
                  {{utilsService.bodyTypeCustomValueConverter(getFeatureValue('BodyType').toString())}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('LicensingGroup')"></span>
                <span id="f-LicensingGroup" class="car-feature-value">
                  {{getFeatureValue('LicensingGroup')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('LicensingGroupPrice')"></span>
                <span id="f-LicensingGroupPrice" class="car-feature-value">
                  {{getFeatureValue('LicensingGroupPrice')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('UsageTax')"></span>
                <span id="f-UsageTax" class="car-feature-value">
                  {{getFeatureValue('UsageTax')}}
                </span>
              </div>
            </tr>
            <tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('CountryOfOrigin')"></span>
                <span id="f-CountryOfOrigin" class="car-feature-value">
                  {{getFeatureValue('CountryOfOrigin')}}
                </span>
              </div>
            </tr>
            <tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('DoorNumber')"></span>
                <span id="f-DoorNumber" class="car-feature-value">
                  {{getFeatureValue('DoorNumber')}}
                </span>
              </div>
            </tr>
            <tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('Seats')"></span>
                <span id="f-Seats" class="car-feature-value">
                  {{getFeatureValue('Seats')}}
                </span>
              </div>
            </tr>
            <tr>
          </tbody>
        </table>

        <table *ngIf="showDiff === false" class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" style="padding-right: 0; height: 40px">
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex == 0">מנוע</span>
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex > 0">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('EngineType')"></span>
                <span id="f-EngineType" class="car-feature-value">
                  {{getFeatureValue('EngineType')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('IsHybrid')"></span>
                <span id="f-IsHybrid" class="car-feature-value">
                  {{getFeatureValue('IsHybrid')}}
                </span>
              </div>
            </tr>
            <!-- <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('FuelType')"></span>
                <span id="f-FuelType" class="car-feature-value">
                  {{getFeatureValue('FuelType')}}
                </span>
              </div>
            </tr> -->
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('Drivetrain')"></span>
                <span id="f-Drivetrain" class="car-feature-value">
                  {{getFeatureValue('Drivetrain')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('BHP')"></span>
                <span id="f-BHP" class="car-feature-value">
                  {{getFeatureValue('BHP')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('EngineCapacity')"></span>
                <span id="f-EngineCapacity" class="car-feature-value">
                  {{getFeatureValue('EngineCapacity')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('GreenScore')"></span>
                <span id="f-GreenScore" class="car-feature-value">
                  {{getFeatureValue('GreenScore')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('DegreeOfPollution')"></span>
                <span id="f-DegreeOfPollution" class="car-feature-value">
                  {{getFeatureValue('DegreeOfPollution')}}
                </span>
              </div>
            </tr>
          </tbody>
        </table>

        <table *ngIf="showDiff === false" class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" style="padding-right: 0; height: 40px">
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex == 0">בטיחות</span>
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex > 0">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyEquipmentLevel')"></span>
                <span id="f-SafetyEquipmentLevel" class="car-feature-value">
                  {{getFeatureValue('SafetyEquipmentLevel')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyScore')"></span>
                <span id="f-SafetyScore" class="car-feature-value">
                  {{getFeatureValue('SafetyScore')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasESP')"></span>
                <span id="f-HasESP" class="car-feature-value">
                  {{getFeatureValue('HasESP')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('PedestrianDetection')"></span>
                <span id="f-PedestrianDetection" class="car-feature-value">
                  {{getFeatureValue('PedestrianDetection')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('AdaptiveCruiseControl')"></span>
                <span id="f-AdaptiveCruiseControl" class="car-feature-value">
                  {{getFeatureValue('AdaptiveCruiseControl')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title"
                  [innerHTML]="getFeatureTitle('FailureToMaintainDistanceDetection')"></span>
                <span id="f-FailureToMaintainDistanceDetection" class="car-feature-value">
                  {{getFeatureValue('FailureToMaintainDistanceDetection')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('DeadSpaceDetection')"></span>
                <span id="f-DeadSpaceDetection" class="car-feature-value">
                  {{getFeatureValue('DeadSpaceDetection')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('TrafficSignsDetection')"></span>
                <span id="f-TrafficSignsDetection" class="car-feature-value">
                  {{getFeatureValue('TrafficSignsDetection')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyBeltsSensors')"></span>
                <span id="f-SafetyBeltsSensors" class="car-feature-value">
                  {{getFeatureValue('SafetyBeltsSensors')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('TPMS')"></span>
                <span id="f-TPMS" class="car-feature-value">
                  {{getFeatureValue('TPMS')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('AirbagsNumber')"></span>
                <span id="f-AirbagsNumber" class="car-feature-value">
                  {{getFeatureValue('AirbagsNumber')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('BrakingAuxiliarySystem')"></span>
                <span id="f-BrakingAuxiliarySystem" class="car-feature-value">
                  {{getFeatureValue('BrakingAuxiliarySystem')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('ReverseCamera')"></span>
                <span id="f-ReverseCamera" class="car-feature-value">
                  {{getFeatureValue('ReverseCamera')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('FrontDistanceMonitoring')"></span>
                <span id="f-FrontDistanceMonitoring" class="car-feature-value">
                  {{getFeatureValue('FrontDistanceMonitoring')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasABS')"></span>
                <span id="f-HasABS" class="car-feature-value">
                  {{getFeatureValue('HasABS')}}
                </span>
              </div>
            </tr>
          </tbody>
        </table>

        <table *ngIf="showDiff === false" class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" style="padding-right: 0; height: 40px">
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex == 0">אבזור</span>
                <span class="car-feature-main-title" *ngIf="carModelInfoIndex > 0">&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('AutomaticHighLightingControl')"></span>
                <span id="f-AutomaticHighLightingControl" class="car-feature-value">
                  {{getFeatureValue('AutomaticHighLightingControl')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasAlloyWheels')"></span>
                <span id="f-HasAlloyWheels" class="car-feature-value">
                  {{getFeatureValue('HasAlloyWheels')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasSunroof')"></span>
                <span id="f-HasSunroof" class="car-feature-value">
                  {{getFeatureValue('HasSunroof')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('PowerWindowsNumber')"></span>
                <span id="f-PowerWindowsNumber" class="car-feature-value">
                  {{getFeatureValue('PowerWindowsNumber')}}
                </span>
              </div>
            </tr>
            <tr>
              <div>
                <span class="car-feature-title" [innerHTML]="getFeatureTitle('AutomaticLightingOnDriving')"></span>
                <span id="f-AutomaticLightingOnDriving" class="car-feature-value">
                  {{getFeatureValue('AutomaticLightingOnDriving')}}
                </span>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="carModelInfo === null" style="height: 200px">

  </div>
</div>