import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.css']
})
export class UtilitiesComponent implements OnInit {

  constructor(public utilsService: UtilsService) { }

  ngOnInit(): void {

    const htmlTitle = 'שימושונים | CarFind השוואת רכבים חדשים או  0 ק"מ';
    const htmlDescription = 'ריכזנו עבורכם מספר אתרים אינפורמטיביים ופונקציונאלים כדי לסייע לכם לקבל את המידע הרלוונטי בתחום הרכב בישראל. היכנסו לפרטים >>';

    this.utilsService.generatePageTitle(htmlTitle);
    this.utilsService.addMetaDescriptionTag(htmlDescription);
  }

}
