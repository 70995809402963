<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper">

    <div class="container-fluid page-content-wrapper">
      <div class="row" style="padding-top:150px">
        <div class="col-md-12 col-12">
          <div class="row">
            <span class="title">נא כתוב את הסיבה בגינה אינך יכול לספק ללקוח את הרכב המבוקש</span>
          </div>
          <div class="row" style="padding-top:30px">
              <input type="text" [(ngModel)]="comment" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom" style="height: 36px; width: 700px" id="name"
              name="first_name" placeholder="סיבה" />
          </div>
          <div class="row" style="padding-top: 50px">
            <button class="button-submit" (click)="updateResponse()">
              <span>שליחה</span>
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>
  <app-footer></app-footer>

</div>