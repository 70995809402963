import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SharedDataService {

    static EngManufacturerList: string[] = ['audi','opel','aiways','isuzu','alfa-romeo','aston-martin','bmw','bentley','gmc','jeep','gac','geely','dacia','dodge','ds','dfsk','honda','volvo','toyota','tesla','jaguar','hyundai','land-rover','lexus','mg','man','maserati','mazda','mitsubishi','maxus','mercedes','nissan','ssangyong','subaru','suzuki','seat','citroen','smart','centro','skoda','seres','volkswagen','ford','porsche','fiat','peugeot','ferrari','cadillac','kia','chrysler','renault','chevrolet','infiniti','mini','cupra','lamborghini','genesis','lincoln','abarth','levc','lynk-&-co','skywell','karma','hongqi'];
    static DealTypes: string[] = ['new-cars', 'car-deals', 'leasing-0-km', 'importer-deals', 'used-cars'];
    static BodyTypesValues: string[] = ['coupe-converible', 'pickup-van', 'small-hatchback', 'sedan', 'suv', '7plus-seats'];
    static EngineValues: string[] = ['benzin', 'diesel', 'hybrid', 'plug-in', 'electric'];
    static DrivetrainValues: string[] = ['4x4', '2x4'];
    static TransmissionValues: string[] = ['manual', 'automatic'];
    static SeatsValues: string[] = ['1-row', '2-rows', '3-plus-rows'];

    static bodyTypesCatalogFilterValues: Record<string, Partial<string>>;
    static dealTypesCatalogFilterValues: Record<string, Partial<string>>;
    static dealTypesCatalogFilterPluralValues: Record<string, Partial<string>>;
    static manufacturersCatalogFilterValues: Record<string, Partial<string>>;
    static engineCatalogFilterValues: Record<string, Partial<string>>;
    static drivetrainCatalogFilterValues: Record<string, Partial<string>>;
    static yearsFilterValues: Record<string, Partial<string>>;
    static transmissionCatalogFilterValues: Record<string, Partial<string>>;
    static seatsCatalogFilterValues: Record<string, Partial<string>>;

    static initialize() {
        this.bodyTypesCatalogFilterValues = {
            'coupe-converible': 'קבריולה + קופה',
            'pickup-van': 'מסחרי + טנדר',
            'small-hatchback': 'קטן והאצ׳בק',
            'sedan': 'סדאן',
            'suv': 'פנאי SUV',
            '7plus-seats': '7+ מקומות',
        };

        this.dealTypesCatalogFilterValues = {
            'new-cars': 'רכבים חדשים', //TODO: 2023
            'car-deals': 'מבצעי רכב', //TODO: 2023
            'leasing-0-km': 'רכב 0 ק"מ', //TODO: 2023
            'importer-deals': 'מבצעי יבואן', //TODO: 2023
            'used-cars': 'רכבים משומשים',
        };

        this.dealTypesCatalogFilterPluralValues = {
            'new-cars': 'רכבים חדשים',
            'car-deals': 'רכבים במבצע',
            'leasing-0-km': 'רכבים 0 ק"מ',
            'importer-deals': 'מבצעי יבואן',
        };

        this.manufacturersCatalogFilterValues = {
            toyota: 'טויוטה',
            hyundai: 'יונדאי',
            kia: 'קיה',
            chevrolet: 'שברולט',
            dacia: 'דאצ׳יה',
            honda: 'הונדה',
            mazda: 'מאזדה',
            mitsubishi: 'מיצובישי',
            suzuki: 'סוזוקי',
            seat: 'סיאט',
            citroen: 'סיטרואן',
            skoda: 'סקודה',
            volkswagen: 'פולקסווגן',
            peugeot: 'פיג\'ו',
            renault: 'רנו',
            audi: 'אאודי',
            bmw: 'ב.מ.וו',
            mini: 'מיני',
            smart: 'סמארט',
            fiat: 'פיאט',
            nissan: 'ניסאן',
            subaru: 'סובארו',
            infiniti: 'אינפיניטי',
            volvo: 'וולוו',
            lexus: 'לקסוס',
            mercedes: 'מרצדס',
            opel: 'אופל',
            'alfa-romeo': 'אלפא רומיאו',
            jaguar: 'יגואר',
            cadillac: 'קדילאק',
            'aston-martin': 'אסטון מרטין',
            bentley: 'בנטלי',
            maserati: 'מזראטי',
            porsche: 'פורשה',
            ferrari: 'פרארי',
            jeep: 'ג׳יפ',
            mg: 'אם ג\'י',
            ssangyong: 'סאנגיונג',
            ford: 'פורד',
            ds: 'די אס',
            'land-rover': 'לנד רובר',
            chrysler: 'קרייזלר',
            isuzu: 'איסוזו',
            dodge: 'דודג\'',
            man: 'מאן',
            gac: 'גאק',
            cupra: 'קופרה',
            lamborghini: 'למבורגיני',
            genesis: 'ג\'נסיס',
            lincoln: 'לינקולן',
            abarth: 'אברת\'',
            levc: 'אל אי וי סי',
            'lynk-&-co': 'לינק & קו',
            skywell: 'סקיוול',
            karma: 'קארמה',
            tesla: 'טסלה',
            aiways: 'איווייז',
            gmc: 'ג׳ אם סי',
            Hongqi: 'הונגצ\'י',
            dfsk: 'די אף סי קיי',
            maxus: 'מקסוס',
            centro: 'סנטרו',
            seres: 'סרס',
            geely: 'ג׳ילי'
        };

        this.engineCatalogFilterValues = {
            benzin: 'בנזין',
            diesel: 'דיזל',
            hybrid: 'היברידי',
            electric: 'חשמלי',
            'plug-in': 'בנזין / חשמל',
        };

        this.drivetrainCatalogFilterValues = {
            '4x4': '4x4',
            '2x4': '2x4',
        };

        this.yearsFilterValues = {
            '2021': '2021',
            '2022': '2022',
        };

        this.transmissionCatalogFilterValues = {
            manual: 'ידני',
            automatic: 'אוטומטי',
        };

        this.seatsCatalogFilterValues = {
            '1-row': 'שורה אחת',
            '2-rows': 'שתי שורות',
            '3-plus-rows': 'שלוש שורות ויותר',
        };
    }

}

SharedDataService.initialize();

export enum FilterType {
    None = 0,
    Engine,
    Transmission,
    Drivetrain,
    Seats,
    Years,
    Manufacturer,
    BodyTypes,
    Price,
    PaymentPrice,
    MaxPaymentPrice,
    PaymentPriceAdvance,
    PaymentPriceBalloon,
    KML,
    BootCapacity,
    Length,
    DealType,
    General
}
