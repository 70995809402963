<div class="modal fade model-container" id="contact-dialog" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">

    <div class="modal-content" style="width: 406px; height: 580px; border-radius: 15px;">
      <div class="modal-body p-0">
        <img src="assets/images/svg/close-white.svg" data-dismiss="modal" class="dialog-close-button point"
          (click)="reset()" />

        <div *ngIf="isFinished === false">
          <div class="d-flex align-items-center justify-content-center">
            <span class="title">מבצע: {{carInfo.name}} {{subVersionName}}</span>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="padding-top: 15px; width: 100%;">
            <img style="max-height: 86px; max-width: 186px;" src="{{getImageUrl()}}"
              (error)="getDefaultImageUrl($event)" />
          </div>
          <div class="d-flex align-items-center justify-content-center" style="padding-top: 15px">
            <span class="text">לסיוע וייעוץ חינם ללא התחייבות ממומחה רכב</span>
          </div>

          <div *ngIf="!isMobile; else mobileContactButton" class="d-flex align-items-center justify-content-center"
            style="padding-top: 0px">
            <span class="text-red">
              <a (click)="callUs()" href="tel:035245241">03-524-5241</a>
            </span>
            <img src="assets/images/svg/ic-call-24-px.svg" alt="" style="padding-right: 7px; padding-top: 0px;" />
          </div>
          <ng-template #mobileContactButton>
            <div class="d-flex align-items-center justify-content-center" style="padding-top: 15px">
              <button type="button" class="header-button point" style="border-radius: 6px;" (click)="openDialer()">
                <span>
                  התקשרו עכשיו
                </span>
                <img src="assets/images/svg/ic-call-24-px.svg" />
              </button>
            </div>
          </ng-template>

          <div class="d-flex align-items-center justify-content-center padding-section">
            <span class="text">
              או השאירו פרטים ונחזור אליכם בהקדם
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="padding-top: 0px">
            <input type="text" [ngClass]="nameInputClass" style="height: 36px" id="name" name="first_name"
              placeholder="שם" (focus)="setNameFocus()" [(ngModel)]="leadTicket.customerInfo.fullName" />
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <input type="text" [ngClass]="phoneInputClass" style="height: 36px" id="name" name="first_name"
              placeholder="טלפון" (focus)="setPhoneFocus()" [(ngModel)]="leadTicket.customerInfo.phoneNumber" />
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div style="position: relative; width: 180px; padding: 0;">
              <img id="location-popup-opener" src="assets/images/svg/arrow-down.svg" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" (click)="showYeshuvPopup()" class="point" />
              <input type="text" autocomplete="off" [ngClass]="cityInputClass" style="height: 36px; text-indent: 19px;"
                id="city" name="city" placeholder="עיר קרובה" (focus)="setLocationFocus()"
                [(ngModel)]="leadTicket.customerInfo.cityName" />
              <div id="yeshuv-popup-b" class="dropdown-menu dropdown-menu-right">
                <a *ngFor="let item of yeshuvimList; let i=index;" class="dropdown-item"
                  (click)="SelectYeshuv(item.name)">{{item.name}}</a>
              </div>
            </div>
          </div>
          <div *ngIf="hasErrors" style="padding-top: 15px; text-align: center; color: #ff2800;">
            אחד השדות חסר או אינו תקין
          </div>
          <div class="d-flex align-items-center justify-content-center" style="padding-top: 15px">
            <button type="button" class="header-button point" style="border-radius: 6px;" (click)="submit($event)">
              שליחה </button>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="padding-top: 15px">
            <span class="text-footer-title">
              מתלבטים איזה רכב לקנות?
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <span class="text-footer">
              התייעצו חינם עם מומחי הרכב שלנו למציאת
              <br />
              הרכב שמתאים לכם בעסקה המשתלמת ביותר
            </span>
          </div>
        </div>

        <div *ngIf="isFinished === true">
          <div style="margin-top: 10px; margin-right: 20px;"><span class="title" style="line-height: 2.5;">
              תודה!<br />
              מומחי הרכב שלנו יצרו<br />
              איתך קשר בהקדם.<br />
              רוצים לשוחח בחינם וללא התחייבות, עם מומחי הרכב
              שלנו כבר עכשיו?<br />
              <span class="d-none d-xl-inline">
                התקשר עכשיו <a (click)="openDialer()" data-dismiss="modal" href="tel:035245241">03-524-5241</a>
              </span>
              <span class="d-inlinr d-sm-none">
                התקשר עכשיו <a (click)="openDialer()" data-dismiss="modal" href="tel:035245241">03-524-5241</a>
                <br />
                <div class="d-flex align-items-center justify-content-center">
                  <button type="button" style="border-radius: 6px; width: 170px" data-dismiss="modal"
                    (click)="openDialer()">
                    <span>
                      התקשרו עכשיו
                    </span>
                    <img src="assets/images/svg/ic-call-24-px.svg" />
                  </button>
                </div>

              </span>
            </span>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</div>