<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper">

    <div class="container-fluid page-content-wrapper">
      <div class="row page-container">
        <app-view-breadcrumbs [items]="generateBreadcrumbs()"></app-view-breadcrumbs>
      </div>
      <div class="row" style="padding-top: 25px;">
        <app-view-title [items]="generateBreadcrumbs()"></app-view-title>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="row" style="padding-top:15px">
            <span class="text">
              הצעות שיפור? שיתוף פעולה? בעיות? אנחנו מאוד רוצים לשמוע מכם ומחויבים לחזור לפנייתכם תוך יום עסקים.
              <br />
              באפשרותכם ליצור עמנו קשר באמצעות טופס זה או בטלפון

              <span class="d-none d-xl-inline">
                <a (click)="callUs()" href="tel:035245241">03-524-5241</a>
              </span>

              <span class="d-inline d-sm-none">
                <a (click)="callUs()" href="tel:035245241">03-524-5241</a>
                &nbsp;
                <button (click)="openDialer()" type="button" class="header-button point" style="border-radius: 6px;">
                  <span>
                    התקשרו עכשיו
                  </span>
                  <img src="assets/images/svg/ic-call-24-px.svg" />
                </button>
              </span>
            </span>
          </div>
          <form [formGroup]="registerForm" (ngSubmit)="submit()">
            <div class="row" style="padding-top:15px">
              <input type="text" style="height: 36px; width: 300px" [(ngModel)]="contactForm.name" placeholder="שם"
                formControlName="fullName" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom" (focus)="setNameFocus()"
                [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }" />
              <div *ngIf="submitted && f.fullName.errors" class="invalid-form-feedback">
                <div *ngIf="f.fullName.errors.required">שדה חובה</div>
              </div>
            </div>
            <div class="row" style="padding-top:30px">
              <input type="text" style="height: 36px; width: 300px" [(ngModel)]="contactForm.phone" placeholder="טלפון"
                formControlName="phone" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom" (focus)="setPhoneFocus()"
                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
              <div *ngIf="submitted && f.phone.errors" class="invalid-form-feedback">
                <div *ngIf="f.phone.errors.required">שדה חובה</div>
              </div>
              <div *ngIf="submitted && phoneHasErros" class="invalid-form-feedback">
                <div>מספר טלפון אינו תקין</div>
              </div>
            </div>
            <div class="row" style="padding-top:30px">
              <input type="text" style="height: 36px; width: 300px" [(ngModel)]="contactForm.email"
                placeholder="דואר אלקטרוני" formControlName="email" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom"
                (focus)="setEmailFocus()" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-form-feedback">
                <div *ngIf="f.email.errors.required">שדה חובה</div>
                <div *ngIf="f.email.errors.email">כתובת דואר אלקטרוני לא תקינה</div>
              </div>
            </div>
            <div class="row" style="padding-top:30px">
              <input type="text" style="height: 36px; width: 300px" [(ngModel)]="contactForm.message"
                placeholder="הודעה" formControlName="message" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom"
                (focus)="setMessageFocus()" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" />
              <div *ngIf="submitted && f.message.errors" class="invalid-form-feedback">
                <div *ngIf="f.message.errors.required">שדה חובה</div>
              </div>
            </div>
            <div class="row d-flex align-items-center justify-content-center" style="padding-top: 50px">
              <button class="button-submit" style="border-radius: 6px;">
                <span>סיום</span>
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>

  </div>

  <app-footer></app-footer>

</div>