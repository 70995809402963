<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>


    <div class="container-fluid">
        <div class="row" style="padding-top: 150px">
            <div class="col-md-12 p-0">
                <div class="main-image">
                    <span class="main-text">
                    </span>
                </div>
            </div>
        </div>

        <div class="row page-content-wrapper" style="padding-top: 30px; padding-bottom: 30px;">
            <div class="col-md-12 description-container-title p-0">
                <span class="description-title"> {{article.title}}
                </span>
            </div>
        </div>

        <div class="row description-container page-content-wrapper">
            <div class="col-12 p-0 d-none d-xl-block">
                <div>
                    <span class="feature-text">
                        {{article.subtitle}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row page-content-wrapper main-features-container">

            <div class="col-md-9 p-0">

                <div class="text-content">
                    <span class="text" [innerHTML]="article.content">
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>