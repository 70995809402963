<div class="modal fade" id="infoModalCenter" style="z-index: 888888" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body" style="height: 150px;">
        {{content}}
        <br />
        <br />
        <div class="d-flex align-items-center justify-content-center">
          <button type="button" class="header-button point" style="border-radius: 6px; width: 70px;" data-dismiss="modal">סגור</button>
        </div>
      </div>
    </div>
  </div>
</div>