import { Component, OnInit, Inject, AfterContentChecked, OnDestroy } from '@angular/core';
import { BasicContactForm } from '../../../../app/models/basicContactForm';
import { DialogService } from '../../../../app/services/dialog.service';
import { HttpService } from '../../../../app/services/http.service';
import { Router } from '@angular/router';
import { MessageService } from '../../../../app/services/message.service';
import { CarDetailsService } from '../../../../app/services/car-details.service';
import { Subscription } from 'rxjs';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { LeadTicket, CustomerInfo } from '../../../../app/models/leadTicket';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { CityInfo } from './../../../../app/models/cityInfo';
declare var $: any;

@Component({
  selector: 'app-cars-contact-dialog',
  templateUrl: './cars-contact-dialog.component.html',
  styleUrls: ['./cars-contact-dialog.component.css']
})
export class CarsContactDialogComponent implements OnInit, OnDestroy, AfterContentChecked {

  leadTicket: LeadTicket;
  subscription: Subscription;
  messages: any[] = [];
  carInfo: CarGroupInfo = new CarGroupInfo();
  isMonthlyPayment = false;
  requestedPrice = 0;
  subModelId = 0;
  subVersionName: string;
  isMobile = this.utilsService.IsMobile();
  nameInputClass: string;
  phoneInputClass: string;
  cityInputClass: string;
  yeshuvimList: CityInfo[];
  defaultStyle = 'mr-sm-2 mb-sm-0 padding-section text-input align-bottom';
  emptyStyle = `${this.defaultStyle} text-empty`;
  private eventsSubscriptionHandler = Subscription.EMPTY;
  hasErrors = false;
  isFinished = false;

  constructor(
    private dialogService: DialogService
    , public httpClient: HttpService
    , private router: Router
    , private messageService: MessageService
    , public carDetailsService: CarDetailsService
    , private utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-loading-data-done') {
        this.carInfo = carDetailsService.getData();
      } else if (message.text.startsWith('car-deailes-meta-info')) {
        const metaInfo = message.text.split('@');
        this.requestedPrice = metaInfo[1];
        this.subModelId = metaInfo[2];
        this.carInfo = carDetailsService.getData();
        this.isMonthlyPayment = metaInfo[3] == 'true' ? true : false;
      } else if (message.text.startsWith('car-model-meta-info')) {
        const metaInfo = message.text.split('@');
        this.requestedPrice = metaInfo[1];
        this.isMonthlyPayment = metaInfo[3] == 'true' ? true : false;
        this.carDetailsService.getCarDetails(+metaInfo[2]).subscribe((data: CarGroupInfo) => {
          this.carInfo = data;
        });
      } else {
        this.messages = [];
      }
    });
  }

  ngAfterContentChecked(): void {
    if (this.carInfo !== undefined && this.carInfo.subModels !== undefined) {
      const selectedVerson = this.carInfo.subModels.filter(u => u.subModelId == this.subModelId)[0];
      if (selectedVerson != null) {
        this.subVersionName = selectedVerson.name;
      }
    }

    const currentContact = this.dialogService.getCurrentContact();
    if (currentContact !== undefined) {

      if (currentContact.name !== undefined) {
        this.leadTicket.customerInfo.fullName = currentContact.name;
      }

      if (currentContact.phone !== undefined) {
        this.leadTicket.customerInfo.phoneNumber = currentContact.phone;
      }
    }
  }

  ngOnInit() {
    this.leadTicket = new LeadTicket();
    this.leadTicket.customerInfo = new CustomerInfo();
    this.leadTicket.customerInfo.fullName = '';
    this.leadTicket.customerInfo.cityName = '';
    this.leadTicket.customerInfo.fullName = '';
    this.leadTicket.customerInfo.phoneNumber = '';
    this.leadTicket.customerInfo.cityName = '';
    this.leadTicket.requestedPrice = 0;
    this.leadTicket.isMonthlyPayment = false;
    this.nameInputClass = this.defaultStyle;
    this.phoneInputClass = this.defaultStyle;
    this.cityInputClass = `${this.defaultStyle} point`;

    this.getYeshuvimList();
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  submit(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const contactForm = new BasicContactForm();
    contactForm.name = this.leadTicket.customerInfo.fullName;
    contactForm.phone = this.leadTicket.customerInfo.phoneNumber;
    contactForm.city = this.leadTicket.customerInfo.cityName;

    let isValid = true;
    if (contactForm.name === '' || contactForm.name === null) {
      this.nameInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.nameInputClass = this.defaultStyle;
    }

    if (contactForm.phone === '' || contactForm.phone === null || !this.utilsService.isValidPhoneNumber(contactForm.phone)) {
      this.phoneInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.phoneInputClass = this.defaultStyle;
    }

    if (contactForm.city === '' || contactForm.city === null) {
      this.cityInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.cityInputClass = this.defaultStyle;
    }

    if (!isValid) {
      this.hasErrors = true;
      event.preventDefault();
      event.stopImmediatePropagation();
      this.googleAnalyticsService.eventEmitter('PopUp1b', 'Click', '!Submit', 1);
      return false;
    } else {
      this.dialogService.setDialog(target, contactForm, this.router.url);

      let modelType = 'm';
      if (this.subModelId > 0) {
        modelType = 'sm';
        this.leadTicket.CarFindCarVersionId = this.subModelId;
      } else {
        this.leadTicket.CarFindCarVersionId = this.carInfo.id;
      }
      this.leadTicket.CarFindCarVersionName = `${this.carInfo.name}, ${this.carInfo.engine}, ${this.carInfo.bodyType}`;
      this.leadTicket.requestedPrice = this.requestedPrice;
      this.leadTicket.isMonthlyPayment = this.isMonthlyPayment;
      const paymentInfo = this.utilsService.getPaymentInfo();
      this.leadTicket.paymentBalloon = paymentInfo[0];
      this.leadTicket.maxPaymentPrice = paymentInfo[1];
      this.leadTicket.advancePaymentPrice = paymentInfo[2];

      this.httpClient.SendLeadTicket(this.leadTicket, modelType).subscribe((result: any) => {
        console.log('sent!');
        this.googleAnalyticsService.eventEmitter('PopUp1b', 'Click', 'Submit', 1);
        this.googleAnalyticsService.SendCompletedConversionEvent();
        this.isFinished = true;
      });
    }
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.eventEmitter('PopUp1b', 'Click', 'Call', 1);
      this.googleAnalyticsService.SendPhoneEvent();
      document.location.href = 'tel:035245241';
    }
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('PopUp1b', 'Click', 'Call', 1);
  }

  getYeshuvimList() {
    this.utilsService.getYeshuvimList().subscribe((data: CityInfo[]) => {
      this.yeshuvimList = data;
    });
  }

  SelectYeshuv(name) {
    this.leadTicket.customerInfo.cityName = name;
    $('#yeshuv-popup-b').css('display', 'none');
  }

  setLocationFocus() {
    if (isPlatformBrowser(this.platformId)) {
      this.showYeshuvPopup();
      this.googleAnalyticsService.eventEmitter('PopUp1b', 'Focus', 'Location', 1);
    }
  }

  setNameFocus() {
    this.googleAnalyticsService.eventEmitter('PopUp1b', 'Focus', 'Name', 1);
  }

  setPhoneFocus() {
    this.googleAnalyticsService.eventEmitter('PopUp1b', 'Focus', 'Phone', 1);
  }

  showYeshuvPopup() {
    if (isPlatformBrowser(this.platformId)) {
      $('#yeshuv-popup-b').css('display', 'block');
    }
  }

  getDefaultImageUrl(event) {
    if (this.carInfo !== undefined) {
      const imageUrl = this.utilsService.getDefaultImageUrl(this.carInfo.bodyType).toLowerCase();
      event.target.src = imageUrl;
    } else {
      return 'assets/images/pixel.png';
    }
  }

  getImageUrl() {
    try {
      if (this.carInfo !== undefined) {
        const url = `https://carfind-cdn-media.azureedge.net/cars/L/${this.carInfo.images['L']}`;;
        return url;
      } else {
        return 'assets/images/pixel.png';
      }
    } catch (error) {
      return 'assets/images/pixel.png';
    }
  }

  reset() {
    this.isFinished = false;
  }
}
