import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { GoogleAnalyticsService } from '../../../app/services/google-analytics.service';
import { BasicContactForm } from '../../../app/models/basicContactForm';
import { DialogService } from '../../../app/services/dialog.service';
import { HttpService } from '../../../app/services/http.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { FavoritesService } from '../../../app/services/favorites.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../app/services/message.service';
import { UtilsService } from '../../../app/services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  contactForm: BasicContactForm;
  showFooter = true;
  subscription: Subscription;
  private eventsSubscriptionHandler = Subscription.EMPTY;
  srcUrl = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fbusiness.facebook.com%2F2008097506152222%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=539946899481435';

  constructor(private router: Router
    , private ngNavigatorShareService: NgNavigatorShareService
    , public googleAnalyticsService: GoogleAnalyticsService
    , private favoritesService: FavoritesService
    , public httpClient: HttpService
    , private dialogService: DialogService
    , private messageService: MessageService
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.contactForm = new BasicContactForm();

    if (this.utilsService.IsHomePage()) {
    }

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'data-done') {
        this.showFooter = true;
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  goToCarPage(configurationId) {
    this.router.navigate([configurationId]);
  }

  goToPage(path) {
    this.router.navigate(['/' + path]);
  }

  goToLink(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

  shareOnMail() {
    this.goToLink('mailto:email@example.com?subject=Car from CarFind&body=' + location.href);
  }

  share() {
    this.ngNavigatorShareService.share({
      title: 'Share from CarFind',
      text: 'מצאתי אתר שמשווה בין כל עסקאות הרכב בישראל למציאת העסקה הזולה ביותר',
      url: this.router.url
    }).then((response) => {
    })
      .catch((error) => {
        console.log(error);
      });

    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Share', 1);
  }

  showFavoritesList(event) {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Favorite', 1);

    const target = event.target || event.srcElement || event.currentTarget;
    if (this.favoritesService.carIds.length === 0) {
      this.dialogService.showAlert(target, 'show-nofav-alert');
    } else {
      this.dialogService.removeAlert(target);

      this.router.navigate([`/favorites/${this.favoritesService.carIds.join('-')}`]);
    }
  }

  openDialog(event, subject) {
    const target = event.target || event.srcElement || event.currentTarget;
    this.contactForm.subject = subject;
    this.dialogService.setDialog(target, this.contactForm, this.router.url);
  }

  goToDealersPage() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Agencies', 1);
  }

  goToAboutPage() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'About', 1);
  }

  goToFacebook() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Facebook', 1);
    this.goToLink('https://business.facebook.com/%D7%A7%D7%A8%D7%A4%D7%99%D7%99%D7%A0%D7%93-%D7%9E%D7%A6%D7%99%D7%90%D7%AA-%D7%A2%D7%A1%D7%A7%D7%AA-%D7%94%D7%A8%D7%9B%D7%91-%D7%94%D7%9E%D7%A9%D7%AA%D7%9C%D7%9E%D7%AA-%D7%91%D7%99%D7%95%D7%AA%D7%A8-CarFind-2008097506152222/');
  }

  goToInstagram() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Instagram', 1);
    this.goToLink('https://www.instagram.com/carfind_il/');
  }

  goToDealAlert(event, text) {
    this.openDialog(event, text);
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Alert', 1);
  }

  goToCheapestDeals(event, text) {
    this.openDialog(event, text);
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Hazaot', 1);
  }

  goToTestDrive(event, text) {
    this.openDialog(event, text);
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Nesiat', 1);
  }

  goToCarRepository() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Hiztarfu', 1);
  }

  goToCademy() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Cademy', 1);
  }

  goToContactUsPage() {
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'ContactUs', 1);
  }
}
