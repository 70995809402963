import { Component, OnInit, Input, Inject, PLATFORM_ID, AfterViewInit, AfterContentChecked } from '@angular/core';
import { UtilsService } from '../../../../app/services/utils.service';
import { TagItem } from '../../../../app/models/tagItem';
import { CatalogService } from '../../../../app/services/catalog.service';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../../app/services/shared-data.service';
declare var $: any;

@Component({
  selector: 'app-view-tags',
  templateUrl: './view-tags.component.html',
  styleUrls: ['./view-tags.component.css']
})
export class ViewTagsComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @Input() onTagRemove: (tag: TagItem) => boolean;
  @Input() items: TagItem[] = [];

  constructor(
    private utilsService: UtilsService
    , public catalogService: CatalogService
    , private router: Router
    , @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    const filterCount = this.catalogService.getFilterItemsCount();
    if (filterCount >= 4
      || this.router.url.includes(SharedDataService.DealTypes[3])
      || this.router.url.includes('/favorites')
      || this.catalogService.catalogFilter.SortBy !== undefined
      || this.isBlockedFilter()) {
      this.utilsService.encryptLinks('filter-tags');
    }
  }

  ngAfterViewInit() {
  }

  generateUrl(index: number, value: string) {
    if (index > 1) {
      return `/${this.items[1].value}/${value}`;
    } else {
      return `/${this.items[1].value}`;
    }
  }

  isBlockedFilter() {
    const url = this.router.url;
    return url.includes('price-')
      || url.includes('kml-')
      || url.includes('boot-')
      || url.includes('length-');
  }

}
