<div class="wrapper fadeInDown">
  <div id="formContent">
 
    <div id="formFooter">
        <h3 class="underlineHover">כניסת סוכנויות</h3>
      </div>

    <!-- Login Form -->
    <form>
      <input [(ngModel)]="userName" type="text" id="login" class="fadeIn second" name="login" placeholder="שם משתמש">
      <input [(ngModel)]="password" type="password" id="password" class="fadeIn third" name="login" placeholder="סיסמה">
      <input type="submit" (click)="login()" class="fadeIn fourth point" value="התחברות">
    </form>

    <!-- Remind Passowrd -->
    <div id="formFooter">
      <a class="underlineHover" href="#">שכחת סיסמה?</a>
    </div>

  </div>
</div>