<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>


    <div class="container-fluid">
        <div class="row" style="padding-top: 150px">
            <div class="col-md-12 p-0">
                <div class="main-image">
                    <span class="main-text">
                        מדיניות הפרטיות ותנאי השימוש
                    </span>
                </div>
            </div>
        </div>

        <div class="row page-content-wrapper" style="padding-top: 30px; padding-bottom: 30px;">
            <div class="col-md-12 description-container-title p-0">
                <span class="description-title">מה מייחד את קארפיינד</span>
            </div>
        </div>

        <div class="row description-container page-content-wrapper">
            <div class="col-md-4 col-12 p-0 d-none d-xl-block">
                <div>
                    <div class="float-left point">
                        <img src="assets/images/svg/icon-target.svg" class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            אתר הרכב המקיף בישראל למציאת
                            <br />
                            הדגם המתאים ביותר עבורך
                        </span>
                    </div>
                </div>
                <div class="description-second-item-container">
                    <div class="float-left point">
                        <img src="assets/images/svg/icon-shield.svg" class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            קנייה בטוחה - עם אחריות של
                            <br />
                            יבואן רשמי על כל הרכבים
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12 p-0 d-none d-xl-block">
                <div>
                    <div class="float-left point">
                        <img style="width: 60px; height: 60px" src="assets/images/svg/icon-hand.svg"
                            class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            מציאת המחיר המפורסם הכי זול
                            <br />
                            בישראל לכל רכב
                        </span>
                    </div>
                </div>
                <div class="description-second-item-container">
                    <div class="float-left point">
                        <img src="assets/images/svg/icon-search.svg" class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            חווית קנייה ייחודית: מנוע חיפוש
                            <br />
                            חדשני בשילוב עם "יועץ דיגיטלי"
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0 d-none d-xl-block">
                <div>
                    <div class="float-left gpointrab">
                        <img src="assets/images/svg/icon-money-2.svg" class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            השוואת עסקאות לכל דגם מבין מגוון
                            <br />
                            סוכנויות: יבואנים וחברות 0 ק"מ
                        </span>
                    </div>
                </div>
                <div class="description-second-item-container">
                    <div class="float-left point">
                        <img src="assets/images/svg/icon-network.svg" class="description-image" />
                    </div>
                    <div>
                        <span class="feature-text">
                            שירות ויעוץ אוביקטיבי, מגוון ובחינם
                            <br />
                            בטלפון, בצאט ופורום לכל דגם
                        </span>
                    </div>
                </div>
            </div>

            <ul class="d-block d-sm-none list-inline" style="width: 100%">
                <li>
                    <div>
                        <div class="float-left point">
                            <img src="assets/images/svg/icon-target.svg" class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                אתר הרכב המקיף בישראל למציאת
                                <br />
                                הדגם המתאים ביותר עבורך
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div class="float-left point">
                            <img src="assets/images/svg/icon-shield.svg" class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                קנייה בטוחה - עם אחריות של
                                <br />
                                יבואן רשמי על כל הרכבים
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div class="float-left point">
                            <img style="width: 60px; height: 60px" src="assets/images/svg/icon-hand.svg"
                                class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                מציאת המחיר המפורסם הכי זול
                                <br />
                                בישראל לכל רכב
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div class="float-left point">
                            <img src="assets/images/svg/icon-search.svg" class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                חווית קנייה ייחודית: מנוע חיפוש
                                <br />
                                חדשני בשילוב עם "יועץ דיגיטלי"
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div class="float-left point">
                            <img src="assets/images/svg/icon-money-2.svg" class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                השוואת עסקאות לכל דגם מבין מגוון
                                <br />
                                סוכנויות: יבואנים וחברות 0 ק"מ
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div class="float-left point">
                            <img src="assets/images/svg/icon-network.svg" class="description-image" />
                        </div>
                        <div>
                            <span class="feature-text">
                                שירות ויעוץ אוביקטיבי, מגוון ובחינם
                                <br />
                                בטלפון, בצאט ופורום לכל דגם
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row page-content-wrapper main-features-container">
            <div class="col-md-3 p-0">
                <ul class="list-unstyled  ul-menu-item ul-text point">
                    <li class="ul-text-li">
                        <span (click)="scrollToElement(c1)">מדיניות הפרטיות</span>
                    </li>
                    <li class="ul-text-li">
                        <span (click)="scrollToElement(c2)">תנאי שימוש</span>
                    </li>
                </ul>
            </div>
            <div class="col-md-9 p-0">
                <div #c1 class="text-title p-0">
                    <span class="text-title">
                        מדיניות פרטיות
                    </span>
                </div>
                <div class="text-content">
                    <span class="text">
                        קארפינד בע"מ ("קארפינד","אנחנו" או "אנו") מחויבת להגן על פרטיות משתמשי האתר שלה ("משתמש/ים" או
                        "אתה"). מדיניות פרטיות זו ("מדיניות הפרטיות") נוצרה כדי ליידע אותך לגבי הדרך שבה אנו מנהלים,
                        אוספים, מאחסנים ועושים שימוש במידע שאתה מספק לנו באמצעות אתר האינטרנט של קארפינד ("האתר").
                        בנוסף, מדיניות הפרטיות שלהלן מתארת את האמצעים בהם אנו נוקטים על מנת להגן על בטיחותו של המידע
                        הנאסף על ידנו וכיצד ניתן ליצור איתנו קשר בנוגע לנוהלי הפרטיות שלנו.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        אנא שים לב שהיקף מדיניות פרטיות זו מוגבל רק למידע שנאסף על-ידינו באמצעות השימוש שאתה עושה
                        בשירותי אתר האינטרנט שלנו (בין שהשימוש כאמור הוא באמצעות כל מחשב בו נעשה שימוש כדי לקבל גישה
                        לשירותי האתר, לרבות ללא הגבלה, מחשב נייח, מחשב נייד, טלפון נייד או כל מכשיר אלקטרוני אחר).
                        באמצעות שימוש באתר שלנו, אתה מסכים למדיניות הפרטיות שלנו.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        קארפינד מספקת למשתמשי האתר מידע אודות רכבים שונים, תוך השוואת המחירים המפורסמים באמצעות יבואני
                        רכב, חברות ליסינג ומסוכנויות רכב מובילות בישראל ("שירותי האתר").
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        <span style="text-decoration: underline;">בעת השימוש באתר שלנו, אנו עשויים לאסוף ממך שני סוגי
                            מידע עליך בקשר לשירותי האתר:</span>
                        <br />
                        <br />
                        "מידע אישי" – משמעו מידע עליך שניתן להשתמש בו על מנת ליצור עמך קשר או לזהות אותך. חלק מהמידע
                        מזהה אותך באופן אישי, כגון שמך, דואר האלקטרוני שלך, מספר הטלפון שלך וכיו"ב. זהו המידע שאתה מוסר
                        ביודעין, בהסכמתך, לטובת קבלת מידע ושירותים שמוצגים באתר שלנו.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        "מידע לא אישי" – משמעו מידע שכשלעצמו, לא מאפשר לעשות שימוש לשם זיהוי או יצירת קשר איתך. זהו מידע
                        סטטיסטי ומצטבר. לדוגמה, פרסומות שקראת באתר, העמודים שבהם צפית, ההצעות ושירותי האתר שעניינו אותך
                        ועוד.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        אנו רשאים לאסוף מידע לא אישי ולהשתמש בו לכל מטרה באמצעות כל אחת מהשיטות לעיל וכן באופן אוטומטי
                        או באופן אחר באמצעות השימוש שתעשה בשירותי האתר.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        אינך מחויב לפי חוק למסור לנו את פרטי המידע האישי המתוארים להלן, אך לצורך השימוש בשירותי האתר
                        באופן המיטבי, הינך נדרש לשתף עם קארפינד את פרטי המידע האישי המפורטים. במידה ותסרב למסור חלק
                        מהמידע האישי שלך, ייתכן ולא תוכל לעשות שימוש בשירותי האתר או שתוכל לעשות שימוש חלקי בלבד.
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        1. <span style="text-decoration: underline;">כיצד אנו אוספים את המידע שלך?</span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                1)
                                אנו נבקש ממך מידע אישי ממך כגון שמך המלא, כתובת הדואר האלקטרוני שלך, עיר המגורים שלך
                                ומספר
                                הטלפון שלך כאשר ברצונך לקבל את שירותי האתר, בעת הרשמתך לאתר וכאשר אתה יוצר איתנו קשר
                                לטובת
                                קבלת שירותי האתר. כאשר אתה יוצר איתנו קשר באמצעות האתר, בנוסף לפרטיך נאסוף את תוכן הפניה
                                שלך. כמו כן, במידה ואתה בוחר לשתף תוכן באמצעות הפורום הזמין באתר, נאסוף את פרטי המשתמש
                                שלך
                                והתוכן שאתה בוחר לשתף. דע, ששם המשתמש שלך, והתוכן שאתה בוחר לשתף בפורום, הופך להיות
                                פומבי
                                ומכאן שזמין לכל משתמשי האתר
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        2. <span style="text-decoration: underline;">כיצד אנו משתמשים בפרטים שלך?</span>
                        <br />
                        <br />
                        <span>אנו משתמשים בפרטים שנאספו ממך באופן הבא:</span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                2)
                                <span style="text-decoration: underline;">ניהול המערכת ואספקת שירותי האתר</span> אנו
                                עשויים להשתמש במידע אישי ובמידע לא אישי על מנת
                                לספק לך את שירותי האתר, ועל מנת לנהל, לתמוך, לפתור בעיות טכניות, להגן ולשפר את שירותי
                                האתר, לוודא תפעול נאות של השירות שלנו וכן למטרות יצירת קשר איתך. </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                3)
                                <span style="text-decoration: underline;">:שליחת הודעות</span> אנו עשויים להשתמש במידע
                                האישי על מנת לשלוח לך הודעות הקשורות למשל שירותי האתר, פעילות קארפינד, ניהול מבצע או
                                סקר, וכן לצורך מתן הוראות או עזרה הקשורות לשירותי האתר ו/או לשימוש שלך בו. כמו כן, יתכן
                                ונשתמש במידע האישי שלך לצורך משלוח ניוזלטר ועדכונים לגבי פעילות קארפינד והשירותים שהיא
                                מציעה. אם בשלב כלשהו תעדיף שלא לקבל הודעות כאמור, הינך רשאי לעשות זאת בכל עת בהתאם
                                להוראות המפורטות ביחס להסרה מרשימת התפוצה בתחתית כל הודעה שתישלח על ידי קארפינד.</span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                4)
                                <span style="text-decoration: underline;">:צירוף מידע אנונימי</span> ככל המותר על פי
                                הדין החל, אנו רשאים לצרף את סוגי הנתונים השונים שאנו אוספים ממך עם סוגי נתונים אחרים
                                שאנו אוספים ממך, מהלקוחות והשותפים העסקיים האחרים שלנו. איסוף זה יתבצע באופן אנונימי
                                ומצטבר ובצורה שלא מזהה אותך באופן אישי לרבות באמצעות העברת מידע שעבר גיבוב (“hashing”).
                                מידע אנונימי, סטטיסטי או מצטבר זה, ישמש רק על מנת לשפר את המוצרים והשירותים שלנו. אם
                                מידע לא אישי נאסף לצורך פעילות שדורשת גם מידע אישי, אנו רשאים לצרף את המידע הלא אישי שלך
                                עם המידע האישי שלך בניסיון לספק לך חווית משתמש טובה יותר, לשפר את ערך ואיכות שירותי האתר
                                ולנתח כיצד נעשה שימוש באתר. </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        3. <span style="text-decoration: underline;">מסירת המידע האישי שלך </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אתה מבין ומסכים לכך שייתכן ונידרש למסור מידע אישי אם נידרש לכך על פי חוק או אם נהיה
                                סבורים כי מסירה כאמור דרושה באופן סביר על מנת להימנע מחבות משפטית שלנו, לשם ציות להליכים
                                משפטיים, לרבות, ומבלי לגרוע, זימון לדין, צו חיפוש או צו בית משפט, או על מנת להגן על רכוש
                                וזכויות שלנו או של צד שלישי, להגן על בטיחות הציבור או של כל אדם, או למנוע או לעצור
                                פעילות שנמצא שהיא בלתי חוקית, בלתי אתית או ברת תביעה או שיש סכנה שתהיה כזו.
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                בכל הנוגע לשירותי האתר אנו נעביר את המידע האישי שלך לשותפים העסקיים שלנו, בהתאם לרכבים
                                שבהם התעניינת ולעסקאות שאתה שוקל לבצע, כגון ספקי משנה, מתווכים, חברות ליסינג וסוכנויות
                                ליסינג למיניהן. מלבד לאמור במדיניות פרטיות זו, המידע האישי שתספק לא יימסר, יושכר, יושאל,
                                יוחכר, יימכר או יופץ לצדדים שלישיים (להוציא שותפים עסקיים ו/או צדדים אחרים המפורטים
                                במדיניות פרטיות זו) ואנו לא נשתף את המידע האישי אודותיך עם צדדים שלישיים למטרות שיווק
                                ללא רשותך, אלא כפי שמפורט בתנאי מדיניות פרטיות זו. אנא שים לב שאנו רשאים למכור, להעביר
                                או לשתף באופן אחר חלק או את כל הנכסים שלנו, לרבות המידע האישי שלך בקשר למיזוג, רכישה,
                                ארגון מחדש או מכירה של הנכסים שלנו או במקרה של פשיטת רגל.
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אנו רשאים ועשויים לשתף מידע אישי עם צדדים שלישיים אמינים על מנת לסייע בביצוע שירותי
                                האתר, לספק את שירותי האתר, לבצע משימות (לדוגמה, ללא הגבלה, שירותי תחזוקה, ניהול בסיס
                                נתונים, ניתוח אתרים ושיפור השירותים) או לסייע לנו בניתוח האופן בו נעשה שימוש באתר. צדדים
                                שלישיים או סוכנים אלה רשאים להשתמש במידע האישי שלך רק ככל הדרוש לבצע את השירותים שהם
                                מסוכנויות לנו והם נדרשים לנקוט באמצעי הגנה סבירים על מנת להגן ולשמור בסודיות על המידע האישי
                                שלך. </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        4. <span style="text-decoration: underline;">שמירת המידע שלך </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפינד מאחסנת מידע שנאסף בקשר לשירותי האתר על שרתים מאובטחים המסוכנויות רמת אבטחת מידע
                                גבוהה למידע שלך. כמו כן, אנו נוקטים בצעדי הגנה על מנת להגן על המידע האישי שלך. על מנת
                                להפחית את הסיכון של גישה או העברה בלתי מאושרת, ולטובת ניהול נתונים באופן מדויק ווידוא
                                שימוש הולם במידע אישי, אנו נוקטים באמצעים פיזיים, אלקטרוניים ומנהליים מתאימים על מנת
                                לשמור ולהגן על המידע אותו אנו מעבדים. במקרה של העברה רשלנית, ננקוט בצעדים סבירים מבחינה
                                מסחרית על מנת להגביל ולתקן את ההעברה. עם זאת, אין אנו יכולים לערוב לכך שצדדים שלישיים
                                בלתי מאושרים לא יצליחו לעולם לעקוף הליכים אלה או לעשות שימוש במידע כלכלי או במידע אישי
                                למטרות בלתי הולמות. זוהי אחריותך להגן על כל הסיסמאות ושמות המשתמש בהם אתה עושה שימוש
                                בגישה לאתר ולהודיע לנו מיד אם אתה יודע או חושד שהסודיות של כל סיסמה ו/או שם משתמש הופרו.
                                אתה האחראי הבלעדי לכל שימוש בלתי מאושר שמתבצע באמצעות הסיסמה ושם המשתמש שלך. </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        5. <span style="text-decoration: underline;">שימוש ב-Cookies </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אנו משתמשים ב Cookies-לצורך תפעולו השוטף והתקין של האתר, ובכלל זה כדי לאסוף נתונים
                                סטטיסטים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיך האישיות ולצרכי
                                אבטחת מידע. דפדפנים מודרניים כוללים אפשרות להימנע מקבלת Cookies. אם אינך יודע כיצד לעשות
                                זאת, בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        6. <span style="text-decoration: underline;">חומרים של צדדים שלישיים </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אתה עשוי להיות רשאי להיכנס, לבדוק, להציג או להשתמש בשירותים, במשאבים, בתכנים, במידע או
                                בקישורים של צדדים שלישיים, לאתרים או למשאבים אחרים ("חומרי צדדים שלישיים") באמצעות האתר.
                                מלבד אתר הרכב של קארפינד שאליו תוכל לקבל גישה באמצעות האתר, חומרי הצדדים השלישיים אינם
                                תחת שליטת קארפינד ואתה מאשר כי הינך נוטל אחריות בלעדית ונוטל את כל הסיכונים הנובעים
                                מכניסה, שימוש או הסתמכות על חומרי צדדים שלישיים כאמור. קארפינד לא תישא בשום חבות שתיגרם
                                לך כתוצאה מכניסה, שימוש או הסתמכות על חומרי צדדים שלישיים באמצעות האתר. לידיעתך, מדיניות
                                פרטיות זו אינה מכסה את נהלי הפרטיות והגנת המידע המוטמעים על ידי אותם צדדים שלישיים ולכן
                                אנו ממליצים לך לקרוא את מדיניות הפרטיות של הצד השלישי שאליו אתה מקבל גישה, טרם מתן מידע
                                אישי.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        7. <span style="text-decoration: underline;">זכות לעיין במידע </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                במידה ותרצה לעיין במידע האישי שלך שנמצא ברשותנו וכן לצורך בירור פרטים אחרים הקשורים
                                למידע שלך שנמצא ברשותנו, הינך מוזמן ליצור עימנו קשר בדרך טופס צור קשר או בטלפון
                                03-5245241. </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        8. <span style="text-decoration: underline;">תיקון המידע שלך </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אנו נשמור את המידע האישי שלך כל עוד חשבונך פעיל או לפי הצורך על מנת לספק לך את שירותי
                                האתר, או כנדרש למלא אחר התחייבויותינו החוקיות, לפתור סכסוכים ולאכוף את ההסכמים שלנו.
                                במידה ותרצה שככל הנדרש, נתקן את הרישומים שלך במערכת שלנו, אנא צור עימנו קשר ב:
                                03-5245241 ואנו ננסה למלא את בקשתך.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        9. <span style="text-decoration: underline;">פרטיות של קטינים </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפינד מחויבת להגן על צרכי הפרטיות של ילדים ואנו ממליצים להורים ולאפוטרופוסים לקחת
                                תפקיד פעיל בפעילויות ובאינטרסים של ילדיהם באינטרנט. אין אנו אוספים ביודעין מידע אישי
                                מילדים מתחת לגיל 18 וקארפינד לא מייעדת את האתר ופעילותה לילדים מתחת לגיל האמור. אם נהיה
                                מודעים לכך שילד מתחת לגיל 18 סיפק לנו מידע אישי, ננקוט בצעדים הדרושים להסיר את המידע
                                האישי כאמור ולסגור את חשבונו של הילד. אם אתה מתחת לגיל 18, אנא אל תמסור כל מידע אישי
                                לאתר שלנו.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        10. <span style="text-decoration: underline;">שינויים במדיניות הפרטיות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אנו שומרים לעצמנו את הזכות לשנות מדיניות פרטיות זו מעת לעת. הגרסה העדכנית ביותר של
                                המדיניות היא הגרסה הקובעת לעניין השימוש שנעשה במידע האישי ובמידע הלא אישי שלך. במקרה
                                שאנו, לפי שיקול דעתנו הבלעדי, נחליט שעדכונים של מדיניות הפרטיות מהווים שינוי מהותי, אנו
                                נודיע לך על השינויים כאמור בהודעה שתפורסם באמצעות האתר. על אף האמור לעיל, אין אנו
                                מחויבים להודיע למשתמש על שינויים במדיניות זו שאינם מהותיים ולכן על המשתמשים לבקר מעת לעת
                                בעמוד זה על מנת לעיין במדיניות הפרטיות העדכנית שלה הם כפופים.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        11. <span style="text-decoration: underline;">יצירת קשר </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אם יש לך שאלות או חששות בנוגע למדיניות פרטיות זו או בנוגע לכל נושא אחר בעניין שירותי
                                האתר, אנא צור עימנו קשר ב:,03-5245241 </span>
                            <br />
                            <br />
                            עדכון אחרון: 12 ביולי, 2020.
                        </div>
                    </span>
                </div>

                <br />
                <br />

                <div #c2 class="text-title p-0">
                    <span class="text-title">
                        תנאי שימוש
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        1. <span style="text-decoration: underline;">מבוא </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                תנאי שימוש אלה ("התנאים") מהווים הסכם מחייב משפטית בין חברת קארפיינד בע"מ ("קארפיינד",
                                "אנחנו" או "אנו") לבינך ("אתה", "הנך", "משתמש"). למען הסר ספק, ההגדרות "אתה", "הנך",
                                "משתמש" מתייחסות לכלל הגורמים המשתמשים בשירות (כהגדרתו להלן), לרבות לקוחות וסוכנויות של
                                קארפיינד. על ידי כניסה או שימוש באתר האינטרנט של קארפיינד ("האתר"), האפליקציה ו/או יתר
                                השירותים של קארפיינד, לרבות באמצעות עמוד הפייסבוק של החברה או באמצעי תקשורת אחרים (ביחד
                                - "השירות"), אתה מאשר שקראת והבנת תנאים אלה ושאתה מקבל ומסכים להיות כפוף להם. מבלי לגרוע
                                מהאמור לעיל, כניסה ו/או דפדוף ו/או שימוש בשירות, בכל אופן שהוא ולכל מטרה שהיא, אף יהוו
                                אישור מוחלט ובלתי חוזר לתנאים. ככל שהמשתמש אינו מסכים לתנאים, במלואם או בחלקם, נאסר עליו
                                לבקר או להשתמש בשירות.
                                <br />
                                <br />
                                שימוש בלשון זכר בתנאים אלה נעשה לצורך הנוחות בלבד, וכל התנאים מתייחסים לגברים ונשים
                                כאחד.
                                <br />
                                <br />
                                קארפיינד שומרת לעצמה את הזכות, לפי שיקול דעתה הבלעדי, לתקן ולשנות תנאים אלה בכל זמן,
                                ואתה מסכים להיות כפוף לתיקונים ושינויים אלה. אתה אחראי לבדוק תנאים אלה מפעם לפעם. במקרה
                                שקארפיינד לפי שיקול דעתה הבלעדי, תחליט שעדכונים של התנאים מהווים שינוי מהותי, אנו עשויים
                                להודיע לך על השינויים כאמור בהודעה שתפורסם באמצעות האתר. על אף האמור לעיל, אין אנו
                                מחויבים להודיע למשתמש על שינויים בתנאים אלה ולכן על המשתמשים לבקר מעת לעת בעמוד זה על
                                מנת לעיין בתנאים העדכניים שלנו. המשך שימושך בשירות לאחר שנעשה שינוי או תיקון בתנאים אלה,
                                מהווה הסכמה מדעת להסכמתך וקבלתך של התנאים המתוקנים. כל התנאים החדשים ו/או המתוקנים
                                ייכנסו לתוקף מיד עם פרסומם ויחולו על השימוש שלך בשירות מאותו תאריך ואילך. אם אינך מסכים
                                לתנאים, התרופה היחידה העומדת לך הינה הפסקת השימוש בשירות.
                                <br />
                                <br />
                                במידה ותפר תנאים אלו, קארפיינד שומרת לעצמה את הזכות להנפיק לך אזהרה בנוגע להפרה או
                                להפסיק או להשעות מיד את שימושך בשירות, וזאת מבלי לגרוע או למצות מכל זכות ו/או סעד
                                העומדים לקארפיינד על פי כל דין ו/או הסכם.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        2. <span style="text-decoration: underline;">השירות המוצע </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפיינד מפעילה שירות המספק למשתמשים מידע על רכבים שונים בשוק הישראלי, ובפרט, מנהלת
                                השוואה בין ספקי רכב המוכרים רכבים. קארפיינד מספקת מידע במטרה לסייע למשתמשים למצוא עסקה
                                עבורם וכן שירותים נלווים.
                                <br />
                                <br />
                                במסגרת השירות קארפיינד מציגה מידע כללי וראשוני למשתמשים בקשר עם כלי רכב המוצעים וכן
                                אפשרות השארת פרטים ליצירת קשר. כמו כן, במסגרת השירות, קארפיינד מחברת בין סוכנויות ולקוחות
                                פוטנציאליים לצורך מימוש עסקאות ביניהם. מובהר כי המחירים המוצגים במסגרת השירות לא כוללים
                                אגרת רישוי.
                                <br />
                                <br />
                                קארפיינד שומרת לעצמה את הזכות, בכפוף לתנאים אלה, לשנות מעת לעת את פרטי כלי הרכב המוצגים
                                במסגרת השירות, מחיריהם ותנאי העסקה (לרבות הצעות מיוחדות ומבצעים), על פי שיקול דעתה
                                הבלעדי וללא הודעה מוקדמת.
                                <br />
                                <br />
                                כל התמונות המופיעות במסגרת השירות הנן להמחשה בלבד ועשויים להיות שינויים ו/או אי דיוקים
                                ו/או פערים בין תמונות כלי הרכב המוצגות במסגרת השירות לבין כלי הרכב בפועל. לאור האמור
                                מובהר, כי התמונות אינן מהוות מצג או התחייבות של קארפיינד ביחס לעסקה של כלי רכב, ומבלי
                                לגרוע מתנאי מבצעים ספציפיים שמפרסמת קארפיינד מעת לעת, התכנים והמידע המופיעים במסגרת
                                השירות לא יפורשו כהצעה כהגדרתה בחוק החוזים (חלק כללי), תשל"ג-1973 ו/או כהתחייבות של
                                קארפיינד כלפי משתמש מסוים בקשר עם תנאי עסקה כלשהי, זמינותה או אפשרות מימושה.
                                <br />
                                <br />
                                במסגרת השירות מוצגים (לרבות באמצעות קישורים) נתונים אודות כלי הרכב ומפרטים אשר נלקחו
                                מאתרי היבואנים. נתונים ומפרטים אלו מובאים כמידע כללי לנוחות המשתמש בלבד ועשויים להתעדכן
                                מעת לעת וקארפיינד אינה מתחייבת לכך שהנתונים הנם עדכניים או נכונים. קארפיינד ממליצה
                                למשתמש לפנות ישירות לאתרי היבואנים השונים על מנת לצפות במפרט העדכני והמלא של כלי הרכב
                                הרלוונטי.
                                <br />
                                <br />
                                קארפיינד אינה נושאת באחריות או חבות בקשר עם תקלות או שגיאות אשר עשויות להופיע במסגרת
                                השירות, לרבות אי דיוקים בכל הנוגע לתיאור של כלי הרכב ו/או מחיריהם. השימוש בשירות
                                וההסתמכות על המידע המופיע בו נעשה אפוא על אחריותו הבלעדית של המשתמש.
                                <br />
                                <br />
                                כל התייחסות ל"מחירון" ביחס לכלי רכב חדשים מתייחסת למחירון היבואן כולל מע"מ (לא כולל אגרת
                                רישוי). המחירים המוצגים במסגרת השירות עשויים להשתנות מעת לעת בהתאם לשינויים שחלים
                                במחירון היבואן.
                                <br />
                                <br />
                                על מנת לרכוש כלי רכב מקארפיינד דרך האתר, על המשתמש להשאיר פרטים באתר. על המשתמש למסור
                                שם, טלפון, ולעיתים גם כתובת ודואר אלקטרוני. מובהר כי עסקת כלי הרכב אינה נעשית בפועל דרך
                                האתר. לאחר השארת פרטים כאמור, נציג יצור קשר טלפוני עם המשתמש תוך יום עבודה על מנת לספק
                                לו הצעה לרכישה של כלי רכב.
                                <br />
                                <br />
                                מובהר כי, השלמת עסקת כלי הרכב כפופה להימצאות הרכב הרלוונטי במלאי באותה העת. כמו כן מובהר
                                כי השלמת עסקת כלי רכב מקארפיינד הינה בהתאם ובכפוף לתנאי הסכם ספציפי שיחתם בין הצדדים,
                                והשארת פרטים בלבד באתר לא תחייב את קארפיינד או את המשתמש. בכל מקרה של סתירה ו/או חוסר
                                התאמה בין האמור ו/או המוצע ו/או המתואר באתר (לרבות צילומים) לבין ההסכם שייחתם, יגבר
                                האמור בהסכם.
                                <br />
                                <br />
                                קארפיינד שומרת לעצמה את הזכות שלא ליצור קשר עם המשתמש ו/או לסרב להציע למשתמש כלי רכב מכל
                                סיבה שהיא בהתאם לשיקול דעתה הבלעדי.
                                <br />
                                <br />
                                הנך מתחייב לעדכן את קארפיינד בתוך 7 ימי עבודה בכל מקרה שנערכה עסקה בינך לבין צד שלישי
                                כתוצאה מהשימוש בשירות, וזאת באמצעות הודעה בכתב או בפנייה טלפונית לטלפון: 03-524-5241.
                                למען הסר ספק, ההתחייבות לעדכן את קארפיינד בכל עסקה חלה על כל משתמשי השירות, הן לקוחות
                                והן סוכנויות.

                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        3. <span style="text-decoration: underline;">זכות להשתמש בשירות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                בכפוף להסכמתך לתנאים אלה ולכך שתפעל לפיהם, קארפיינד מעניקה לך זכות אישית, מוגבלת, לא
                                בלעדית ושאינה ניתנת להעברה לגישה ושימוש בשירות. אתה מאשר בזאת כי זכותך לגישה ושימוש
                                בשירות מוגבלת על ידי תנאים אלה וכי אם תפר או אם בכל שלב שהוא, תסרב להסכים לאיזה מתנאים
                                אלו, זכותך לגישה ושימוש בשירות תסתיים באופן מיידי. במידה ויקבע שהשירות או כל חלק ממנו
                                הנו בלתי חוקיי על פי חוקי המדינה שבה אתה נמצא, לא תוענק לך כל זכות להשתמש או לקחת חלק
                                בו.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        4. <span style="text-decoration: underline;">פרטי התחברות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                כל המידע האישי שתספק לקארפיינד או שתמסור תוך שימוש בשירות, לרבות פרטי התחברות והקמת
                                חשבון באתר (ככל שיוקם), יוחזק וייעשה בו שימוש בהתאם לתנאים אלה ומדיניות הפרטיות שלנו
                                הזמינה ב: https://carfind.co.il/terms. אתה מסכים לספק לנו מידע נכון ומלא וכן לעדכן את
                                המידע הזה מיד עם שינויו. אתה מתחייב ומאשר כי יש לך זכות וסמכות מלאות לספק לקארפיינד את
                                המידע האמור לעיל, לרבות, ללא הגבלה, הסכמה של כל צד שלישי (ככל הדרוש על פי כל חוק החל).
                            </span>
                        </div>
                    </span>
                </div>

    
                <div class="text-content">
                    <span class="text">
                        5. <span style="text-decoration: underline;">גישה לשירות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפיינד אינה מספקת ציוד לטובת גישה ו/או שימוש בשירות. לכן, באחריותך לוודא שהמחשב בו אתה
                                משתמש, לרבות ללא הגבלה, מחשב נייח, מחשב נייד, טלפון נייד או כל מכשיר אלקטרוני אחר, עומד
                                בכל המפרטים הטכניים הדרושים לגישה ושימוש בשירות. הנך אחראי לכל התשלומים, הוצאות, אגרות
                                וכדומה, הנגבים על ידי צדדים שלישיים הקשורים לגישה ולשימוש שלך בשירות (לדוגמה, חיובים על
                                ידי ספקי שירותי אינטרנט או חיובים בגין זמן אוויר). אין ביכולתה של קארפיינד לערוב לכך
                                שהשירות יפעל תמיד ללא הפרעות, עיכובים או שגיאות. קיימים מספר גורמים שעלולים להשפיע על
                                איכות התקשורת והשימוש שלך בשירות ולהוביל לאי יכולת מצדך ליצור תקשורת, לרבות, ללא הגבלה,
                                הרשת המקומית שלך, חומת האש, ספק שירותי האינטרנט שלך, האינטרנט הציבורי וספק הכוח שלך.
                                קארפיינד אינה נוטלת כל אחריות בגין כל הפרעה, שיבוש או עיכוב הנגרמים בשל כל בעיה או אי
                                התאמה באיזה מהגורמים הללו או בגורמים אחרים שעליהם אין לקארפיינד שליטה עליהם, לרבות, וללא
                                הגבלה, בעיות או עיכובים הנובעים מסכסוכי עבודה, שביתות, הפסקת פעילות, מחסור או אי יכולת
                                להשיג חשמל, חומרי גלם או ציוד, מלחמה, טרור, מהומות או כוח עליון.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        6. <span style="text-decoration: underline;">כללי התנהגות ושימוש בשירות</span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                הנך מתחייב ומאשר כי לך הסמכות להשתמש בשירות ולהיות מחויב על פי תנאים אלו. הנך מסכים למלא
                                אחר
                                תנאים אלו במלואם וכן לציית לכל החוקים, התקנות, החיקוקים והפקודות המקומיים והבינלאומיים
                                הרלוונטיים לשימושך בשירות כאמור. מבלי להגביל את האמור לעיל ומתוך הכרה באופי הגלובלי של
                                האינטרנט, אתה מסכים למלא אחר כל הכללים המקומיים והבינלאומיים בנוגע להתנהגות באינטרנט.
                                כמו
                                כן, הנך מסכים למלא אחר כל החוקים החלים המשפיעים על העברת תכנים או על פרטיותם של אנשים.
                                <br />
                                הנך מתחייב שלא תונה או תנסה להונות את קארפיינד או משתמשים אחרים וכי לא תפעל בחוסר תום לב
                                בשימושך בשירות. במידה וקארפיינד תקבע שפעלת בחוסר תום לב ו/או תוך הפרת תנאים אלה, או כי
                                פעולותיך אינן נהוגות לפי סטנדרטים סבירים, קארפיינד רשאית, לפי שיקול דעתה הבלעדי, לסיים
                                את
                                חשבונך ולאסור עליך להשתמש בשירות.
                                <br />
                                בנוסף על האמור, וכדוגמא ולא כהגבלה, הנך מסכים שלא:
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                1) ליצור חשבון או להיכנס לשירות אם תהיה חסום מלקבל את השירות על פי הוראות תנאים אלה או
                                כל חוק רלוונטי;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                2) להעלות, לפרסם, להעביר או להפיץ באופן אחר כל חומר שהוא וולגרי, מגונה, גס, פורנוגרפי,
                                בעל אופי מיני או שהוא, לדעת אדם סביר, פוגעני או מעורר התנגדות באופן אחר;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                3)
                                להוציא דיבה, ללעוג, להכפיש, ללגלג, לעקוב, להפחיד, לאיים, להטריד או לפגוע באיש באיבה, על
                                בסיס גזעי, אתני או בכל אופן אחר;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                4)
                                להעתיק, לשכור, להחכיר, למכור, להעביר, להמחות, להעניק ברישיון משנה, לפרק, לפתח לאחור או
                                להנדס לאחור (אלא אם מאושר באופן מפורש על ידי קארפיינד או על פי חוק סטטוטורי רלוונטי),
                                להתאים או לשנות כל חלק מהשירות;להעתיק, לשכור, להחכיר, למכור, להעביר, להמחות, להעניק
                                ברישיון משנה, לפרק, לפתח לאחור או להנדס לאחור (אלא אם מאושר באופן מפורש על ידי קארפיינד
                                או על פי חוק סטטוטורי רלוונטי), להתאים או לשנות כל חלק מהשירות; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                5)
                                להעלות או להעביר (או לנסות להעלות או להעביר) קבצים המכילים וירוסים, סוסים טרויאנים,
                                תולעים (worms), פצצות זמן (time bombs), רובוטי ביטול (cancelbots), קבצים או נתונים
                                פגומים או כל תוכנה או תוכנית דומה אחרת שעלולה לפגוע בתפעול השירות או המחשבים או המכשירים
                                הניידים של משתמשים אחרים בשירות; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                6)
                                להפר זכויות חוזיות, אישיות, של קניין רוחני או אחרות של כל צד, לרבות על ידי שימוש, העלאה,
                                העברה, הפצה או פרסום באופן אחר של כל מידע או חומר שהפך זמין באמצעות השירות בכל דרך המפרה
                                זכויות יוצרים, סימני מסחר, פטנטים, סודות מסחריים או כל זכות אחרת של כל צד (לרבות זכויות
                                של פרטיות או פרסום); </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                7)
                                ליצור זהות שקרית, מספר זהויות, חשבונות משתמש רבים, ליצור חשבון מטעם מישהו מלבד עצמך,
                                להשתמש בבוטים או בתוכניות תכנות אוטומטי אחרות על מנת להונות או שמפרים באופן אחר תנאים
                                אלה ו/או תנאי שירות של צדדים שלישיים;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                8)
                                לנסות להשיג סיסמאות או מידע אישי אחר מחברים אחרים, לרבות מידע המאפשר זיהוי אישי (בין אם
                                בצורת טקסט, תמונה או וידאו), מסמכי זיהוי או מידע פיננסי; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                9)
                                להכין ו/או להציג מצגים בנוגע ו/או בקשר עם קארפיינד אותם קארפיינד לא אישרה מראש ובכתב.
                                עליך לקבל אישור בכתב ומראש מקארפיינד לגבי תוכן של כל מודעת שיווק וביחס לכל שימוש בשם
                                המסחרי ו/או בסימני המסחר ו/או במדגמים של קארפיינד בקשר לשירות;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                10)
                                לעסוק בכל פעולת הונאה ביחס לשיטות תשלום;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                11)
                                להפר כל חוק או תקנה רלוונטיים או לעודד או לקדם כל פעילות בלתי חוקית לרבות וללא הגבלה
                                הפרת זכויות יוצרים, הפרת סימני מסחר, הכפשה, חדירה לפרטיות, גניבת זהות, פריצה למחשבים,
                                פריצה או הפצת תוכנות מזויפות, או תרמיות או פריצה לשירות; ו/או </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                12)
                                לנסות להתערב, לפרוץ או לפענח שדרים מהשרתים ואליהם עבור השירות.
                            </span>
                        </div>
                        <br />
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        7. <span style="text-decoration: underline;">הפסקת שירות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפיינד רשאית לסרב להעניק גישה לשירות, להשעות או להפסיק את חשבונך ללא הודעה, מכל סיבה
                                או חשד כי תנאים אלה הופרו, או כי נעשה שימוש בלתי חוקי או בלתי הולם בחשבונך, או שימוש
                                בלתי חוקי או בלתי הולם בשירות, במוצרים או בקניין רוחני של קארפיינד, כפי שייקבע על ידי
                                קארפיינד לפי שיקול דעתה הבלעדי. אתה עלול לאבד את שם המשתמש שלך כתוצאה מסיום או השעיית
                                החשבון, ללא כל אחריות מצידה של קארפיינד לכל נזק שייגרם מהאמור לעיל.
                                קארפיינד אינה נדרשת לספק לך הודעה לפני השעיה או סיום של חשבונך. במקרה שקארפיינד תסיים את
                                חשבונך, לא תוכל להשתתף או להשתמש בשירות שוב ללא הסכמה מפורשת של קארפיינד. קארפיינד שומרת
                                לעצמה את הזכות לסרב לשמור חשבונות ולספק גישה לשירות לכל אדם שהוא. כמו כן, כפי שצוין,
                                נאסר עלייך לאפשר לאדם שחשבונותיו הופסקו על ידינו להשתמש בחשבון שלך. במידה והנך סבור כי
                                ננקטה פעולה כלשהי נגד חשבונך, אנא צור עמנו קשר בטלפון:03-5245241.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        8. <span style="text-decoration: underline;">מידע של צדדים שלישיים </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                יתכן השירות יכלול מידע מסחרי, מודעות ופרסומות ("מידע של צדדים שלישיים"). מידע של צדדים
                                שלישיים עשוי לקפוץ או להיות מוצג מעת לעת. המקור למידע כאמור יהיה צדדים שלישיים, וככזה,
                                אין אנו ערבים לאמינות או לדיוק שלו.
                                <br />
                                <br />
                                מובהר בזאת שפרסום של תוכן מסחרי על ידי קארפיינד אינו מהווה המלצה או עידוד לרכוש את
                                השירות המפורסם. ככל שהפלטפורמה תכלול קישורים לשירותים שאינם מופעלים או מנוהלים על ידי
                                קארפיינד, קארפיינד לא תהיה אחראית לכל חבות הנובעת מהסתמכותך בקשר לתוכן של השירותים כאמור
                                או כל מידע שסופק על ידם, לרבות, אולם ללא הגבלה, בנוגע לשלמותו, דייקנותו, נכונותו או
                                העדכניות שלו. מבלי לגרוע מהאמור לעיל, קארפיינד לא תהיה אחראית לכל נזק, ישיר או עקיף,
                                כספי או אחר, שינבע מהשימוש או ההסתמכות שלך על התכנים או השירותים אליהם נכנסת במסגרת
                                השירות, לרבות מידע של צדדים שלישיים או קישורים אחרים.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        9. <span style="text-decoration: underline;">קישורים ומנועי חיפוש</span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                אתה עשוי להיות רשאי להיכנס, לבדוק, להציג או להשתמש בשירותים, במשאבים, בתכנים, במידע או
                                בקישורים של צדדים שלישיים, לאתרים או למשאבים אחרים ("חומרי צדדים שלישיים") באמצעות
                                השירות. חומרי הצדדים השלישיים אינם תחת שליטתה של קארפיינד ואתה מאשר כי הנך נוטל אחריות
                                בלעדית ונוטל את כל הסיכונים הנובעים מכניסה, שימוש או הסתמכות על חומרי צדדים שלישיים
                                כאמור. קארפיינד לא תישא בשום חבות שתיגרם לך כתוצאה מכניסה, שימוש או הסתמכות על חומרי
                                צדדים שלישיים באמצעות השירות. אתה מאשר ומסכים שקארפיינד:
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                1)
                                אינה אחראית על זמינות, דיוק, שלמות, איכות ו/או חוקיות חומרי הצדדים השלישיים כאמור ו/או
                                המוצרים ו/או השירותים הזמינים בחומרי הצדדים השלישיים כאמור;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                2)
                                אינה אחראית כלפיך או כלפי כל צד שלישי בגין כל נזק, פגיעות או הפסדים כתוצאה מכניסתך או
                                שימושך בחומרי הצדדים השלישיים כאמור; וכן
                                אינה מציגה כל הבטחה להסיר את אפשרות הגישה לחומרי צדדים שלישיים באמצעות השירות. יכולתך
                                להיכנס לקישור לחומרי צדדים שלישיים או לשירותי צדדים שלישיים אינה מצביעה על כל מתן חסות
                                על ידי קארפיינד לחומרי צדדים שלישיים או לשירותים של צדדים שלישיים כלשהם.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        10. <span style="text-decoration: underline;">בעלות בקניין רוחני </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                קארפיינד ו/או החברות הקשורות לה שומרות לעצמן את כל הזכויות בשירות (לרבות, אולם ללא
                                הגבלה, באפליקציות, תוכנות, מדגמים, שיטות, גרפיקה, טקסטים, מידע, תמונות, וידאו, קול,
                                מוזיקה וקבצים אחרים ובבחירה ובארגון שלהם) (ביחד, "החומרים"). כל החומרים מוגנים על פי
                                החוקים הרלוונטיים של זכויות יוצרים, חוזי המוצר, פטנטים וסמני מסחר, אמנות בינלאומיות
                                וחוקים אחרים המגנים על קניין רוחני ועל זכויות קנייניות קשורות. נאסר עליך בעצמך וכן נאסר
                                עליך לגרום לכל צד אחר להתאים, להנדס לאחור, לפרק, לפתח לאחור, להעתיק, להעביר, ליצור
                                עבודות נגזרות, להשכיר, להעניק רישיון משנה, לחלק, לשכפל, למסגר, לפרסם מחדש, לאסוף,
                                להוריד, להציג, להעביר, להעלות, להחכיר או למכור בכל צורה או בכל אמצעי, באופן מלא או חלקי,
                                להשתמש לכל מטרה לבד מאשר לצורך שימוש בשירות על פי תנאים אלה או להשתמש באופן אחר באיזה
                                מהחומרים ללא הסכמה מפורשת, מראש ובכתב של קארפיינד. כל יתר השימושים בחומרי זכויות יוצרים
                                או סימני מסחר, לרבות כל שימוש נגזר, דורשים הסכמה מפורשת, מראש ובכתב של קארפיינד. כל
                                שכפול או הפצה מחדש של חומרים שלא בהתאם לתנאים אלה אסורים בהחלט ויובילו לסיום חשבונך
                                ויהיו כפופים לעונשים אזרחיים ופליליים.
                                <br />
                                <br />
                                אינך נדרש לספק לקארפיינד כל משוב או הצעה בנוגע לשירות, או לאיזה מהחומרים. עם זאת, אם
                                תספק לקארפיינד הערות או הצעות לשינוי, תיקון, שיפור או השבחת השירות או איזה מהחומרים, כי
                                אז, בכפוף לתנאים ולהתניות של תנאים אלה, אתה מעניק בזאת להרשות רישיון לא בלעדי, כלל
                                עולמי, בלתי חוזר וללא תמלוגים, לרבות זכות להעניק רישיון משנה, להשתמש ולמסור את ההערות
                                וההצעות כאמור בכל אופן אותו קארפיינד תבחר ולהציג, לבצע, להעתיק, לבצע העתקה, ליצור, לבצע
                                יצירה, להשתמש, למכור, להציע למכירה ולמסור באופן אחר את המוצרים והתכנים של קארפיינד ושל
                                בעלי רישיונות המשנה שלה, המגלמים את ההערות או ההצעות כאמור בכל דרך ובאמצעות כל מדיום
                                שקארפיינד תבחר, אולם ללא התייחסות למקור של ההערות או ההצעות כאמור.
                                <br />
                                <br />
                                כל משוב, הצעות, דעות ועצות כאמור וכל המידע האחר המבוטא על ידי צדדים שלישיים כלשהם בשירות
                                מייצגים את דעותיהם שלהם ולא את אלה של קארפיינד. אין להסתמך על המשוב, ההצעות, הדעות,
                                העצות או המידע האחר כאמור. קארפיינד ואף אחד מהחברות הקשורות לה או הסוכנים, הדירקטורים,
                                העובדים, ספקי המידע, מעניקי הרישיון, בעלי הרישיון, נושאי המשרה ו/או הסניפים המתאימים
                                שלהם לא יהיו אחראים ולא יהיו בעלי כל חבות בגין כל החלטה שתעשה על בסיס המידע כאמור.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        11. <span style="text-decoration: underline;">הסדרי תשלום למתן השירות </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                כעת, ההרשמה לשירות והשימוש בו הינם ללא תשלום. יתכן ובשלב כלשהו, קבלת השירות תהא כרוכה
                                בתשלום. לקארפיינד עומדת הזכות לשנות/לעדכן/לבטל את תנאי השירות, לקבוע דמי שימוש בשירות
                                ו/או לשנותם, לשנות את סוג חשבון המשתמש ו/או בסיסו, וזאת בכפוף לפרסום כל שינוי שכזה מראש
                                באתר.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        12. <span style="text-decoration: underline;">הגבלת אחריות ושיפוי</span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                הנך מסכים שהשימוש שלך בשירות יהיה על אחריותך ועל סיכונך בלבד. השירות מסופק "כפי שהוא"
                                (AS IS) ו-"כפי שהוא זמין" (AS AVAILABLE). אתה אחראי באופן בלעדי לכל פעולה ומחדל שיתבצעו
                                או ייעשו תוך הסתמכות על השירות. ככל המותר על פי החוק, קארפיינד, בעלי המניות, הדירקטורים,
                                נושאי המשרה, הסוכנים, הקבלנים, השותפים, היועצים ו/או העובדים שלה לא נושאים בשום אחריות,
                                מפורשת או מכללא, בקשר לשירות והשימוש שלך בו.
                                <br />
                                יודגש כי תיתכן אפשרות שבמידע המופיע בשירות נפלו טעויות הגהה, טעויות ניסוח, טעויות סופר
                                וכיוצא בזה, וכן, טעויות שנגרמו עקב חדירה של גורמים לא מורשים. קארפיינד לא תהיה אחראית
                                לנזק שנגרם עקב טעויות מכל סוג כאמור. לאור זאת, קארפיינד לא נוטלת כל חבות או אחריות בגין
                                כל:
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                1)
                                טעות, שגיאה, אי דיוק או חוסר התאמה של כל תוכן; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                2)
                                נזקים ישירים, עקיפים, מיוחדים, מקריים, עונשיים או תוצאתיים, לרבות מבלי לגרוע, נזק אישי,
                                נזק לרכוש ו/או נזקים כספיים, נזק רפואי, מכל סוג, הנובעים משימוש בשירות או אי יכולת
                                להשתמש בו;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                3)
                                גישה או שימוש בלתי מאושרים לחומרי צד שלישי, שרתים מאובטחים ו/או כל מידע אישי ו/או מידע
                                פיננסי המאוחסן בהם; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                4)
                                הפרעה או הפסקה של העברה אל השירות או ממנו;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                5)
                                באג, וירוס, סוס טרויאני וכיו"ב שיועברו אל או באמצעות השירות על ידי כל צד שלישי; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                6)
                                תוצאות שיושגו כתוצאה משימוש בשירות;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                7)
                                איכות כל מידע או חומר אחר שהושגו על ידך באמצעות השירות; </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                8)
                                חומר שמפורסם, מוצג ו/או מוצע באמצעות השירות, שלמותו, דייקנותו ו/או מהימנותו; ו/או
                                טעות או השמטה בכל תוכן או בגין אובדן או נזק מכל סוג שייגרם כתוצאה מהשימוש בכל תוכן
                                שהועלה, נשלח בהודעה, הועבר או הפך זמין באופן אחר באמצעות השירות.
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                ייתכן שהתכנים אשר מתפרסמים בהמלצות אינם אמיתיים ו/או מדויקים ו/או נכונים, במלואם או
                                בחלקם. קארפיינד לא תהיה אחראית לכל נזק, הפסד, חסרון כיס או הוצאה שייגרמו לך אם תפעל על
                                בסיס המידע, התכנים או המסרים המפורסמים בהמלצות וכל הסתמכות עליהם הינה באחריותך הבלעדית.
                                קארפיינד אינה מתחייבת לכך שהמחירים והנתונים בשירות משקפים בהכרח את מחיר ונתוני המוצר כפי
                                שיימכר בפועל על ידי בית העסק ו/או נותן שירותים. תוצאות החיפוש, המחירים וההשוואות המוצגים
                                בשירות מוצגים על ידי מנגנון אוטומטי ממוחשב. הנתונים המוצגים בשירות כתוצאות חיפוש ו/או
                                במסגרת השוואת מוצרים ו/או הצגת מחירים ו/או המקושרים על ידי השירות, נערכים על ידי
                                קארפיינד, אך אין לקארפיינד אחריות לתוכנם, לנכונותם או למהימנותם, לרבות לשמות היצרנים
                                ו/או הסוכנויות של המוצרים ו/או השירותים, מחירים ומצגים וכל מידע אחר שמקורו במידע המוצג
                                בשירות.
                                <br />
                                <br />
                                מבלי לגרוע מהאמור לעיל, קארפיינד, בעלי המניות, הדירקטורים, נושאי המשרה, הסוכנים,
                                הקבלנים, השותפים, היועצים ו/או העובדים שלה לא יהיו בשום מקרה אחראים כלפיך או כלפי כל צד
                                שלישי בגין כל נזקים מיוחדים, ישירים, עקיפים, מקריים, עונשיים או תוצאתיים, לרבות כל אובדן
                                רווחים או אובדן נתונים, הנובעים מהשימוש שלך בשירות או בחומרים אחרים שאליהם נכנסת או קראת
                                או הורדת באמצעות השירות, בין שעל בסיס אחריות, חוזה, נזיקין או כל תיאוריה משפטית אחרת
                                ובין שקארפיינד קיבלה הודעה על האפשרות של נזקים אלה ובין שלא. מבלי לגרוע מהאמור לעיל, בכל
                                מקרה, החבות המרבית של קארפיינד בכל המקרים ובשל כל העילות תוגבל להחזר הכספי או לזיכוי
                                הלקוח בגובה סכום דמי השימוש לשירות ששולמו בפועל בלבד, במשך 6 החודשים שקדמו לאירוע(ים)
                                שיצר את החבות, אלא אם החוק מחייב אחרת.
                                <br />
                                <br />
                                אתה מסכים לשפות ולקיים את קארפיינד, וכל אחד מהדירקטורים, נושאי משרה, סוכנים, קבלנים,
                                שותפים ועובדים של קארפיינד, בגין כל אובדן, חבות, תביעה, דרישה, נזקים, עלויות והוצאות,
                                לרבות שכר טרחת עו"ד, הנובעים או הקשורים לאחד מאלה:
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                1)
                                השימוש שלך בשירות;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                2)
                                הפרה מצדך של אחד או יותר מתנאים אלה;
                            </span>
                        </div>
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                3)
                                הפרה מצדן של זכות כלשהי של צד שלישי, לרבות ללא הגבלה כל זכות יוצרים, קניין רוחני או
                                פרטיות; וכן
                                כל תוכן שהעלית או שיתפת במסגרת השירות או באמצעותו.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        13. <span style="text-decoration: underline;">הודעות וניוזלטר </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                כתובת המייל ומספר הטלפון שתמסור לנו, ישמשו בין היתר כדי להציע לך שירותים שונים מעת לעת
                                ו/או הצעות שיווקיות שונות ו/או "דבר פרסומת" בהתאם לחוק התקשורת (בזק ושירותים),
                                התשמ"ב-1982, בכל אמצעי התקשורת המפורטים בחוק התקשורת, לרבות דואר אלקטרוני והודעת מסר
                                קצר, אלא אם תודיע לקארפיינד על אי רצונך לקבל הודעות מסוג זה. בנוסף, קארפיינד רשאית לשלוח
                                לך הודעות על שינויים בתנאים אלה או בנוגע לעניינים אחרים, לרבות הודעות הקשורות לשימוש
                                בשירות והודעות תפעוליות אחרות.
                                <br />
                                <br />
                                אתה מסכים שכל ההסכמים, ההודעות, הגילויים וכל תקשורת אחרת שקארפיינד שולחת כאמור לעיל
                                עומדים בכל דרישה חוקית שהתכתובת כאמור תהיה בכתב. כל ההודעות הנשלחות אליך יהוו משלוח
                                והודעה מספיקים ותקפים אליך, בין שנכנסת או קראת את ההודעה ובין שלא, וייחשבו כאילו נשלחו
                                אליך, בין שהתקבלו על ידך בפועל ובין שלא.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        14. <span style="text-decoration: underline;">כללי </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                על ידי שימוש בשירות לרבות ביקור באתר, הנך מסכים כי חוקי מדינת ישראל, ללא קשר לכללי ברירת
                                הדין וללא קשר למיקומך, ישלטו בתנאים אלה ובכל סכסוך מכל סוג שיעלה בינך לבין קארפיינד. כל
                                תביעה או סכסוך בינך לבין קארפיינד שינבע במלואו או בחלקו משימושך בשירות, יוכרע באופן
                                בלעדי על ידי בית משפט מוסמך הממוקם בתל אביב, ישראל, כשלבתי משפט אחרים לא תהיה סמכות,
                                ואתה מסכים בזאת ומוותר על כל טענה בדבר חוסר סמכות מקומית ופורום לא נאות ביחס למקום
                                וסמכות השיפוט של בתי המשפט של תל אביב, ישראל.
                                <br />
                                <br />
                                על אף כל חוק או חקיקה סותרים, אתה מסכים כי כל תביעה או עילת תביעה הנובעות או קשורות
                                לשירות תוגש על ידך תוך שנה אחת (1) לאחר התרחשות עילת התביעה, שאם לא כן תבוטל עילת התביעה
                                לצמיתות.
                                <br />
                                <br />
                                אי אכיפה על ידי קארפיינד של כל הוראה בתנאים אלה לא תהווה ויתור כל אכיפה נוספת של הוראה
                                זו או של כל הוראה אחרת. במידה וקארפיינד תחליט לחרוג מהתנאים, במקרים שיקבעו על פי שיקול
                                דעתה הבלעדי, לא יהיה בדבר כדי ליצור תקדים מחייב בכל צורה שהיא ו/או כדי לחייב אותה לפעול
                                באותו האופן או באופן דומה, בכל מקרה אחר. כל ויתור מצידה של קארפיינד בנוגע להפרה של תנאים
                                אלה לא יהווה ויתור בקשר עם הפרה אחרת של התנאים.
                                <br />
                                <br />
                                אם, מכל סיבה, בית משפט מוסמך יקבע שהוראה כלשהי בתנאים אלה בלתי תקפה או בלתי ניתנת
                                לאכיפה, הוראה זו תיאכף במידה האפשרית המותרת ושאר ההוראות בתנאים אלה יישארו במלוא התוקף.
                                תנאים אלה מהווים את מלוא ההבנה הבלעדית בין קארפיינד לבינך בנוגע לתוכנם והם מחליפים את כל
                                הצעות המחיר, ההסכמים, התכתובות או ההבנות, הקודמים או הנוכחיים, בין בכתב או בעל פה,
                                הקשורים לתוכנם.
                            </span>
                        </div>
                    </span>
                </div>

                <div class="text-content">
                    <span class="text">
                        15. <span style="text-decoration: underline;">יצירת קשר </span>
                        <br />
                        <br />
                        <div style="padding-right: 30px;">
                            <span>
                                במידה ויש לך שאלות בנוגע לתנאים אלו או בנוגע לכל נושא אחר בעניין השירות, אנא צור עמנו קשר ב: 03-5245241.                                <br />
                            </span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>

</div> 

<app-footer></app-footer>