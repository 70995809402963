import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit, OnDestroy {
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'thanks-page');
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  goToHomePage() {
    this.router.navigate(['']);
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.eventEmitter('ThankYou', 'Click', 'Call', 1);
      this.googleAnalyticsService.SendPhoneEvent();
      document.location.href = 'tel:035245241';
    }
  }
}
