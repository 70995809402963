import { Injectable } from '@angular/core';
import { BasicContactForm } from '../models/basicContactForm';
import { HttpService } from './http.service';
import { MessageService } from './message.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({ providedIn: 'root' })
export class DialogService {

    currentContact: BasicContactForm;

    constructor(
        public httpClient: HttpService
        , public googleAnalyticsService: GoogleAnalyticsService
        ,private messageService: MessageService
    ) {
    }

    getCurrentContact() {
        return this.currentContact;
    }

    setDialog(element: any, data: BasicContactForm, url: string, genreralDialog = true, showDealDialog = false, isMonthlyPayment = false) {
        if (data !== null && data.name !== undefined && data.phone !== undefined && data.name !== '' && data.phone !== '') {
            // this.currentContact = data;
            // element.setAttribute('data-toggle', 'modal');
            // element.setAttribute('data-target', '#services-dialog');
            // this.googleAnalyticsService.AddPageNavigation('services-dialog');
        } else {
            if (data !== null) {
                this.currentContact = data;
            }

            element.setAttribute('data-toggle', 'modal');
            if (url.split('/').length > 5 || (url.includes('/compare-cars') && !genreralDialog) || showDealDialog) {
                element.setAttribute('data-target', '#contact-dialog');
                this.googleAnalyticsService.AddPageNavigation('more-details-dialog');
            } else {
                element.setAttribute('data-target', '#deal-contact-dialog');
                this.googleAnalyticsService.AddPageNavigation('contact-us-dialog');
            }
        }
    }

    showContactDialog(element: any, data: BasicContactForm) {
        if (data !== undefined && data.name !== undefined && data.phone !== '') {
            this.currentContact = data;
        }

        element.setAttribute('data-toggle', 'modal');
        element.setAttribute('data-target', '#deal-contact-dialog');
        this.googleAnalyticsService.AddPageNavigation('contact-us-dialog');
    }

    showAlert(element: any, key: string) {
        this.messageService.sendMessage(key);

        element.setAttribute('data-toggle', 'modal');
        element.setAttribute('data-target', '#infoModalCenter');
        element.click();
    }

    removeAlert(element: any) {
        element.removeAttribute('data-toggle', 'modal');
        element.removeAttribute('data-target', '#infoModalCenter');
    }
}