import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '../../../../app/services/dialog.service';
import { Router } from '@angular/router';
import { MessageService } from '../../../../app/services/message.service';
import { Subscription } from 'rxjs';
import { CarDetailsService } from '../../../../app/services/car-details.service';
import { HttpService } from '../../../../app/services/http.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { ContactForm } from '../../../../app/models/contactForm';
import { threadId } from 'worker_threads';
import { UtilsService } from '../../../../app/services/utils.service';
import { BasicContactForm } from 'src/app/models/basicContactForm';

@Component({
  selector: 'app-generic-contact-form',
  templateUrl: './generic-contact-form.component.html',
  styleUrls: ['./generic-contact-form.component.css']
})
export class GenericContactFormComponent implements OnInit {

  contactForm: BasicContactForm;
  subscription: Subscription;
  messages: any[] = [];
  hasErrors = false;

  @Input()
  subTitle: string;

  constructor(private dialogService: DialogService
    , private router: Router
    , private messageService: MessageService
    , public carDetailsService: CarDetailsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , public utilsService: UtilsService
    , public httpClient: HttpService) {
  }

  ngOnInit() {
    this.contactForm = new BasicContactForm();
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('ContactWidget', 'Click', 'Call', 1);
  }

  submit(event) {
    const target = event.target || event.srcElement || event.currentTarget;    
    if (this.contactForm.name !== '' 
    && this.contactForm.phone !== ''
    && this.contactForm.name !== undefined
    && this.contactForm.phone !== undefined
    && this.utilsService.isValidPhoneNumber(this.contactForm.phone)) {
      this.contactForm.subject = 'CarFind Lead City+Manf+(Model)';
      this.contactForm.channelId = 5;
      this.contactForm.message = `רכב  ${this.subTitle}`;
      this.httpClient.SendBasicContactForm(this.contactForm).subscribe((result: any) => {
        this.googleAnalyticsService.SendCompletedConversionEvent();
        this.googleAnalyticsService.eventEmitter('ContactWidget', 'Click', 'Submit', 1);
        this.router.navigate(['/thanks']);
      });
    }
    else {
      this.hasErrors = true;
    }
  }
}
