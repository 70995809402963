<div class="contact-form-container">
  <div class="contact-form-title">
    לפרטים נוספים וייעוץ חינם לגבי
    <br />
    {{carInfo?.manufacturerName}} {{carInfo?.modelName}} ומבצעיו
    העדכניים</div>
  <div style="padding-top: 10px; text-align: center;">
    <span class="contact-form-call-title">
      התקשרו עכשיו <a (click)="callUs()" href="tel:035245241">03-524-5241</a>
    </span>
    <img src="assets/images/svg/ic-call-24-px.svg" alt="" style="padding-right: 5px; padding-bottom: 4px; width: 20px; height: 20px;" />
  </div>
  <div style="padding-top: 10px; text-align: center;">
    <span class="contact-form-description">
      או השאירו פרטים ומומחי הרכב
      <br />
      שלנו ישובו אליכם בהקדם:
    </span>
  </div>
  <div style="padding-top: 5px; text-align: center;">
    <input type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom" style="height: 36px" id="name"
      name="first_name" placeholder="שם" (focus)="setNameFocus()" [(ngModel)]="leadTicket.customerInfo.fullName"/>
  </div>
  <div style="padding-top: 5px; text-align: center;">
    <input type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom" style="height: 36px" id="name"
      name="first_name" placeholder="טלפון" (focus)="setPhoneFocus()" [(ngModel)]="leadTicket.customerInfo.phoneNumber"/>
  </div>
  <div *ngIf="hasErrors" style="padding-top: 15px; text-align: center; color: #ff2800;">
    אחד השדות חסר או אינו תקין
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <button type="button" (click)="submit($event)" class="contact-form-submit-button point" style="margin-top: 32px; border-radius: 6px;">
      <span class="contact-form-submit-text">צרו איתי קשר</span>
    </button>
  </div>
  <div class="contact-form-title" style="padding-top: 25px;">מתלבטים איזה רכב לקנות?</div>
  <div class="contact-form-text">
    התייעצו חינם עם מומחי הרכב שלנו למציאת
    <br />
    הרכב שמתאים לכם בעסקה המשתלמת ביותר
  </div>
</div>