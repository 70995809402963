import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { CatalogService } from '../../../services/catalog.service';
import { MessageService } from '../../../services/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { DialogService } from '../../../../app/services/dialog.service';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { UtilsService } from '../../../services/utils.service';
import { FilterType, SharedDataService } from '../../../services/shared-data.service';
import { TagItem } from '../../../models/tagItem';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';
declare var $: any;

@Component({
  selector: 'app-car-catalog-view',
  templateUrl: './car-catalog-view.component.html',
  styleUrls: ['./car-catalog-view.component.css']
})
export class CarCatalogViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('filtersModal') filtersModal: ElementRef;
  @ViewChild('filtersModalShowResult') filtersModalShowResult: ElementRef;
  @ViewChild('filtersModalClear') filtersModalClear: ElementRef;

  viewTitle = undefined;
  showDealerImages = true;
  filterTags: TagItem[] = [];
  isMobile = false;
  subscription: Subscription;
  messages: any[] = [];
  resultLabel: number;
  newUserCookie = 'new-user';
  whatsappButtonClass = 'whatsapp-button whatsapp-button-base';
  private eventsSubscriptionHandler = Subscription.EMPTY;
  isCatalogFiltered = false;
  showViewTitle = false;
  activeUrlSegments: UrlSegment[];
  isBlocked = false;
  filterDescription: string;

  constructor(
    private messageService: MessageService
    , public catalogService: CatalogService
    , private spinner: NgxSpinnerService
    , private router: Router
    , private route: ActivatedRoute
    , private ractivatedRoute: ActivatedRoute
    , public googleAnalyticsService: GoogleAnalyticsService
    , private dialogService: DialogService
    , private cookieService: CookieService
    , private titleService: Title
    , private meta: Meta
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isMobile = utilsService.IsMobile();
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'main-page');
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'loading-stop') {
        this.resultLabel = this.catalogService.GetTotalCarCount();
        this.generateTitleAndMetaTag(this.activeUrlSegments);

        if (this.activeUrlSegments.length > 0) {
          const dealType = this.activeUrlSegments[0].path;
          const newCarsText = 'מחפשים רכב חדש או רכב יד ראשונה? אתר CarFind מציג מגוון דגמים של רכבים חדשים של מיטב היצרנים. קטלוג הרכבים החדשים מכיל 313 דגמים ו-418 תצורות במחיר המפורסם הזול בישראל ועסקאות מימון רבות. מתלבטים בין דגמים? בצעו השוואת רכבים חדשים עם מאפיינים דומים כמו: צריכת דלק, נפח מנוע, סוג מנוע, נפח תא מטען, דרגת זיהום ועוד וקבלו החלטה נבונה. יש לכם שאלות? התקשרו לייעוץ חינם - 03-524-5241';
          const zeroKMText = 'רכישת רכב חדש עם אפס קילומטר היא דרך משתלמת להוזלת מחיר רכב חדש. מה זה רכב אפס ק"מ? חברות ליסינג רוכשות מיבואני הרכב מלאי מכוניות גדול, מהלך המוזיל את התשלום הסופי מהמחירים הרשמיים לצרכן כתוצאה מהנחה סיטונאית. חברות הליסינג מוכרות חלק מהרכבים שנקנו כחדשים עם 0 ק"מ והלקוח מקבל הנחה נוספת. בעת רכישת רכב 0 ק"מ מחברת ליסינג, הצרכן הופך להיות "יד שנייה" בבעלות על הרכב, למרות שהוא הראשון שנוהג במכונית. מחפשים רכב 0 קילומטר? ב- CarFind 63 מגוון הכוללים 72 תצורות של מיטב היצרנים במחיר המפורסם הזול בישראל ועסקאות מימון רבות. מתלבטים בין דגמים? בצעו השוואת רכבים 0 ק"מ וקבלו החלטה נבונה. יש לכם שאלות? התקשרו לייעוץ חינם - 03-524-5241';
          const carDealsText = 'אתר CarFind מציג לפניכם מבצעי רכב על מגוון דגמים של מיטב יצרניות הרכב בעולם. קטלוג הרכבים במבצע מכיל 66 דגמים ו-78 תצורות במחיר המפורסם הזול בישראל ועסקאות מימון רבות. מתלבטים בין דגמים? בצעו השוואת רכבים חדשים עם מאפיינים דומים וקבלו החלטה נבונה. יש לכם שאלות? התקשרו לייעוץ חינם - 03-524-5241';

          switch (dealType) {
            case SharedDataService.DealTypes[0]:
              this.filterDescription = newCarsText;
              break;
            case SharedDataService.DealTypes[1]:
              this.filterDescription = carDealsText;
              break;
            case SharedDataService.DealTypes[2]:
              this.filterDescription = zeroKMText;
              break;
          }

          if (this.activeUrlSegments.length >= 4) {
            this.showDealerImages = true;
            this.filterDescription = '';
          } else if (this.activeUrlSegments.length >= 1) {
            this.showDealerImages = false;
          }
        }

        if (isPlatformBrowser(this.platformId)) {
          const filterCount = this.catalogService.getFilterItemsCount();
          if (filterCount >= 4
            || this.router.url.includes('/favorites')
            || this.router.url.includes(SharedDataService.DealTypes[3])
            || this.catalogService.catalogFilter.SortBy !== undefined
            || this.isBlocked
          ) {
            window.addEventListener('load', () => {
              this.utilsService.encryptLinks('side-menu-filter');
            });

            $('#side-menu-filter').ready(() => {
              this.utilsService.encryptLinks('side-menu-filter');
            });
          }
        }

      } else if (message.text === 'show-filters-modal') {
        this.showFiltersModal();
      } else if (message.text === 'data-done') {
        this.showDealerImages = true;
      } else if (message.text === 'reset-filters') {
        this.isCatalogFiltered = false;
        this.viewTitle = '';
        this.filterTags = [];
      }
    });
  }

  ngOnInit() {
    this.utilsService.setSessionValue('page', 'catalog');
    let pageNumber = 0;
    this.route.queryParams
      .subscribe(params => {
        if (params.kw !== undefined) {
          this.viewTitle = `תוצאות חיפוש "${params.kw}"`;
          this.catalogService.generateFilter();
          this.filterTags = [];
          this.filterTags.push(this.utilsService.generateNewTag(this.viewTitle, this.viewTitle, false));
          this.isCatalogFiltered = true;
          pageNumber = 1;

          if (params.page !== undefined) {
            pageNumber = +params.page;
            this.catalogService.setPageNumber(pageNumber);
            return;
          }
        }
        if (params.page !== undefined) {
          this.catalogService.generateFilter();
          pageNumber = +params.page;
          if (pageNumber === 1) {
            this.utilsService.redirect('/', 301);
          }

          this.viewTitle = undefined;
          this.showViewTitle = false;
        }

        if (params.kw === undefined && params.page === undefined) {
          pageNumber = 1;
          this.viewTitle = undefined;
          this.showViewTitle = false;
        }
      });

    this.route.url.subscribe((segments: UrlSegment[]) => {
      if (this.utilsService.IsHomePage()) {
        if (window.location.toString().includes('?kw')) {
        } else {
          this.messageService.sendMessage('reset-search');
          this.viewTitle = undefined;
        }
      }

      if (this.utilsService.IsMobile()) {
        this.showViewTitle = true;
      }

      this.catalogService.generateFilter();
      if (pageNumber > 1) {
        this.catalogService.setPageNumber(pageNumber);
      }

      this.catalogService.parseFilterUrl(segments);
      this.activeUrlSegments = segments;

      if (!window.location.toString().includes('?kw')) {
        this.filterTags = this.catalogService.generateFilterTags(segments);
        this.isCatalogFiltered = false;
      }

      SharedDataService.DealTypes.forEach(deal => {
        if (this.router.url.includes(deal)) {
          this.isCatalogFiltered = true;
        }
      });

      if (this.router.url.includes(SharedDataService.DealTypes[3])) {
        this.utilsService.addNoIndexMeta(true);
      }

      if (!this.router.url.includes('/favorites') && !this.router.url.includes('?kw')) {
        if (this.filterTags !== undefined && this.filterTags.length > 0) {
          const breadcrumbPath: BreadcrumbSchemeItem[] = [];
          breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
          for (let i = 0; i < this.filterTags.length; i++) {
            const element = this.filterTags[i];
            breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(element.title, element.value, i === this.filterTags.length - 1));
          }
          this.utilsService.setBreadcrumbScheme(breadcrumbPath);
        }
      } else {
        const breadcrumbPath: BreadcrumbSchemeItem[] = [];
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('מועדפים', 'favorites', true));
        this.utilsService.setBreadcrumbScheme(breadcrumbPath);
      }

      if (!this.router.url.includes('?kw')) {
        this.messageService.sendMessage('refresh');
      }

      if (this.isMobile && this.utilsService.IsHomePage() && !this.isCatalogFiltered) {
        this.viewTitle = 'קארפיינד משווה למעלה ממאה סוכנויות למציאת המחיר הזול בישראל';
      }
    });

    this.catalogService.setDealType('new-cars');

    for (let i = 0; i < SharedDataService.DealTypes.length; i++) {
      if (this.router.url.includes(SharedDataService.DealTypes[i])) {
        this.catalogService.setDealType(SharedDataService.DealTypes[i]);
      }
    }

    if (!this.isCatalogFiltered) {
      const description = `CarFind מידע על רכבים חדשים ו- 0 ק"מ - מחירון, מפרט טכני, הצעות של למעלה ממאה סוכנויות ועוד. Car-Find מחירי כל הדגמים של מיטב היצרנים: טויוטה, יונדאי, קיה, התקשרו לייעוץ חינם 03-5245241`;
      const title = 'האתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ במחיר המפורסם הזול ביותר - Car-Find';
      this.utilsService.addMetaDescriptionTag(description);
      this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), title);
    }

    this.utilsService.createCanonicalURL();
  }

  generateTitleAndMetaTag(segments: UrlSegment[]) {
    let title = '';
    let description = '';

    const firstCar = this.catalogService.carResult[0];
    const secondCar = this.catalogService.carResult[1];
    const thirdCar = this.catalogService.carResult[2];
    const carLowestPrice = firstCar.zeroKMPricesCount > 0 ? firstCar.lowestZeroKMPrice : firstCar.basePrice;

    if (segments.length === 0) {
      title = 'האתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ במחיר המפורסם הזול ביותר';
    } else if (segments.length === 1) {
      switch (segments[0].path) {
        case SharedDataService.DealTypes[0]:
          title = 'רכבים חדשים במחיר המפורסם הזול בישראל';
          description = 'מחפשים רכב חדש? ב- CarFind רכבים חדשים במחיר המפורסם הזול בישראל. היכנסו לכל המידע הנחוץ לקניית רכב חדש של מיטב היצרנים. לרשותכם: מפרט, השוואה, ייעוץ חינם 03-5245241 ועוד';
          break;
        case SharedDataService.DealTypes[1]:
          title = 'מבצעי רכב חדש, כל הדגמים במחיר המפורסם הזול בישראל';
          description = 'מבצעי רכב חדש על מגוון דגמים של מיטב היצרנים ביניהם: טויוטה יאריס, קיה פיקנטו, יונדאי ועוד ב- CarFind. היכנסו, לקטלוג רכבים במבצע, בצעו השוואה וקבלו החלטה נבונה, לכל המידע >>';
          break;
        case SharedDataService.DealTypes[2]:
          title = 'רכבים חדשים 0 ק״מ מחברות ליסינג במחיר המפורסם הזול בישראל';
          description = 'רכב 0 קילומטר בוחרים עם .CarFind כל המידע הנחוץ לבחירת רכב 0 ק"מ מליסינג של מיטב היצרנים קיה, יונדאי, טויוטה ועוד. היכנסו לעסקאות משתלמות, מפרט, השוו דגמים מבוקשים ועוד >>';
          break;
        case SharedDataService.DealTypes[3]:
          title = 'מבצעי יבואן על רכב חדש - מכונית יד ראשונה במבצע';
          description = 'היכנסו לכל מבצעי רכב חדש מיבואן על מגוון דגמים של מיטב היצרנים במחיר המפורסם הזול בישראל. כל המידע הנחוץ לקניית רכב חדש, מפרט טכני, מחירים עדכניים ועוד ב - CarFind.';
          break;
      }
    } else if (segments.length === 2 && segments[0].path === 'favorites') {
      this.isCatalogFiltered = true;
      this.filterTags = [];
      this.filterTags.push(this.utilsService.generateNewTag('מועדפים', 'מועדפים', false));

      this.utilsService.addNoIndexMeta(true);
      title = 'הרכבים המועדפים עלי';
      description = 'הוסף למועדפים את גרסאות הרכבים המתאימים לך ביותר והתעדכן במחיר העסקאות השונות. קארפיינד אתר הרכב המקיף בישראל למציאת הדגם המתאים ביותר עבורך.';
    }
    else if (segments.length === 2) {
      if (segments[0].path == "used-cars") {
        title = 'מבצעי רכבים משומשים, כל הדגמים במחיר המפורסם הזול בישראל';
      } else {
        const filterValue = segments[1].path;
        const dealType = segments[0].path;

        if (this.isBlockedFilter(filterValue)) {
          this.isBlocked = true;
          this.utilsService.addNoIndexMeta(true);
          title = 'האתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ במחיר הזול ביותר';
          description = `כל המידע על ${SharedDataService.dealTypesCatalogFilterPluralValues[dealType]} ${this.getFilterValue(filterValue)} ב- CarFind. היכנסו לכל הדגמים של מיטב היצרנים: טויוטה, יונדאי, קיה והתקשרו לייעוץ חינם 03-5245241`;
        } else {
          title = `${SharedDataService.dealTypesCatalogFilterPluralValues[dealType]} ${this.getFilterValue(filterValue)} במחיר המפורסם הזול בישראל`;
          description = `${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${this.getFilterValue(filterValue)} במחיר המפורסם הזול בישראל, מפרט טכני, השוואת רכבים ב- CarFind. מחיר דגמי ${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${this.getFilterValue(filterValue)} החל מ-${this.utilsService.numberWithCommas(carLowestPrice)} ש"ח עבור ${firstCar.name}. למחירי ${secondCar !== undefined ? ', ' + secondCar.name : ''} ${thirdCar !== undefined ? ', ' + thirdCar.name : ''} ועוד היכנסו >>`;
        }
      }
    } else if (segments.length === 3 || segments.length === 4) {
      const dealType = segments[0].path;
      const values: string[] = [];
      for (let index = 1; index < segments.length; index++) {
        const element = segments[index].path;
        if (this.isBlockedFilter(element)) {
          this.isBlocked = true;
          this.utilsService.addNoIndexMeta(true);
          break;
        }

        values.push(this.getFilterValue(element));
      }

      if (this.isBlocked) {
        title = 'האתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ במחיר הזול ביותר';
      } else {
        title = `${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${values.join(' ')} במחיר המפורסם הזול בישראל`;
      }

      if (segments.length === 3) {
        const moreCars = `${secondCar !== undefined ? secondCar.name : ''}` + (thirdCar !== undefined ? `, ${thirdCar.name}` : '');
        description = `${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${values.join(' ')}, מפרט טכני, השוואת רכבים ב- CarFind. מחיר ${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${values.join(' ')} החל מ-${this.utilsService.numberWithCommas(carLowestPrice)} ש"ח עבור ${firstCar.name}. למחירי ${moreCars} ועוד היכנסו >>`;
      } else if (segments.length === 4) {
        this.utilsService.addNoIndexMeta(true);
        description = `${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${values.join(' ')} במחיר המפורסם הזול בישראל ב-CarFind. המחיר של ${SharedDataService.dealTypesCatalogFilterValues[dealType]} ${values.join(' ')} החל מ- ${this.utilsService.numberWithCommas(carLowestPrice)} ש"ח, היכנסו לכל הדגמים המובילים וקבלו הצעת מחיר >>`;
      }
    } else if (segments.length > 4) {
      title = 'קטלוג הרכב המקיף בישראל למציאת המכונית המתאימה ביותר עבורך';
      this.utilsService.addNoIndexMeta(true);
    }

    if (this.isCatalogFiltered) {
      this.utilsService.addMetaDescriptionTag(description);
      this.utilsService.generatePageTitle(title);
      this.utilsService.setOGMeta(`${description}`, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), title);
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  getFilterValue(filter: string) {
    const values: string[] = [];
    const pathValues = filter.split(',');
    pathValues.forEach(element => {
      if (SharedDataService.dealTypesCatalogFilterValues[element] !== undefined) {
        values.push(SharedDataService.dealTypesCatalogFilterValues[element]);
      } else if (SharedDataService.manufacturersCatalogFilterValues[element] !== undefined) {
        values.push(`${SharedDataService.manufacturersCatalogFilterValues[element]} ${this.utilsService.capitalizeString(element)}`);
      } else if (SharedDataService.engineCatalogFilterValues[element] !== undefined) {
        values.push(`מנוע ${SharedDataService.engineCatalogFilterValues[element]}`);
      } else if (SharedDataService.drivetrainCatalogFilterValues[element] !== undefined) {
        values.push(SharedDataService.drivetrainCatalogFilterValues[element]);
      } else if (SharedDataService.transmissionCatalogFilterValues[element] !== undefined) {
        values.push(SharedDataService.transmissionCatalogFilterValues[element]);
      } else if (SharedDataService.seatsCatalogFilterValues[element] !== undefined) {
        values.push(`${SharedDataService.seatsCatalogFilterValues[element]} מושבים`);
      } else if (SharedDataService.bodyTypesCatalogFilterValues[element] !== undefined) {
        values.push(SharedDataService.bodyTypesCatalogFilterValues[element]);
      } else if (filter.startsWith('price-')) {
        values.push(`מחיר ${this.utilsService.numberWithCommas(filter.replace('price-', ''))} ש״ח`);
      } else if (filter.startsWith('kml-')) {
        values.push(`${filter.replace('kml-', '')} ק״מ לליטר`);
      } else if (filter.startsWith('boot-')) {
        values.push(`${filter.replace('boot-', '')} ליטר מטען`);
      } else if (filter.startsWith('length-')) {
        values.push(`${filter.replace('length-', '')} ס״מ אורך`);
      }
    });

    const value = values.join(' ');
    return value;
  }

  isBlockedFilter(filter: string) {
    return filter.startsWith('price-')
      || filter.startsWith('kml-')
      || filter.startsWith('boot-')
      || filter.startsWith('length-');
  }

  showFiltersModal() {
    this.filtersModal.nativeElement.style.display = 'block';
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Filter', 1);
  }

  hideFiltersModal() {
    this.filtersModal.nativeElement.style.display = 'none';
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Hazeg', 1);
  }

  Search(event) {
    const searchKeyword = event.target.value;
    this.catalogService.Search(searchKeyword);
    this.messageService.sendMessage('refresh');
  }

  resetFilters() {
    this.messageService.sendMessage('reset-filters');
    this.hideFiltersModal();
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Apes', 1);
  }

  goToPage(path) {
    this.router.navigate(['/' + path]);
  }

  goToLeasingPage() {
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Agencies', 1);
    this.goToPage('car-agencies');
  }

  resetSearch() {
    if (isPlatformBrowser(this.platformId)) {
      $('#mainsearch').val('');
      $('#mainsearchmobile').val('');
      $('#resultSearchMobile').val('');
      $('#resultSearch').val('');
    }
  }

  onFilterTagRemove(tag: TagItem) {
    this.resetSearch();
    if (tag.filterType === FilterType.DealType) {
      this.messageService.sendMessage('reset-filters');
      this.router.navigate(['']);
    } else {
      this.catalogService.removeFilterItem(tag.value);
    }
    return false;
  }

  generateTitle(): TagItem[] {
    return this.filterTags;
  }

  generateTags(): TagItem[] {

    const filterTagsPath: TagItem[] = [];
    const rootTag = new TagItem();
    rootTag.isRoot = true;
    rootTag.title = 'ראשי';
    rootTag.value = '';
    filterTagsPath.push(rootTag);

    this.filterTags.forEach(element => {
      filterTagsPath.push(element);
    });

    return filterTagsPath;
  }
}