import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogFilter } from '../models/catalogFilter';
import { BasicContactForm } from '../models/basicContactForm';
import { ContactForm } from '../models/contactForm';
import { CarInformationContactForm } from '../models/carInformationContactForm';
import { LeadTicket } from '../models/leadTicket';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CarFindCarVersionBasicInfo } from '../models/carSubModelBasicInfo';
import { environment } from './../../environments/environment';
import { CarFindDealerLeadTicket } from '../models/CarFindDealerLeadTicket';
import { Dealers01Query } from '../models/dealers01Query';

const endpoint = environment.apiEndpointUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private http: HttpClient, private transferState: TransferState, @Inject(PLATFORM_ID) public platformId: Object) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  compare(carIds: number[]): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('compare');
    const url = endpoint + '/CarComparison/';

    if (isPlatformServer(this.platformId)) {
      return this.http.post(url, carIds).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.post(url, carIds).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getSubModelsById(carIds: number[]): Observable<any> {
    return this.http.post(endpoint + '/CarCatalog/versions/info/', carIds).pipe(
      map(this.extractData));
  }

  getCarModelsCategoryList(): Observable<any> {
    return this.http.get(endpoint + '/CarCatalog/models/list').pipe(
      map(this.extractData));
  }

  getNewsItems(): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getNewsItems');
    const url = endpoint + '/news';
    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getCarFindCarVersions(id: number): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getCarFindCarVersions');
    const url = endpoint + '/CarCatalog/versions/' + id;

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getCarFindCarVersionsData(manufacturer: string, model: string, bodyType: string, engine: string, doors: number, year: number = 0): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getCarFindCarVersionsData');
    let url = encodeURI(endpoint + `/CarCatalog/versions/${manufacturer}/${model}/${bodyType}/${engine}/${doors}`);
    if (year > 0) {
      url = `${url}/${year}`;
    }

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getCarFindCarVersionsDataEx(manufacturer: string, pmodel: string, model: string, bodyType: string, engine: string, doors: number, year: number = 0): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getCarFindCarVersionsDataEx');
    let url = encodeURI(endpoint + `/CarCatalog/versions/${manufacturer}/${pmodel}/${model}/${year}/${bodyType}/${engine}/${doors}`);
    // if (year > 0) {
    //   url = `${url}/${year}`;
    // }

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getCarModelInfo(configurationId: number): Observable<any> {
    return this.http.get(endpoint + `/CarCatalog/model/${configurationId}`).pipe(
      map(this.extractData));
  }

  getCatalogDataByFilter(filter: CatalogFilter): Observable<any> {
    const CAR_CATALOG_KEY: StateKey<any> = makeStateKey<any>('getCatalogDataByFilter');
    const url = endpoint + '/CarCatalog/catalog/filter/';

    if (isPlatformServer(this.platformId)) {

      return this.http.post(url, filter).pipe(
        map((data: Response) => {
          this.transferState.set(CAR_CATALOG_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CAR_CATALOG_KEY)) {
      return this.http.post(url, filter).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const cars = this.transferState.get<any>(CAR_CATALOG_KEY, null as any);
      this.transferState.remove(CAR_CATALOG_KEY);
      return of(cars);
    }
  }

  getCatalogFilterCountData(filter: CatalogFilter): Observable<any> {
    const CAR_CATALOG_KEY: StateKey<any> = makeStateKey<any>('getCatalogFilterCountData');
    const url = endpoint + '/CarCatalog/catalog/filter/count';

    if (isPlatformServer(this.platformId)) {

      return this.http.post(url, filter).pipe(
        map((data: Response) => {
          this.transferState.set(CAR_CATALOG_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CAR_CATALOG_KEY)) {
      return this.http.post(url, filter).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const cars = this.transferState.get<any>(CAR_CATALOG_KEY, null as any);
      this.transferState.remove(CAR_CATALOG_KEY);
      return of(cars);
    }
  }

  getSelectedCarIdInfo(info: CarFindCarVersionBasicInfo): Observable<any> {
    return this.http.post(endpoint + '/CarCatalog/catalog/model', info).pipe(
      map(this.extractData));
  }

  getLeadTicketInfo(id: string): Observable<any> {
    return this.http.get(endpoint + `/LeadTicket/${id}`).pipe(
      map(this.extractData));
  }

  updateLeadTicketResponse(leadTicket: LeadTicket): Observable<any> {
    return this.http.put(endpoint + `/LeadTicket/status/update`, leadTicket).pipe(
      map(this.extractData));
  }

  updateLeadTicketDeclineResponse(leadTicket: LeadTicket): Observable<any> {
    return this.http.post(endpoint + `/LeadTicket/status/update/no/${leadTicket.id}`, leadTicket).pipe(
      map(this.extractData));
  }

  getCarInfo(id: number): Observable<any> {
    return this.http.get(endpoint + '/car' + id).pipe(
      map(this.extractData));
  }

  SendBasicContactForm(data: BasicContactForm): Observable<any> {
    return this.http.post(endpoint + '/Support/contact/base', data).pipe(
      map(this.extractData));
  }

  SendLeadTicket(data: LeadTicket, type: string): Observable<any> {
    return this.http.post(endpoint + '/LeadTicket/' + type, data).pipe(
      map(this.extractData));
  }

  SendContactForm(data: ContactForm): Observable<any> {
    return this.http.post(endpoint + '/Support/contact/', data).pipe(
      map(this.extractData));
  }

  SendServicesContactForm(data: CarInformationContactForm): Observable<any> {
    return this.http.post(endpoint + '/LeadTicket/contact/services', data).pipe(
      map(this.extractData));
  }

  getManufacturerList(): Observable<any> {
    return this.http.get(endpoint + '/CarCatalog/manufacturer/list').pipe(
      map(this.extractData));
  }

  getModelList(name: string): Observable<any> {
    return this.http.get(endpoint + '/CarCatalog/configuration/' + name).pipe(
      map(this.extractData));
  }

  getSubModelrList(configurationId: number): Observable<any> {
    return this.http.get(endpoint + '/CarCatalog/versions/configuration/' + configurationId).pipe(
      map(this.extractData));
  }

  getSearchSuggestions(keyword: string): Observable<any> {
    return this.http.get(endpoint + '/CarCatalog/search/suggestions/' + keyword).pipe(
      map(this.extractData));
  }

  getDealersInfo(): Observable<any> {
    return this.http.get(endpoint + '/Common/dealers/').pipe(
      map(this.extractData));
  }

  getYeshuvimList(): Observable<any> {
    return this.http.get(endpoint + '/Common/yeshuvim/').pipe(
      map(this.extractData));
  }

  getManufacturerInfo(name: string, year: number): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getManufacturerInfo');
    const url = endpoint + `/CarCatalog/catalog/manufacturer/${name}/${year}`;

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getConfigurationInfoByManufacturer(manufacturer: string): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getConfigurationInfoByManufacturer');
    const url = endpoint + `/CarCatalog/catalog/configuration/${manufacturer}`;

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getConfigurationInfoByModel(manufacturer: string, model: string, year: number): Observable<any> {
    const CACHE_KEY: StateKey<any> = makeStateKey<any>('getConfigurationInfoByModel');
    const url = endpoint + `/CarCatalog/catalog/configuration/${manufacturer}/${model}/${year}`;

    if (isPlatformServer(this.platformId)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          this.transferState.set(CACHE_KEY, data);
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId) && !this.transferState.hasKey(CACHE_KEY)) {
      return this.http.get(url).pipe(
        map((data: Response) => {
          return data;
        })
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const data = this.transferState.get<any>(CACHE_KEY, null as any);
      this.transferState.remove(CACHE_KEY);
      return of(data);
    }
  }

  getConfigurationUrl(configurationId: number, submodelId: number): Observable<any> {
    return this.http.get(endpoint + `/CarCatalog/catalog/configuration/url/${configurationId}/${submodelId}`).pipe(
      map(this.extractData));
  }

  getDealerLeadTickets(): Observable<any> {
    return this.http.get(endpoint + `/Dealers/leads/`).pipe(
      map(this.extractData));
  }

  getMagazineArticleById(articleId: string): Observable<any> {
    return this.http.get(endpoint + `/Magazine/${articleId}`).pipe(
      map(this.extractData));
  }

  search01Deals(query: Dealers01Query): Observable<any> {
    return this.http.post(endpoint + '/Dealers01Dashboard/', query).pipe(
      map(this.extractData));
  }

  getDealerLeadTicketHiddenInfo(leadId: number): Observable<any> {
    return this.http.get(endpoint + `/Dealers/leads/${leadId}`).pipe(
      map(this.extractData));
  }

  setDealerLeadTicketStatus(leadId: number, status: number): Observable<any> {
    return this.http.put(endpoint + `/Dealers/leads/${leadId}/status`, status).pipe(
      map(this.extractData));
  }

  setDealerLeadTicketRemark(lead: CarFindDealerLeadTicket): Observable<any> {
    return this.http.put(endpoint + `/Dealers/leads/${lead.id}/remark`, lead).pipe(
      map(this.extractData));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
