import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CatalogService } from '../../../services/catalog.service';
import { MessageService } from '../../../services/message.service';
import { ComparisonService } from '../../../services/comparison.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../../app/services/utils.service';
import { SharedDataService } from '../../../../app/services/shared-data.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})

export class SideMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('resultsearch') resultsearch: ElementRef;

  @ViewChild('categorySmallFilterItem') categorySmallFilterItem: ElementRef;
  @ViewChild('categoryFamilyFilterItem') categoryFamilyFilterItem: ElementRef;
  @ViewChild('categoryPremiumFilterItem') categoryPremiumFilterItem: ElementRef;
  @ViewChild('categorySuvFilterItem') categorySuvFilterItem: ElementRef;
  @ViewChild('categoryTrunkFilterItem') categoryTrunkFilterItem: ElementRef;
  @ViewChild('category7PlusFilterItem') category7PlusFilterItem: ElementRef;

  @ViewChild('ftBenzinFilterItem') ftBenzinFilterItem: ElementRef;
  @ViewChild('ftDieselFilterItem') ftDieselFilterItem: ElementRef;
  @ViewChild('ftHybridFilterItem') ftHybridFilterItem: ElementRef;
  @ViewChild('ftElectricFilterItem') ftElectricFilterItem: ElementRef;
  @ViewChild('ftPluginFilterItem') ftPluginFilterItem: ElementRef;

  @ViewChild('dealsFilterItem') allDealsFilterItem: ElementRef;
  @ViewChild('seatsThreePlusFilterItem') seatsThreePlusFilterItem: ElementRef;

  @ViewChild('transmissionManualFilterItem') transmissionManualFilterItem: ElementRef;
  @ViewChild('transmissionAutoFilterItem') transmissionAutoFilterItem: ElementRef;
  @ViewChild('transmissionFourFilterItem') transmissionFourFilterItem: ElementRef;
  @ViewChild('transmissionTwoFilterItem') transmissionTwoFilterItem: ElementRef;

  @ViewChild('dealCarDealsFilterItem') dealCarDealsFilterItem: ElementRef;


  @Input() isMobileMode: boolean = false;
  subscription: Subscription;
  messages: any[] = [];

  searchResultKeyword: string;
  smallCategoryCountLabel: string;
  familyCategoryCountLabel: string;
  premiumCategoryCountLabel: string;
  suvCategoryCountLabel: string;
  truckCategoryCountLabel: string;
  bhpMaxSliderLabel: string;
  kmlMaxSliderLabel: string;
  bootCapacityMaxSliderLabel: string;

  minPrice: number;
  maxPrice: number;
  priceValue: number[];

  minKML: number;
  maxKML: number;
  kmlValue: number[];

  minBootCapacity: number;
  maxBootCapacity: number;
  bootCapacityValue: number[];

  minLength: number;
  maxLength: number;
  LengthValue: number[];

  minSeats: number;
  maxSeats: number;
  seatsValue: number[];

  minBHP: number;
  maxBHP: number;
  bhpValue: number[];

  enabled: boolean = true;
  resultLabel: number;
  actionFromWizard = false;
  dealType = 'deal1';

  newCarsFilterCount: string;

  constructor(public httpClient: HttpService
    , public catalogService: CatalogService
    , private messageService: MessageService
    , public comparisonService: ComparisonService
    , private route: ActivatedRoute
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const isMobileDevice = this.utilsService.IsMobile();
    if (this.isMobileMode && isMobileDevice) {
      this.SubscribeToNotifications();
    } else if (!this.isMobileMode && !isMobileDevice) {
      this.SubscribeToNotifications();
    }

    this.ResetFilters();
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.checkDealType();
  }

  checkDealType() {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.catalogService.catalogFilter.DealType) {
        case SharedDataService.DealTypes[1]:
          $('#dealCarDealsFilterItem').prop('checked', true);
          break;
        case SharedDataService.DealTypes[2]:
          $('#dealLeasing0KmFilterItem').prop('checked', true);
          break;
        case SharedDataService.DealTypes[3]:
          $('#dealImporterDealsFilterItem').prop('checked', true);
          break;
        case SharedDataService.DealTypes[4]:
          $('#dealUsedCars2021FilterItem').prop('checked', true);
          break;
        default:
          $('#dealNewCarsFilterItem').prop('checked', true);
          break;
      }
    }
  }

  SubscribeToNotifications() {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text.includes('wizard-')) {
        this.actionFromWizard = true;
        const filterItemInfo = message.text.split('-');
        const filterType = filterItemInfo[1];
        const filterValue = filterItemInfo[2];
        switch (filterType) {
          case 'category':
            this.activateCategoryClick(filterValue);
            break;
          case 'fuelType':
            this.activateFuelTypeClick(filterValue);
            break;
          case 'deal':
            this.activateDealTypeClick(filterValue);
            break;
          case 'transmission':
            this.activateTransmissionTypeClick(filterValue);
            break;
          case 'price':
            this.setPricerange(filterItemInfo[2], filterItemInfo[3]);
            break;
          case 'paymentprice':
            this.setPaymentRange(filterItemInfo[2], filterItemInfo[3]);
            break;
          case 'seats':
            this.activateSeatsClick(filterValue);
            break;
        }
      } else {
        if (message.text === 'data-done') {
        } else if (message.text === 'reset-filters') {
          this.checkDealType();
          this.ResetFilters();
        } else if (message.text === 'refresh') {
          const filter = this.catalogService.getFilter();
          if (filter.MainSearchKeyword !== '' && filter.MainSearchKeyword !== undefined) {
            this.resultsearch.nativeElement.value = filter.MainSearchKeyword;
          }
        } else if (message.text === 'filter-count-refresh') {
        } else {
          this.messages = [];
        }
      }
    });
  }

  activateCategoryClick(categoryId: string) {
    let domElement: ElementRef;
    switch (categoryId) {
      case 'coupe':
        domElement = this.categorySmallFilterItem;
        break;
      case 'pickup':
        domElement = this.categoryFamilyFilterItem;
        break;
      case 'small':
        domElement = this.categoryPremiumFilterItem;
        break;
      case 'sedan':
        domElement = this.categorySuvFilterItem;
        break;
      case 'suv':
        domElement = this.categoryTrunkFilterItem;
        break;
      case '7plus':
        domElement = this.category7PlusFilterItem;
        break;
    }

    domElement.nativeElement.click();
  }

  activateFuelTypeClick(categoryId: string) {
    let domElement: ElementRef;
    switch (categoryId) {
      case '1':
        domElement = this.ftBenzinFilterItem;
        break;
      case '2':
        domElement = this.ftDieselFilterItem;
        break;
      case '3':
        domElement = this.ftHybridFilterItem;
        break;
      case '4':
        domElement = this.ftPluginFilterItem;
        break;
      case '5':
        domElement = this.ftElectricFilterItem;
        break;
    }

    domElement.nativeElement.click();
  }

  activateDealTypeClick(categoryId: string) {
    let domElement: ElementRef;
    switch (categoryId) {
      case '1':
        domElement = this.dealCarDealsFilterItem;
        domElement.nativeElement.click();
        break;
    }
  }

  activateSeatsClick(categoryId: string) {
    let domElement: ElementRef;
    switch (categoryId) {
      case '1':
        domElement = this.seatsThreePlusFilterItem;
        domElement.nativeElement.click();
        break;
    }
  }

  activateTransmissionTypeClick(categoryId: string) {
    let domElement: ElementRef;
    switch (categoryId) {
      case '1':
        domElement = this.transmissionManualFilterItem;
        break;
      case '2':
        domElement = this.transmissionAutoFilterItem;
        break;
      case '4':
        domElement = this.transmissionFourFilterItem;
        break;
      case '3':
        domElement = this.transmissionTwoFilterItem;
        break;
    }

    domElement.nativeElement.click();
  }

  ResetFilters() {
    this.bhpMaxSliderLabel = "ללא הגבלה";
    this.kmlMaxSliderLabel = "ללא הגבלה";
    this.bootCapacityMaxSliderLabel = "ללא הגבלה";

    this.minPrice = 60000;
    this.maxPrice = 300000;
    this.priceValue = [this.minPrice, this.maxPrice];

    this.minKML = 4;
    this.maxKML = 50;
    this.kmlValue = [this.minKML, this.maxKML];

    this.minBootCapacity = 80;
    this.maxBootCapacity = 999;
    this.bootCapacityValue = [this.minBootCapacity, this.maxBootCapacity];

    this.minLength = 260;
    this.maxLength = 750;
    this.LengthValue = [this.minLength, this.maxLength];

    this.minSeats = 2;
    this.maxSeats = 15;
    this.seatsValue = [this.minSeats, this.maxSeats];

    this.minBHP = 67;
    this.maxBHP = 800;
    this.bhpValue = [this.minBHP, this.maxBHP];

    this.enabled = true;
    this.actionFromWizard = false;

    if (this.allDealsFilterItem !== undefined) {
      this.allDealsFilterItem.nativeElement.checked = false;
    }

    if (this.allDealsFilterItem !== undefined) {
      this.allDealsFilterItem.nativeElement.value = '';
    }

    if (this.resultsearch !== undefined) {
      this.resultsearch.nativeElement.value = '';
    }
  }

  Search(event) {
    this.searchResultKeyword = event.target.value;
    this.catalogService.SearchInResult(this.searchResultKeyword);
    this.messageService.sendMessage('refresh');
  }

  toggleEngineFilterItem(key: string) {
    this.catalogService.toggleEngine(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Engine', 1);
    this.messageService.sendMessage(`catalog-filter-${key}`);
    this.resetSearch();
    this.catalogService.navigateToFilterPage();
    return false;
  }

  toggleDealtypeFilterItem(key: number) {
    const isClicked = this.catalogService.toggleDealType(key);
    this.messageService.sendMessage('refresh');
    this.resetSearch();

    if (!this.actionFromWizard || this.isMobileMode) {
      this.messageService.sendMessage(`catalog-filter-deal-${key}`);
    }
    this.actionFromWizard = false;
  }

  toggleDealTypeFilterItem() {
    this.catalogService.toggleDealType(1);
    this.catalogService.toggleDealType(2);
    this.messageService.sendMessage('refresh');
    this.resetSearch();

    if (!this.actionFromWizard || this.isMobileMode) {
      this.messageService.sendMessage(`catalog-filter-deal`);
    }
    this.actionFromWizard = false;
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Checkbox', 1);
  }

  toggleSeatsFilterItem(key: string) {
    const isClicked = this.catalogService.toggleSeats(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Seats', 1);
    this.messageService.sendMessage(`catalog-filter-seats`);
    this.resetSearch();
    this.catalogService.navigateToFilterPage();
    return false;
  }

  toggleTransmissionFilterItem(key: string) {
    const isClicked = this.catalogService.toggleTransmission(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Gear', 1);
    this.messageService.sendMessage(`catalog-filter-trans`);
    this.resetSearch();
    this.catalogService.navigateToFilterPage();
    return false;
  }

  toggleYearFilterItem(key: number) {
    const isClicked = this.catalogService.toggleYear(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Gear', 1);
    this.messageService.sendMessage(`catalog-filter-trans`);
    this.resetSearch();
    this.catalogService.navigateToFilterPage();
    return false;
  }

  toggleDrivetrainFilterItem(key: string) {
    const isClicked = this.catalogService.toggleDrivetrain(key);
    this.messageService.sendMessage(`catalog-filter-dt`);
    this.messageService.sendMessage('refresh');
    this.resetSearch();

    this.catalogService.navigateToFilterPage();
    return false;
  }

  priceSlideStop(event) {
    let maxPrice = 0;
    let minPrice = 0;

    if (event[0] !== this.minPrice) {
      minPrice = event[0];
    } else {
      minPrice = 0;
    }

    if (event[1] === this.maxPrice) {
      maxPrice = 0;
    } else {
      maxPrice = event[1];
      minPrice = event[0];
    }

    this.bhpMaxSliderLabel = maxPrice === 0 ? 'ללא הגבלה' : maxPrice.toLocaleString();
    this.priceValue[0] = minPrice;

    const pricerange: number[] = [minPrice, maxPrice];
    this.catalogService.setPrice(pricerange);
    this.resetSearch();
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Price', 1);
    this.catalogService.navigateToFilterPage();
  }

  setPricerange(minPrice, maxPrice) {
    this.priceValue = [+minPrice, +maxPrice];
    this.priceSlideChange(null);
    this.catalogService.setPrice(this.priceValue);
    this.catalogService.navigateToFilterPage();
  }

  setPaymentRange(minPrice, maxPrice) {
    this.priceValue = [+minPrice, +maxPrice];
    this.catalogService.setPaymentPrice(this.priceValue);
    this.catalogService.navigateToFilterPage();
  }

  priceSlideChange(event) {
    if (this.priceValue[1] !== this.maxPrice) {
      this.bhpMaxSliderLabel = this.priceValue[1].toLocaleString();
    } else if (this.priceValue[1] === this.maxPrice) {
      this.bhpMaxSliderLabel = 'ללא הגבלה';
    }
  }

  kmlSlideChange(event) {
    if (this.kmlValue[1] !== this.maxKML) {
      this.kmlMaxSliderLabel = this.kmlValue[1].toLocaleString();
    } else if (this.kmlValue[1] === this.maxKML) {
      this.kmlMaxSliderLabel = 'ללא הגבלה';
    }
  }

  bootSlideChange(event) {
    if (this.bootCapacityValue[1] !== this.maxBootCapacity) {
      this.bootCapacityMaxSliderLabel = `${this.bootCapacityValue[1].toLocaleString()} ליטר`;
    } else if (this.bootCapacityValue[1] === this.maxBootCapacity) {
      this.bootCapacityMaxSliderLabel = 'ללא הגבלה';
    }
  }

  kmlSlideStop(event) {

    let maxKML = 0;
    let minKML = 0;

    if (event[0] !== this.minKML) {
      minKML = event[0];
    } else {
      minKML = 0;
    }

    if (event[1] === this.maxKML) {
      maxKML = 0;
    } else {
      maxKML = event[1];
      minKML = event[0];
    }

    this.kmlMaxSliderLabel = maxKML.toLocaleString();
    this.kmlValue[0] = minKML;

    const pricerange: number[] = [minKML, maxKML];
    this.catalogService.setKML(pricerange);
    this.resetSearch();
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'KML', 1);

    this.catalogService.navigateToFilterPage();
  }

  bootCapacitySlideStop(event) {

    let maxBootCapacity = 0;
    let minBootCapacity = 0;

    if (event[0] !== this.minBootCapacity) {
      minBootCapacity = event[0];
    } else {
      minBootCapacity = 0;
    }

    if (event[1] === this.maxBootCapacity) {
      maxBootCapacity = 0;
    } else {
      maxBootCapacity = event[1];
      minBootCapacity = event[0];
    }

    this.bootCapacityMaxSliderLabel = maxBootCapacity.toLocaleString();
    this.bootCapacityValue[0] = minBootCapacity;

    const pricerange: number[] = [minBootCapacity, maxBootCapacity];
    this.catalogService.setBootCapacity(pricerange);
    this.resetSearch();
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Trunk', 1);
    this.catalogService.navigateToFilterPage();
  }

  lengthSlideStop(event) {
    this.catalogService.setLength(event);
    this.resetSearch();
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Length', 1);
    this.catalogService.navigateToFilterPage();
  }

  seatsSlideStop(event) {
    this.catalogService.setSeats(event);
    this.resetSearch();
    this.messageService.sendMessage('refresh');
  }

  bhpSlideStop(event) {
    this.catalogService.setBHP(event);
    this.resetSearch();
    this.messageService.sendMessage('refresh');
  }

  toggleEnginesTitleClass() {
    const hasItems = this.catalogService.isEngineSelected();
    if (!hasItems)
      return "side-nav-title selected-filter-item point"
    else {
      return "side-nav-title point";
    }
  }

  toggleTransmissionTitleClass() {
    const hasItems = this.catalogService.isTransmissionSelected();
    if (!hasItems)
      return "side-nav-title selected-filter-item point"
    else {
      return "side-nav-title point";
    }
  }

  toggleSeatsTitleClass() {
    const hasItems = this.catalogService.isSeatsSelected();
    if (!hasItems)
      return "side-nav-title selected-filter-item point"
    else {
      return "side-nav-title point";
    }
  }

  toggleModelsTitleClass() {
    const hasItems = this.catalogService.isModelsSelected();
    if (!hasItems)
      return "side-nav-title selected-filter-item point"
    else {
      return "side-nav-title point";
    }
  }

  isModelsSelected() {
    return this.catalogService.isModelsSelected();
  }

  toggleCategoryTitleClass() {
    const hasItems = this.catalogService.isCategorySelected();
    if (!hasItems) {
      return 'side-nav-title selected-filter-item point';
    } else {
      return 'side-nav-title point';
    }
  }

  toggleSeatsFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isSeatsFilterExist(key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  toggleEngineFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isEngineFilterExist(key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  toggleTransmissionFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isTransmissionFilterExist(key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  toggleYearFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isYearFilterExist(+key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  toggleDrivetrainFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isDrivetrainFilterExist(key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  clearAllModelCategoryFilterItem() {
    this.catalogService.clearAllModelCategory();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  clearAllCategoryFilterItem() {
    this.catalogService.clearCategories();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  clearAllEngineCategoryFilterItem() {
    this.catalogService.clearAllEngineCategory();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  clearAllDealerCategoryFilterItem() {
    this.catalogService.clearAllDealerCategory();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  clearAllTransmissionCategoryFilterItem() {
    this.catalogService.clearAllTransmissionCategory();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  clearAllSeatsCategoryFilterItem() {
    this.catalogService.clearAllSeatsCategory();
    this.resetSearch();
    this.messageService.sendMessage('refresh');
    this.catalogService.navigateToFilterPage();
  }

  setSearchFocus() {
    this.googleAnalyticsService.eventEmitter('Filter', 'Focus', 'Search', 1);
  }

  setDealType(value: string) {
    this.catalogService.setDealType(value);
    if (value == 'used-cars') {
      this.catalogService.toggleYear(2021);
    }

    this.resetSearch();
    this.messageService.sendMessage(`catalog-filter-deal`);
    this.catalogService.navigateToFilterPage();

    this.checkDealType();
  }

  setDealTypeLink(value: string) {
    this.setDealType(value);
    if (value == 'used-cars') {
      this.catalogService.toggleYear(2021);
    }
    return false;
  }

  toggleDealTypeFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled option-disabled';
    }

    return 'deal-type-option';
  }

  toggleCategoryFilterItemClass(key: string) {
    if (+this.getFilterItemCount(key) === 0) {
      return 'disabled';
    }

    const isClicked = this.catalogService.isCategoryFilterExist(key);
    if (isClicked)
      return "selected-filter-item point"
    else {
      return "side-nav-item point";
    }
  }

  toggleCategoryFilterItem(key: string) {
    this.resetSearch();
    const isClicked = this.catalogService.toggleCategory(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'click', 'Category', 1);
    this.messageService.sendMessage(`catalog-filter-category-${key}`);
    this.catalogService.navigateToFilterPage();
    return false;
  }

  resetSearch() {
    if (isPlatformBrowser(this.platformId)) {
      $('#mainsearch').val('');
      $('#mainsearchmobile').val('');
      $('#resultSearchMobile').val('');
      $('#resultSearch').val('');
    }
  }

  getFilterItemCount(key: string) {
    return this.catalogService.getFilterItemCount(key);
  }

  getFilterItemUrl(item: string, key: string, id: number) {
    if (+this.getFilterItemCount(key) > 0) {
      return this.catalogService.urlBuilder(key, id);
    } else {
      if (isPlatformBrowser(this.platformId)) {
        const href = $(`#${item}`).attr('href');
        if (href !== undefined) {
          $(`#${item}`).removeAttr('href');
        }
      }
    }
  }

  setDealTypeStatus(item: string, key: string) {
    if (isPlatformBrowser(this.platformId)) {
      $(`#${item}`).attr('disabled', +this.getFilterItemCount(key) === 0);
      return 'form-check-input';
    }
  }

  getFilterItemCountText(key: string) {
    // return '';
    const value = +this.getFilterItemCount(key);
    if (value === 0) {
      return '';
    } else {
      return `(${value})`;
    }
  }

  getManufacturerFilterTitleText() {
    const hasItems = this.catalogService.isModelsSelected();
    if (!hasItems)
      return "סינון על-פי יצרן"
    else {
      return "כל היצרנים";
    }
  }
}
