import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { CatalogFilter } from '../models/catalogFilter';
import { HttpService } from '../services/http.service';
import { FilterType, SharedDataService } from '../services/shared-data.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { TagItem } from '../models/tagItem';
import { UtilsService } from './utils.service';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { CarFindCarVersion } from '../models/CarFindCarVersion';
import { CarFindCarVersionBasicInfo } from '../models/carSubModelBasicInfo';

@Injectable({ providedIn: 'root' })
export class CatalogService {
    catalogFilter: CatalogFilter;
    carResult: CarFindCarVersion[];

    private isResulrFiltered: boolean;
    private allCarResult: CarFindCarVersion[];
    private currentDataPageNumber = 1;
    public defaultPaymentBalloonValue = 30;
    public defaultPaymentAdvanceValue = 40000;
    private carCategoryCount: number[];
    private filtetItemCount: Record<string, Partial<string>>;
    private totalResultCount: number;
    private carsPerPage = 20;
    private sortByProperty: (c: CarFindCarVersion) => {};
    private sortByDirection: string;
    private selectedDealType: string;
    private filterItemsCount: number;

    ALBUM_PHOTOS_KEY: StateKey<any> = makeStateKey<any>('test');

    constructor(public httpClient: HttpService
        , private router: Router
        , private utilsService: UtilsService
        , private transferState: TransferState
        , @Inject(PLATFORM_ID) private platformId: Object) {
        this.generateFilter();
    }

    IsLastPage(): boolean {
        const result = (((this.catalogFilter.PageNumber - 1) * this.carsPerPage) >= this.totalResultCount);
        return result;
    }

    IsPageExist(): boolean {
        const result = Math.round((this.totalResultCount / this.carsPerPage)) >= this.catalogFilter.PageNumber;
        return result;
    }

    GetTotalCarCount(): number {
        return this.totalResultCount;
    }

    GetCarCount(): number {
        return this.carResult.length;
    }

    IsResulrFiltered(): boolean {
        return this.isResulrFiltered;
    }

    ResetFilter() {
        this.sortByProperty = undefined;
        this.sortByDirection = '';
    }

    SetResultFilterStatus(status: boolean) {
        this.isResulrFiltered = status;
    }

    Search(keyword: string) {
        this.ResetFilter();
        this.SetResultFilterStatus(true);
        this.catalogFilter.MainSearchKeyword = keyword;
    }

    getFilterItemCount(key: string) {
        if (this.filtetItemCount !== undefined) {
            return this.filtetItemCount[key];
        }
        return 0;
    }

    SearchInResult(keyword: string) {
        this.catalogFilter.PageNumber = 1;
        this.catalogFilter.SecondarySearchKeyword = keyword;
        this.SetResultFilterStatus(true);
    }

    isCategoryFilterExist(name: string): boolean {
        return this.catalogFilter.Categories.includes(name);
    }

    toggleCategory(name: string): boolean {
        let isClicked: boolean;
        if (this.catalogFilter.Categories.includes(name)) {
            this.catalogFilter.Categories.splice(this.catalogFilter.Categories.indexOf(name), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Categories.push(name);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    toggleManufacturerCategory(manufacturer: string): boolean {
        if (manufacturer === '' || manufacturer === undefined || !SharedDataService.EngManufacturerList.includes(manufacturer)) {
            return false;
        }

        manufacturer = manufacturer.replace(/ /g, '-');
        let isClicked: boolean;
        if (this.catalogFilter.Manufacturers.includes(manufacturer)) {
            this.catalogFilter.Manufacturers.splice(this.catalogFilter.Manufacturers.indexOf(manufacturer), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Manufacturers.push(manufacturer);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    clearAllModelCategory() {
        this.catalogFilter.Manufacturers = [];
    }

    clearCategories() {
        this.catalogFilter.Categories = [];
    }

    clearAllEngineCategory() {
        this.catalogFilter.Engines = [];
    }

    clearAllDealerCategory() {
    }

    clearAllTransmissionCategory() {
        this.catalogFilter.Transmissions = [];
    }

    clearAllSeatsCategory() {
        this.catalogFilter.Seats = [];
    }

    toggleDealType(engine: number): boolean {
        let isClicked: boolean;
        return isClicked;
    }

    toggleEngine(engine: string): boolean {
        let isClicked: boolean;

        if (this.catalogFilter.Engines.includes(engine)) {
            this.catalogFilter.Engines.splice(this.catalogFilter.Engines.indexOf(engine), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Engines.push(engine);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    toggleTransmission(name: string): boolean {
        let isClicked: boolean;
        if (this.catalogFilter.Transmissions.includes(name)) {
            this.catalogFilter.Transmissions.splice(this.catalogFilter.Transmissions.indexOf(name), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Transmissions.push(name);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    toggleYear(year: number): boolean {
        let isClicked: boolean;
        // if (this.catalogFilter.years.includes(year)) {
        //     this.catalogFilter.years.splice(this.catalogFilter.years.indexOf(year), 1);
        //     isClicked = false;
        // } else {
        //     this.catalogFilter.years.push(year);
        //     isClicked = true;
        // }

        this.catalogFilter.years.splice(0);
        this.catalogFilter.years.push(year);
        isClicked = true;

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    toggleSeats(name: string): boolean {
        let isClicked: boolean;
        if (this.catalogFilter.Seats.includes(name)) {
            this.catalogFilter.Seats.splice(this.catalogFilter.Seats.indexOf(name), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Seats.push(name);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    toggleDrivetrain(name: string): boolean {
        let isClicked: boolean;
        if (this.catalogFilter.Drivetrains.includes(name)) {
            this.catalogFilter.Drivetrains.splice(this.catalogFilter.Drivetrains.indexOf(name), 1);
            isClicked = false;
        } else {
            this.catalogFilter.Drivetrains.push(name);
            isClicked = true;
        }

        this.SetResultFilterStatus(true);
        return isClicked;
    }

    setDealType(value: string) {
        this.selectedDealType = value;
        this.catalogFilter.DealType = value;

        if (value != 'used-cars') {
            this.catalogFilter.years.splice(0);
        }
    }

    setPrice(priceRange: number[]) {
        this.catalogFilter.MinPrice = priceRange[0];
        this.catalogFilter.MaxPrice = priceRange[1] === 300000 ? 0 : priceRange[1];
        this.SetResultFilterStatus(true);
    }

    setPaymentPrice(priceRange: number[]) {
        this.catalogFilter.MinPaymentPrice = priceRange[0];
        this.catalogFilter.MaxPaymentPrice = priceRange[1] === 4000 ? 0 : priceRange[1];
        this.SetResultFilterStatus(true);
    }

    setKML(kmlRange: number[]) {
        this.catalogFilter.MinKML = kmlRange[0];
        this.catalogFilter.MaxKML = kmlRange[1];
        this.SetResultFilterStatus(true);
    }

    setBootCapacity(bcRange: number[]) {
        this.catalogFilter.MinBootCapacity = bcRange[0];
        this.catalogFilter.MaxBootCapacity = bcRange[1];
        this.SetResultFilterStatus(true);
    }

    setLength(lengthRange: number[]) {
        this.catalogFilter.MinLength = lengthRange[0];
        this.catalogFilter.MaxLength = lengthRange[1];
        this.SetResultFilterStatus(true);
    }

    setSeats(seatsRange: number[]) {
        this.catalogFilter.MinSeats = seatsRange[0];
        this.catalogFilter.MaxSeats = seatsRange[1];
        this.SetResultFilterStatus(true);
    }

    setBHP(bhpRange: number[]) {
        this.catalogFilter.MinBHP = bhpRange[0];
        this.catalogFilter.MaxBHP = bhpRange[1];
        this.SetResultFilterStatus(true);
    }

    isSeatsFilterExist(seats: string): boolean {
        return this.catalogFilter.Seats.includes(seats);
    }

    isEngineSelected(): boolean {
        return this.catalogFilter.Engines.length > 0;
    }

    isTransmissionSelected(): boolean {
        return this.catalogFilter.Transmissions.length > 0;
    }

    isCategorySelected(): boolean {
        return this.catalogFilter.Categories.length > 0;
    }

    isSeatsSelected(): boolean {
        return this.catalogFilter.Seats.length > 0;
    }

    isModelsSelected(): boolean {
        return this.catalogFilter.Manufacturers.length > 0;
    }

    isEngineFilterExist(engine: string): boolean {
        return this.catalogFilter.Engines.includes(engine);
    }

    isTransmissionFilterExist(engine: string): boolean {
        return this.catalogFilter.Transmissions.includes(engine);
    }

    isYearFilterExist(year: number): boolean {
        if (year === 2022 && this.catalogFilter.years.length == 0) {
            return true;
        }

        return this.catalogFilter.years.includes(year);
    }

    isDrivetrainFilterExist(engine: string): boolean {
        return this.catalogFilter.Drivetrains.includes(engine);
    }

    isModelCategoryFilterExist(name: string): boolean {
        return this.catalogFilter.Manufacturers.includes(name);
    }

    isAnyModelCategoryFilterExist(): boolean {
        return this.catalogFilter.Manufacturers.length > 0;
    }

    setfavorites(subModels: number[]) {
        this.catalogFilter.SubModels = subModels;
    }

    getCatalogByFilter(): Observable<CarFindCarVersion[]> {
        return this.httpClient.getCatalogDataByFilter(this.catalogFilter).pipe(
            map((cars: any) => {
                this.carResult = this.generateCarFindCarVersions(cars);
                return this.carResult;
            })
        );
    }

    getCatalogFilterCountData(): Observable<any> {
        return this.httpClient.getCatalogFilterCountData(this.catalogFilter).pipe(
            map((data: any) => {
                this.filtetItemCount = {
                    'benzin': data.benzin,
                    'diesel': data.diesel,
                    'hybrid': data.hybrid,
                    'electric': data.electric,
                    'plug-in': data['plug-in'],
                    'coupe-converible': data['coupe-converible'],
                    'pickup-van': data['pickup-van'],
                    'small-hatchback': data['small-hatchback'],
                    'sedan': data.sedan,
                    'suv': data.suv,
                    '7plus-seats': data['7plus-seats'],
                    'new-cars': data['new-cars'],
                    'used-cars': data['used-cars'],
                    'car-deals': data['car-deals'],
                    'importer-deals': data['importer-deals'],
                    'leasing-0-km': data['leasing-0-km'],
                    '1-row': data['1-row'],
                    '2-rows': data['2-rows'],
                    '3-plus-rows': data['3-plus-rows'],
                    'manual': data.manual,
                    'automatic': data.automatic,
                    '2x4': data['2x4'],
                    '4x4': data['4x4'],
                    '2021': data['2021'],
                    '2022': data['2022']
                };

                SharedDataService.EngManufacturerList.forEach(manufacturer => {
                    const m = manufacturer.replace('-',' ')
                    this.filtetItemCount[manufacturer] = data[m];
                });
            })
        );
    }

    getCarresultPage(pageSize: number, pageNumber: number) {
        --pageNumber;
        return this.allCarResult.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
    }

    getSortableResults() {
        this.carResult = this.getCarresultPage(this.carsPerPage, 1);
        return this.carResult;
    }

    sortCars<T>(prop: (c: CarFindCarVersion) => T, order: string): void {

        this.sortByProperty = prop;
        this.sortByDirection = order;

        if (order == 'ASC') {
            this.allCarResult.sort((a, b) => {
                if (prop(a) < prop(b))
                    return -1;
                if (prop(a) > prop(b))
                    return 1;
                return 0;
            });
        } else {
            this.allCarResult.sort((a, b) => {
                if (prop(a) > prop(b))
                    return -1;
                if (prop(a) < prop(b))
                    return 1;
                return 0;
            });
        }
    }

    getCategoryCount(categoryId: number) {
        return this.carCategoryCount[categoryId - 1];
    }

    getFilter() {
        return this.catalogFilter;
    }

    removeFilterItem(item: string) {
        const url = this.urlBuilder('', FilterType.None, item);
        this.router.navigateByUrl(url);
    }

    generateFilterTags(segments: UrlSegment[]): TagItem[] {
        const tags: TagItem[] = [];

        for (let i = 0; i < segments.length; i++) {
            const pathValues = segments[i].path.split(',');
            pathValues.forEach(element => {

                const newTag = new TagItem();

                if (element.startsWith('price-')) {
                    const values = element.split('-');
                    const maxPrice = values.length > 2 ? values[2] : undefined;

                    if (maxPrice === undefined) {
                        newTag.title = `₪${this.utilsService.numberWithCommas(values[1])}`;
                    } else {
                        newTag.title = `₪${this.utilsService.numberWithCommas(values[1])}-${this.utilsService.numberWithCommas(maxPrice)}`;
                    }

                    newTag.filterType = FilterType.Price;
                }
                // else if (element.startsWith('monthly-payment-')) {
                //     const values = element.split('-');
                //     const maxPrice = values.length > 3 ? values[3] : undefined;

                //     if (maxPrice === undefined) {
                //         newTag.title = `תשלום חודשי מ ₪${this.utilsService.numberWithCommas(values[2])}`;
                //     } else {
                //         newTag.title = `תשלום חודשי בין ₪${this.utilsService.numberWithCommas(values[2])} ל ₪${this.utilsService.numberWithCommas(maxPrice)}`;
                //         // newTag.title = `₪${this.utilsService.numberWithCommas(values[2])}-${this.utilsService.numberWithCommas(maxPrice)}`;
                //     }

                //     newTag.filterType = FilterType.PaymentPrice;
                // } 
                else if (element.startsWith('kml-')) {
                    newTag.title = `${element.replace('kml-', '')} ק״מ לליטר`;
                    newTag.filterType = FilterType.KML;
                } else if (element.startsWith('boot-')) {
                    newTag.title = `${element.replace('boot-', '')} ליטר מטען`;
                    newTag.filterType = FilterType.BootCapacity;
                } else if (element.startsWith('length-')) {
                    newTag.title = `${element.replace('length-', '')} ס״מ אורך`;
                    newTag.filterType = FilterType.Length;
                } else if (SharedDataService.dealTypesCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.dealTypesCatalogFilterValues[element];
                    newTag.filterType = FilterType.DealType;
                } else if (SharedDataService.manufacturersCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.manufacturersCatalogFilterValues[element];
                    newTag.filterType = FilterType.Manufacturer;
                } else if (SharedDataService.engineCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.engineCatalogFilterValues[element];
                    newTag.filterType = FilterType.Engine;
                } else if (SharedDataService.drivetrainCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.drivetrainCatalogFilterValues[element];
                    newTag.filterType = FilterType.Drivetrain;
                } else if (SharedDataService.yearsFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.yearsFilterValues[element];
                    newTag.filterType = FilterType.Years;
                } else if (SharedDataService.transmissionCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.transmissionCatalogFilterValues[element];
                    newTag.filterType = FilterType.Transmission;
                } else if (SharedDataService.seatsCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.seatsCatalogFilterValues[element];
                    newTag.filterType = FilterType.Seats;
                } else if (SharedDataService.bodyTypesCatalogFilterValues[element] !== undefined) {
                    newTag.title = SharedDataService.bodyTypesCatalogFilterValues[element];
                    newTag.filterType = FilterType.BodyTypes;
                }
                // else if (element.startsWith('advance-')) {
                //     newTag.title = `₪${this.utilsService.numberWithCommas(element.replace('advance-', ''))} מקדמה`;
                //     newTag.filterType = FilterType.PaymentPriceAdvance;
                // } else if (element.startsWith('balloon-')) {
                //     newTag.title = `%${element.replace('balloon-', '')} בלון`;
                //     newTag.filterType = FilterType.PaymentPriceBalloon;
                // } else if (element.startsWith('monthly-')) {
                //     newTag.title = `₪${this.utilsService.numberWithCommas(element.replace('monthly-', ''))} תשלום חודשי`;
                //     newTag.filterType = FilterType.MaxPaymentPrice;
                // } 

                if (!element.includes('order-by')) {
                    newTag.value = element;
                    tags.push(newTag);
                }
            });
        }
        return tags;
    }

    getFilterItemsCount() {
        return this.filterItemsCount;
    }

    parseFilterUrl(segments: UrlSegment[]) {
        this.filterItemsCount = segments.length;
        if (segments !== undefined && segments.length > 0 && segments[0].path === 'favorites') {
            const subModels = segments[1].path.split('-');
            subModels.forEach(sm => {
                this.catalogFilter.SubModels.push(+sm);
            });
            return;
        }

        if (segments !== undefined && segments.length > 0
            && (segments[0].path.startsWith('?kw') || segments[0].path.startsWith('?page'))) {
            return;
        }

        const validUrlParams: string[] = [];
        let urlNeedClean = false;

        if (segments[0] !== undefined) {
            if (SharedDataService.DealTypes.includes(segments[0].path)) {
                this.setDealType(segments[0].path);
                this.addValidUrlParam(validUrlParams, segments[0].path);
            } else {
                // this.utilsService.redirect('/', 301);
                // this.utilsService.goToErrorPage(this.router.url);
            }
        } else {
            this.setDealType('new-cars');
            this.addValidUrlParam(validUrlParams, 'new-cars');
        }

        for (let index = 1; index < segments.length; index++) {
            let isValidParam = false;
            const segment = segments[index];
            const pathValues = segment.path.split(',');
            pathValues.forEach(element => {

                if (element.startsWith('20')) {
                    this.catalogFilter.years.push(+element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (element.startsWith('advance')) {
                    const orderValues = element.split('-');
                    this.catalogFilter.PaymentAdvance = +orderValues[1];
                    this.utilsService.addNoIndexMeta(true);
                    this.utilsService.setSessionValue('AdvancePaymentPrice',this.catalogFilter.PaymentAdvance);
                    this.utilsService.setSessionValue('BalloonPaymen',this.catalogFilter.PaymentBalloon);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (element.startsWith('balloon')) {
                    const orderValues = element.split('-');
                    this.catalogFilter.PaymentBalloon = +orderValues[1];
                    this.utilsService.addNoIndexMeta(true);
                    this.utilsService.setSessionValue('AdvancePaymentPrice',this.catalogFilter.PaymentAdvance);
                    this.utilsService.setSessionValue('BalloonPaymen',this.catalogFilter.PaymentBalloon);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (element.startsWith('monthly')) {
                    const orderValues = element.split('-');
                    this.catalogFilter.MaxPaymentPrice = +orderValues[1];
                    this.utilsService.addNoIndexMeta(true);
                    this.utilsService.setSessionValue('MaxPaymentPrice',this.catalogFilter.MaxPaymentPrice);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.BodyTypesValues.includes(element)) {
                    this.toggleCategory(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.EngineValues.includes(element)) {
                    this.toggleEngine(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.TransmissionValues.includes(element)) {
                    this.toggleTransmission(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.DrivetrainValues.includes(element)) {
                    this.toggleDrivetrain(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.SeatsValues.includes(element)) {
                    this.toggleSeats(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (SharedDataService.EngManufacturerList.includes(element)) {
                    this.toggleManufacturerCategory(element);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                let rangeValues = this.getSegmentRange(element, segment, 'price-');
                if (rangeValues !== undefined && rangeValues.length > 0) {
                    this.setPrice(rangeValues);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                    this.utilsService.addNoIndexMeta(true);
                }

                rangeValues = this.getMonthlyPaymentSegmentRange(element, segment, 'monthly-payment-');
                if (rangeValues !== undefined && rangeValues.length > 0) {
                    this.utilsService.redirect('/', 301);
                }

                rangeValues = this.getSegmentRange(element, segment, 'kml-');
                if (rangeValues !== undefined && rangeValues.length > 0) {
                    this.setKML(rangeValues);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                rangeValues = this.getSegmentRange(element, segment, 'boot-');
                if (rangeValues !== undefined && rangeValues.length > 0) {
                    this.setBootCapacity(rangeValues);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                rangeValues = this.getSegmentRange(element, segment, 'length-');
                if (rangeValues !== undefined && rangeValues.length > 0) {
                    this.setLength(rangeValues);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (element.startsWith('order-by')) {
                    const orderValues = element.split('-');
                    this.catalogFilter.SortBy = orderValues[2];
                    this.catalogFilter.SortDirection = orderValues[3] === 'up' ? 1 : 2;
                    this.utilsService.addNoIndexMeta(true);
                    isValidParam = this.addValidUrlParam(validUrlParams, segment.path);
                }

                if (!isValidParam) {
                    urlNeedClean = true;
                }
            });
        }

        // if (this.catalogFilter.years.length == 0) {
        //     this.catalogFilter.years.push(2022);
        // }

        if (urlNeedClean) {
            const cleanUrl = `/${validUrlParams.join('/')}`;
            this.utilsService.redirect(cleanUrl, 301);
        }
    }

    addValidUrlParam(validUrlParams: string[], param: string) {
        if (!validUrlParams.includes(param)) {
            validUrlParams.push(param);
        }
        return true;
    }

    private getSegmentRange(element: string, segment: UrlSegment, prefix: string): number[] {
        if (!element.startsWith('order-by') && element.includes(prefix)) {
            const rangeData = segment.path.split('-');
            const rangeValues: number[] = [];
            rangeValues.push(+rangeData[1]);

            if (rangeData.length > 2) {
                rangeValues.push(+rangeData[2]);
            } else {
                rangeValues.push(0);
            }
            return rangeValues;
        }
    }

    private getMonthlyPaymentSegmentRange(element: string, segment: UrlSegment, prefix: string): number[] {
        if (element.startsWith('monthly-payment') && element.includes(prefix)) {
            const rangeData = segment.path.split('-');
            const rangeValues: number[] = [];
            rangeValues.push(+rangeData[2]);

            if (rangeData.length > 3) {
                rangeValues.push(+rangeData[3]);
            } else {
                rangeValues.push(0);
            }
            return rangeValues;
        }
    }

    addRangeFilterValue(minValue: number, maxValue: number, prefix: string, params: string[]) {
        if (minValue > 0 || maxValue > 0) {
            const value = `${prefix}-${minValue}-${maxValue}`;
            params.push(value);
        }
    }

    addPriceRangeFilterValue(minValue: number, maxValue: number, params: string[]) {
        let price = '';
        if (minValue > 0 && maxValue > 0) {
            price = `price-${minValue}-${maxValue}`;
            params.push(price);
        } else if (minValue > 0 && maxValue === 0) {
            price = `price-${minValue}`;
            params.push(price);
        }
    }

    addPaymentPriceRangeFilterValue(minValue: number, maxValue: number, params: string[]) {
        let price = '';
        if (minValue > 0 && maxValue > 0) {
            price = `monthly-payment-${minValue}-${maxValue}`;
            params.push(price);
        } else if (minValue > 0 && maxValue === 0) {
            price = `monthly-payment-${minValue}`;
            params.push(price);
        }
    }

    urlBuilder(extraValue: string = '', filterType: FilterType = FilterType.None, paramToRemove = '') {
        const params: string[] = [];

        // deal
        params.push(this.catalogFilter.DealType);

        // manufacturer
        this.addFilterType(filterType, FilterType.Manufacturer, extraValue.replace(/ /g, '-'), params, this.catalogFilter.Manufacturers, paramToRemove);

        // body
        this.addFilterType(filterType, FilterType.BodyTypes, extraValue, params, this.catalogFilter.Categories, paramToRemove);

        // engine
        this.addFilterType(filterType, FilterType.Engine, extraValue, params, this.catalogFilter.Engines, paramToRemove);

        // transmissions
        this.addFilterType(filterType, FilterType.Transmission, extraValue, params, this.catalogFilter.Transmissions, paramToRemove);

        // drive
        this.addFilterType(filterType, FilterType.Drivetrain, extraValue, params, this.catalogFilter.Drivetrains, paramToRemove);

        // seat
        this.addFilterType(filterType, FilterType.Seats, extraValue, params, this.catalogFilter.Seats, paramToRemove);

        // year
        this.addFilterType(filterType, FilterType.Years, extraValue, params, this.catalogFilter.years, paramToRemove);

        // balloon
        if (this.catalogFilter.PaymentBalloon != this.defaultPaymentBalloonValue) {
            const value = `balloon-${this.catalogFilter.PaymentBalloon}`;
            params.push(value);
        }

        // advance
        if (this.catalogFilter.PaymentAdvance != this.defaultPaymentAdvanceValue) {
            const value = `advance-${this.catalogFilter.PaymentAdvance}`;
            params.push(value);
        }

        // monthly
        if (this.catalogFilter.MaxPaymentPrice > 0) {
            const value = `monthly-${this.catalogFilter.MaxPaymentPrice}`;
            params.push(value);
        }

        // price
        if (!paramToRemove.startsWith('price-')) {
            this.addPriceRangeFilterValue(this.catalogFilter.MinPrice
                , this.catalogFilter.MaxPrice
                , params);
        }

        // // payment price
        // if (!paramToRemove.startsWith('monthly-payment-')) {
        //     this.addPaymentPriceRangeFilterValue(this.catalogFilter.MinPaymentPrice
        //         , this.catalogFilter.MaxPaymentPrice
        //         , params);
        // }

        // consumption
        if (!paramToRemove.startsWith('kml-')) {
            this.addRangeFilterValue(this.catalogFilter.MinKML
                , this.catalogFilter.MaxKML
                , 'kml'
                , params);
        }

        // trunk
        if (!paramToRemove.startsWith('boot-')) {
            this.addRangeFilterValue(this.catalogFilter.MinBootCapacity
                , this.catalogFilter.MaxBootCapacity
                , 'boot'
                , params);
        }

        // length
        if (!paramToRemove.startsWith('length-')) {
            this.addRangeFilterValue(this.catalogFilter.MinLength
                , this.catalogFilter.MaxLength
                , 'length'
                , params);
        }

        // order-by
        if (this.catalogFilter.SortBy !== undefined) {
            let sortValue = `order-by-${this.catalogFilter.SortBy}`;
            if (this.catalogFilter.SortDirection !== undefined) {
                switch (this.catalogFilter.SortDirection) {
                    case 1:
                        sortValue = `${sortValue}-up`;
                        break;
                    case 2:
                        sortValue = `${sortValue}-down`;
                        break;
                }
            } else {
                sortValue = `${sortValue}-up`;
            }

            params.push(sortValue);
        }

        let url = `/${params.join('/')}`;
        if (params.length > 3) {
            url += '?noidx=1';
        }

        return url;
    }

    private addFilterType(filterType: FilterType, sourceFilterType: FilterType, extraValue: string, params: string[], arrValues: any[], paramToRemove: string) {

        if (paramToRemove !== '') {
            const itemIndex = arrValues.indexOf(paramToRemove);
            if (itemIndex >= 0) {
                arrValues.splice(itemIndex, 1);
            }
        }

        let data = arrValues.join(',');
        if (filterType === sourceFilterType && extraValue !== '' && !arrValues.includes(extraValue)) {
            data += data !== '' ? ',' + extraValue : extraValue;

            if (arrValues.length !== undefined) {
                params.push(data);
            }
        } else {
            if (arrValues.length > 0) {
                params.push(data);
            }
        }
    }

    navigateToFilterPage() {
        const url = this.urlBuilder();
        this.router.navigateByUrl(url);
    }

    generateFilter() {
        this.catalogFilter = new CatalogFilter();
        this.setAdvancePaymentPrice(this.defaultPaymentAdvanceValue);
        this.setBalloonPaymentPrice(this.defaultPaymentBalloonValue);
        this.setPageNumber(1);

        const url = this.router.url.split('/');

        if (this.selectedDealType !== '') {
            this.catalogFilter.DealType = this.selectedDealType;
        } else {
            this.setDealType(url[0]);
        }

        if (!SharedDataService.DealTypes.includes(url[1])) {
            if ((url.length === 2 || url.length === 3 && (+url[2] > 2010))
                && !url[1].includes('kw=')
                && !url[1].includes('page=')) {
                this.toggleManufacturerCategory(url[1].toLowerCase());
                
                if (url.length === 3 ) {
                    this.catalogFilter.years.push(+url[2]);
                }
            } else if ((url.length === 3 || url.length === 4 && (+url[3] > 2010)) && !url.includes('favorites')) {
                this.toggleManufacturerCategory(url[1].toLowerCase());
                this.catalogFilter.groupName = url[2].toLowerCase();

                if (url.length === 4) {
                    this.catalogFilter.years.push(+url[3]);
                }
            }
        }
    }

    setPageNumber(pageNumber: number) {
        this.catalogFilter.PageNumber = pageNumber;
        this.catalogFilter.PageItemsCount = pageNumber * this.carsPerPage;
    }

    setAdvancePaymentPrice(value: number) {
        this.catalogFilter.PaymentAdvance = value;
    }

    setBalloonPaymentPrice(value: number) {
        this.catalogFilter.PaymentBalloon = value;
    }

    setMaxPaymentPrice(value: number) {
        this.catalogFilter.MaxPaymentPrice = value;
    }

    private generateCarFindCarVersions(data: any): CarFindCarVersion[] {

        if (data.totalCount !== undefined) {
            this.totalResultCount = data.totalCount;
        }

        this.allCarResult = data.cars;
        let carList: CarFindCarVersion[] = [];

        if (this.sortByProperty !== undefined) {
            this.sortCars(this.sortByProperty, this.sortByDirection);
        }

        carList = this.getCarresultPage(this.carsPerPage * this.catalogFilter.PageNumber, 1);
        return carList;
    };
}