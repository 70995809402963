import { Component, OnInit, Input, ElementRef, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { CarDetailsService } from '../../../services/car-details.service';
import { SubModelInfo } from '../../../../app/models/CarGroupInfo';
import { MessageService } from '../../../services/message.service';
import { DialogService } from '../../../../app/services/dialog.service';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from './../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { UtilsService } from './../../../../app/services/utils.service';
declare var $: any;

@Component({
  selector: 'app-car-feature-comparison',
  templateUrl: './car-feature-comparison.component.html',
  styleUrls: ['./car-feature-comparison.component.css']
})
export class CarFeatureComparisonComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  messages: any[] = [];
  hasSpecialDealsHover = false;
  isScrolling: any = null;
  divider: any;
  dividerClass = '';
  showNext = false;
  showPrev = false;
  isMobile = false;

  @Input()
  carModelInfo: SubModelInfo;

  @Input()
  modelName: SubModelInfo;

  @Input()
  configHasSpecialDeals: boolean;

  @Input()
  carModelInfoIndex: number;

  @Input()
  lowestMonthlyPayment: number;

  @Input()
  configHasZeroKMPrice: number;

  fullSafetyLevel: number[] = [];
  blankSafetyLevel: number[] = [];

  constructor(
    public httpClient: HttpService
    , public carDetailsService: CarDetailsService
    , private messageService: MessageService
    , private dialogService: DialogService
    , private router: Router
    , public googleAnalyticsService: GoogleAnalyticsService
    , private _elementRef: ElementRef
    , private deviceService: DeviceDetectorService
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-navigation-refresh') {
        this.showNavigation();
      } else {
        this.messages = [];
      }
    });

    this.showNavigation();
  }

  ngOnInit() {
    this.isMobile = this.utilsService.IsMobile();
    this.divider = this._elementRef.nativeElement.querySelector(`#divider`);
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('[data-toggle="popover"]').popover({ trigger: 'hover', container: 'body' });
    }
  }

  resetPage() {
    this.carDetailsService.resetSubModels();
  }

  highlightFeatureRow(id, status) {
    const domElements = this._elementRef.nativeElement.querySelectorAll(`#${id}`);
    domElements.forEach(element => {
      element.style.backgroundColor = status ? 'yellow' : 'white';
    });
  }

  colorValue(isHighest: boolean) {
    return isHighest ? "car-feature-ishighest-value" : "car-feature-value";
  }

  getFeatureTitle(key: string) {
    return this.carModelInfoIndex > 0 ? '&nbsp;' : this.getFeature(key).title;
  }

  getFeatureValue(key: string) {
    return this.getFeature(key).value;
  }

  getDiffFeatureTitle(key: string) {
    return this.getDiffFeature(key).title;
  }

  getDiffFeatureValue(key: string) {
    return this.getDiffFeature(key).value;
  }

  getFeature(key: string) {
    const feature = this.carModelInfo.features.find(x => x.key === key);
    return feature;
  }

  getDiffFeature(key: string) {
    const feature = this.carModelInfo.differentFeatures.find(x => x.key === key);
    return feature;
  }

  openDialog(event, price, subModelId, isMonthlyPayment = false) {
    this.messageService.sendMessage(`car-deailes-meta-info@${price}@${subModelId}@${isMonthlyPayment}`);

    const target = event.target || event.srcElement || event.currentTarget;
    this.dialogService.setDialog(target, null, this.router.url, false, false, isMonthlyPayment);
  }

  openPriceDialog(event, price, subModelId, isMonthlyPayment = false) {
    this.openDialog(event, price, subModelId, isMonthlyPayment);
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Price', 1);
  }

  
  openMonthlyPriceDialog(event, price, subModelId) {
    this.openDialog(event, price, subModelId, true);
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Price', 1);
  }

  hasSpecialDealsButtonMouseEnter() {
    this.hasSpecialDealsHover = true;
  }

  hasSpecialDealsButtonMouseLeave() {
    this.hasSpecialDealsHover = false;
  }

  setSticky() {
    return 'sticky-car-info';
  }

  getPrevPage() {
    this.messageService.sendMessage(`car-deailes-show-prev`);
  }

  getNextPage() {
    this.messageService.sendMessage(`car-deailes-show-next`);
  }

  showNavigation() {
    this.showPrev = this.carDetailsService.isFirstPage === false;
    this.showNext = this.carDetailsService.isLastPage === false;
  }

  isVersionTitleLong() {
    const limit = 17;
    let name = this.carModelInfo.name;
    return name.length > limit;
  }

  getVersionTitleClass() {
    return 'car-model-name';
  }

  openCheaperDeals(event) {
    if (this.utilsService.IsMobile()) {
      if (isPlatformBrowser(this.platformId)) {
        const carInfo = this.carDetailsService.getData();
        const url = 'https://wa.me/972546606517?text=https%3A%2F%2Fcarfind.co.il%0D%0A%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%9C+%D7%99%D7%99%D7%A2%D7%95%D7%A5+%D7%97%D7%99%D7%A0%D7%9D+%D7%95%D7%94%D7%A6%D7%A2%D7%94+%D7%9E%D7%95%D7%96%D7%9C%D7%AA+%D7%9C%D7%A8%D7%9B%D7%91+'
          + encodeURIComponent(`${carInfo.manufacturerName} ${carInfo.modelName}`);
        window.open(url);
      }
    } else {
      this.openDialog(event, this.carModelInfo.lowerZeroKMPrice, this.carModelInfo.subModelId);
    }

    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Muzalot', 1);
  }

  showZeroKKMPopup() {
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'QuestionMark', 1);
  }

  getPrice(officalDealerPrice) {
    if (officalDealerPrice > 0)
      return `${officalDealerPrice.toLocaleString()}`;
    else
      return '-';
  }

  getDealerType(type: number) {
    return this.utilsService.getDealerTypeValue(type);
  }
}
