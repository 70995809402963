import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { CarDetailsService } from '../../../services/car-details.service';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { MessageService } from '../../../services/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FavoritesService } from '../../../../app/services/favorites.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { CarFindCarVersion } from '../../../../app/models/CarFindCarVersion';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComparisonService } from 'src/app/services/comparison.service';
declare var $: any;

@Component({
  selector: 'app-car-description',
  templateUrl: './car-description.component.html',
  styleUrls: ['./car-description.component.css']
})
export class CarDescriptionComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  messages: any[] = [];
  carInfo: CarGroupInfo;
  fullSafetyLevel: number[] = [];
  blankSafetyLevel: number[] = [];
  carImages: any[] = [];
  showRotateButton = true;
  timeLeft = 60;
  interval;
  favImageSource = 'assets/images/svg/favorite-border.svg';
  private sub: any;
  sid: number;
  carDescription: string;
  isMobile: boolean;
  isRotating: boolean;
  carImageUrl: any;
  showSafeyLevel = true;
  hasImages: boolean;
  isDealerSearchLoggedIn = false;
  title = '';
  titleYear = '';

  @ViewChild('carMainImage') carMainImage: ElementRef;

  constructor(
    public httpClient: HttpService
    , public carDetailsService: CarDetailsService
    , private messageService: MessageService
    , public router: Router
    , public favoritesService: FavoritesService
    , private ngNavigatorShareService: NgNavigatorShareService
    , public utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , private route: ActivatedRoute
    , private sanitizer: DomSanitizer
    , public comparisonService: ComparisonService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-loading-data-done') {
        this.carInfo = carDetailsService.getData();
        this.getTitle();

        this.hasImages = this.carInfo.hasImages;
        if (this.carInfo.images !== null) {

          this.getImageUrlByKey('LF', this.carInfo.images);
          this.getImageUrlByKey('F', this.carInfo.images);
          this.getImageUrlByKey('RF', this.carInfo.images);
          this.getImageUrlByKey('R', this.carInfo.images);
          this.getImageUrlByKey('RB', this.carInfo.images);
          this.getImageUrlByKey('B', this.carInfo.images);
          this.getImageUrlByKey('LB', this.carInfo.images);
          this.getImageUrlByKey('L', this.carInfo.images);

          if (this.isMobile) {
            this.getImageUrlByKey('IN', this.carInfo.images);
          }
        }

        if (this.hasImages) {
          this.stopRotate360();
          this.stopRotate360Notification();

          this.setImageurl(this.carImages[0]);
          this.startRotate360();
        } else {
          if (this.carInfo !== undefined && this.carInfo.images !== null) {
            this.carImageUrl = `https://carfind-cdn-media.azureedge.net/cars/LF/${this.carInfo.images['LF']}`;
          }
        }

        if (this.carInfo.description !== null) {
          this.carDescription = ` ${this.carInfo.description}`;
        }

        this.carInfo.subModels.forEach(car => {
          if (this.favoritesService.IsChecked(car.subModelId)) {
            this.favImageSource = 'assets/images/svg/ic-favorite-red.svg';
          }
        });

        this.showSafeyLevel = this.carInfo.safetyScore > 0;
        if (this.showSafeyLevel) {
          // this.fullSafetyLevel = Array(this.carInfo.safetyLevel).fill(0).map((x, i) => i);
          // this.blankSafetyLevel = Array(5 - this.carInfo.safetyLevel).fill(0).map((x, i) => i);
        }
      } else {
        this.messages = [];
      }
    });
  }

  getImageUrlByKey(direction: string, images: string[]) {
    direction = direction.toUpperCase();
    if (this.carInfo.images[direction] !== undefined && this.carInfo.images[direction] !== null) {
      this.carImages.push(this.sanitizer.bypassSecurityTrustResourceUrl(`https://carfind-cdn-media.azureedge.net/cars/${direction}/${this.carInfo.images[direction]}`));
    }
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.sid = +params['sid'];

      this.isMobile = this.utilsService.IsMobile();
      this.showRotateButton = !this.isMobile;
    });

    this.isDealerSearchLoggedIn = this.utilsService.isDealerSearchLoggedIn();
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  shareOnFacebook() {
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Share', 1);
    this.goToLink('https://www.facebook.com/sharer/sharer.php?u=' + location.href);
  }

  shareOnMail() {
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Share', 1);
    const title = 'אתר הרכב הגדול בישראל לעסקאות רכב חדש ו 0 ק"מ';
    const content = `${this.carInfo.name} - חוות דעת קארפיינד: רכב במחיר ${this.carInfo.priceIsAboveGroupAvg ? 'מעל' : 'מתחת'} לממוצע ${this.carInfo.categoryName}${this.carInfo.description === null ? '' : ', ' + this.carInfo.description}`;
    this.goToLink(`mailto:email@example.com?subject=${title}&body=${content}`);
  }

  share() {
    this.ngNavigatorShareService.share({
      title: 'Share from CarFind',
      text: 'מצאתי אתר שמשווה בין כל עסקאות הרכב בישראל למציאת העסקה הזולה ביותר',
      url: this.router.url
    }).then((response) => {
    })
      .catch((error) => {
        console.log(error);
      });

    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Share', 1);
  }

  goToLink(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

  stopRotate360() {
    this.isRotating = false;
    this.showRotateButton = !this.isMobile;
    clearInterval(this.interval);
  }

  stopRotate360Notification() {
  }

  setImage(event) {
    if (!this.isMobile) {
      this.stopRotate360();
      const target = event.target || event.srcElement || event.currentTarget;
      const imageUrl = target.src;
      this.setImageurl(imageUrl);
    }
  }

  mainImageClick() {
    if (this.isRotating) {
      this.stopRotate360();
      this.stopRotate360Notification();
    } else {
      this.startRotate360();
    }
  }

  setImageurl(imageUrl) {
    this.carImageUrl = imageUrl;
  }

  startRotate360() {
    this.isRotating = true;
    this.showRotateButton = false;
    let counter = 0;

    $(document).ready(() => {
      this.interval = this.setIntervalAndExecute(() => {
        this.setImageurl(this.carImages[counter++]);
        if (counter === this.carImages.length) {
          counter = 0;
        }
      }, 1000);
    });

  }

  setIntervalAndExecute(fn, t) {
    fn();
    return (setInterval(fn, t));
  }

  setFavourite(event: any, configId: number, car: any) {
    const id = this.sid ? this.sid : this.carInfo.subModels[0].subModelId;
    if (this.favoritesService.IsChecked(id)) {
      this.favoritesService.Remove(id);
      this.favImageSource = 'assets/images/svg/favorite-border.svg';
    } else {
      const selectedCar = new CarFindCarVersion();
      selectedCar.id = car.subModelId;
      selectedCar.name = this.carInfo.name;

      this.favoritesService.Add(id, selectedCar);
      this.favImageSource = 'assets/images/svg/ic-favorite-red.svg';
    }

    this.messageService.sendMessage('favorite-update');
    this.googleAnalyticsService.eventEmitter('Config', 'Click', 'Heart', 1);
  }

  getPositivevFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין יתרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getNegativeFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין חסרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getFeaturesSummary(features) {
    return this.utilsService.getCarFeaturesSummary(features);
  }

  getImageUrl(carInfo: CarGroupInfo, direction: string) {
    if (this.carInfo !== undefined && this.carInfo.images !== null) {
      return `https://carfind-cdn-media.azureedge.net/cars/${direction.toUpperCase()}/${this.carInfo.images[direction.toUpperCase()]}`;
    } else {
      return '';
    }
  }

  getImageAlt(car: CarGroupInfo) {
    if (car === undefined)
      return;

    return `${car.manufacturerName} ${car.modelName} ${car.bodyType} ${car.engine}`;
  }

  getImageTitle(car: CarGroupInfo) {
    if (car === undefined)
      return;

    return `${car.engManufacturerName} ${car.engModelName} ${this.utilsService.bodyTypeValueConverter(car.bodyType)} ${this.utilsService.engineValueConverter(car.engine)}`;
  }

  getDefaultImageUrl(event) {
    // this.stopRotate360();
    // const imageUrl = this.utilsService.getDefaultImageUrl(this.carInfo.bodyType).toLowerCase();

    const imageUrl = 'assets/images/pixel.png';
    event.target.src = imageUrl;
  }

  search01Price() {
    const value = this.carInfo?.engModelName.toLowerCase();
    return `/dealers/search/dashboard?keyword=${value}`;
  }

  getYearInfo(yearInfo) {
    return yearInfo.split('@');
  }

  getYearUrl(yearInfo) {
    return this.router.url.replace(yearInfo == '2022' ? '2021' : '2022', yearInfo);
  }

  getYearClass(year: number) {
    return this.router.url.includes(year.toString()) ? 'year-active-link' : 'year-not-active-link';
  }

  getTitle() {
    if (this.carInfo !== undefined) {
      this.carInfo.years.forEach(yearInfo => {
        const year = this.getYearInfo(yearInfo)[0];
        if (this.router.url.includes(year)) {
          this.title = `${this.carInfo.name}`;
          // this.titleYear = `${year}`; //TODO: 2023
        }
      });
    }
  }

  getOtherYear() {
    return this.router.url.includes('2021') ? 2022 : 2021;
  }

  hasOtherYear() {
    return this.carInfo?.years.length > 1;
  }


  getOtherYearClass() {
    return this.router.url.includes(this.getOtherYear().toString()) ? 'year-active-link' : 'year-not-active-link';
  }

  isCheckedForComparison(carId: any) {
    return this.comparisonService.IsChecked(carId);
  }

  GetComparisonCarCount() {
    const count = this.comparisonService.GetCarCount();
    return count;
  }

  checkValue(event: any, carId: any) {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.checked) {
      this.comparisonService.Clear();
      this.comparisonService.AddCar(carId);
      const carIds = this.comparisonService.getCompareUrl();
      this.router.navigate([`compare-cars/versions/${carIds}`]);
      return false;
    }
    else {
      this.comparisonService.RemoveCar(carId);
    }
  }
}