import { Component, OnInit } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { CarFindDealer01DashboardSearchResult, CarFindDealer01DashboardView } from '../../../models/CarFindDealer01DashboardView';
import { MessageService } from '../../../../app/services/message.service';
import { DealersService } from '../../../../app/services/dealers.service';
import { Dealers01Query } from '../../../models/dealers01Query';
import { UtilsService } from '../../../services/utils.service';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dealers-search01-view',
  templateUrl: './dealers-search01-view.component.html',
  styleUrls: ['./dealers-search01-view.component.css']
})
export class DealersSearch01ViewComponent implements OnInit {
  isLoggedIn = false;
  hasErrors = false;
  errorText = '';
  subscription: Subscription;
  query: Dealers01Query;
  result: CarFindDealer01DashboardView[];
  similarVersions: CarFindDealer01DashboardView[];

  constructor(private messageService: MessageService
    , public dealersService: DealersService
    , private route: ActivatedRoute
    , public utilsService: UtilsService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'dealer-looged-in') {
        this.isLoggedIn = true;
      }
    });
  }

  ngOnInit(): void {
    this.query = new Dealers01Query();
    this.isLoggedIn = this.utilsService.getLocalValueWithExpiry('IsDealerLoggedIn') == 1 ? true : false;

    this.route.queryParams
      .subscribe(params => {
        if (params.keyword !== undefined) {
          this.query.leadPhoneNumber = '0546600269';
          this.query.keyword = params.keyword;
          this.search();
        }
      });
  }

  search() {
    let isValid = true;
    if (this.query.keyword == null || this.query.keyword == '') {
      this.hasErrors = true;
      this.errorText = 'יש להקליד ערך חיפוש'
    } else if (this.query.keyword.length < 2) {
      this.hasErrors = true;
      this.errorText = 'ערך החיפוש קצר מדי'
    } else if (this.query.leadPhoneNumber == null || this.query.leadPhoneNumber == '') {
      this.hasErrors = true;
      this.errorText = 'יש להקליד מספר טלפון של ליד'
    } else if (!this.utilsService.isValidPhoneNumber(this.query.leadPhoneNumber)) {
      this.hasErrors = true;
      this.errorText = 'יש להקליד מספר טלפון תקין'
    } else {
      this.hasErrors = false;
    }

    isValid = !(this.hasErrors);

    if (isValid) {
      this.hasErrors = false;

      this.query.userId = this.dealersService.userId;
      this.dealersService.search01Deals(this.query).pipe(
        catchError(err => {
          this.hasErrors = true;
          this.errorText = 'ערך חיפוש לא תקין';
          this.result = null;
          this.similarVersions = null;
          return throwError(err);
        }))
        .subscribe((data: CarFindDealer01DashboardSearchResult) => {
          this.dealersService.allCarResult = data.versions;
          this.dealersService.similarVersions = data.similarVersions;

          this.result = this.dealersService.allCarResult;
          this.similarVersions = this.dealersService.similarVersions;

          if (data.informationCode == 2 || data.informationCode == 3 || data.informationCode == 4) {
            this.errorText = 'לאט לאט עם החיפושים... :)';
            this.hasErrors = true;
          }
          else if (data.informationCode == 5) {
            this.errorText = 'ערך חיפוש לא תקין, לא ניתן לחפש עפ״י שם יצרן בלבד';
            this.hasErrors = true;
          }
          else if (this.result.length == 0) {
            this.errorText = 'לא נמצאו תוצאות מדויקות';
            this.hasErrors = true;
          }
          else if (this.result.length == 0 && this.similarVersions.length == 0) {
            this.errorText = 'לא נמצאו תוצאות ';
            this.hasErrors = true;
          }
        });
    }
  }

  sort(filedId: number) {
    switch (filedId) {
      case 0:
        this.dealersService.sortByProperty = c => c.id;
        break;
      case 1:
        this.dealersService.sortByProperty = c => c.dealerName;
        break;
      case 2:
        this.dealersService.sortByProperty = c => c.title;
        break;
      case 3:
        this.dealersService.sortByProperty = c => c.carFindMatchedIdInfo;
        break;
      case 4:
        this.dealersService.sortByProperty = c => c.engineCapacity;
        break;
      case 5:
        this.dealersService.sortByProperty = c => c.d01;
        break;
      case 6:
        this.dealersService.sortByProperty = c => c.d00;
        break;
      case 7:
        this.dealersService.sortByProperty = c => c.m00;
        break;
      case 8:
        this.dealersService.sortByProperty = c => c.manufacturer;
        break;
      case 9:
        this.dealersService.sortByProperty = c => c.model;
        break;
      case 10:
        this.dealersService.sortByProperty = c => c.availableColors;
        break;
      case 11:
        this.dealersService.sortByProperty = c => c.dealType;
        break;
    }

    this.dealersService.sortByDirection = this.dealersService.sortByDirection == 'ASC' ? 'DESC' : 'ASC';
    this.dealersService.sort(this.dealersService.sortByProperty, this.dealersService.sortByDirection);
    this.result = this.dealersService.allCarResult;
    this.similarVersions = this.dealersService.similarVersions;
  }

  formatListValue(data: string[]) {
    if (data == null || data == undefined)
      return '';

    return data.join('<br />');
  }

  formatDealerUrl(url: string) {
    if (url.includes("03-5189189") || url.includes("lironcar"))
      return `http://${url}`
    else if (url.includes("carwiz") || url.includes("zapcars"))
      return '';

    return `https://${url}`
  }

  formatDealerUrlText(url: string) {
    if (url.includes("carwiz") || url.includes("zapcars"))
      return '';

    return 'קישור';
  }

  formatDealerId(did: number) {
    return did.toString().padStart(4, '0');
  }

  isValidDealerUrl(url: string) {
    return !((url.includes("carwiz") || url.includes("zapcars")) || url == '');
  }

  searchKeyDown(event) {
    this.search();
  }

  getValue(value) {
    if (value === 0 || value === '0') {
      return '-';
    }
    return value;
  }

  getDealType(type: number) {
    switch (type) {
      case 0:
        return 'לא ידוע';
      case 1:
        return 'רשמי';
      case 2:
        return 'מקביל';
    }
  }
}
