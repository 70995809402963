import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../../app/services/http.service';
import { UtilsService } from '../../../../app/services/utils.service';

@Component({
  selector: 'app-car-id-redirect',
  templateUrl: './car-id-redirect.component.html',
  styleUrls: ['./car-id-redirect.component.css']
})
export class CarIdRedirectComponent implements OnInit {

  private sub: any;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpService, public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const configurationId = +params.id;
      const subModelId = params.sid === undefined ? 0 : +params.sid;

      this.httpClient.getConfigurationUrl(configurationId, subModelId).subscribe((data: any) => {        
        this.utilsService.redirect(`/${data.singleModelUrl.replace(/ /g, '-')}`, 301);
      });
    });
  }

}
