import { Component, OnInit, AfterViewInit, AfterContentChecked, Inject, OnDestroy } from '@angular/core';
import { BasicContactForm } from '../../../../app/models/basicContactForm';
import { DialogService } from '../../../../app/services/dialog.service';
import { Router } from '@angular/router';
import { HttpService } from '../../../../app/services/http.service';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { CityInfo } from './../../../../app/models/cityInfo';
declare var $: any;

@Component({
  selector: 'app-cars-deal-contact-dialog',
  templateUrl: './cars-deal-contact-dialog.component.html',
  styleUrls: ['./cars-deal-contact-dialog.component.css']
})
export class CarsDealContactDialogComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {

  contactForm: BasicContactForm;
  isMobile = this.utilsService.IsMobile();
  nameInputClass: string;
  phoneInputClass: string;
  cityInputClass: string;
  defaultStyle = 'mr-sm-2 mb-2 mb-sm-0 text-input align-bottom';
  emptyStyle = `${this.defaultStyle} text-empty`;
  yeshuvimList: CityInfo[];
  isFinished = false;
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(
    private dialogService: DialogService
    , private router: Router
    , public httpClient: HttpService
    , private utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
    });
  }

  ngOnInit() {
    this.contactForm = new BasicContactForm();
    this.contactForm.name = '';
    this.contactForm.phone = '';
    this.contactForm.city = '';
    this.contactForm.subject ='CarFind Lead City';
    this.contactForm.channelId = 2;
    this.nameInputClass = this.defaultStyle;
    this.phoneInputClass = this.defaultStyle;
    this.cityInputClass = `${this.defaultStyle} point`;
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  ngAfterViewInit() {
    this.getYeshuvimList();
  }

  ngAfterContentChecked(): void {
    const currentContact = this.dialogService.getCurrentContact();
    if (currentContact !== undefined) {

      if (currentContact.name !== undefined) {
        this.contactForm.name = currentContact.name;
      }

      if (currentContact.phone !== undefined) {
        this.contactForm.phone = currentContact.phone;
      }
    }
  }

  submit(event) {
    const target = event.target || event.srcElement || event.currentTarget;

    let isValid = true;
    if (this.contactForm.name === '' || this.contactForm.name === null) {
      this.nameInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.nameInputClass = this.defaultStyle;
    }

    if (this.contactForm.phone === '' || this.contactForm.phone === null || !this.utilsService.isValidPhoneNumber(this.contactForm.phone)) {
      this.phoneInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.phoneInputClass = this.defaultStyle;
    }

    if (this.contactForm.city === '' || this.contactForm.city === null) {
      this.cityInputClass = this.emptyStyle;
      isValid = false;
    } else {
      this.cityInputClass = this.defaultStyle;
    }

    if (!isValid) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.googleAnalyticsService.eventEmitter('PopUp1a', 'Click', '!Submit', 1);
      return false;
    } else {
      const paymentInfo = this.utilsService.getPaymentInfo();
      this.contactForm.paymentBalloon = paymentInfo[0];
      this.contactForm.maxPaymentPrice = paymentInfo[1];
      this.contactForm.advancePaymentPrice = paymentInfo[2];

      // this.dialogService.setDialog(target, this.contactForm, this.router.url);
      this.httpClient.SendBasicContactForm(this.contactForm).subscribe((result: any) => {
        console.log('sent!');
        this.isFinished = true;
        this.googleAnalyticsService.eventEmitter('PopUp1a', 'Click', 'Submit', 1);
        this.googleAnalyticsService.SendCompletedConversionEvent();
      });
    }
  }

  reset() {
    this.isFinished = false;
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.SendPhoneEvent();
      this.googleAnalyticsService.eventEmitter('PopUp1a', 'Click', 'Call', 1);
      document.location.href = 'tel:035245241';
    }
  }

  getYeshuvimList() {
    this.utilsService.getYeshuvimList().subscribe((data: CityInfo[]) => {
      this.yeshuvimList = data;
    });
  }

  SelectYeshuv(name) {
    this.contactForm.city = name;
    $('#yeshuv-popup').css('display', 'none');
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('PopUp1a', 'Click', 'Call', 1);
  }

  setNameFocus() {
    this.googleAnalyticsService.eventEmitter('PopUp1a', 'Focus', 'Name', 1);
  }

  setPhoneFocus() {
    this.googleAnalyticsService.eventEmitter('PopUp1a', 'Focus', 'Phone', 1);
  }

  setLocationFocus() {
    this.showYeshuvPopup();
    this.googleAnalyticsService.eventEmitter('PopUp1a', 'Focus', 'Location', 1);
  }

  showYeshuvPopup() {
    if (isPlatformBrowser(this.platformId)) {
      $('#yeshuv-popup').css('display', 'block');
    }
  }
}
