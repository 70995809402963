<p>utilities works!</p>
<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>


    <div class="container-fluid">
        <div class="row" style="padding-top: 150px">
            <div class="col-md-12 p-0">
                <div class="main-image">
                    <span class="main-text">
                    </span>
                </div>
            </div>
        </div>

        <div class="row page-content-wrapper" style="padding-top: 30px; padding-bottom: 30px;">
            <div class="col-md-12 description-container-title p-0">
                <span class="description-title"> שימושונים
                </span>
            </div>
        </div>

        <div class="row description-container page-content-wrapper">
            <div class="col-12 p-0">
                <div>
                    <span class="feature-text">
                        ריכזנו עבורכם מספר אתרים אינפורמטיביים ופונקציונאלים כדי לסייע לכם לקבל את המידע הרלוונטי בתחום הרכב בישראל.
                    </span>
                </div>
            </div>
        </div>

        <div class="row page-content-wrapper main-features-container">

            <div class="col-md-9 p-0">

                <div class="text-content">
                    <span>
                        1. <a href="https://www.gov.il/he/subjects/automotive_and_transportation/drivers_license_and_vehicle_registration" target="_blank">אתר משרד התחבורה</a> - כאן תמצאו מענה לרוב בענייני הבירוקרטיה הקשורה ברישוי רכב. דוגמאות לשירותים שניתן למצוא: חידוש רישיון רכב, העברת בעלות לכלי רכב ובנוסף ניתן לפנות ישירות לאגף הרישוי. 
                        <br />
                        <br />
                        2. <a href="https://www.gov.il/he/Departments/DynamicCollectors/private-and-commercial-vehicles?skip=0" target="_blank">מספרי רישוי של כלי רכב פרטיים ומסחריים</a> - כאן ניתן למצוא מפרט טכני של רכב לפי חיפוש מספר רישוי. יעיל עבור אנשים המכירים רכב של מכר או חבר ורוצים לדעת מה המפרט הטכני שלו כדי לחפש דגמים חדשים דומים. לאחר הבירור אנחנו ממליצים לכם לבצע <a href="https://www.carfind.co.il/compare-cars" target="_blank">השוואת רכבים</a> אצלנו באתר ולקבל את המחיר המפורסם הזול ביותר. 
                        <br />
                        <br />
                        3. <a href="https://www.gov.il/he/subjects/automotive_and_transportation/change_and_registration_of_vehicle" target="_blank">רישום ושינוי בעלות</a> - כאן ניתן להעביר בקשות למשרד התחבורה בכל הקשור להעברת בעלות על רכב, עדכון פרטים ברישיון רכב ועוד. 
                        <br />
                        <br />
                      </span>
                </div>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>