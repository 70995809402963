import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarFindArticleItem } from 'src/app/models/CarFindArticleItem';
import { MagazineService } from 'src/app/services/magazine.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-article-item-view',
  templateUrl: './article-item-view.component.html',
  styleUrls: ['./article-item-view.component.css']
})
export class ArticleItemViewComponent implements OnInit {

  private sub: any;
  article: CarFindArticleItem;

  constructor(private route: ActivatedRoute, private magazineService: MagazineService, public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const id = encodeURIComponent(params.id);
      this.magazineService.getArticleById(id).subscribe((data: CarFindArticleItem) => {
        this.article = data;

        this.utilsService.generatePageTitle(data.htmlTitle);
        this.utilsService.addMetaDescriptionTag(data.htmlDescription);
      });
    });
  }
}
