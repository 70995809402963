<div *ngIf="showFooter">
    <div class="col-md-6 p-0 d-inline d-sm-none">
        <ul class="list-inline ul-above-footer-links" style="padding-top: 50px; ">
            <li class="header-link" style="padding-right: 15px">
                <a (click)="goToDealAlert($event, 'התראת מבצעים')">
                    התראת מבצעים
                </a>
            </li>
            <li class="header-link">
                <a (click)="goToCheapestDeals($event, 'הצעות מוזלות')">
                    הצעות מוזלות
                </a>
            </li>
            <li class="header-link" style="padding-left: 15px">
                <a (click)="goToTestDrive($event, 'נסיעת מבחן')">
                    נסיעת מבחן
                </a>
            </li>
        </ul>
    </div>

    <footer style="padding-top:20px;">
        <div class="footer-middle">
            <div class="container-fluid footer-content-menu">
                <div class="row d-block d-sm-none p-0">
                    <div class="footer-content">
                        <ul class="list-unstyled">
                            <!-- <li><a class="footer-menu-link-item" (click)="showFavoritesList($event)">מועדפים</a></li> -->
                            <li><a class="footer-menu-link-item" (click)="goToPage('car-agencies')">סוכנויות
                                    רכב</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('utilitis')">שימושונים</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('about')">אודות</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('contact')">יצירת קשר</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('compare-cars')">השוואת רכבים</a>
                            </li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('new-cars')">רכב חדש</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('leasing-0-km')">רכב אפס ק"מ</a></li>
                            <li><a class="footer-menu-link-item" (click)="goToPage('car-deals')">מבצעי רכב</a></li>
                            <li><a class="footer-menu-link-item" (click)="share()">שתפו עם חברים <img
                                        style="padding-right: 40px" src="assets/images/svg/ic-share-24-px.svg" /></a>
                            </li>
                            <li class="footer-menu-link-item">עקבו אחרינו
                                <img style="padding-right: 25px" src="assets/images/svg/fb.svg"
                                    (click)="goToLink('https://www.facebook.com/%D7%A7%D7%A8%D7%A4%D7%99%D7%99%D7%A0%D7%93-%D7%9E%D7%A6%D7%99%D7%90%D7%AA-%D7%A2%D7%A1%D7%A7%D7%AA-%D7%94%D7%A8%D7%9B%D7%91-%D7%94%D7%9E%D7%A9%D7%AA%D7%9C%D7%9E%D7%AA-%D7%91%D7%99%D7%95%D7%AA%D7%A8-CarFind-2008097506152222/')" />
                                <!-- <img style="padding-right: 25px" src="assets/images/svg/inst.svg"
                                    (click)="goToLink('https://www.instagram.com/carfind_il/')" /> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row p-0 info-list-container">
                    <div class="col-md-6 col-12 p-0">
                        <div class="footer-pad">
                            <ul class="list-unstyled ul-footer footer-content-desc">
                                <li class="footer-item-title">מה מייחד את CarFind?</li>
                                <li>
                                    <img src="assets/images/svg/icon-target.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        אתר הרכב המקיף בישראל למציאת הדגם המתאים ביותר
                                        עבורך
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-money-2.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        השוואת עסקאות לכל דגם מבין מגוון סוכנויות: יבואנים וחברות 0 ק"מ
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-money.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        מיתר את הצורך להתמקח עם כל ספק בנפרד - חוסך זמן ועצבים
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-hand.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        מציאת המחיר המפורסם הכי זול בישראל לכל רכב
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-network.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        שירות ויעוץ אוביקטיבי, מגוון ובחינם - בטלפון, בצאט
                                        ופורום לכל דגם
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-search.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        חווית קנייה ייחודית: מנוע חיפוש חדשני בשילוב עם יועץ דיגיטלי
                                    </span>
                                </li>
                                <li>
                                    <img src="assets/images/svg/icon-shield.svg" class="d-none d-xl-inline"
                                        style="margin-left: 10px" alt="" />
                                    <span>
                                        קנייה בטוחה - אחריות של יבואן רשמי על כל הרכבים
                                    </span>
                                </li>
                                <li class="d-block d-sm-none" style="padding-top: 15px;">
                                    <iframe
                                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcarfind.co.il&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=790535161330236"
                                        width="340" height="440" style="border:none;overflow:hidden" scrolling="no"
                                        frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                                </li>
                            </ul>
                            <ul class="list-inline">
                                <!-- <li><img class="footer-social-link point" (click)="goToFacebook()"
                                        src="assets/images/svg/facebook.svg" /></li>
                                <li><img class="footer-social-link point" (click)="goToInstagram()"
                                        src="assets/images/svg/instagram.svg" /></li> -->

                                <li>
                                    <a [routerLink]="['/car-agencies']"
                                        class="d-none d-xl-block link leas-companies-text">
                                        <strong class="link">חברות ליסינג פרטי / תפעולי:</strong>
                                        אלבר, אלדן, שלמה סיקסט, קל-אוטו, אוויס, הרץ, אוטו סנטר, אופרייט ליס, פריים ליס,
                                        לובינסקי טרייד,
                                        באדג'ט, ביי אנד דרייב, סאן קאר, טרייד מוביל
                                    </a>
                                </li>
                            </ul>

                            <div class="col-md-12 d-none d-xl-block" style="padding-right: 0">
                                <span>
                                    <strong class="link">הדגמים הנמכרים בישראל:</strong>&nbsp;
                                    <a class="point link" href="/toyota/">טויוטה</a>&nbsp;<a class="point link"
                                        href="/toyota/yaris">יאריס</a>,&nbsp;
                                    <a class="point link" href="/toyota/">טויוטה</a>&nbsp;<a class="point link"
                                        href="/toyota/rav-4">ראב 4</a>,&nbsp;
                                    <a class="point link" href="/toyota/">טויוטה</a>&nbsp;<a class="point link"
                                        href="/toyota/c-hr">C-HR</a>,&nbsp;
                                    <a class="point link" href="/hyundai/">יונדאי</a>&nbsp;<a class="point link"
                                        href="/hyundai/ioniq">איוניק</a>,&nbsp;
                                    <a class="point link" href="/hyundai/">יונדאי</a>&nbsp;<a class="point link"
                                        href="/hyundai/tucson">טוסון</a>,&nbsp;
                                    <a class="point link" href="/hyundai/">יונדאי</a>&nbsp;<a class="point link"
                                        href="/hyundai/i10">i10</a>,&nbsp;
                                    <a class="point link" href="/hyundai/">יונדאי</a>&nbsp;<a class="point link"
                                        href="/hyundai/i20">i20</a>,&nbsp;
                                    <a class="point link" href="/mazda/">מאזדה</a>&nbsp;<a class="point link"
                                        href="/mazda/3">3</a>,&nbsp;
                                    <a class="point link" href="/mitsubishi/">מיצובישי</a>&nbsp;<a class="point link"
                                        href="/mitsubishi/outlander">אאוטלנדר</a>,&nbsp;
                                    <a class="point link" href="/mitsubishi/">מיצובישי</a>&nbsp;<a class="point link"
                                        href="/mitsubishi/asx">ASX</a>,&nbsp;
                                    <a class="point link" href="/skoda/">סקודה</a>&nbsp;<a class="point link"
                                        href="/skoda/octavia">אוקטביה</a>,&nbsp;
                                    <a class="point link" href="/peugeot/">פיג'ו</a>&nbsp;<a class="point link"
                                        href="/peugeot/3008">3008</a>,&nbsp;
                                    <a class="point link" href="/kia/">קיה</a>&nbsp;<a class="point link"
                                        href="/kia/niro">נירו</a>,&nbsp;
                                    <a class="point link" href="/kia/">קיה</a>&nbsp;<a class="point link"
                                        href="/kia/stonic">סטוניק</a>,&nbsp;
                                    <a class="point link" href="/kia/">קיה</a>&nbsp;<a class="point link"
                                        href="/kia/sportage">ספורטז'</a>,&nbsp;
                                    <a class="point link" href="/kia/">קיה</a>&nbsp;<a class="point link"
                                        href="/kia/picanto">פיקנטו</a>,&nbsp;
                                    <a class="point link" href="/chevrolet/">שברולט</a>&nbsp;<a class="point link"
                                        href="/chevrolet/spark">ספארק</a>,&nbsp;
                                    <a class="point link" href="/renault/">רנו</a>&nbsp;<a class="point link"
                                        href="/renault/megane">מגאן</a>,&nbsp;
                                    <a class="point link" href="/tesla">טסלה</a>,&nbsp;
                                    <a class="point link" href="/mercedes">מרצדס</a>,&nbsp;
                                </span>
                            </div>

                            <br />

                            <div class="col-md-12 p-0 d-none d-xl-block">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-none d-xl-block">
                        <div class="row">
                            <div class="footer-pad" style="padding-top:70px">
                                <ul class="list-unstyled ul-footer" style="padding-top:7px">
                                    <li><a class="li-footer-link point" (click)="goToContactUsPage()"
                                            [routerLink]="['/contact']">יצירת קשר</a>
                                    </li>
                                    <li><a class="li-footer-link point" (click)="goToDealersPage()"
                                            [routerLink]="['/car-agencies']">סוכנויות רכב</a></li>
                                            <li><a class="li-footer-link point"
                                                [routerLink]="['/utilities']">שימושונים</a>
                                        </li>
                                    <li><a class="li-footer-link point" (click)="goToAboutPage()"
                                            [routerLink]="['/about']">אודות</a>
                                    </li>
                                    <!-- <li><a class="li-footer-link point" (click)="goToCarRepository()"
                                            [routerLink]="['/contact']">הצטרפו למאגר ספקי
                                            הרכב</a></li> -->
                                    <!-- <li><a class="li-footer-link point" (click)="goToCademy()" target="_blank"
                                            href="https://www.cademy.co.il">מועדון
                                            צרכנות לאקדמאיים</a></li> -->
                                    <li><a class="li-footer-link point" (click)="goToCarRepository()"
                                            [routerLink]="['/compare-cars']">השוואת רכבים</a></li>
                                    <li><a class="li-footer-link point" (click)="goToCarRepository()"
                                            [routerLink]="['/new-cars']">רכב חדש</a></li>
                                    <li><a class="li-footer-link point" (click)="goToCarRepository()"
                                            [routerLink]="['/leasing-0-km']">רכב אפס ק"מ</a></li>
                                    <li><a class="li-footer-link point" (click)="goToCarRepository()"
                                            [routerLink]="['/car-deals']">מבצעי רכב</a></li>
                                </ul>

                            </div>
                        </div>
                        <!-- <div class="row">
                            <span class="link" style="padding-top: 14px; padding-left: 50px;">
                                <strong class="link">חברות ליסינג פרטי / תפעולי:</strong>
                                אלבר, אלדן, שלמה סיקסט, קל-אוטו, אוויס, הרץ, אוטו סנטר, אופרייט ליס, פריים ליס,
                                לובינסקי טרייד,
                                באדג'ט, ביי אנד דרייב, סאן קאר, טרייד מוביל
                            </span>
                        </div> -->
                    </div>
                    <div class="col-md-3 p-0">
                        <div class="footer-pad d-none d-xl-block"
                            style="float: left;padding-top:26px; padding-bottom:45px">

                            <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcarfind.co.il&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=790535161330236"
                                width="340" height="440" style="border:none;overflow:hidden" scrolling="no"
                                frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>