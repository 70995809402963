<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper" [attr.cf-data]="carInfo?.id">
    <div class="container-fluid page-content-wrapper car-detailes-container ">
      <div class="row breadcrumbs-container">
        <app-view-breadcrumbs [items]="generateTags()"></app-view-breadcrumbs>
      </div>

      <div class="title-container">
        <div style="padding-top: 20px;" *ngIf="isMobile && !hasImages">
          <app-car-video></app-car-video>
        </div>
        <app-car-description></app-car-description>
      </div>

      <div class="row" style="padding-top: 20px; padding-right: 10px;">

        <div *ngFor="let car of CarFindCarVersionInfo; let i=index;" class="col-md-2 col-6 features-list-container">

          <app-car-feature-comparison [carModelInfo]="car" [configHasSpecialDeals]="carInfo.hasSpecialDeals"
            [configHasZeroKMPrice]="carInfo.hasVersionWithZeroKMPrice" [modelName]="carInfo.modelName"
            [carModelInfoIndex]="i" [lowestMonthlyPayment]="carInfo.lowestMonthlyPayment"></app-car-feature-comparison>

          <div *ngIf="i == 0 && car.differentFeatures.length == 0; else hasDiffMainTitle">
            &nbsp;
          </div>

          <ng-template #diffMainTitle>
            <h2 class="car-feature-main-title diff-title">&nbsp;</h2>
          </ng-template>

          <ng-template #hasDiffMainTitle>
            <h2 class="diff-title" *ngIf="i == 0 && car.differentFeatures.length > 0; else diffMainTitle">
              הבדלים בין גרסאות של {{carInfo.name}} שנת {{year}}</h2>
          </ng-template>

          <div *ngFor="let feature of car.differentFeatures;">
            <div class="features-group-container">
              <div>
                <span *ngIf="i == 0; else diffTitle" class="car-feature-title">
                  {{feature.title}}
                </span>
                <ng-template #diffTitle>
                  <span class="car-feature-title">&nbsp;</span>
                </ng-template>
                <span id="f-diff-{{feature.key}}" class="car-feature-value" [ngClass]="colorValue(feature.isHighest)">
                  {{feature.value}}
                </span>
              </div>
            </div>
          </div>

          <div class="car-feature-main-title" *ngIf="i == 0; else diffMainTitle">כללי</div>

          <div class="features-group-container">
            <div *ngIf="i == 0 && hasFeatureValue('BodyType', car)">
              <span class="car-feature-title">יבואן</span>
              <span id="f-BodyType" (mouseenter)="highlightFeatureRow('f-BodyType', true)"
                (mouseleave)="highlightFeatureRow('f-BodyType', false)" class="car-feature-value">
                {{getDealerType(car.dealerType)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('BodyType', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('BodyType', car, i)"></span>
              <span id="f-BodyType" (mouseenter)="highlightFeatureRow('f-BodyType', true)"
                (mouseleave)="highlightFeatureRow('f-BodyType', false)" class="car-feature-value">
                {{utilsService.bodyTypeCustomValueConverter(getFeatureValue('BodyType', car))}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('LicensingGroup', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('LicensingGroup', car, i)"></span>
              <span id="f-LicensingGroup" (mouseenter)="highlightFeatureRow('f-LicensingGroup', true)"
                (mouseleave)="highlightFeatureRow('f-LicensingGroup', false)" class="car-feature-value">
                {{getFeatureValue('LicensingGroup', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('LicensingGroupPrice', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('LicensingGroupPrice', car, i)"></span>
              <span id="f-LicensingGroupPrice" (mouseenter)="highlightFeatureRow('f-LicensingGroupPrice', true)"
                (mouseleave)="highlightFeatureRow('f-LicensingGroupPrice', false)" class="car-feature-value">
                {{getFeatureValue('LicensingGroupPrice', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('UsageTax', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('UsageTax', car, i)"></span>
              <span id="f-UsageTax" (mouseenter)="highlightFeatureRow('f-UsageTax', true)"
                (mouseleave)="highlightFeatureRow('f-UsageTax', false)" class="car-feature-value">
                {{getFeatureValue('UsageTax', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('CountryOfOrigin', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('CountryOfOrigin', car, i)"></span>
              <span id="f-CountryOfOrigin" (mouseenter)="highlightFeatureRow('f-CountryOfOrigin', true)"
                (mouseleave)="highlightFeatureRow('f-CountryOfOrigin', false)" class="car-feature-value">
                {{getFeatureValue('CountryOfOrigin', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('DoorNumber', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('DoorNumber', car, i)"></span>
              <span id="f-DoorNumber" (mouseenter)="highlightFeatureRow('f-DoorNumber', true)"
                (mouseleave)="highlightFeatureRow('f-DoorNumber', false)" class="car-feature-value">
                {{getFeatureValue('DoorNumber', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('Seats', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('Seats', car, i)"></span>
              <span id="f-Seats" (mouseenter)="highlightFeatureRow('f-Seats', true)"
                (mouseleave)="highlightFeatureRow('f-Seats', false)" class="car-feature-value">
                {{getFeatureValue('Seats', car)}}</span>
            </div>
          </div>


          <div class="car-feature-main-title" *ngIf="i == 0; else diffMainTitle">מידות</div>

          <div class="features-group-container">
            <div *ngIf="i == 0 && hasFeatureValue('Height', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('Height', car, i)"></span>
              <span id="f-Height" class="car-feature-value">
                {{getFeatureValue('Height', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('TotalWeight', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('TotalWeight', car, i)"></span>
              <span id="f-TotalWeight" class="car-feature-value">
                {{getFeatureValue('TotalWeight', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('HasCabin', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasCabin', car, i)"></span>
              <span id="f-HasCabin" class="car-feature-value">
                {{getFeatureValue('HasCabin', car)}}</span>
            </div>
          </div>

          <div class="car-feature-main-title" *ngIf="i == 0; else diffMainTitle">מנוע</div>

          <div class="features-group-container">
            <div *ngIf="i == 0 && hasFeatureValue('EngineType', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('EngineType', car, i)"></span>
              <span id="f-EngineType" class="car-feature-value">
                {{getFeatureValue('EngineType', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('IsHybrid', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('IsHybrid', car, i)"></span>
              <span id="f-IsHybrid" class="car-feature-value">
                {{getFeatureValue('IsHybrid', car)}}</span>
            </div>

            <!-- <div *ngIf="i == 0 && hasFeatureValue('FuelType', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('FuelType', car, i)"></span>
              <span id="f-FuelType" class="car-feature-value">
                {{getFeatureValue('FuelType', car)}}</span>
            </div> -->

            <div *ngIf="i == 0 && hasFeatureValue('TransmissionValue', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('TransmissionValue', car, i)"></span>
              <span id="f-TransmissionValue" class="car-feature-value">
                {{getFeatureValue('TransmissionValue', car)}}</span>
            </div>


            <div *ngIf="i == 0 && hasFeatureValue('Drivetrain', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('Drivetrain', car, i)"></span>
              <span id="f-Drivetrain" class="car-feature-value">
                {{getFeatureValue('Drivetrain', car)}}</span>
            </div>


            <div *ngIf="i == 0 && hasFeatureValue('BHP', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('BHP', car, i)"></span>
              <span id="f-BHP" class="car-feature-value">
                {{getFeatureValue('BHP', car)}}</span>
            </div>


            <div *ngIf="i == 0 && hasFeatureValue('EngineCapacity', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('EngineCapacity', car, i)"></span>
              <span id="f-EngineCapacity" class="car-feature-value">
                {{getFeatureValue('EngineCapacity', car)}}</span>
            </div>


            <div *ngIf="i == 0 && hasFeatureValue('GreenScore', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('GreenScore', car, i)"></span>
              <span id="f-GreenScore" class="car-feature-value">
                {{getFeatureValue('GreenScore', car)}}</span>
            </div>


            <div *ngIf="i == 0 && hasFeatureValue('DegreeOfPollution', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('DegreeOfPollution', car, i)"></span>
              <span id="f-DegreeOfPollution" class="car-feature-value">
                {{getFeatureValue('DegreeOfPollution', car)}}</span>
            </div>
          </div>

          <div class="car-feature-main-title" *ngIf="i == 0; else diffMainTitle">בטיחות</div>

          <div class="features-group-container">
            <div *ngIf="i == 0 && hasFeatureValue('SafetyEquipmentLevel', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyEquipmentLevel', car, i)"></span>
              <span id="f-SafetyEquipmentLevel" class="car-feature-value">
                {{getFeatureValue('SafetyEquipmentLevel', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('SafetyScore', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyScore', car, i)"></span>
              <span id="f-SafetyScore" class="car-feature-value">
                {{getFeatureValue('SafetyScore', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('HasESP', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasESP', car, i)"></span>
              <span id="f-HasESP" class="car-feature-value">
                {{getFeatureValue('HasESP', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('LaneDepartureControl', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('LaneDepartureControl', car, i)"></span>
              <span id="f-LaneDepartureControl" class="car-feature-value">
                {{getFeatureValue('LaneDepartureControl', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('AdaptiveCruiseControl', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('AdaptiveCruiseControl', car, i)"></span>
              <span id="f-AdaptiveCruiseControl" class="car-feature-value">
                {{getFeatureValue('AdaptiveCruiseControl', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('PedestrianDetection', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('PedestrianDetection', car, i)"></span>
              <span id="f-PedestrianDetection" class="car-feature-value">
                {{getFeatureValue('PedestrianDetection', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('FailureToMaintainDistanceDetection', car)">
              <span class="car-feature-title"
                [innerHTML]="getFeatureTitle('FailureToMaintainDistanceDetection', car, i)">
              </span>
              <span id="f-FailureToMaintainDistanceDetection" class="car-feature-value">
                {{getFeatureValue('FailureToMaintainDistanceDetection', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('DeadSpaceDetection', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('DeadSpaceDetection', car, i)"></span>
              <span id="f-DeadSpaceDetection" class="car-feature-value">
                {{getFeatureValue('DeadSpaceDetection', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('TrafficSignsDetection', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('TrafficSignsDetection', car, i)"></span>
              <span id="f-TrafficSignsDetection" class="car-feature-value">
                {{getFeatureValue('TrafficSignsDetection', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('SafetyBeltsSensors', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('SafetyBeltsSensors', car, i)"></span>
              <span id="f-SafetyBeltsSensors" class="car-feature-value">
                {{getFeatureValue('SafetyBeltsSensors', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('TPMS', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('TPMS', car, i)"></span>
              <span id="f-TPMS" class="car-feature-value">
                {{getFeatureValue('TPMS', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('AirbagsNumber', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('AirbagsNumber', car, i)"></span>
              <span id="f-AirbagsNumber" class="car-feature-value">
                {{getFeatureValue('AirbagsNumber', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('BrakingAuxiliarySystem', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('BrakingAuxiliarySystem', car, i)"></span>
              <span id="f-BrakingAuxiliarySystem" class="car-feature-value">
                {{getFeatureValue('BrakingAuxiliarySystem', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('ReverseCamera', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('ReverseCamera', car, i)"></span>
              <span id="f-ReverseCamera" class="car-feature-value">
                {{getFeatureValue('ReverseCamera', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('FrontDistanceMonitoring', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('FrontDistanceMonitoring', car, i)"></span>
              <span id="f-FrontDistanceMonitoring" class="car-feature-value">
                {{getFeatureValue('FrontDistanceMonitoring', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('HasABS', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasABS', car, i)"></span>
              <span id="f-HasABS" class="car-feature-value">
                {{getFeatureValue('HasABS', car)}}</span>
            </div>

            <div class="car-feature-main-title" *ngIf="i == 0; else diffMainTitle">אבזור</div>

            <div *ngIf="i == 0 && hasFeatureValue('AutomaticHighLightingControl', car)">
              <span class="car-feature-title"
                [innerHTML]="getFeatureTitle('AutomaticHighLightingControl', car, i)"></span>
              <span id="f-AutomaticHighLightingControl" class="car-feature-value">
                {{getFeatureValue('AutomaticHighLightingControl', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('HasAlloyWheels', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('HasAlloyWheels', car, i)"></span>
              <span id="f-HasAlloyWheels" class="car-feature-value">
                {{getFeatureValue('HasAlloyWheels', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('HasSunroof', car)"><span class="car-feature-title"
                [innerHTML]="getFeatureTitle('HasSunroof', car, i)"></span>
              <span id="f-HasSunroof" class="car-feature-value">
                {{getFeatureValue('HasSunroof', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('PowerWindowsNumber', car)">
              <span class="car-feature-title" [innerHTML]="getFeatureTitle('PowerWindowsNumber', car, i)"></span>
              <span id="f-PowerWindowsNumber" class="car-feature-value">
                {{getFeatureValue('PowerWindowsNumber', car)}}</span>
            </div>

            <div *ngIf="i == 0 && hasFeatureValue('AutomaticLightingOnDriving', car)">
              <span class="car-feature-title"
                [innerHTML]="getFeatureTitle('AutomaticLightingOnDriving', car, i)"></span>
              <span id="f-AutomaticLightingOnDriving" class="car-feature-value">
                {{getFeatureValue('AutomaticLightingOnDriving', car)}}</span>
            </div>
          </div>
        </div>

        <div *ngFor="let car of blankCarModelsCount; let i=index;" class="col-md-2 col-4 p-0 features-list-container">
        </div>

        <div class="col-md-4 p-0">
          <div>
            <app-safety-score-meter [value]="carInfo?.safetyScore"></app-safety-score-meter>
          </div>
          <div style="padding-top: 25px; padding-bottom: 40px">
            <app-pollution-level-meter *ngIf="carInfo?.degreeOfPollution > 0" [value]="carInfo.degreeOfPollution">
            </app-pollution-level-meter>
          </div>
      
          <div>
            <div >
              <app-car-video></app-car-video>
            </div>
            <app-car-details-contact-form></app-car-details-contact-form>
            <!-- <app-car-community-info></app-car-community-info> -->
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>

</div>