import { Component, OnInit, OnDestroy, Optional, Inject, PLATFORM_ID } from '@angular/core';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UtilsService } from '../../../../app/services/utils.service';
declare var $: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , @Optional() @Inject(RESPONSE) private response: any
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, '404-not-found');
    });
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }

    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(() => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
          document.location.href = '';
        }, 5000);
      });
    }
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  goToHomePage() {
    this.router.navigate(['']);
  }
}
