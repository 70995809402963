<div class="container">
  <div class="row">
    <div class="col-7" style="padding-right: 0;">
      <div class="manf-container" *ngFor="let model of firstColumnCarModels; let i=index;">
        <a href="{{catalogService.urlBuilder(model.value.toLowerCase(), 5)}}"
          (click)="toggleModelCategoryFilterItem(model.value)"
          [ngClass]="toggleModelCategoryFilterItemClass(model.value)">{{model.name}}</a>
        <div class="manf-image-container d-none d-xl-block">
          <a href="{{getUrl(model.value)}}">
            <img loading="lazy" alt="{{getCapitalizeString(model.value)}}" title="{{getCapitalizeString(model.value)}}"
              src="{{getImage(model.value)}}" />
          </a>
        </div>
      </div>

    </div>
    <div class="col-5" style="padding-right: 0;">
      <div class="manf-container" *ngFor="let model of secondColumnCarModels; let i=index;">
        <a href="{{catalogService.urlBuilder(model.value.toLowerCase(), 5)}}"
          (click)="toggleModelCategoryFilterItem(model.value)"
          [ngClass]="toggleModelCategoryFilterItemClass(model.value)">{{model.name}}</a>
        <div class="manf-image-container d-none d-xl-block">
          <a href="{{getUrl(model.value)}}">
            <img loading="lazy" alt="{{getCapitalizeString(model.value)}}" title="{{getCapitalizeString(model.value)}}"
              src="{{getImage(model.value)}}" />
          </a>
        </div>
        
      </div>

    </div>
  </div>
</div>