import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '../../../../app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadTicket } from '../../../../app/models/leadTicket';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lead-no',
  templateUrl: './lead-no.component.html',
  styleUrls: ['./lead-no.component.css']
})
export class LeadNoComponent implements OnInit, OnDestroy {

  id: string;
  comment: string;
  private sub: any;
  leadTicket: LeadTicket;
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(public httpClient: HttpService
    , private route: ActivatedRoute
    , private router: Router
    , public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'lead-no-page');
    });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getLeadTicketInfo();
    });
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  getLeadTicketInfo() {
    this.httpClient.getLeadTicketInfo(this.id).subscribe((data: any) => {
      this.leadTicket = data;
    });
  }

  updateResponse() {
    this.leadTicket.comment = this.comment;
    this.httpClient.updateLeadTicketDeclineResponse(this.leadTicket).subscribe((data: any) => {
      this.router.navigate(['/thanks']);
    });
  }
}
