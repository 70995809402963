import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, AfterContentChecked, Inject, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { ComparisonService } from '../../../services/comparison.service';
import { CatalogService } from '../../../services/catalog.service';
import { MessageService } from '../../../services/message.service';
import { CarFindCarVersion } from '../../../models/CarFindCarVersion';
import { Subscription } from 'rxjs';
import { FavoritesService } from '../../../../app/services/favorites.service';
import { DialogService } from '../../../../app/services/dialog.service';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser, Location, PlatformLocation } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { SharedDataService } from '../../../services/shared-data.service';
import { memoize } from 'lodash-decorators';
import { urlencoded } from 'express';
import { escape } from 'querystring';
import { ImageTypeSchemeItem } from 'src/app/models/imageTypeSchemeItem';
import { catchError } from 'rxjs/operators';
import { resourceUsage } from 'process';
declare var $: any;

@Component({
  selector: 'app-car-list',
  templateUrl: './car-list.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./car-list.component.css']
})
export class CarListComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {

  public carList: CarFindCarVersion[] = [];
  subscription: Subscription;
  messages: any[] = [];
  favorites: number[] = [];
  sortByLabel: string;
  resultLabel: string;
  isResultFiltered: boolean;
  promiseSetBySomeAction: any;
  isMoreCarsButtonVisible: boolean;
  filteredByZeroKMPriceDirection: number;
  filteredByBasePriceDirection: number;
  filteredByMonthlyPaymentDirection: number;
  sortDirection: number;
  isFilteredByZeroKMPrice: boolean;
  isFilteredByBasePrice: boolean;
  isFilteredByMonthlyPayment: boolean;
  isSorted: boolean;
  sortImageDirection: string;
  basePriceSortImageDirection: string;
  monthlyPaymentSortImageDirection: string;
  zeroKMsortImageDirection: string;
  sortBy: string;
  fromDealerText: string;
  fromZero0KmText: string;
  favoritesActionContextMenuText: string;
  comparisonTitle: string;
  private sub: any;
  f: string;
  mainSearchKeyword: string = null;
  newUserCookie = 'new-user';
  isMobile = this.utilsService.IsMobile();
  isModelPage = false;
  isManufacturerPage = false;
  isBrowser: boolean;
  showView: boolean = true;
  isMainDealType = true;
  isHomePage = true;
  backButtonPressed = false;
  changeDetection: ChangeDetectionStrategy.Default
  isDealerSearchLoggedIn = false;
  catalogImages: ImageTypeSchemeItem[] = [];

  @Input()
  IsFiltered = false;

  @Input()
  Year = 0;

  constructor(
    private router: Router
    , public httpClient: HttpService
    , public comparisonService: ComparisonService
    , public catalogService: CatalogService
    , private messageService: MessageService
    , public utilsService: UtilsService
    , public favoritesService: FavoritesService
    , private dialogService: DialogService
    , private _elementRef: ElementRef
    , private route: ActivatedRoute
    , private cookieService: CookieService
    , private meta: Meta
    , private titleService: Title
    , private location: Location
    , private sanitizer: DomSanitizer
    , plocation: PlatformLocation
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    plocation.onPopState(() => {
      this.backButtonPressed = true;
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'refresh') {
        this.messageService.sendMessage('loading-start');
        // this.carList = null;

        if (this.Year > 0) {
          this.catalogService.catalogFilter.years.splice(0);
          this.catalogService.catalogFilter.years.push(this.Year);
        }

        this.catalogService.getCatalogByFilter().subscribe((data: CarFindCarVersion[]) => {
          if (data.length === 0) {
            this.showView = false;
            this.messageService.sendMessage('loading-stop');
          }
          else
            this.showView = true;

          this.carList = data;

          if (this.carList != null && this.carList.length > 0) {
            this.carList.forEach(item => {
              const image = new ImageTypeSchemeItem();
              image.url = this.getImageUrl(item);
              image.title = this.getImageTitle(item);
              image.fileName = image.url.substring(image.url.lastIndexOf('/') + 1);
              this.catalogImages.push(image)
            });

            this.utilsService.setImageScheme(this.catalogImages);
          }

          this.isMainDealType = this.catalogService.catalogFilter.DealType == SharedDataService.DealTypes[0];
          this.isHomePage = this.utilsService.IsHomePage();

          this.isResultFiltered = this.catalogService.IsResulrFiltered();

          if (!this.catalogService.IsPageExist() && this.router.url.includes('page=')) {
            const url = this.catalogService.urlBuilder();
            // this.utilsService.redirect(url, 301);
          }

          this.catalogService.setPageNumber(++this.catalogService.catalogFilter.PageNumber);
          this.utilsService.setPaginationLinks(this.catalogService.catalogFilter.MainSearchKeyword, this.catalogService.catalogFilter.PageNumber, this.catalogService.IsLastPage());

          if (this.utilsService.IsHomePage() && this.isResultFiltered) {
            this.resultLabel = `הסר סינון ${this.catalogService.GetTotalCarCount()} דגמים`;
          } else if (this.utilsService.IsHomePage()) {
            this.resultLabel = this.getResultCountText();
          } else {
            const urlData = this.router.url.split('/');
            this.isModelPage = (urlData.length === 3 && isNaN(+urlData[2])) || (urlData.length === 4 && (+urlData[3] > 2010));
            this.isManufacturerPage = urlData.length === 2 || (urlData.length === 3 && (+urlData[2] > 2010));
            let resultTitle = '';
            if (this.isModelPage) {
              resultTitle = `${this.catalogService.GetTotalCarCount()} תצורות`;
            } else {
              resultTitle = `${this.catalogService.GetTotalCarCount()} תצורות של ${this.carList[0].hebManufacturerName}`;
            }
            this.sortByLabel = resultTitle;
            this.resultLabel = resultTitle;
          }

          this.SetMoreCarsButtonStatus();
          this.messageService.sendMessage('loading-stop');

          this.messageService.sendMessage(`catalog-filter-result-count-${this.catalogService.GetTotalCarCount()}`);

          if (this.catalogService.catalogFilter.MainSearchKeyword !== undefined
            && this.catalogService.catalogFilter.MainSearchKeyword.length > 0 && this.carList.length === 0) {
            $('#noResultsInSearchAlert').modal('show');
          } else if (this.carList.length === 0 && this.utilsService.IsHomePage()) {
            this.utilsService.addNoIndexMeta(false);
            $('#noResultsInFiltersAlert').modal('show');
          }

          if (this.isHomePage) {
            this.catalogService.getCatalogFilterCountData().subscribe((data: any[]) => {
              this.messageService.sendMessage('filter-count-refresh');
            });
          }
        });
      } else if (message.text === 'reset-filters') {
        this.catalogService.setDealType('new-cars');
        this.resetAll();
        this.catalogService.setPageNumber(2);
      } else if (message.text === 'favorites-show') {
      } else {
        this.messages = [];
      }
    });

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.kw !== undefined) {
          this.mainSearchKeyword = params.kw;
          this.searchByKeyword(this.mainSearchKeyword);
        }
      });

    // if (isPlatformBrowser(this.platformId)) {
    //   $(document).ready(function () {
    //     $("iframe").load(function () {
    //       $(this).contents().on("click", function () {
    //       });
    //     });
    //   });
    // }

    const isMobile = this.utilsService.IsMobile();
    this.fromDealerText = 'תשלום חודשי';

    if (isMobile) {
      this.fromZero0KmText = 'יבואן (יד 00)';
      this.comparisonService.Clear();
    } else {
      this.fromZero0KmText = 'מחיר יבואן (יד 00)';
    }

    this.sortByLabel = this.sortDefaultText();
    this.setComparisonText();

    this.isDealerSearchLoggedIn = this.utilsService.isDealerSearchLoggedIn();

    if (isPlatformBrowser(this.platformId)) {
      if (this.catalogService.catalogFilter.PageNumber >= 5) {
        window.addEventListener('load', () => {
          this.utilsService.encryptLinks('more-button-container');
        });
      }
    }
  }

  resetAll() {
    this.reset();
    this.catalogService.generateFilter();
    this.catalogService.SetResultFilterStatus(false);
    this.isResultFiltered = this.catalogService.IsResulrFiltered();
    this.getCatalog();
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('[data-toggle="popover"]').popover({ trigger: 'hover' });
    }
  }

  showSortComparePopover() {
    if (isPlatformBrowser(this.platformId)) {
      const data = this.cookieService.get(this.newUserCookie);
      if (data === '' || data === 'NaN') {
        $('#header-comparison').popover({
          placement: 'bottom',
          html: true,
          selector: false,
          container: 'body',
          offset: 130,
          content: '<img id="img-close" class="point" src="assets/images/svg/close-x-button-red.svg"/><br />'
            + '<span class="popover-text">להשוות דגמים: לחצו על כפתור ההשוואה</span><br />'
            + '<span>למיון דגמים: לחצו על כפתור המיון</span><br />'
        });

        $('#header-comparison').popover('show');
        $('#img-close').click(() => {
          $('#header-comparison').popover('hide');
        }).popover('show');
      }
    }
  }

  searchByKeyword(searchKeyword) {
    this.catalogService.Search(searchKeyword);
    this.messageService.sendMessage('refresh');

    this.utilsService.generatePageTitle(`תוצאות חיפוש - ${searchKeyword}`);
    this.utilsService.addMetaDescriptionTag(`אתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ. כל הדגמים של מיטב היצרנים במקום אחד עם המחיר המפורסם הזול בישראל. ניתן למצוא אצלנו את כל המידע על ${searchKeyword}.`);

    this.meta.updateTag(
      { name: 'ROBOTS', content: 'NOINDEX, FOLLOW' },
    );
  }

  showFavoritesList(event) {
    const target = event !== null ? (event.target || event.srcElement || event.currentTarget) : null;
    if (this.favoritesService.carIds.length > 0) {
      this.catalogService.setfavorites(this.favoritesService.carIds);
      this.getCatalog();
      this.isResultFiltered = true;

      if (target !== null) {
        this.dialogService.removeAlert(target);
      }
    } else {
      if (target !== null) {
        this.dialogService.showAlert(target, 'show-nofav-alert');
      }
    }
  }

  geCarMainPrice(basePrice: number, discountPrice: number) {
    if (discountPrice > 0)
      return `${discountPrice.toLocaleString()} ₪`;
    else if (basePrice == 0)
      return '-';
    else
      return `${basePrice.toLocaleString()} ₪`;
  }

  geCarCategories(categories: number[]) {
    let categoriesLabel: string = "";
    const cat = categories[0];
    switch (cat) {
      case 1:
        categoriesLabel += " רכב קטן";
        break;
      case 2:
        categoriesLabel += " משפחתית";
        break;
      case 3:
        categoriesLabel += " יוקרה";
        break;
      case 4:
        categoriesLabel += " רכב פנאי";
        break;
      case 5:
        categoriesLabel += " מסחרית";
        break;
    }

    return `${categoriesLabel}`;
  }

  hideBasePriceDiscountLabel(basePrice: number, discountPrice: number) {
    if (discountPrice > 0)
      return `${basePrice.toLocaleString()} ₪`;
  }

  sortDefaultText() {
    const isMobile = this.utilsService.IsMobile();
    if (this.utilsService.IsHomePage()) {
      if (isMobile) {
        return 'בחר/י מיון';
      } else {
        return 'לחצו למיון תוצאות';
      }
    } else {
      if (isMobile) {
        return `תצורות`;
      } else {
        return '';
      }
    }
  }

  reset() {
    this.mainSearchKeyword = null;
    this.sortByLabel = this.sortDefaultText();
    this.resultLabel = '';
    this.isResultFiltered = false;
    this.isMoreCarsButtonVisible = true;
    this.filteredByZeroKMPriceDirection = 2;
    this.filteredByBasePriceDirection = 2;
    this.filteredByMonthlyPaymentDirection = 2;
    this.sortDirection = 1;
    this.isFilteredByZeroKMPrice = false;
    this.isFilteredByBasePrice = false;
    this.isFilteredByMonthlyPayment = false;
    this.isSorted = false;
    this.sortImageDirection = "&#x25B2;";
    this.basePriceSortImageDirection = "&#x25B2;";
    this.zeroKMsortImageDirection = "&#x25B2;";
    this.monthlyPaymentSortImageDirection = "&#x25B2;";
    this.sortBy = '';
  }

  ResetResult() {
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'ClearResults', 1);

    this.messageService.sendMessage('reset-filters');

    if (isPlatformBrowser(this.platformId)) {
      document.location.href = '/';
    }
  }

  GetCarCount(): number {
    return this.catalogService.GetCarCount();
  }

  getCatalog() {
    if (this.mainSearchKeyword == null) {
      this.messageService.sendMessage('loading-start');
      this.catalogService.getCatalogByFilter().subscribe((data: CarFindCarVersion[]) => {
        this.carList = data;
        this.ShowResultText(this.catalogService.GetTotalCarCount());
        this.messageService.sendMessage('data-done');
        this.messageService.sendMessage('loading-stop');

        this.messageService.sendMessage(`catalog-filter-result-count-${this.catalogService.GetTotalCarCount()}`);
        this.SetMoreCarsButtonStatus();

        if (this.isHomePage) {
          this.catalogService.getCatalogFilterCountData().subscribe((data: any) => {
            this.messageService.sendMessage('filter-count-refresh');
          });
        }
      });
    }
  }

  getMore() {
    if (this.utilsService.IsHomePage()) {
      this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'ShowMore', 1);
    }

    this.router.navigateByUrl(this.generateMoreUrl());

    // this.catalogService.setPageNumber(2);
    // this.getCatalog();

    return false;
  }

  getMoreUrl() {
    return this.generateMoreUrl();
  }

  generateMoreUrl() {
    let url = '';
    if (this.router.url.includes('?kw')) {
      if (this.router.url.includes('page=')) {
        url = this.router.url;
        url = `/?kw=${this.catalogService.catalogFilter.MainSearchKeyword}&page=${this.catalogService.catalogFilter.PageNumber + 1}`;
      } else {
        url = this.router.url;
        url = `${url}&page=${this.catalogService.catalogFilter.PageNumber}`;
      }
    } else {
      url = this.catalogService.urlBuilder();
      url = `${url}?page=${this.catalogService.catalogFilter.PageNumber}`;
    }
    return url;
  }

  SetMoreCarsButtonStatus() {
    this.isMoreCarsButtonVisible = !(this.catalogService.IsLastPage());
  }

  toggleFavoriteStyle(carId: any) {
    if (this.favoritesService.IsChecked(carId)) {
      return "d-none d-xl-block point favorite-icon-selected";
    } else {
      return "d-none d-xl-block point favorite-icon";
    }
  }

  toggleFavoriteStyleMobile(carId: any) {
    if (this.favoritesService.IsChecked(carId)) {
      return 'assets/images/svg/ic-favorite-red.svg';
    } else {
      return 'assets/images/svg/favorite-border.svg';
    }
  }

  setFavorite(car: any) {
    if (isPlatformBrowser(this.platformId)) {
      const key = 'favInfoAlert';
      const favInfoAlertShown = this.utilsService.getLocalValue(key);
      if (!favInfoAlertShown) {
        $('#favInfoAlert').modal('show');
        this.utilsService.setLocalValue(key, true);
      }
    }

    const carId = car.id;
    if (this.favoritesService.IsChecked(carId)) {
      this.favoritesService.Remove(carId);
    } else {
      this.favoritesService.Add(carId, car);
    }
    this.messageService.sendMessage('favorite-update');
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Heart', 1);
  }

  setFavoriteMobile(event: any, car: any) {
    const carId = car.id;
    const domElement = this._elementRef.nativeElement.querySelector(`#fav-${carId}`);

    if (this.favoritesService.IsChecked(carId)) {
      this.favoritesService.Remove(carId);
      domElement.src = 'assets/images/svg/favorite-border.svg';
    } else {
      this.favoritesService.Add(carId, car);
      domElement.src = 'assets/images/svg/ic-favorite-red.svg';
    }
    this.messageService.sendMessage('favorite-update');
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Heart', 1);
  }

  initFavoriteIconMobile(carId: any) {
    const domElement = this._elementRef.nativeElement.querySelector(`#fav-${carId}`);
    if (this.favoritesService.IsChecked(carId)) {
      domElement.src = 'assets/images/svg/favorite-border.svg';
    } else {
      domElement.src = 'assets/images/svg/ic-favorite-red.svg';
    }
  }

  ShowResultText(count: number) {
    if (this.isMobile) {
      this.resultLabel = `מציג ${this.catalogService.GetTotalCarCount()} דגמים`;
    } else {
      if (this.IsFiltered) {
        this.isResultFiltered = true;
        this.resultLabel = `לחצו לחזרה לעמוד הראשי`;
      } else {
        this.resultLabel = this.getResultCountText();
      }
    }
  }

  getResultCountText() {
    const dealType = this.catalogService.catalogFilter.DealType;
    switch (dealType) {
      case SharedDataService.DealTypes[0]:
        return `${this.catalogService.GetTotalCarCount()} רכבים המייצגים 3,864 גרסאות`;
      case SharedDataService.DealTypes[1]:
        return `${this.catalogService.GetTotalCarCount()} רכבים ולהם 1206 מבצעים`;
      case SharedDataService.DealTypes[2]:
        return `${this.catalogService.GetTotalCarCount()} רכבים ולהם 1125 מבצעי 0 ק"מ`;
      case SharedDataService.DealTypes[3]:
        return `${this.catalogService.GetTotalCarCount()} רכבים ולהם 81 מבצעי יבואן`;
    }
  }

  setComparisonText() {
    const count = this.comparisonService.GetCarCount();
    if (count === 0) {
      this.comparisonTitle = 'לחצו להשוואת דגמים';
    } else {
      this.comparisonTitle = 'השווה';
    }
  }

  checkValue(event: any, carId: any) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target || event.srcElement || event.currentTarget;
    const idAttr = target.id;

    if (event.target.checked) {
      this.comparisonService.AddCar(carId);

      const isMobile = this.utilsService.IsMobile();
      const comparisonLimit = isMobile ? 1 : 4;

      if (this.GetComparisonCarCount() > comparisonLimit) {
        if (isMobile) {
          this.dialogService.removeAlert(target);
          const carIds = this.comparisonService.getCompareUrl();
          this.router.navigate([`compare-cars/versions/${carIds}`]);
        } else {
          this.dialogService.showAlert(target, 'show-comparison-limit-alert');
        }
      }
    } else {
      this.comparisonService.RemoveCar(carId);
    }

    this.setComparisonText();
    this.GetComparisonCarCount();

    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Checkbox', 1);

    return false;
  }

  isCheckedForComparison(carId: any) {
    return this.comparisonService.IsChecked(carId);
  }

  navigateToCarPage(index: number) {
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Config', 1);
    this.router.navigate([this.getConfigurationPageUrl(index)]);
  }

  GetComparisonCarCount() {
    const count = this.comparisonService.GetCarCount();
    return count;
  }

  refreshSort() {
    this.closeSortPopup();

    this.sortDirection = this.sortDirection === 1 ? 2 : 1;
    this.SortBySelection(this.sortBy);
  }

  SortByBasePrice() {
    if (!this.utilsService.IsHomePage()) {
      return;
    }

    this.filteredByZeroKMPriceDirection = 2;
    this.filteredByBasePriceDirection = this.filteredByBasePriceDirection === 1 ? 2 : 1;
    this.setSortFilter('pricedealer', this.filteredByBasePriceDirection);

    this.isFilteredByBasePrice = true;
    this.isFilteredByZeroKMPrice = false;
    this.isFilteredByMonthlyPayment = false;
    this.isSorted = false;
    const direction = this.filteredByBasePriceDirection === 1 ? 'up' : 'down';
    this.basePriceSortImageDirection = this.filteredByBasePriceDirection === 1 ? '&#x25B2;' : '&#x25BC;';
    this.sortByLabel = this.sortDefaultText();

    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Yevuan', 1);
    this.navigateToFilterPage();
    // this.messageService.sendMessage('refresh');
  }

  SortByMonthlyPayment() {
    if (!this.utilsService.IsHomePage()) {
      return;
    }

    this.filteredByZeroKMPriceDirection = 2;
    this.filteredByMonthlyPaymentDirection = this.filteredByMonthlyPaymentDirection === 1 ? 2 : 1;
    this.setSortFilter('monthlypayment', this.filteredByMonthlyPaymentDirection);

    this.isFilteredByBasePrice = false;
    this.isFilteredByMonthlyPayment = true;
    this.isFilteredByZeroKMPrice = false;
    this.isSorted = false;
    const direction = this.filteredByMonthlyPaymentDirection === 1 ? 'up' : 'down';
    this.monthlyPaymentSortImageDirection = this.filteredByMonthlyPaymentDirection === 1 ? '&#x25B2;' : '&#x25BC;';
    this.sortByLabel = this.sortDefaultText();

    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'MonthlyPayment', 1);
    this.navigateToFilterPage();
    // this.messageService.sendMessage('refresh');
  }

  SortByZeroKMPrice() {
    if (!this.utilsService.IsHomePage()) {
      return;
    }

    this.filteredByBasePriceDirection = 2;
    this.filteredByZeroKMPriceDirection = this.filteredByZeroKMPriceDirection === 1 ? 2 : 1;
    this.setSortFilter('pricezerokm', this.filteredByZeroKMPriceDirection);

    this.isFilteredByBasePrice = false;
    this.isFilteredByMonthlyPayment = false;
    this.isFilteredByZeroKMPrice = true;
    this.isSorted = false;
    const direction = this.filteredByZeroKMPriceDirection === 1 ? 'up' : 'down';
    this.zeroKMsortImageDirection = this.filteredByZeroKMPriceDirection === 1 ? '&#x25B2;' : '&#x25BC;';
    this.sortByLabel = this.sortDefaultText();

    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Efes', 1);
    this.navigateToFilterPage();

    // this.messageService.sendMessage('refresh');
  }

  showSortMenu(event) {
    if (isPlatformBrowser(this.platformId)) {
      $('#sortDropDown').css('display', 'block');
    }

    event.preventDefault();
    event.stopPropagation();
  }

  Sort(sortBy: string, event) {
    if (isPlatformBrowser(this.platformId)) {
      this.closeSortPopup();
    }

    this.sortDirection = 1;
    this.SortBySelection(sortBy);

    event.preventDefault();
    event.stopPropagation();

    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'Sort', 1);
  }

  sortOnServer() {
    this.catalogService.getCatalogByFilter().subscribe((data: CarFindCarVersion[]) => {
      this.carList = data;

      if (this.isHomePage) {
        this.catalogService.getCatalogFilterCountData().subscribe((data: any) => {
          this.messageService.sendMessage('filter-count-refresh');
        });
      }
    });
  }

  navigateToFilterPage() {
    let url = this.catalogService.urlBuilder();
    if (this.catalogService.catalogFilter.MainSearchKeyword !== undefined) {
      url += '?kw=' + this.catalogService.catalogFilter.MainSearchKeyword;
    }

    this.router.navigateByUrl(url);
  }

  setSortFilter(sortId, sortDirection = this.sortDirection) {
    this.catalogService.catalogFilter.SortBy = sortId;
    this.catalogService.catalogFilter.SortDirection = sortDirection;
  }

  SortBySelection(sortBy: string) {

    this.isFilteredByBasePrice = false;
    this.isFilteredByZeroKMPrice = false;
    this.isFilteredByMonthlyPayment = false;

    this.sortBy = sortBy;
    let sortByText = '';

    const direction = this.sortDirection === 1 ? 'up' : 'down';
    this.sortImageDirection = this.sortDirection === 1 ? '&#x25B2;' : '&#x25BC;';

    this.isSorted = true;

    switch (sortBy) {
      case 'price':
        this.sortByLabel = this.sortDefaultText();
        this.SortByBasePrice();
        return;
      case 'year':
        sortByText = 'שנת השקה';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'enginecapacity':
        sortByText = 'נפח';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'bhp':
        sortByText = 'הספק מרבי';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'kml':
        sortByText = 'צריכת דלק';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'length':
        sortByText = 'אורך';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'bootcapacity':
        sortByText = 'תא מטען';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'pricezerokm':
        this.sortByLabel = this.sortDefaultText();
        this.SortByZeroKMPrice();
        return;
      case 'name':
        sortByText = 'שם דגם';
        this.setSortFilter(sortBy);
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'monthlypayment':
        this.sortByLabel = this.sortDefaultText();
        this.SortByMonthlyPayment();
        return;
      case 'height':
        sortByText = 'גובה';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'weight':
        sortByText = 'משקל';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'safetyEquipmentLevel':
        sortByText = 'רמת אבזור בטיחותי';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
      case 'safetyScore':
        sortByText = 'ניקוד מבחן ריסוק';
        this.isFilteredByBasePrice = false;
        this.isFilteredByZeroKMPrice = false;
        this.isFilteredByMonthlyPayment = false;
        this.setSortFilter(sortBy);
        this.navigateToFilterPage();
        break;
    }

    this.messageService.sendMessage('refresh');
    this.sortByLabel = `מיון: ${sortByText}`;
  }

  compareCars() {
    if (this.GetComparisonCarCount() === 0) {
      return `/compare-cars`;
    } else {
      const carIds = this.comparisonService.getCompareUrl();
      return `/compare-cars/versions/${carIds}`;
    }
  }

  getConsumption(fuelType: string, data: number) {
    if (fuelType === 'חשמלי') {
      return `${data} ק"מ`;
    } else {
      return `${data}`;
    }
  }

  getConsumptionLabel(fuelType: string) {
    if (fuelType === 'חשמלי') {
      return `טווח`;
    } else {
      return `לליטר`;
    }
  }

  initFavoritesMenu(carId) {
    const isExist = this.favoritesService.IsChecked(carId);
    this.favoritesActionContextMenuText = isExist ? 'הסר' : 'הוסף';
  }

  getCarName(index) {
    const car = this.carList[index];
    // if (this.utilsService.IsHomePage()) {
    const isMobile = this.utilsService.IsMobile();
    let limit = 35;

    if (isMobile) {
      limit = 30;
    }

    return car.name.length > limit ? car.name.substring(0, limit) : car.name;
    // } else {
    //   return car.engModelName;
    // }
  }

  openDialog(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    target.setAttribute('data-toggle', 'modal');

    const url = this.router.url;
    if (url.includes('')) {
      target.setAttribute('data-target', '#services-dialog');
    } else {
      target.setAttribute('data-target', '#contact-dialog');
    }
  }

  getCarPropertyValue(value) {
    return isNaN(value) ? '-' : value;
  }

  resetAllFilterFromDialog() {
    $('#noResultsInSearchAlert').modal('hide');
    $('#noResultsInFiltersAlert').modal('hide');
    $('#favInfoAlert').modal('hide');
    this.ResetResult();
  }

  changeFilterFromDialog() {
    $('#noResultsInSearchAlert').modal('hide');
    $('#noResultsInFiltersAlert').modal('hide');
    $('#favInfoAlert').modal('hide');

    if (this.isMobile) {
      this.messageService.sendMessage('show-filters-modal');
    }
  }

  closeSortPopup() {
    $('#sortDropDown').css('display', 'none');
  }

  showZeopKMPopup() {
    this.googleAnalyticsService.eventEmitter('Welcome', 'Click', 'QuestionMark', 1);
  }

  getConfigurationPageUrl(index: number) {
    const car = this.carList[index];
    const url = `/${car.engManufacturerName.replace(/ /g, '-')}/${car.engParentModelName.replace(/ /g, '-')}/${car.engModelName.replace(/ /g, '-')}/${car.yearOfManufacture}/${this.utilsService.bodyTypeValueConverter(car.bodyType)}/${this.utilsService.engineValueConverter(car.engineType)}/${car.doors.toString()}-doors`.toLowerCase();
    return url;
  }

  @memoize()
  getImageUrl(car: CarFindCarVersion) {
    if (isPlatformBrowser(this.platformId)) {
      const url = this.utilsService.getCarVersionMainImage(car.mainImageFileName);
      return url;
    } else {
      return '';
    }
  }

  getImageAlt(car: CarFindCarVersion) {
    const urlData = this.router.url.split('/');
    const bodyType = car.bodyType;
    const engine = car.engineType;
    const doors = car.doors;
    const deal = this.utilsService.IsHomePage() ? `${SharedDataService.dealTypesCatalogFilterValues[this.catalogService.catalogFilter.DealType]} ` : '';

    if (car !== undefined) {
      return `${car.name} ${deal}${bodyType} ${engine} ${doors} דלתות`;
    }
  }

  getImageTitle(car: CarFindCarVersion) {
    const urlData = this.router.url.split('/');
    const bodyType = this.utilsService.bodyTypeValueConverter(car.bodyType).toLowerCase();
    const engine = this.utilsService.engineValueConverter(car.engineType).toLowerCase();
    const doors = car.doors;
    const deal = this.utilsService.IsHomePage() ? `${this.catalogService.catalogFilter.DealType} ` : '';

    let model = car.engParentModelName;
    if (car.engModelName !== '') {
      model = car.engModelName;
    }

    if (car !== undefined) {
      return `${car.engManufacturerName} ${model} ${deal}${bodyType} ${engine} ${doors} doors`;
    }
  }

  cancelLastFilter() {
    if (isPlatformBrowser(this.platformId)) {
      this.location.back();
      $('#noResultsInSearchAlert').modal('hide');
      $('#noResultsInFiltersAlert').modal('hide');
      $('#favInfoAlert').modal('hide');
      this.ResetResult();
    }
  }

  confirmFavInfoAlert() {
    if (isPlatformBrowser(this.platformId)) {
      $('#favInfoAlert').modal('hide');
    }
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      document.location.href = 'tel:035245241';
    }
  }

  @memoize()
  getDefaultImageUrl(event, bodyType) {
    if (isPlatformBrowser(this.platformId)) {
      const imageUrl = this.utilsService.getDefaultImageUrl(bodyType).toLowerCase();
      event.target.src = imageUrl;
    }
  }

  getCarVersionName(index) {
    const car = this.carList[index];
    return `${car.engSubModelName}`;

    // if (car.hebSubModelName !== undefined && car.hebSubModelName !== '') {
    //   return `${car.hebSubModelName}`;
    // } else {
    //   return `${car.engSubModelName}`;
    // }
  }

  search01Price(index) {
    const car = this.carList[index];
    let keyword = car.engModelName.toLowerCase();
    if (keyword.length <= 2) {
      keyword = `${car.hebManufacturerName} ${car.engModelName.toLowerCase()}`;
    }

    return `/dealers/search/dashboard?keyword=${keyword}`;
  }

  openPriceDialog(event, price, subModelId) {
    this.messageService.sendMessage(`car-model-meta-info@${price}@${subModelId}`);

    const target = event.target || event.srcElement || event.currentTarget;
    this.dialogService.setDialog(target, null, this.router.url, false, true);
  }

  getDrivingRangeText(value) {
    if (true) {
      return `הספק: ${this.getCarPropertyValue(value)} כ״ס`
    }

    return `טווח: ${this.getCarPropertyValue(value)} ק״מ`
  }

  getImageLoadingState(index) {
    if (index > 3) {
      return 'lazy';
    }
    return 'eager';
  }

  getDoorsText(showDoorsInfo: boolean, doors: number) {
    if (showDoorsInfo) {
      return `, ${doors} דלתות`
    }

    return '';
  }

  @memoize()
  getVideoUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @memoize()
  getVideoThumbnail(url: string) {
    return this.utilsService.getVideoThumbnail(url);
  }
}
