import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterContentChecked, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { MessageService } from '../../../services/message.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { DialogService } from '../../../services/dialog.service';
import { BasicContactForm } from '../../../models/basicContactForm';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CatalogService } from '../../../../app/services/catalog.service';
import { SharedDataService } from '../../../../app/services/shared-data.service';
import { reduceTicks } from '@swimlane/ngx-charts';
declare var $: any;

@Component({
  selector: 'app-car-wizard',
  templateUrl: './car-wizard.component.html',
  styleUrls: ['./car-wizard.component.css']
})
export class CarWizardComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  navigationMode: string = "free";
  subscription: Subscription;
  messages: any[] = [];
  currentStep = 1;
  step1HeaderClass1: string;
  step1HeaderClass2: string;
  step1HeaderClass3: string;
  step2HeaderClass1: string;
  step2HeaderClass2: string;
  step3HeaderClass1: string;
  step3HeaderClass2: string;
  step4HeaderClass1: string;
  step4HeaderClass2: string;
  step5HeaderClass1: string;
  step5HeaderClass2: string;
  isFinished = false;
  contactForm: BasicContactForm;
  resultCount = 0;
  minPrice: number;
  maxPrice: number;
  paymentsMinPrice: number;
  paymentsMaxPrice: number;
  priceValue: number[];
  paymentsPriceValue: number[];
  isMobile = false;
  priceMaxSliderLabel: string;
  paymentsPriceMaxSliderLabel: string;
  showPriceSlider = false;
  defaultPaymentBalloonValue: string;
  defaultPaymentAdvanceValue: string;
  defaultMaxPaymentValue: string;

  constructor(
    private messageService: MessageService
    , private utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , private dialogService: DialogService
    , public catalogService: CatalogService
    , private router: Router
    , @Inject(PLATFORM_ID) private platformId: Object
    , private _elementRef: ElementRef) {

    this.isMobile = this.utilsService.IsMobile();

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'reset-filters') {
        this.reset();
      } else if (message.text.includes('catalog-filter-result-count')) {
        const filterItemInfo = message.text.split('-');
        this.resultCount = +filterItemInfo[4];
      } else if (message.text.includes('catalog-filter-')) {
        this.initWizardByFilter();
      } else if (message.text === 'data-done') {
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.contactForm = new BasicContactForm();
    this.resetPriceSlider();
    this.priceMaxSliderLabel = "ללא הגבלה";
    this.paymentsPriceMaxSliderLabel = "ללא הגבלה";
    // this.defaultPaymentBalloonValue = `${this.catalogService.defaultPaymentBalloonValue.toString()}%`;
    // this.defaultPaymentAdvanceValue = `₪${this.catalogService.defaultPaymentAdvanceValue.toString()}`;

    this.defaultPaymentBalloonValue = `${this.catalogService.catalogFilter.PaymentBalloon.toLocaleString()}%`;
    this.defaultPaymentAdvanceValue = `₪${this.catalogService.catalogFilter.PaymentAdvance.toLocaleString()}`;
    this.defaultMaxPaymentValue = this.catalogService.catalogFilter.MaxPaymentPrice == 0 || this.catalogService.catalogFilter.MaxPaymentPrice == undefined ? 'בחירה' : `₪${this.catalogService.catalogFilter.MaxPaymentPrice.toLocaleString()}`;

    if (isPlatformBrowser(this.platformId)) {
      this.showPriceSlider = true;
    }

    this.setStatus(1);
    this.setStatus(2);
    this.setStatus(3);
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    this.initWizardByFilter();
  }

  ngAfterViewInit() {
  }

  initWizardByFilter() {
    if (isPlatformBrowser(this.platformId)) {
      $('#wizard-category-checkbox-1').prop('checked', this.catalogService.isCategoryFilterExist('coupe-converible'));
      this.setTextColor(`wizard-category-txtm-1`, this.catalogService.isCategoryFilterExist('coupe-converible'));

      $('#wizard-category-checkbox-2').prop('checked', this.catalogService.isCategoryFilterExist('pickup-van'));
      this.setTextColor(`wizard-category-txtm-2`, this.catalogService.isCategoryFilterExist('pickup-van'));

      $('#wizard-category-checkbox-3').prop('checked', this.catalogService.isCategoryFilterExist('small-hatchback'));
      this.setTextColor(`wizard-category-txtm-3`, this.catalogService.isCategoryFilterExist('small-hatchback'));

      $('#wizard-category-checkbox-4').prop('checked', this.catalogService.isCategoryFilterExist('sedan'));
      this.setTextColor(`wizard-category-txtm-4`, this.catalogService.isCategoryFilterExist('sedan'));

      $('#wizard-category-checkbox-5').prop('checked', this.catalogService.isCategoryFilterExist('suv'));
      this.setTextColor(`wizard-category-txtm-5`, this.catalogService.isCategoryFilterExist('suv'));

      $('#wizard-category-checkbox-6').prop('checked', this.catalogService.isCategoryFilterExist('7plus-seats'));
      this.setTextColor(`wizard-category-txtm-6`, this.catalogService.isCategoryFilterExist('7plus-seats'));

      $('#wizard-fuelType-checkbox-1').prop('checked', this.catalogService.isEngineFilterExist('benzin'));
      this.setTextColor(`wizard-fuelType-txtm-1`, this.catalogService.isEngineFilterExist('benzin'));

      $('#wizard-fuelType-checkbox-2').prop('checked', this.catalogService.isEngineFilterExist('diesel'));
      this.setTextColor(`wizard-fuelType-txtm-2`, this.catalogService.isEngineFilterExist('diesel'));

      $('#wizard-fuelType-checkbox-3').prop('checked', this.catalogService.isEngineFilterExist('hybrid'));
      this.setTextColor(`wizard-fuelType-txtm-3`, this.catalogService.isEngineFilterExist('hybrid'));

      $('#wizard-fuelType-checkbox-5').prop('checked', this.catalogService.isEngineFilterExist('electric'));
      this.setTextColor(`wizard-fuelType-txtm-5`, this.catalogService.isEngineFilterExist('electric'));

      $('#wizard-fuelType-checkbox-4').prop('checked', this.catalogService.isEngineFilterExist('plug-in'));
      this.setTextColor(`wizard-fuelType-txtm-4`, this.catalogService.isEngineFilterExist('plug-in'));

      $('#wizard-advanced-checkbox-2').prop('checked', this.catalogService.catalogFilter.DealType === SharedDataService.DealTypes[1]);
      this.setTextColor(`wizard-advanced-txtm-2`, this.catalogService.catalogFilter.DealType === SharedDataService.DealTypes[1]);

      $('#wizard-advanced-checkbox-4').prop('checked', this.catalogService.isTransmissionFilterExist('manual'));
      this.setTextColor(`wizard-advanced-txtm-4`, this.catalogService.isTransmissionFilterExist('manual'));

      $('#wizard-advanced-checkbox-1').prop('checked', this.catalogService.isDrivetrainFilterExist('4x4'));
      this.setTextColor(`wizard-advanced-txtm-1`, this.catalogService.isDrivetrainFilterExist('4x4'));

      $('#wizard-advanced-checkbox-3').prop('checked', this.catalogService.isSeatsFilterExist('3-plus-rows'));
      this.setTextColor(`wizard-advanced-txtm-3`, this.catalogService.isSeatsFilterExist('3-plus-rows'));
    }
  }

  resetPriceSlider() {
    this.minPrice = 60000;
    this.maxPrice = 300000;
    this.priceValue = [this.minPrice, this.maxPrice];

    this.paymentsMinPrice = 500;
    this.paymentsMaxPrice = 4000;
    this.paymentsPriceValue = [this.paymentsMinPrice, this.paymentsMaxPrice];
  }

  resetAll() {
    this.reset();
    this.messageService.sendMessage('reset-filters');
  }

  reset() {
    this.isFinished = false;

    this.GoTo(1);
    this.resetPriceSlider();
    this.priceMaxSliderLabel = "ללא הגבלה";
    this.paymentsPriceMaxSliderLabel = "ללא הגבלה";

    for (let index = 1; index <= 6; index++) {
      this.resetImage(`wizard-category-image-${index}`);
      this.resetCheckBox(`wizard-category-checkbox-${index}`);
      this.resetTextColor(`wizard-category-txtm-${index}`);
    }

    for (let index = 1; index <= 4; index++) {
      this.resetImage(`wizard-fuelType-image-${index}`);
      this.resetCheckBox(`wizard-fuelType-checkbox-${index}`);
      this.resetTextColor(`wizard-category-txtm-${index}`);
    }

    for (let index = 1; index <= 4; index++) {
      this.resetImage(`wizard-advanced-image-${index}`);
      this.resetCheckBox(`wizard-advanced-checkbox-${index}`);
      this.resetTextColor(`wizard-advanced-txtm-${index}`);
    }

    this.defaultPaymentBalloonValue = `${this.catalogService.defaultPaymentBalloonValue}%`;
    this.defaultPaymentAdvanceValue = `₪${this.catalogService.defaultPaymentAdvanceValue.toLocaleString()}`;
    this.defaultMaxPaymentValue = 'בחירה';
  }

  priceSlideChange(event) {
    if (this.priceValue[1] !== this.maxPrice) {
      this.priceMaxSliderLabel = this.priceValue[1].toLocaleString();
    } else if (this.priceValue[1] === this.maxPrice) {
      this.priceMaxSliderLabel = 'ללא הגבלה';
    }
  }

  paymentsPriceSlideChange(event) {
    if (this.paymentsPriceValue[1] !== this.paymentsMaxPrice) {
      this.paymentsPriceMaxSliderLabel = this.paymentsPriceValue[1].toLocaleString();
    } else if (this.paymentsPriceValue[1] === this.paymentsMaxPrice) {
      this.paymentsPriceMaxSliderLabel = 'ללא הגבלה';
    }
  }

  priceSlideStop(event) {

    let maxPrice = 0;
    let minPrice = 0;

    if (event[0] !== this.minPrice) {
      minPrice = event[0];
    } else {
      minPrice = 0;
    }

    if (event[1] === this.maxPrice) {
      maxPrice = 0;
    } else {
      maxPrice = event[1];
      minPrice = event[0];
    }

    const pricerange: number[] = [minPrice, maxPrice];
    this.priceMaxSliderLabel = maxPrice === 0 ? 'ללא הגבלה' : maxPrice.toLocaleString();
    this.priceValue[1] = minPrice;
    this.messageService.sendMessage(`wizard-price-${minPrice}-${maxPrice}`);
    this.resetSearch();

    // if (+maxPrice > 0) {
    this.GoToWithDelay(2);
    // }

    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'PriceSelected', 1);
  }

  paymentsPriceSlideStop(event) {

    let maxPrice = 0;
    let minPrice = 0;

    if (event[0] !== this.paymentsMinPrice) {
      minPrice = event[0];
    } else {
      minPrice = 0;
    }

    if (event[1] === this.paymentsMaxPrice) {
      maxPrice = 0;
    } else {
      maxPrice = event[1];
      minPrice = event[0];
    }

    const pricerange: number[] = [minPrice, maxPrice];
    this.paymentsPriceMaxSliderLabel = maxPrice === 0 ? "ללא הגבלה" : maxPrice.toLocaleString();
    this.paymentsPriceValue[1] = minPrice;
    this.messageService.sendMessage(`wizard-paymentprice-${minPrice}-${maxPrice}`);
    this.resetSearch();

    // if (+maxPrice > 0) {
    this.GoToWithDelay(2);
    // }

    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'PriceSelected', 1);
  }

  GoToWithDelay(stepNumber: number) {
    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      this.GoTo(stepNumber);
    }, 500);
  }

  GoTo(stepNumber: number) {
    this.currentStep = stepNumber;
    this.wizard?.goToStep(stepNumber - 1);

    let gaValue = '';
    switch (stepNumber) {
      case 1:
        gaValue = 'Price';
        break;
      case 2:
        gaValue = 'Category';
        break;
      case 3:
        gaValue = 'Engine';
        break;
      case 4:
        gaValue = 'Advance';
        break;
    }

    this.setStatus(stepNumber);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', gaValue, 1);
  }

  setStatusMobile(stepNumber: number) {
    this.setStatus(stepNumber);
    return stepNumber === this.currentStep ? 'wizard-step-header-selected' : 'wizard-step-header';
  }

  setStatus(stepNumber: number) {

    switch (this.currentStep) {
      case 1:
        this.step1HeaderClass1 = 'point wizard-header-step-body-selected';
        this.step1HeaderClass2 = 'point wizard-header-step-connector1-selected';
        this.step1HeaderClass3 = 'point wizard-header-step-first-start-selected';
        this.step2HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step2HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step3HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step3HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step4HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step4HeaderClass2 = 'point wizard-header-step-last-unselected';
        break;
      case 2:
        this.step1HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step1HeaderClass2 = 'point wizard-header-step-connector2-selected';
        this.step1HeaderClass3 = 'point wizard-header-step-first-start-unselected';
        this.step2HeaderClass1 = 'point wizard-header-step-body-selected';
        this.step2HeaderClass2 = 'point wizard-header-step-connector1-selected';
        this.step3HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step3HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step4HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step4HeaderClass2 = 'point wizard-header-step-last-unselected';
        break;
      case 3:
        // this.step1HeaderClass1 = 'point wizard-header-step-body-unselected';
        // this.step1HeaderClass2 = 'point wizard-header-step-connector-unselected';
        // this.step1HeaderClass3 = 'point wizard-header-step-first-start-unselected';
        // this.step2HeaderClass1 = 'point wizard-header-step-body-unselected';
        // this.step2HeaderClass2 = 'point wizard-header-step-connector2-selected';
        // this.step3HeaderClass1 = 'point wizard-header-step-body-selected';
        // this.step3HeaderClass2 = 'point wizard-header-step-connector1-selected';
        // this.step4HeaderClass1 = 'point wizard-header-step-body-unselected';
        // this.step4HeaderClass2 = 'point wizard-header-step-last-unselected';

        this.step1HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step1HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step1HeaderClass3 = 'point wizard-header-step-first-start-unselected';
        this.step2HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step2HeaderClass2 = 'point wizard-header-step-connector2-selected';
        this.step3HeaderClass1 = 'point wizard-header-step-body-selected';
        this.step3HeaderClass2 = 'point wizard-header-step-connector1-selected';
        this.step4HeaderClass1 = 'point wizard-header-step-body-selected';
        this.step4HeaderClass2 = 'point wizard-header-step-last-selected';
        break;
      case 4:
        this.step1HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step1HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step1HeaderClass3 = 'point wizard-header-step-first-start-unselected';
        this.step2HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step2HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step3HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step3HeaderClass2 = 'point wizard-header-step-connector2-selected';
        this.step4HeaderClass1 = 'point wizard-header-step-body-selected';
        this.step4HeaderClass2 = 'point wizard-header-step-last-selected';
        break;
      case 5:
        this.step1HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step1HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step1HeaderClass3 = 'point wizard-header-step-first-start-unselected';
        this.step2HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step2HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step3HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step3HeaderClass2 = 'point wizard-header-step-connector-unselected';
        this.step4HeaderClass1 = 'point wizard-header-step-body-unselected';
        this.step4HeaderClass2 = 'point wizard-header-step-connector2-selected';
        break;
    }
  }

  setSelectedImage(img: any) {
    if (img !== null) {
      const domElement = this._elementRef.nativeElement.querySelector(`#${img}`);
      const imageUrl = domElement.src;
      if (imageUrl.includes('_red')) {
        this.resetImage(img);
      } else {
        this.selectImage(img);
      }
    }
  }

  setCgeckbox(cb: any) {
    if (cb !== null) {
      const domElement = this._elementRef.nativeElement.querySelector(`#${cb}`);
      domElement.checked = !domElement.checked;
    }
  }

  setTextColor(txt: any, isSelected: boolean) {
    const domElement = this._elementRef.nativeElement.querySelector(`#${txt}`);
    if (domElement !== null) {
      domElement.className = (!isSelected ? 'text-color' : 'text-color-selected');
    }
  }

  resetCheckBox(cb: string) {
    const domElement = this._elementRef.nativeElement.querySelector(`#${cb}`);
    if (domElement !== null) {
      domElement.checked = false;
    }
  }

  resetImage(img: string) {
    const domElement = this._elementRef.nativeElement.querySelector(`#${img}`);
    if (domElement !== null) {
      const fileExt = domElement.src.substr(domElement.src.lastIndexOf('.') + 1);

      const filename = domElement.src.substring(domElement.src.lastIndexOf('/') + 1).replace('_red', '').replace(`.${fileExt}`, '');
      if (fileExt === 'svg') {
        domElement.src = `assets/images/svg/${filename}.${fileExt}`;
      } else {
        domElement.src = `assets/images/${filename}.${fileExt}`;
      }
    }
  }

  resetTextColor(txt: string) {
    const domElement = this._elementRef.nativeElement.querySelector(`#${txt}`);
    if (domElement !== null) {
      domElement.className = 'text-color';
    }
  }

  selectImage(img: string) {
    const domElement = this._elementRef.nativeElement.querySelector(`#${img}`);
    const fileExt = domElement.src.substr(domElement.src.lastIndexOf('.') + 1);

    const filename = domElement.src.substring(domElement.src.lastIndexOf('/') + 1).replace(`.${fileExt}`, '');
    if (fileExt === 'svg') {
      domElement.src = `assets/images/svg/${filename}_red.${fileExt}`;
    } else {
      domElement.src = `assets/images/${filename}_red.${fileExt}`;
    }
  }

  SelectCategoryImage(id: string) {
    if (this.isMobile) {
      this.SelectCategory(null, null, null, id);
    }
  }

  SelectFueltypeImage(id: number) {
    if (this.isMobile) {
      this.SelectFueltype(null, null, null, id);
    }

    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'EngineSelected', 1);
  }

  SelectDealtypeImage(id: number) {
    if (this.isMobile) {
      this.SelectDealtype(null, null, null, id);
    }
  }

  SelectDealTypeImage(id: number) {
    if (this.isMobile) {
      this.SelectDealtype(null, null, null, id);
    }
  }

  SelectTransmissiontypeImage(id: number) {
    if (this.isMobile) {
      this.SelectTransmissiontype(null, null, null, id);
    }
  }

  SelectSeatsImage(id: number) {
    if (this.isMobile) {
      this.SelectSeats(null, null, null, id);
    }
  }

  SelectAdvancedOptionImage(id: number) {
    if (this.isMobile) {
      switch (id) {
        case 1:
          this.SelectDealtypeImage(1);
          break;
        case 2:
          this.SelectTransmissiontypeImage(4);
          break;
        case 3:
          this.SelectSeatsImage(1);
          break;
        case 4:
          this.SelectTransmissiontypeImage(1);
          break;
      }
    }

    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'AdvanceSelected', 1);
  }

  SelectCategory(img: any, cb: any, txt: any, id: string) {
    this.resetSearch();
    this.messageService.sendMessage(`wizard-category-${id}`);
    this.GoToWithDelay(3);
  }

  SelectSeats(img: any, cb: any, txt: any, id: number) {
    this.resetSearch();
    this.messageService.sendMessage(`wizard-seats-${id}`);
    this.GoToWithDelay(4);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'AdvanceSelected', 1);

  }

  SelectFueltype(img: any, cb: any, txt: any, id: number) {
    this.resetSearch();
    this.messageService.sendMessage(`wizard-fuelType-${id}`);
    // this.GoToWithDelay(4);
  }

  SelectDealtype(img: any, cb: any, txt: any, id: number) {
    this.resetSearch();
    this.messageService.sendMessage(`wizard-deal-${id}`);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'AdvanceSelected', 1);
  }

  SelectTransmissiontype(img: any, cb: any, txt: any, id: number) {
    this.resetSearch();
    this.messageService.sendMessage(`wizard-transmission-${id}`);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'AdvanceSelected', 1);
  }

  itemMouseEnter(cb, img) {
    const isMobile = this.utilsService.IsMobile();
    if (!isMobile) {
      const cbDomElement = this._elementRef.nativeElement.querySelector(`#${cb}`);
      cbDomElement.focus();

      this.setSelectedImage(img);
    }
  }

  itemMouseLeave(cb, img) {
    const isMobile = this.utilsService.IsMobile();
    if (!isMobile) {
      const domElement = this._elementRef.nativeElement.querySelector(`#${cb}`);
      domElement.blur();
      this.setSelectedImage(img);
    }
  }

  wizardIsFinished() {
    this.isFinished = true;
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Sium', 1);
  }

  openDialog(event, subject) {
    const target = event.target || event.srcElement || event.currentTarget;
    this.contactForm.subject = subject;
    this.dialogService.setDialog(target, this.contactForm, this.router.url);
  }

  contactUs(event, subject) {
    this.openDialog(event, subject);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Zru', 1);
  }

  newSearch() {
    this.resetAll();
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Hazeg', 1);
  }

  setTestdrive(event, subject) {
    this.openDialog(event, subject);
    this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Nesiat', 1);
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Call', 1);
      this.googleAnalyticsService.SendPhoneEvent();
      document.location.href = 'tel:035245241';
    }
  }

  callUs() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.SendPhoneEvent();
      this.googleAnalyticsService.eventEmitter('Wizard', 'Click', 'Call', 1);
    }
  }

  resetSearch() {
    if (isPlatformBrowser(this.platformId)) {
      $('#mainsearch').val('');
      $('#mainsearchmobile').val('');
      $('#resultSearchMobile').val('');
      $('#resultSearch').val('');
    }
  }

  setMaxPaymentPrice(value) {
    this.catalogService.setMaxPaymentPrice(value);
    // this.utilsService.setSessionValue('MaxPaymentPrice',value);
    this.defaultMaxPaymentValue = value == 0 ? 'בחירה' : `₪${value.toLocaleString()}`;
    this.catalogService.navigateToFilterPage();
  }

  setAdvancePaymentPrice(value) {
    this.catalogService.setAdvancePaymentPrice(value);
    // this.utilsService.setSessionValue('AdvancePaymentPrice',value);
    this.defaultPaymentAdvanceValue = value == 0 ? 'ללא' : `₪${value.toLocaleString()}`;
    this.catalogService.navigateToFilterPage();
  }

  setBalloonPaymentPrice(value) {
    this.catalogService.setBalloonPaymentPrice(value);
    // this.utilsService.setSessionValue('BalloonPaymen',value);
    this.defaultPaymentBalloonValue = value == 0 ? 'ללא' : `${value.toLocaleString()}%`;
    this.catalogService.navigateToFilterPage();
  }
}
