import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../app/services/utils.service';
import { SharedDataService } from '../../../../app/services/shared-data.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-manufacturer-image-list',
  templateUrl: './manufacturer-image-list.component.html',
  styleUrls: ['./manufacturer-image-list.component.css']
})
export class ManufacturerImageListComponent implements OnInit {

  manufacturerList: string[] = [];
  public firstManufacturerList: any[] = [];
  public secondManufacturerList: any[] = [];
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.manufacturerList  = Object.assign([], SharedDataService.EngManufacturerList).sort(this.sortNyName());

    if (this.utilsService.IsMobile()) {
      this.firstManufacturerList = this.manufacturerList;

      for (let index = 0; index < this.firstManufacturerList.length; index++) {
        this.firstManufacturerList[index] = this.utilsService.capitalizeString(this.firstManufacturerList[index]);
      }
    } else {
      this.firstManufacturerList = this.manufacturerList.splice(0, Math.ceil(this.manufacturerList.length / 2));
      this.secondManufacturerList = this.manufacturerList;
    }
  }

  getCapitalizeString(m: string) {
    return this.utilsService.capitalizeString(m.replace('-', ' '));
  }

  sortNyName(): (a: string, b: string) => number {
    return (n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    };
  }

  getImage(imageName) {
    const url = `${environment.cdnEndpointUrl}/general/manufacturers/${imageName.replace(/-/g,'')}.webp`;
    return url;
  }

  getUrl (value) {
    return `${environment.baseUrl}/${value}`;
    ;
  }
}


