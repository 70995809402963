import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TreeModule } from 'angular-tree-component';
import { FormsModule } from '@angular/forms';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ArchwizardModule } from 'angular-archwizard';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarDescriptionComponent } from './components/car-details/car-description/car-description.component';
import { CarFeatureComparisonComponent } from './components/car-details/car-feature-comparison/car-feature-comparison.component';
import { CarCommunityInfoComponent } from './components/car-details/car-community-info/car-community-info.component';
import { CarDetailsViewComponent } from './components/car-details/car-details-view/car-details-view.component';
import { CarListComponent } from './components/car-catalog/car-list/car-list.component';
import { SideMenuComponent } from './components/car-catalog/side-menu/side-menu.component';
import { CarCatalogViewComponent } from './components/car-catalog/car-catalog-view/car-catalog-view.component';
import { CarComparisonViewComponent } from './components/car-comparison/car-comparison-view/car-comparison-view.component';
import { CarComparisonDetailsComponent } from './components/car-comparison/car-comparison-details/car-comparison-details.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { LeadComponent } from './components/general/lead/lead.component';
import { CarModelTreeviewComponent } from './components/car-catalog/car-model-treeview/car-model-treeview.component';
import { CarWizardComponent } from './components/car-catalog/car-wizard/car-wizard.component';
import { CarNewsFeedComponent } from './components/car-catalog/car-news-feed/car-news-feed.component';
import { CarDetailsContactFormComponent } from './components/car-details/car-details-contact-form/car-details-contact-form.component';
import { CarsServicesDialogComponent } from './components/dialogs/cars-services-dialog/cars-services-dialog.component';
import { AboutComponent } from './components/general/about/about.component';
import { CarsContactDialogComponent } from './components/dialogs/cars-contact-dialog/cars-contact-dialog.component';
import { CarsDealContactDialogComponent } from './components/dialogs/cars-deal-contact-dialog/cars-deal-contact-dialog.component';
import { ContactUsComponent } from './components/general/contact-us/contact-us.component';
import { AlertComponent } from './components/dialogs/alert/alert.component';
import { LeadNoComponent } from './components/general/lead-no/lead-no.component';
import { ThankYouComponent } from './components/general/thank-you/thank-you.component';
import { WelcomeDialogComponent } from './components/dialogs/welcome-dialog/welcome-dialog.component';

import { GoogleAnalyticsService } from './services/google-analytics.service';

import {
  RECAPTCHA_SETTINGS,
  RecaptchaLoaderService,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { DealersInfoComponent } from './components/general/dealers-info/dealers-info.component';
import { CommandComponent } from './components/general/command/command.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { TermsComponent } from './components/general/terms/terms.component';
import { CarManufacturerViewComponent } from './components/car-manufacturer/car-manufacturer-view/car-manufacturer-view.component';
import { CarManufacturerLogoComponent } from './components/car-manufacturer/car-manufacturer-logo/car-manufacturer-logo.component';
import { CarManufacturerInfoComponent } from './components/car-manufacturer/car-manufacturer-info/car-manufacturer-info.component';
import { CarManufacturerChartComponent } from './components/car-manufacturer/car-manufacturer-chart/car-manufacturer-chart.component';
import { CarManufacturerModelsComponent } from './components/car-manufacturer/car-manufacturer-models/car-manufacturer-models.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ManufacturerImageListComponent } from './components/car-catalog/manufacturer-image-list/manufacturer-image-list.component';
import { ViewTitleComponent } from './components/general/view-title/view-title.component';
import { ViewBreadcrumbsComponent } from './components/general/view-breadcrumbs/view-breadcrumbs.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewTagsComponent } from './components/general/view-tags/view-tags.component';
import { CarIdRedirectComponent } from './components/car-details/car-id-redirect/car-id-redirect.component';
import { CarModelInfoComponent } from './components/car-manufacturer/car-model-info/car-model-info.component';
import { GenericContactFormComponent } from './components/general/generic-contact-form/generic-contact-form.component';
import { DealersLoginComponent } from './components/dealers/dealers-login/dealers-login.component';
import { DealersViewComponent } from './components/dealers/dealers-view/dealers-view.component';
import { DealersLeadListComponent } from './components/dealers/dealers-lead-list/dealers-lead-list.component';
import { CarVideoComponent } from './components/car-details/car-video/car-video.component';
import { DealersSearch01ViewComponent } from './components/dealers/dealers-search01-view/dealers-search01-view.component';
import { ArticleItemViewComponent } from './components/magazine/article-item-view/article-item-view.component';
import { MagazineFpViewComponent } from './components/magazine/magazine-fp-view/magazine-fp-view.component';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { PollutionLevelMeterComponent } from './components/general/pollution-level-meter/pollution-level-meter.component';
import { SafetyScoreMeterComponent } from './components/general/safety-score-meter/safety-score-meter.component';
import { UtilitiesComponent } from './components/general/utilities/utilities.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CarDescriptionComponent,
    CarFeatureComparisonComponent,
    CarCommunityInfoComponent,
    CarDetailsViewComponent,
    CarListComponent,
    SideMenuComponent,
    CarCatalogViewComponent,
    CarComparisonViewComponent,
    CarComparisonDetailsComponent,
    SidebarMenuComponent,
    LeadComponent,
    CarModelTreeviewComponent,
    CarWizardComponent,
    CarNewsFeedComponent,
    CarDetailsContactFormComponent,
    CarsServicesDialogComponent,
    AboutComponent,
    CarsContactDialogComponent,
    CarsDealContactDialogComponent,
    ContactUsComponent,
    AlertComponent,
    LeadNoComponent,
    ThankYouComponent,
    WelcomeDialogComponent,
    DealersInfoComponent,
    CommandComponent,
    NotFoundComponent,
    TermsComponent,
    CarManufacturerViewComponent,
    CarManufacturerLogoComponent,
    CarManufacturerInfoComponent,
    CarManufacturerChartComponent,
    CarManufacturerModelsComponent,
    ManufacturerImageListComponent,
    ViewTitleComponent,
    ViewBreadcrumbsComponent,
    ViewTagsComponent,
    CarIdRedirectComponent,
    CarModelInfoComponent,
    GenericContactFormComponent,
    DealersLoginComponent,
    DealersViewComponent,
    DealersLeadListComponent,
    CarVideoComponent,
    DealersSearch01ViewComponent,
    ArticleItemViewComponent,
    MagazineFpViewComponent,
    PollutionLevelMeterComponent,
    SafetyScoreMeterComponent,
    UtilitiesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    TreeModule.forRoot(),
    FormsModule,
    NgxPageScrollModule,
    ArchwizardModule,
    RecaptchaModule,
    NgxChartsModule,
    NgxBootstrapSliderModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU' } as RecaptchaSettings,
    },
    CookieService,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
