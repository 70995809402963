import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-car-manufacturer-logo',
  templateUrl: './car-manufacturer-logo.component.html',
  styleUrls: ['./car-manufacturer-logo.component.css']
})
export class CarManufacturerLogoComponent implements OnInit, AfterContentChecked {

  @Input()
  EngManufacturerName: string;

  @Input()
  HebManufacturerName: string;

  @Input()
  HebModelName: string;

  @Input()
  EngModelName: string;

  @Input()
  GroupName: string;

  @Input()
  Year: number;

  manufacturerImageName: string;
  title: string;
  isModelPage = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    const urlData = this.router.url.split('/');
    this.isModelPage = (urlData.length === 3 && isNaN(+urlData[2]))  || (urlData.length === 4 && (+urlData[3] > 2010));
  }

  ngAfterContentChecked() {
    this.title = '';
    if (this.isModelPage) {
      if (this.GroupName !== undefined && this.manufacturerImageName !== undefined && this.HebManufacturerName !== undefined  && this.HebManufacturerName !== null) {
        this.title = `${this.HebManufacturerName} ${this.GroupName}`;
      }
    } else {
      if (this.title === '' && this.HebManufacturerName !== undefined  && this.HebManufacturerName !== null) {
        this.title = this.HebManufacturerName;
      }
    }

    if (this.EngManufacturerName != undefined) {
      this.manufacturerImageName = this.EngManufacturerName.toLowerCase().replace(/\s/g, '');
    }

    if (this.Year > 0) {
      // this.title = `${this.title} - ${this.Year}`;
      this.title = `${this.title}`;
    }
  }

  getImage(imageName) {
    const url = `${environment.cdnEndpointUrl}/general/manufacturers/${imageName}.webp`;
    return url;
  }
}
