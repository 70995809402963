import { Component, OnInit, AfterViewInit, AfterContentChecked, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../../app/services/http.service';
import { MessageService } from '../../../../app/services/message.service';
import { CarDetailsService } from '../../../../app/services/car-details.service';
import { Subscription } from 'rxjs';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { ComparisonService } from '../../../../app/services/comparison.service';
import { FavoritesService } from '../../../../app/services/favorites.service';
import { CarInformationContactForm } from '../../../../app/models/carInformationContactForm';
import { DialogService } from '../../../../app/services/dialog.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cars-services-dialog',
  templateUrl: './cars-services-dialog.component.html',
  styleUrls: ['./cars-services-dialog.component.css']
})
export class CarsServicesDialogComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {

  services: number[] = [];
  cars: number[] = [];
  currentCarModel: string = null;
  favouriteCarModels: string = null;
  comparisonCarModels: string = null;
  otherService: string = null;
  isFinished = false;
  hasCars = false;

  subscription: Subscription;
  messages: any[] = [];
  carInfo: CarGroupInfo = new CarGroupInfo();
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(
    public httpClient: HttpService
    , public comparisonService: ComparisonService
    , private messageService: MessageService
    , public carDetailsService: CarDetailsService
    , public favoritesService: FavoritesService
    , private dialogService: DialogService
    , @Inject(PLATFORM_ID) private platformId: Object
    , public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-loading-data-done') {
        this.carInfo = carDetailsService.getData();
        this.currentCarModel = this.carInfo.name;
        this.addCars(1);
      } else if (message.text === 'comparison-loading-data-done') {
        this.getComparisonCarModels();
      } else if (message.text === 'favorite-update') {
        this.getFavoriteCarModels();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.addService(1);
    this.addService(2);
    this.addService(3);
  }

  ngAfterContentChecked() {
    this.resetServices();
    this.getComparisonCarModels();
    this.getFavoriteCarModels();
    this.hasCars = this.cars.length > 0;
  }

  resetServices() {
    this.removeServices(2);
    this.removeServices(3);
  }

  removeServices(serviceId) {
    const index = this.cars.indexOf(2, 0);
    if (index > -1) {
      this.cars.splice(index, 1);
    }
  }

  getComparisonCarModels() {
    this.comparisonCarModels = this.comparisonService.getModelNames();
    if (this.comparisonCarModels !== '' && this.comparisonCarModels !== null) {
      this.addCars(3);
    }
  }

  getFavoriteCarModels() {
    this.favouriteCarModels = this.favoritesService.getModelNames();
    if (this.favouriteCarModels !== '' && this.favouriteCarModels !== null) {
      this.addCars(2);
    }
  }

  addCars(optionId) {
    if (this.cars.includes(optionId)) {
      const index = this.cars.indexOf(optionId, 0);
      if (index > -1) {
        this.cars.splice(index, 1);
      }
    } else {
      this.cars.push(optionId);
    }
  }

  addService(optionId: number) {
    if (this.services.includes(optionId)) {
      const index = this.services.indexOf(optionId, 0);
      if (index > -1) {
        this.services.splice(index, 1);

        let gaValue = '';
        switch (optionId) {
          case 1:
            gaValue = 'Hazaot';
            break;
          case 2:
            gaValue = 'Alert';
            break;
          case 3:
            gaValue = 'Teum';
            break;
        }

        this.googleAnalyticsService.eventEmitter('PopUp2', 'Click', gaValue, 1);
      }
    } else {
      this.services.push(optionId);
    }
  }

  submit() {
    const currentContact = this.dialogService.getCurrentContact();
    const data = new CarInformationContactForm();
    data.subject = 'התעניינות בשירותים/רכבים';
    data.name = currentContact.name;
    data.phone = currentContact.phone;
    data.city = currentContact.city;
    data.Cars = [];
    data.Services = [];

    this.cars.forEach(element => {
      switch (element) {
        case 1:
          data.Cars.push(this.currentCarModel);
          break;
        case 2:
          data.Cars.push(`מועדפים: ${this.favouriteCarModels}`);
          break;
        case 3:
          data.Cars.push(`השוואה: ${this.comparisonCarModels}`);
          break;
        case 4:
          data.Cars.push('לא הוחלט');
          break;
      }
    });

    this.services.forEach(element => {
      switch (element) {
        case 1:
          data.Services.push('הצעות מוזלות');
          break;
        case 2:
          data.Services.push('התראות על מבצעים והשקות');
          break;
        case 3:
          data.Services.push('תאום נסיעת מבחן');
          break;
      }
    });

    if (this.otherService !== null) {
      data.Cars.push(`שירות אחר: ${this.otherService}`);
    }

    this.httpClient.SendServicesContactForm(data).subscribe((result: any) => {
      this.isFinished = true;
      this.googleAnalyticsService.eventEmitter('PopUp2', 'Click', 'Submit', 1);
    });
  }

  reset() {
    this.isFinished = false;
  }

  setDeifferenctServiceFocus() {
    this.googleAnalyticsService.eventEmitter('PopUp2', 'Focus', 'Sherut', 1);
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.SendPhoneEvent();
      this.googleAnalyticsService.eventEmitter('PopUp2', 'Click', 'Call', 1);
      document.location.href = 'tel:035245241';
    }
  }
}
