import { Component, OnInit } from '@angular/core';
import { DealersService } from '../../../../app/services/dealers.service';
import { MessageService } from '../../../../app/services/message.service';

@Component({
  selector: 'app-dealers-login',
  templateUrl: './dealers-login.component.html',
  styleUrls: ['./dealers-login.component.css']
})
export class DealersLoginComponent implements OnInit {

  isLogin = false;
  userName = '';
  password = '';

  constructor(
    private messageService: MessageService
    , public dealerService: DealersService) { }

  ngOnInit(): void {

  }

  login() {
    const isLoggedIn = this.dealerService.login(this.userName, this.password);
    if (isLoggedIn) {
      this.messageService.sendMessage('dealer-looged-in');
    }
  }

}
