import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.css']
})
export class CommandComponent implements OnInit {

  commandType: string;

  constructor(private route: ActivatedRoute, private cookieService: CookieService) {
    this.route.queryParams.subscribe(params => {
      this.commandType = params.ct;
    });
  }

  ngOnInit(): void {
    switch (this.commandType) {
      case 'ga-disable':
        localStorage.setItem('ga-disable', 'true');
        break;
      case 'ga-enable':
        localStorage.setItem('ga-disable', 'false');
        break;
      case 'reset-user':
        this.cookieService.delete('new-user');
        break;
    }
  }
}
