<div *ngIf="showDialog" id="welcome-dialog">
  <div class="wizard-content-container">
    <div class="content-frame">
      <div class="d-none d-xl-inline">
        <div>
        </div>

        <img (click)="confirm()" class="d-none d-xl-inline point"
          style="padding-top: 25px; margin-right: 10px; margin-left: 15px; text-align: right; float: right; width: 30px" src="assets/images/svg/close-x-button-red.svg" />

        <div class="title d-none d-xl-block">קארפיינד - רכב חדש במחיר המפורסם הזול בישראל</div>

        <div class="d-flex align-items-center justify-content-center d-block d-sm-none">
          <div>
            <img class="d-block d-sm-none"
              style="width: 150px; text-align: center; padding-top: 15px; padding-right: 0;"
              src="assets/images/carfind-logo-desktop-white.png" />
          </div>
        </div>
        <div class="title d-block d-sm-none">רכב חדש במחיר המפורסם הזול בישראל
        </div>
        <!-- <div class="text d-block d-sm-none" style="padding-top: 15px; text-align: justify;">קארפיינד משווה מחירי רכב חדש
          מכלל יבואני הרכב, מחברות הליסינג
          ומסוכנויות רכב מובילות. קארפיינד מוצא לך את המחיר הזול ביותר המפורסם בישראל וחוסך לך אלפי שקלים. </div>

        <div class="text d-none d-xl-block" style="padding-top: 15px; text-align: justify;">קארפיינד משווה מחירי רכב חדש
          מכלל יבואני הרכב, מחברות הליסינג
          ומסוכנויות רכב מובילות. קארפיינד מוצא עבורך את המחיר הזול ביותר המפורסם בישראל וחוסך לך אלפי שקלים</div> -->


        <div class="text" style="padding-top: 15px;">
          <p style="line-height: 2;">
            <span class="feature-item"><span class="feature-item-icon">&#x2714;&nbsp;</span> מסייע להשוות ולמצוא את הרכב הנכון עבורך  עם/בלי מימון  יבוא ישיר/מקביל<br></span>
            <span class="feature-item"><span class="feature-item-icon">&#x2714;&nbsp;</span> משווה מחירי רכב חדש מכלל יבואני הרכב, חברות ליסינג וסוכנויות רכב מובילות<br></span>
            <span class="feature-item"><span class="feature-item-icon">&#x2714;&nbsp;</span>  מוצא עבורך את המחיר הזול ביותר המפורסם בישראל וחוסך לך אלפי שקלים<br></span>
            <span class="feature-item"><span class="feature-item-icon">&#x2714;&nbsp;</span>  השימוש באתר חינם, ומומלץ להיוועץ במומחי הרכב שלנו ללא כל מחויבות</span>
          <p>
        </div>
      </div>

      <div class="d-block d-sm-none" style="padding: 5px;">
        <img (click)="confirm()"
        style="padding-top: 5px; float: right;" src="assets/images/svg/close-x-button-red.svg" />

        <div class="text">
          קארפיינד מסייע להשוות ולמצוא את הרכב החדש הנכון עבורך. קארפיינד משווה מחירי רכב חדש מכלל יבואני הרכב, חברות ליסינג וסוכנויות רכב מובילות וכך מוצא עבורך את המחיר הזול ביותר המפורסם בישראל וחוסך אלפי שקלים. השימוש באתר חינם, ומומלץ להיוועץ במומחי הרכב שלנו ללא כל מחויבות.          <br />
        </div>

      </div>
    </div>

  </div>
</div>