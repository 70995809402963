import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, AfterContentChecked, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { MessageService } from '../..//services/message.service';
import { CatalogService } from '../../services/catalog.service';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../app/services/dialog.service';
import { BasicContactForm } from '../../../app/models/basicContactForm';
import { FavoritesService } from '../../../app/services/favorites.service';
import { HttpService } from '../../../app/services/http.service';
import { GoogleAnalyticsService } from '../../../app/services/google-analytics.service';
import { SearchSuggestion } from '../../../app/models/searchSuggestion';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { SharedDataService } from '../../../app/services/shared-data.service';
import { UtilsService } from '../../../app/services/utils.service';
import { ReturnStatement } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  subscription: Subscription;
  messages: any[] = [];
  contactForm: BasicContactForm;
  showFavorites = true;
  favoritesCount = 0;
  searchSuggestions: SearchSuggestion[];
  isMainPage = true;
  isCatalogFiltered = false;
  desktopHeaderLogoText = 'האתר הגדול בישראל לעסקאות רכב חדש ואפס ק״מ';
  showSecondRow = true;

  typingTimer: any;
  doneTypingInterval = 100;

  @ViewChild('mainsearch') mainsearch: ElementRef;
  @ViewChild('mainsearchmobile') mainsearchmobile: ElementRef;

  constructor(
    private messageService: MessageService
    , public catalogService: CatalogService
    , private router: Router
    , private dialogService: DialogService
    , private favoritesService: FavoritesService
    , public httpClient: HttpService
    , private route: ActivatedRoute
    , private utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'reset-filters' || message.text === 'reset-search') {
        this.ResetFilters();
      } else if (message.text === 'favorite-update') {
        this.favoritesCount = favoritesService.getModelCount();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.isMainPage = this.utilsService.IsHomePage();

    if (this.router.url.includes('dealers/search/dashboard')) {
      this.showSecondRow = false;
    }

    this.route.url.subscribe((segments: UrlSegment[]) => {
      this.isCatalogFiltered = false;

      SharedDataService.DealTypes.forEach(deal => {
        if (this.router.url.includes(deal)) {
          this.isCatalogFiltered = true;
        }
      });
    });

    this.contactForm = new BasicContactForm();

    this.route.queryParams
      .subscribe(params => {
        if (params.kw !== undefined) {
          this.closeMobileKeyboard();
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('Click', () => {
        this.showSearchSuggestions(false);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  closeMobileKeyboard() {
    $('#mainsearchmobile').blur();
    $(document).focus();
  }

  ngAfterContentChecked() {
    this.favoritesCount = this.favoritesService.getModelCount();
  }

  ngAfterViewInit() {
    this.mainsearch.nativeElement.focus();

    if (isPlatformBrowser(this.platformId)) {
      $('#main-search-container').on('hide.bs.dropdown', (e) => {
        e.preventDefault();
      });

      $(document).ready(() => {
        const menuDomElement = document.getElementById(`menu-toggle`);
        menuDomElement.onclick = () => {
          const wrapperDomElement = document.getElementById(`wrapper`);
          if (wrapperDomElement != null) {
            wrapperDomElement.classList.toggle('toggled');
          }
        };
      });
    }
  }

  ResetFilters() {
    if (isPlatformBrowser(this.platformId)) {
      $('#mainsearch').val('');
      $('#mainsearchmobile').val('');
      $('#resultSearchMobile').val('');
      $('#resultSearch').val('');
      this.showSearchSuggestions(false);
    }
  }

  Search(event) {
    const searchKeyword = event.target.value;
    this.showSearchSuggestions(false);
    this.router.navigate([''], { queryParams: { kw: searchKeyword } });
  }

  showFavoritesList(event) {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Favorites', 1);

    const target = event.target || event.srcElement || event.currentTarget;
    if (this.favoritesCount === 0) {
      this.dialogService.showAlert(target, 'show-nofav-alert');
    } else {
      this.dialogService.removeAlert(target);

      this.router.navigate([`/favorites/${this.favoritesService.carIds.join('-')}`]);
    }
  }

  openDialog(event, subject, channelId = 2) {
    const target = event.target || event.srcElement || event.currentTarget;

    if (this.contactForm !== null && this.contactForm.phone !== undefined && this.contactForm.phone !== '' && this.utilsService.isValidPhoneNumber(this.contactForm.phone)) {
      this.contactForm.subject = subject;
      this.contactForm.channelId = channelId;

      const paymentInfo = this.utilsService.getPaymentInfo();
      this.contactForm.paymentBalloon = paymentInfo[0];
      this.contactForm.maxPaymentPrice = paymentInfo[1];
      this.contactForm.advancePaymentPrice = paymentInfo[2];
      this.httpClient.SendBasicContactForm(this.contactForm).subscribe((result: any) => {
        console.log('sent!');
        this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Zru', 1);
        this.googleAnalyticsService.SendCompletedConversionEvent();
        this.router.navigate(['/thanks']);
      });
    } else {
      this.contactForm.channelId = channelId;
      this.contactForm.subject = subject;
      this.dialogService.setDialog(target, this.contactForm, this.router.url);
    }
  }

  openDialer() {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Call', 1);
    this.googleAnalyticsService.SendPhoneEvent();

    if (isPlatformBrowser(this.platformId)) {
      document.location.href = 'tel:035245241';
    }
  }

  ResetResult() {
    this.messageService.sendMessage('reset-filters');
    this.router.navigate(['']);
  }


  searchKeyUp(event) {
    if (event.target.value === '' || event.keyCode === 13) {
      this.showSearchSuggestions(false);
    }

    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout((keyCode, value) => {
      const searchKeyword = value;
      if (keyCode !== 13) {
        if (searchKeyword.length >= 3) {
          this.httpClient.getSearchSuggestions(searchKeyword).subscribe((data: any) => {
            this.searchSuggestions = data;
            if (this.searchSuggestions.length > 0) {
              this.showSearchSuggestions(true);
            } else {
              this.showSearchSuggestions(false);
            }
          });
        }
      }

    }, this.doneTypingInterval, event.keyCode, event.target.value);
  }


  searchKeyDown(event) {
    clearTimeout(this.typingTimer);
  }

  showSearchSuggestions(toShow) {
    if (isPlatformBrowser(this.platformId)) {
      if (toShow) {
        $('#search-auto-complete').addClass('show');
        $('#search-auto-complete-mobile').addClass('show');
      } else {
        $('#search-auto-complete').removeClass('show');
        $('#search-auto-complete-mobile').removeClass('show');
      }
    }
  }

  navigateToCarPage(event: any, car: SearchSuggestion) {
    this.ResetFilters();
    if (car.url !== undefined) {
      this.router.navigate([`${car.url}`]);
    }

    const target = event.target || event.srcElement || event.currentTarget;
    const idAttr = target.id;
    if (idAttr === '') {
      this.closeMobileKeyboard();
      const url = `/${car.engManufacturerName.replace(/ /g, '-')}/${car.engModelName.replace(/ /g, '-')}/${car.bodyType}/${car.engine}`;
      this.router.navigate([`${url}`]);
    }
  }

  openThirdButtonCommand(event) {
    if (this.isMainPage) {
      $('#filter-modal').show();
    } else {
      this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Nesiat', 1);
      this.openDialog(event, 'CarFind Lead City');  
    }
  }

  logoClicked() {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Logo', 1);

    this.ResetFilters();
    this.ResetResult();
  }

  setSeatchFocus() {
  }

  toggleMenu() {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Menu', 1);
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Call', 1);
  }

  contactWithMe(event, subject) {
    this.googleAnalyticsService.SendCompletedConversionEvent();
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Zru', 1);
    this.openDialog(event, subject, 3);
  }

  setTestDrive(event, subject) {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Nesiat', 1);
    this.openDialog(event, subject);
  }

  getDealAlert(event, subject) {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Alert', 1);
    this.openDialog(event, subject);
  }

  getCheapDeals(event, subject) {
    this.googleAnalyticsService.eventEmitter('Header', 'Click', 'Hazaot', 1);
    this.openDialog(event, subject);
  }

  setNameFocus() {
    this.googleAnalyticsService.eventEmitter('Header', 'Focus', 'Name', 1);
  }

  setPhoneFocus() {
    this.googleAnalyticsService.eventEmitter('Header', 'Focus', 'Phone', 1);
  }

  scrollToTop() {
    this.utilsService.scrolltoTop();
  }
}
