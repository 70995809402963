<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>

    <div id="page-content-wrapper">

        <div class="container-fluid page-content-wrapper">
            <div class="row page-container">
                <app-view-breadcrumbs [items]="generateBreadcrumbs()"></app-view-breadcrumbs>
            </div>
            <div class="row" style="padding-top: 25px;">
                <app-view-title [items]="generateBreadcrumbs()"></app-view-title>
            </div>
            <div class="row">
                <div class="col-12 p-0">אתר קארפיינד מספק מידע על מאות סוכנויות רכב וחברות ליסינג בישראל, הנמצאות ב 47 ערים ויישובים. להלן רשימה לדוגמא של סוכנויות רכב וחברות ליסינג בישראל. </div>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div class="row" style="padding-top:20px">
                            <div *ngFor="let dealer of dealersInfo; let i=index;" class="col-md-6 col-12 p-0">
                                <div class="dealer-item">
                                    <div class="item-image p-0">
                                        <img class="center-cropped" height="90" width="90" title="{{dealer.address}}"
                                            alt="סוכנות {{dealer.title}}"
                                            src="{{getDealerImage(dealer.imageName)}}"
                                            onerror="this.onerror=null; this.src='assets/images/carfind_logo_gray.png'">

                                    </div>
                                    <div class="item-title">
                                        <div>{{getTitle(dealer.title)}}</div>
                                    </div>
                                    <div>
                                        <span>{{dealer.address}}</span>
                                    </div>
                                    <div>
                                        <span>{{dealer.mainPhoneNumber}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <strong>ליסינג</strong> - שיטת מימון לרכישת רכב המאפשרת לקנות רכב מבלי לשלם עבורו את כל התשלום
                        במזומן
                        בעת הרכישה.
                    </div>
                </div>
                <br />
                <br />
                <div class="row">
                    <div class="col-12">
                        <strong>ליסינג פרטי</strong> - קניית רכב באמצעות הלוואה דרך חברת ליסינג, מתאימה למעוניינים
                        ברכישת
                        רכב
                        המתקשים בגיוס הון לרכישה בתשלום אחד. תהליך קניית רכב בליסינג פרטי כולל שני שלבים תחילה הלקוח
                        משלם
                        סכום
                        ראשוני ואת יתרת החוב משלם בתשלומים או להיפך. בתום התשלומים הלקוח זכאי לבחור אם לרכוש את הרכב
                        תמורת
                        תשלום
                        נוסף. הלקוח אחראי על הנושא התפעולי כולל טסט, ביטוח תיקונים ועוד. המחירים של ליסינג פרטי משתנים
                        בהתאם
                        לדגם הרכב ושנת הייצור לצד פרמטרים נוספים כמו גיל הנהג, וותק הנהג על הכביש ומספר הנהגים.
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-12">
                        <strong>ליסינג תפעולי</strong> - לרוב מבוצע דרך מקום העבודה של העובד. כאשר העובד מקבל רכב כחלק
                        מתנאי
                        ההעסקה במקום העבודה, יידרש לבחור רכב מתוך מבחר של רכבים. לאחר הבחירה העובד יקבל את הרכב באופן
                        מיידי
                        מבלי לשלם תשלומי מקדמה. בסוף תקופת העסקה, מוחזר הרכב לחברת הליסינג. בליסינג תפעולי בעלות הרכב
                        היא של
                        הסוכנות, התשלום החודשי עשוי להיות גבוה מהסיבה שהוא מגלם את ההוצאות שהנהג פטור מהן לדוגמה: טסט
                        שנתי,
                        ביטוח ולרוב גם עלויות דלק. חשוב להבהיר כי בסוף תקופת המימון הרכב חוזר לבעלות הסוכנות ולכן ליסינג
                        תפעולי הוא פתרון זמני ולמעשה לא מדובר בקניית רכב.
                    </div>
                </div>

            </div> 

        </div>

        <app-footer></app-footer>

    </div>