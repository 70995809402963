import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarDetailsViewComponent } from './components/car-details/car-details-view/car-details-view.component';
import { CarCatalogViewComponent } from './components/car-catalog/car-catalog-view/car-catalog-view.component';
import { CarComparisonViewComponent } from './components/car-comparison/car-comparison-view/car-comparison-view.component';
import { LeadComponent } from './components/general/lead/lead.component';
import { AboutComponent } from './components/general/about/about.component';
import { ContactUsComponent } from './components/general/contact-us/contact-us.component';
import { LeadNoComponent } from './components/general/lead-no/lead-no.component';
import { ThankYouComponent } from './components/general/thank-you/thank-you.component';
import { DealersInfoComponent } from './components/general/dealers-info/dealers-info.component';
import { CommandComponent } from './components/general/command/command.component';
import { NotFoundComponent } from './components/general/not-found/not-found.component';
import { TermsComponent } from './components/general/terms/terms.component';
import { CarManufacturerViewComponent } from './components/car-manufacturer/car-manufacturer-view/car-manufacturer-view.component';
import { SharedDataService } from './services/shared-data.service';
import { CarIdRedirectComponent } from './components/car-details/car-id-redirect/car-id-redirect.component';
import { DealersViewComponent } from './components/dealers/dealers-view/dealers-view.component';
import { DealersSearch01ViewComponent } from './components/dealers/dealers-search01-view/dealers-search01-view.component';
import { ArticleItemViewComponent } from './components/magazine/article-item-view/article-item-view.component';
import { MagazineFpViewComponent } from './components/magazine/magazine-fp-view/magazine-fp-view.component';
import { UtilitiesComponent } from './components/general/utilities/utilities.component';

const routes: Routes = [
  { path: 'car/:id', component: CarIdRedirectComponent },
  { path: 'car/:id/:sid', component: CarIdRedirectComponent },
  { path: 'favorites/:ids', component: CarCatalogViewComponent },
  { path: 'compare-cars', component: CarComparisonViewComponent },
  { path: 'compare-cars/versions/:ids', component: CarComparisonViewComponent },
  // { path: 'dealers/dashboard', component: DealersViewComponent },
  { path: 'dealers/search/dashboard', component: DealersSearch01ViewComponent },
  { path: 'about', component: AboutComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'thanks', component: ThankYouComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'car-agencies', component: DealersInfoComponent },
  { path: 'command', component: CommandComponent },
  { path: 'car-news', component: MagazineFpViewComponent },
  { path: 'utilities', component: UtilitiesComponent },
  { path: 'car-news/:id', component: ArticleItemViewComponent },
  { path: 'lead/:id', component: LeadComponent },
  { path: 'lead/:id/no', component: LeadNoComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'car-deals/**', component: CarCatalogViewComponent },
  { path: 'leasing-0-km/**', component: CarCatalogViewComponent },
  { path: 'importer-deals/**', component: CarCatalogViewComponent },
  { path: 'new-cars/**', component: CarCatalogViewComponent },
];

SharedDataService.EngManufacturerList.forEach(manufacturer => {
  routes.push({ path: manufacturer, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:year`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model/:year`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model/:bodyType/:engine`, component: CarDetailsViewComponent });
  routes.push({ path: `${manufacturer}/:model/:bodyType/:engine/:doors`, component: CarDetailsViewComponent });
  routes.push({ path: `${manufacturer}/:pmodel/:model/:year/:bodyType/:engine/:doors`, component: CarDetailsViewComponent });
  routes.push({ path: `${manufacturer}/:pmodel/:year/:bodyType/:engine/:doors`, component: CarDetailsViewComponent });
});

// SEO
const spacedManufacturerList = ['alfa romeo', 'aston martin', 'land rover'];
spacedManufacturerList.forEach(manufacturer => {
  routes.push({ path: manufacturer, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:year`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model/:year`, component: CarManufacturerViewComponent });
  routes.push({ path: `${manufacturer}/:model/:bodyType/:engine`, component: CarDetailsViewComponent });
});

routes.push({ path: '**', component: CarCatalogViewComponent });

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
