import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { CarDetailsService } from '../../../../app/services/car-details.service';
import { MessageService } from '../../../../app/services/message.service';

@Component({
  selector: 'app-car-video',
  templateUrl: './car-video.component.html',
  styleUrls: ['./car-video.component.css']
})
export class CarVideoComponent implements OnInit {

  subscription: Subscription;
  messages: any[] = [];
  carInfo: CarGroupInfo;
  videoUrl: SafeUrl;

  @Input()
  configurationId: string;

  @Input()
  showVideo = true;

  @Input()
  configurationVideoUrl: string;

  constructor(
    public carDetailsService: CarDetailsService
    , private messageService: MessageService
    , private sanitizer: DomSanitizer
  ) {

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-loading-data-done') {
        this.carInfo = carDetailsService.getData();
        this.showVideo = this.carInfo.videourl !== '';
        this.videoUrl = sanitizer.bypassSecurityTrustResourceUrl(this.carInfo.videourl);
      } else {
        this.messages = [];
      }
    });
  }
  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
  }
}
