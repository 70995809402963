import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TagItem } from '../../../../app/models/tagItem';
import { FilterType } from '../../../../app/services/shared-data.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../../../app/services/utils.service';

@Component({
  selector: 'app-view-title',
  templateUrl: './view-title.component.html',
  styleUrls: ['./view-title.component.css']
})
export class ViewTitleComponent implements OnInit, AfterViewInit {

  @Input() items: TagItem[] = [];
  @Input() title: string ;

  text = '';
  params: string[] = [];
  isPluralFilter = false;

  constructor(private route: ActivatedRoute, private utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

  }

  generateTitle(items: TagItem[]): string {
    if (this.title !== undefined) {
      return this.title;
    }

    this.params = [];
    this.GetFilterValue(items, FilterType.DealType, this.params);
    this.GetFilterValue(items, FilterType.Manufacturer, this.params);
    this.GetFilterValue(items, FilterType.BodyTypes, this.params);
    this.GetFilterValue(items, FilterType.Engine, this.params);
    this.GetFilterValue(items, FilterType.Transmission, this.params);
    this.GetFilterValue(items, FilterType.Drivetrain, this.params);
    this.GetFilterValue(items, FilterType.Seats, this.params);
    this.GetFilterValue(items, FilterType.Price, this.params);
    this.GetFilterValue(items, FilterType.PaymentPrice, this.params);
    this.GetFilterValue(items, FilterType.KML, this.params);
    this.GetFilterValue(items, FilterType.BootCapacity, this.params);
    this.GetFilterValue(items, FilterType.Length, this.params);
    this.GetFilterValue(items, FilterType.Years, this.params);
    this.GetFilterValue(items, FilterType.None, this.params);

    if (this.isPluralFilter) {
      this.utilsService.addNoIndexMeta(false);
    }

    const title = this.params.join(', ');
    return title;
  }

  private GetFilterValue(data: TagItem[], filterType: FilterType, params: string[]) {
    const values: string[] = [];
    data.forEach(tag => {
      if (tag.filterType === filterType) {
        values.push(tag.title);
      }
    });

    if (values.length > 0) {
      if (filterType === FilterType.None) {
        params.push(values.join(' '));
      } else {
        params.push(values.join(' או '));

        if (values.length >  1) {
          this.isPluralFilter = true;
        }
      }
    }
  }
}
