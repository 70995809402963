import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CarFindCarVersion } from '../models/CarFindCarVersion';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
    carIds: number[] = [];
    carInfos: CarFindCarVersion[] = [];

    constructor(private cookieService: CookieService, public httpClient: HttpService) {
        this.restore();
    }

    Add(carId: number, car: CarFindCarVersion): void {
        this.carIds.push(carId);
        this.carInfos.push(car);

        this.save();
    }

    Remove(carId: number): void {
        const carIndex = this.carIds.indexOf(carId);
        this.carIds.splice(carIndex, 1);
        this.carInfos.splice(carIndex, 1);
        this.save();
    }

    save() {
        this.cookieService.set('fav-car-ids', this.carIds.join(','), 60);
    }

    restore() {
        const data = this.cookieService.get('fav-car-ids');
        if (data !== '' && data !== 'NaN') {
            this.carIds = this.cookieService.get('fav-car-ids').split(',').map((item) => {
                return parseInt(item, 10);
            });
        }
    }

    IsChecked(carId: number): boolean {
        return (this.carIds.indexOf(carId) > -1);
    }

    getModelCount(): number {
        return this.carIds.length;
    }

    getModelNames(): string {
        const data: string[] = [];
        this.carInfos.forEach(car => {
            data.push(car.name);
        });

        const result = data.join(', ');
        return result;
    }
}