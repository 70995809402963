import { Component, OnInit, OnDestroy, Input, PLATFORM_ID, Inject, AfterViewInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ComparisonService } from '../../../services/comparison.service';
import { MessageService } from '../../../../app/services/message.service';
import { Subscription } from 'rxjs';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { Router } from '@angular/router';
import { DialogService } from '../../../../app/services/dialog.service';
import { BaseItemInfo } from '../../../../app/models/baseItemInfo';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { FavoritesService } from '../../../../app/services/favorites.service';
import { memoize } from 'lodash-decorators';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-car-comparison-details',
  templateUrl: './car-comparison-details.component.html',
  styleUrls: ['./car-comparison-details.component.css']
})
export class CarComparisonDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  subModels: any = [];
  manufacturerList: BaseItemInfo[];
  modelList: BaseItemInfo[];
  subModelList: BaseItemInfo[];
  subscription: Subscription;
  messages: any[] = [];
  isModelSelectionEnabled = false;
  isConfigurationSelectionEnabled = false;
  isSubModelSelectionEnabled = false;
  modelName: string;
  configurationName: string;
  subModelName: string;
  isSelected = false;
  showDiff = false;
  hasZeroKM = false;
  hasDealerDiscount = false;
  isPonterEnabled = '';
  isMobile = this.utilsService.IsMobile();

  @Input()
  carModelInfo: CarGroupInfo = null;

  @Input()
  carModelInfoIndex: number;

  constructor(
    public httpClient: HttpService
    , public carComparisonService: ComparisonService
    , private messageService: MessageService
    , private dialogService: DialogService
    , private router: Router
    , public utilsService: UtilsService
    , public favoritesService: FavoritesService
    , private sanitizer: DomSanitizer
    , @Inject(PLATFORM_ID) private platformId: Object
    , public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-compare-loading-data-done') {
      } else if (message.text === 'comparison-toggle-favorites') {
        this.showDiff = !this.showDiff;
      } else if (message.text === 'comparison-reset-single-selectopn') {
        this.resetCarSelection();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.isPonterEnabled = 'point';
    this.httpClient.getManufacturerList().subscribe((data: any) => {
      this.manufacturerList = data;

      if (this.carModelInfo != null && this.carModelInfo.subModels.length > 0) {
        this.GetSubModelList(null, this.carModelInfo.subModels[0].configurationId);
        this.GetConfigurations(this.carModelInfo.manufacturerName);
      }
    });

    if (this.carModelInfo !== null) {
      this.isModelSelectionEnabled = false;
      this.isConfigurationSelectionEnabled = false;
      this.isSubModelSelectionEnabled = false;

      this.modelName = this.carModelInfo.manufacturerName;
      this.configurationName = `${this.carModelInfo.modelName} ${this.carModelInfo.bodyType} ${this.carModelInfo.engine}`;
      this.subModelName = this.carModelInfo.subModelName;

      this.hasDealerDiscount = this.carModelInfo.subModels[0].discountDealerPrice > 0;
      this.hasZeroKM = this.carModelInfo.subModels[0].zeroKMPriceCount > 0;
    } else {
      this.isModelSelectionEnabled = true;
      this.isConfigurationSelectionEnabled = false;
      this.isSubModelSelectionEnabled = false;

      this.resetCarSelection();
    }
  }

  resetCarSelection() {
    this.modelName = 'הוסף רכב להשוואה';
    this.resetModelValue();
    this.resetSubModelValue();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('[data-toggle="popover"]').popover({ trigger: 'hover', container: 'body' });
    }
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  resetModelValue() {
    this.configurationName = 'בחר דגם';
  }

  resetSubModelValue() {
    this.subModelName = 'בחר גרסה';
  }

  colorValue(isHighest: boolean) {
    // return isHighest ? "car-feature-ishighest-value" : "car-feature-value";
    return "car-feature-value";
  }

  getFeatureTitle(key: string) {
    return this.carModelInfoIndex > 0 ? '&nbsp;' : this.getFeature(key).title;
  }

  getFeatureValue(key: string) {
    const value = this.getFeature(key).value;
    const rawValue = this.getFeature(key).rawValue;
    return value === 0 || value === '' || value === undefined  || rawValue === 0  || rawValue === 0.0  || rawValue === '-' ? '-' : value;
  }

  getDiffFeatureTitle(key: string) {
    return this.getDiffFeature(key).title;
  }

  getDiffFeatureValue(key: string) {
    return this.getDiffFeature(key).value;
  }

  getFeature(key: string) {
    const feature = this.carModelInfo.subModels[0].features.find(x => x.key === key);
    return feature;
  }

  getDiffFeature(key: string) {
    const feature = this.carModelInfo.subModels[0].differentFeatures.find(x => x.key === key);
    return feature;
  }

  openDialog(event, price, subModelId, isMonthlyPayment = false) {
    this.messageService.sendMessage(`car-model-meta-info@${price}@${subModelId}@${isMonthlyPayment}`);
    const target = event.target || event.srcElement || event.currentTarget;
    this.dialogService.setDialog(target, null, this.router.url, false);
  }

  getSelectionArrowStatus(status: boolean) {
    if (this.carModelInfo !== null) {
      return 'assets/images/svg/arrow-down.svg';
    } else {
      return 'assets/images/svg/' + (status ? 'arrow-down.svg' : 'arrow-down-disabled.svg');
    }
  }

  getTextClassStatus(status: boolean) {
    if (this.carModelInfo !== null) {
      return 'car-settings-model';
    } else {
      return (status ? 'point car-settings-model' : 'text-disabled');
    }
  }

  getManufacturerClass() {
    if (this.carModelInfo !== null) {
      return 'car-settings-name';
    } else {
      return 'point car-settings-name';
    }
  }

  GetConfigurationList(name: string) {

    this.GetConfigurations(name);
    this.resetModelValue();
    this.resetSubModelValue();

    if (this.carModelInfo != null) {
      this.carComparisonService.RemoveCar(this.carModelInfo.subModels[0].subModelId);
    }
    this.carModelInfo = null;
  }

  GetConfigurations(name: string) {
    this.modelName = name;
    this.isSubModelSelectionEnabled = false;

    this.httpClient.getModelList(name).subscribe((data: any) => {
      this.modelList = data;
      this.isConfigurationSelectionEnabled = true;
    });
  }

  GetSubModelList(configurationName: string, configurationId: number) {
    // if (this.carModelInfo !== null) {
    //   return;
    // }

    if (configurationName !== null) {
      this.configurationName = configurationName;
      this.resetSubModelValue();

      if (this.carModelInfo != null) {
        this.carComparisonService.RemoveCar(this.carModelInfo.subModels[0].subModelId);
      }

      this.carModelInfo = null;
    }

    this.httpClient.getSubModelrList(configurationId).subscribe((data: any) => {
      this.subModelList = data;
      this.isSubModelSelectionEnabled = true;
    });
  }

  SelectSubModel(subModelName: string, subModelId: number) {

    this.messageService.sendMessage('loading-start');
    this.subModelName = subModelName;

    if (this.carModelInfo !== null) {
      this.carComparisonService.RemoveCar(this.carModelInfo.subModels[0].subModelId);
    }

    this.carComparisonService.AddCar(subModelId);

    const url = this.carComparisonService.getCompareUrl();
    this.router.navigate([`compare-cars/versions/${url}`]);

    this.messageService.sendMessage('car-compare-refresh');

    this.resetCarSelection();
  }

  RemoveSubModel(subModelId: number) {
    this.carComparisonService.RemoveCar(subModelId);

    const url = this.carComparisonService.getCompareUrl();

    if (this.carComparisonService.GetCarCount() > 0) {
      this.router.navigate([`compare-cars/versions/${url}`]);
    } else {
      this.router.navigate([`compare-cars`]);
    }

    this.messageService.sendMessage('car-compare-refresh');
  }

  itemMouseEnter() {
    this.isSelected = true;
  }

  itemMouseLeave() {
    this.isSelected = false;
  }

  toggleDiff() {
    this.messageService.sendMessage('comparison-toggle-favorites');
    this.googleAnalyticsService.eventEmitter('Compare', 'Click', 'Differences', 1);
  }

  getPositivevFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין יתרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getNegativeFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין חסרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getFeaturesSummary(features) {
    return this.utilsService.getCarFeaturesSummary(features);
  }

  openCheaperDeals(event) {
    if (this.utilsService.IsMobile()) {
      if (isPlatformBrowser(this.platformId)) {
        const url = 'https://wa.me/972546606517?text=https%3A%2F%2Fcarfind.co.il%0D%0A%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%9C+%D7%99%D7%99%D7%A2%D7%95%D7%A5+%D7%97%D7%99%D7%A0%D7%9D+%D7%95%D7%94%D7%A6%D7%A2%D7%94+%D7%9E%D7%95%D7%96%D7%9C%D7%AA+%D7%9C%D7%A8%D7%9B%D7%91+' 
        + encodeURIComponent(`${this.carModelInfo.manufacturerName} ${this.carModelInfo.modelName}`);
        window.open(url);
      }
    } else {
      this.openDialog(event, this.carModelInfo.subModels[0].lowerZeroKMPrice, this.carModelInfo.subModels[0].configurationId)
    }

    this.googleAnalyticsService.eventEmitter('Compare', 'Click', 'Muzalot', 1);
  }

  openMonthlyPriceDialog(event, price, subModelId) {
    this.googleAnalyticsService.eventEmitter('Compare', 'Click', 'Price', 1);
    
    this.openDialog(event, price, subModelId, true);
  }

  openPriceDialog(event, price, subModelId) {
    this.googleAnalyticsService.eventEmitter('Compare', 'Click', 'Price', 1);
    
    this.openDialog(event, price, subModelId);
  }

  gerDealersCount() {
    const count = this.carModelInfo.subModels[0].zeroKMPriceCount;
    if (count == 1) {
      return `חדש 0 ק"מ`;
    } else if (count > 1) {
      return `${count} סוכנויות`;
    }
  }

  toggleFavoriteStyle(carId: any) {
    if (this.favoritesService.IsChecked(carId)) {
      return 'assets/images/svg/ic-favorite-red.svg';
    } else {
      return 'assets/images/svg/favorite-border.svg';
    }
  }

  setFavorite(car: any) {
    const carId = car.subModelId;
    if (this.favoritesService.IsChecked(carId)) {
      this.favoritesService.Remove(carId);
    } else {
      this.favoritesService.Add(carId, car);
    }
    this.messageService.sendMessage('favorite-update');
  }

  getImageUrl(car: CarGroupInfo, direction: string) {
    if (car !== null) {
      const engManufacturerName = car.engManufacturerName.toLowerCase().replace(' ', '-');
      const engParentModelName = car.engParentModelName.toLowerCase().replace(' ', '-');
      const engModelName = car.engModelName.toLowerCase().replace(' ', '-');
      const bodyType = this.utilsService.bodyTypeValueConverter(car.bodyType).toLowerCase();
      const engine = this.utilsService.engineValueConverter(car.engine).toLowerCase();
      const doors = car.doors;
      const url = this.utilsService.getCarVersionImage(engManufacturerName, engParentModelName, engModelName, bodyType, engine, doors, direction, 'webp');
      return url;
    } else {
      return '';
    }
  }

  @memoize()
  getVideoUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getDefaultImageUrl(event) {
    const imageUrl = this.utilsService.getDefaultImageUrl(this.carModelInfo.bodyType).toLowerCase();
    event.target.src = imageUrl;
  }

  getPrice(officalDealerPrice) {
    if (officalDealerPrice > 0)
      return `${officalDealerPrice.toLocaleString()}`;
    else
      return '-';
  }

  getConfigurationUrl(car: CarGroupInfo) {
    const url = `/${car.engManufacturerName.replace(/ /g, '-')}/${car.engParentModelName.replace(/ /g, '-')}/${car.engModelName.replace(/ /g, '-')}/2022/${this.utilsService.bodyTypeValueConverter(car.bodyType)}/${this.utilsService.engineValueConverter(car.engine)}/${car.doors.toString()}-doors`.toLowerCase();
    return url;
  }

  @memoize()
  getVideoThumbnail(url: string) {
    return this.utilsService.getVideoThumbnail(url);
  }

  getDealerType(type: number) {
    // return this.utilsService.getDealerTypeValue(type);
    return 'מחירון';
  }
}