import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../../app/services/http.service';
import { NewsItem } from '../../../models/newsItem';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { TransferState } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-car-news-feed',
  templateUrl: './car-news-feed.component.html',
  styleUrls: ['./car-news-feed.component.css']
})
export class CarNewsFeedComponent implements OnInit {

  newsItems: NewsItem[] = [];
  isBrowser: boolean;

  constructor(
    public httpClient: HttpService
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private transferState: TransferState
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
    this.httpClient.getNewsItems().subscribe((data: any) => {
      this.newsItems = data;
    });
  }

  openNewsItem() {
    this.googleAnalyticsService.eventEmitter('Welcome', 'click', 'News', 1);
  }

}
