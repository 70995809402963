import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-safety-score-meter',
  templateUrl: './safety-score-meter.component.html',
  styleUrls: ['./safety-score-meter.component.css']
})
export class SafetyScoreMeterComponent implements OnInit {

  @Input()
  value: number;

  constructor() { }

  ngOnInit(): void {
  }

  getPointerLocation() {
    let position = 0;
    position = 44;
    if (this.value < 8) {
      position += ((8 - this.value) * 36);
    }

    return `margin-right: ${position}px;`;
  }

}
