import { Component, OnInit, OnDestroy, Inject, AfterContentChecked } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MessageService } from './services/message.service';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from './services/utils.service';
import { DialogService } from './services/dialog.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  subscription: Subscription;
  messages: any[] = [];
  whatsappButtonClass = 'whatsapp-button whatsapp-button-base';
  isMainPage = true;
  showWhatsAppBubble = false;
  showWhatsAppIcon = false;
  showCallIcon = false;

  constructor(
    private spinner: NgxSpinnerService
    , private messageService: MessageService
    , private meta: Meta
    , private utilsService: UtilsService
    , private dialogService: DialogService
    , private router: Router
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'loading-start') {
        this.showSpinner();
      } else if (message.text === 'loading-stop') {
        this.hideSpinner();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {

    this.utilsService.wwwredirect();

    const isQA = false;
    const seconds = this.utilsService.IsMobile() ? 40000 : 20000;
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(() => {
        if (!this.router.url.includes("dealers/search/dashboard")) {
          this.showWhatsAppIcon = true;
          this.showCallIcon = true;
          const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
            $('#whatsapp-button-base-icon').hide();
            this.showWhatsAppBubble = true;
          }, seconds);
        }
      });
    }

    const isMobile = this.utilsService.IsMobile();
    if (isPlatformBrowser(this.platformId)) {
      if (this.utilsService.IsTablet() || (isMobile && window.orientation.toString() === '90')) {
        this.meta.updateTag(
          { name: 'viewport', content: 'width=1440px' },
        );
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentChecked() {
    this.isMainPage = window.location.pathname === '/';
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  onActivate(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  clickToChat() {
    this.whatsappButtonClass = 'whatsapp-button whatsapp-button-base';
    this.googleAnalyticsService.SendWhatsAppEvent();
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'WhatsApp', 1);
  }

  getWhatsAppButton() {
    return this.whatsappButtonClass;
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'Call', 1);
      this.googleAnalyticsService.SendPhoneEvent();
      document.location.href = 'tel:035245241';
    }
  }

  hideWhatsAppBubble() {
    this.showWhatsAppBubble = false;
    $('#whatsapp-button-unread-icon').show();
  }

  openWhatsApp() {
    this.googleAnalyticsService.SendWhatsAppEvent();
    this.googleAnalyticsService.eventEmitter('Footer', 'Click', 'WhatsAppSend', 1);
    document.location.href = 'https://wa.me/972546606517?text=https://carfind.co.il%0A%D7%94%D7%99%D7%99%2C%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%99%D7%99%D7%A2%D7%95%D7%A5%20%D7%97%D7%99%D7%A0%D7%9D%20%D7%95%D7%9E%D7%91%D7%A6%D7%A2%D7%99%D7%9D%20%D7%9C%D7%A8%D7%9B%D7%91%D7%99%D7%9D';
  }

  getMessageText() {
    let whatsappText = 'https://wa.me/972546606517?text=https://carfind.co.il%0A%D7%94%D7%99%D7%99%2C%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%99%D7%99%D7%A2%D7%95%D7%A5%20%D7%97%D7%99%D7%A0%D7%9D%20%D7%95%D7%9E%D7%91%D7%A6%D7%A2%D7%99%D7%9D%20%D7%9C%D7%A8%D7%9B%D7%91%D7%99%D7%9D';
    const page = this.utilsService.getSessionValue('page');
    if (page === 'manf' || page === 'model' || page === 'config' || page === 'compare') {
      whatsappText = `https://wa.me/972546606517?text=%D7%91%D7%99%D7%A7%D7%A8%D7%AA%D7%99+%D7%91%3A+https%3A%2F%2Fcarfind.co.il%0A%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%99%D7%99%D7%A2%D7%95%D7%A5%20%D7%97%D7%99%D7%A0%D7%9D%20%D7%95%D7%9E%D7%91%D7%A6%D7%A2%D7%99%D7%9D%20%D7%9C%D7%A8%D7%9B%D7%91%D7%99%D7%9D%20%D7%94%D7%91%D7%90%D7%99%D7%9D%3A%20%0A${this.utilsService.getSessionValue('whatsappText')}`;
    }

    return whatsappText;
  }
}
