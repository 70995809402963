<div [ngClass]="setSticky()" style="padding-bottom: 20px;">
  <div class="car-model-name-container" [attr.cf-data]="carModelInfo.subModelId">
    <div [ngClass]="getVersionTitleClass()">
      <span class="point" title="{{carModelInfo.name}}">{{carModelInfo.name}}</span>
    </div>

    <div class="price-meta-info-container">
      <div
        *ngIf="carModelInfo.discountDealerPrice > 0 && carModelInfo.discountDealerPrice < carModelInfo.officalDealerPrice && carModelInfo.status !== 1">
        <span class="car-model-price-title car-model-price-title-above" style="float: right;">
          {{getDealerType(carModelInfo.dealerType)}}
        </span>
        <span class="car-model-price-title car-price-value base-price-padding-left"
          style="float: left; padding-left: 6px;">
          {{carModelInfo.officalDealerPrice.toLocaleString()}}<span class="car-price-value"> ₪</span>
        </span>
      </div>
    </div>

    <img class="point d-none d-xl-block" *ngIf="showNext && carModelInfoIndex == 3"
      style="position:absolute; top: -3px; left: 18px; width: 30px; height: 30px;"
      src="assets/images/arrow_black_next.png" (click)="getNextPage()" />

    <img class="point d-none d-xl-block" *ngIf="showPrev && carModelInfoIndex == 0"
      style="position:absolute; top: -3px; right: -7px; width: 30px; height: 30px;"
      src="assets/images/arrow_black_prev.png" (click)="getPrevPage()" />

    <img class="point d-block d-sm-none" *ngIf="showNext && carModelInfoIndex == 1"
      style="position:absolute; top: -3px; right: 140px; width: 30px; height: 30px;"
      src="assets/images/arrow_black_next.png" (click)="getNextPage()" />

    <img class="point d-block d-sm-none" *ngIf="showPrev && carModelInfoIndex == 0"
      style="position:absolute; top: -3px; right: -7px; width: 30px; height: 30px;"
      src="assets/images/arrow_black_prev.png" (click)="getPrevPage()" />

  </div>

  <button (click)="openPriceDialog($event,carModelInfo.officalDealerPrice,carModelInfo.subModelId)"
    *ngIf="carModelInfo.discountDealerPrice == 0 || carModelInfo.discountDealerPrice >= carModelInfo.officalDealerPrice; else dealerPriceButton"
    class="button car-model-price point" style="border-radius: 6px; width: 168px; height: 36px;">
    <div *ngIf="!carModelInfo.hideBasePrice">
      <span class="car-model-price-title car-price-value-padding-right"
        style="float: right;">{{getDealerType(carModelInfo.dealerType)}}
      </span>
      <span class="car-price-value car-price-value-padding car-price-value-padding-left" style="float: left;">
        {{getPrice(carModelInfo.officalDealerPrice)}}<span class="car-price-value"> ₪</span>
      </span>
    </div>
    <div *ngIf="carModelInfo.hideBasePrice">
      <span class="button car-model-price point">
        {{getDealerType(carModelInfo.dealerType)}}
      </span>
    </div>
    <div>
    </div>
  </button>

  <ng-template #dealerPriceButton>
    <button (click)="openPriceDialog($event,carModelInfo.discountDealerPrice,carModelInfo.subModelId)"
      class="button car-model-price point" style="border-radius: 6px; width: 168px; height: 36px;">
      <div>
        <span class="car-model-price-title car-price-value-padding-right" style="float: right;">מבצע
          יבואן</span>
        <span class="car-price-value car-price-value-padding car-price-value-padding-left" style="float: left;">
          {{carModelInfo.discountDealerPrice.toLocaleString()}}<span class="car-price-value"> ₪</span>
        </span>
      </div>
    </button>
  </ng-template>
  <ng-template #noZeroKMDealersTitle>
    <div>
      <button (click)="openCheaperDeals($event)" class="button car-model-price point"
        style="border-radius: 6px; width: 168px; height: 36px; margin-bottom: 30px;">
        <span *ngIf="carModelInfo.hasSpecialDeals" class="button car-model-price point">
          מבצע מיוחד לדגם
        </span>
        <span *ngIf="!carModelInfo.hasSpecialDeals && !carModelInfo.hasZeroKMPrice"
          class="button car-model-price point">
          רכבים דומים במבצע
        </span>
      </button>
    </div>
  </ng-template>

  <div class="price-meta-info-container base-price-padding-right d-flex align-items-center justify-content-center"
    style="padding-top: 5px; padding-bottom: 5px;">

    <!-- <img *ngIf="carModelInfo.zeroKMPriceCount > 0 && carModelInfo.status !== 1" src="assets/images/svg/group-3.svg"
      data-toggle="popover" data-placement="top" (mouseover)="showZeroKKMPopup()"
      style="padding-left: 5px; padding-bottom: 8px;"
      data-content="הסוכנויות שלנו הם יבואני רכב וחברות ליסינג כדוגמת אלבר, אוויס ואחרים. רכב 0 ק׳׳מ הינו:       רכב חדש שספק (למשל חברת ליסינג) קונה מיבואן, ומוכר לאדם פרטי - נקרא רכב 0 קילומטר, מאחר והרכב לא צבר קילומטרז. היתרון ברכישת רכב בעסקת 0 קילומטר (עם או בלי מימון): רכישת רכב חדש עם אחריות מהיבואן, במחיר הזול (עד עשרות אלפי ₪) ביחס לרכישת אותו הרכב ישירות מהיבואן)" /> -->

    <span *ngIf="carModelInfo.zeroKMPriceCount === 0 && carModelInfo.status !== 1" class="car-model-price-title">
      <span>&nbsp;</span>
    </span>

    <div *ngIf="carModelInfo.status === 1" class="price-meta-info-container" style="padding-top: 5px;">
      <span class="car-model-price-title" style="margin-right: 30px;">
        משווק שלא ע״י היבואן
      </span>
    </div>

  </div>
  <button *ngIf="(carModelInfo.zeroKMPriceCount > 0); else noZeroKMDealersTitle"
    (click)="openDialog($event,carModelInfo.lowerZeroKMPrice,carModelInfo.subModelId)"
    class="button car-model-price point" style="border-radius: 6px; width: 168px; height: 36px; margin-bottom: 30px;">
    <div>
      <span *ngIf="carModelInfo.zeroKMPriceCount > 1" class="car-model-price-title car-price-value-padding-right"
        style="float: right;">
        {{carModelInfo.zeroKMPriceCount}} סוכנויות
      </span>
      <span *ngIf="carModelInfo.zeroKMPriceCount == 1 && carModelInfo.dealerType != 2"
        class="car-model-price-title car-price-value-padding-right" style="float: right;">
        חדש 0 ק"מ
      </span>
      <span *ngIf="carModelInfo.zeroKMPriceCount == 1 && carModelInfo.dealerType == 2"
        class="button car-model-price point">
        זמינות מיידית
      </span>
      <span
        *ngIf="carModelInfo.zeroKMPriceCount > 0 && !(carModelInfo.zeroKMPriceCount == 1 && carModelInfo.dealerType == 2)"
        class="car-price-value car-price-value-padding car-price-value-padding-left" style="float: left;">
        {{carModelInfo.lowerZeroKMPrice.toLocaleString()}}<span class="car-price-value"> ₪</span>
      </span>
    </div>
  </button>
  <div>
    <button (click)="openMonthlyPriceDialog($event,carModelInfo.monthlyPayment,carModelInfo.subModelId)"
      class="button car-model-price point" style="border-radius: 6px; width: 168px; height: 36px;">
      <div *ngIf="!carModelInfo.hideBasePrice">
        <span class="car-model-price-title car-price-value-padding-right" style="float: right;">תשלום חודשי </span>
        <span class="car-price-value car-price-value-padding car-price-value-padding-left" style="float: left;">
          {{carModelInfo.monthlyPayment.toLocaleString()}}<span class="car-price-value"> ₪</span>
        </span>
      </div>
      <div *ngIf="carModelInfo.hideBasePrice">
        <span class="car-model-price-title car-price-value-padding-right">תשלום חודשי</span>
      </div>
    </button>
  </div>
  <!-- <div *ngIf="!carModelInfo.hasSpecialDeals && !carModelInfo.hasZeroKMPrice">
    <button (click)="openCheaperDeals($event)" class="button car-model-price point"
      style="border-radius: 6px; width: 168px; height: 36px; margin-top: 30px;">
      <span>
        רכבים דומים במבצע
      </span>
    </button>
  </div> -->
</div>