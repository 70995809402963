import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { TransferState } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { CarFindDealerLeadTicket } from '../models/CarFindDealerLeadTicket';
import { Dealers01Query } from '../models/dealers01Query';
import { UtilsService } from './utils.service';
import { CarFindDealer01DashboardSearchResult, CarFindDealer01DashboardView } from '../models/CarFindDealer01DashboardView';

@Injectable({ providedIn: 'root' })
export class MagazineService {

    constructor(
        public httpClient: HttpService
        , private transferState: TransferState
        , public utilsService: UtilsService
        , @Inject(PLATFORM_ID) private platformId: Object) {
    }

    getArticleById(articleId: string) {
        return this.httpClient.getMagazineArticleById(articleId).pipe(
            map(cars => cars)
        );
    }
}