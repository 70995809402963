import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { memoize } from 'lodash-decorators';
import { UtilsService } from '../../../../app/services/utils.service';
import { Model } from './../../../../app/models/CarManufacturer';

@Component({
  selector: 'app-car-manufacturer-models',
  templateUrl: './car-manufacturer-models.component.html',
  styleUrls: ['./car-manufacturer-models.component.css']
})
export class CarManufacturerModelsComponent implements OnInit {

  @Input()
  ManufacturerModels: Model[];

  @Input()
  ManufacturerName: string;

  @Input()
  HebManufacturerName: string;

  @Input()
  Year: number;

  title = '';

  constructor(private utilsService: UtilsService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.title = `על איזה דגם של ${this.HebManufacturerName} חשבת? `;
  }

  getUrl(model: Model) {
    if (model.configurationCount === 1) {
      return (model.singleModelUrl).toLowerCase();
    } else {
      return (`/${this.ManufacturerName.replace(/ /g, '-')}/${model.groupName.replace(/ /g, '-')}`).toLowerCase();
    }
  }

  getImageAlt(model: Model) {
    const modelValue = model.hebModelName === undefined || model.hebModelName === '' ? model.engModelName : model.hebModelName;
    return `${model.hebManufacturerName} ${modelValue}`;
  }

  getImageTitle(model: Model) {
    return `${model.engManufacturerName} ${model.engModelName}`;
  }

  getDefaultImageUrl(event, bodyType) {
    const imageUrl = this.utilsService.getDefaultImageUrl(bodyType).toLowerCase();
    event.target.src = imageUrl;
  }

  @memoize()
  getVideoThumbnail(url: string) {
    if (url == '') {
      return 'https://img.youtube.com/vi/B-nZJkQzAYs/sddefault.jpg';
    } else {
      return this.utilsService.getVideoThumbnail(url);
    }
  }

  @memoize()
  getVideoUrl(url) {
    url = url + '?showinfo=0&controls=0&autohide=1';
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
