import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { NewsItem } from 'src/app/models/newsItem';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-magazine-fp-view',
  templateUrl: './magazine-fp-view.component.html',
  styleUrls: ['./magazine-fp-view.component.css']
})
export class MagazineFpViewComponent implements OnInit {

  newsItems: NewsItem[] = [];
  isBrowser: boolean;
  now: string;

  constructor(
    public httpClient: HttpService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private transferState: TransferState
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }
   
  ngOnInit(): void {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    this.now = `${dd}/${mm}/${yyyy}`;

    this.httpClient.getNewsItems().subscribe((data: any) => {
      this.newsItems = data;
    });
  }
}
