<div class="modal fade model-container" id="services-dialog" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">

    <div class="modal-content d-flex align-items-center justify-content-center"
      style="width: 406px; height: 590px; border-radius: 10px;">

      <div class="modal-body">
        <img src="assets/images/svg/close-white.svg" (click)="reset()" data-dismiss="modal"
          style="margin-top: -75px;margin-right: -55px;" />

        <div *ngIf="isFinished === false">
          <div style="margin-top: 10px; text-align: center;"><span class="title">אילו שירותים מעניינים אותך?</span></div>
          <div style="margin-top: 24px; margin-right: 30px;">
            <ul class="list-unstyled">
              <li>
                <div>
                  <div class="Checkbox float-left point">
                    <input type="checkbox" checked (click)="addService(1)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div style="line-height: 1px; padding-top: 9px">
                    <span class="option-text point">הצעות
                      מוזלות</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div class="Checkbox float-left point">
                    <input type="checkbox" checked (click)="addService(2)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div style="line-height: 1px; padding-top: 9px">
                    <span class="option-text point">התראות על
                      מבצעים והשקות</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div class="Checkbox float-left point">
                    <input type="checkbox" checked (click)="addService(3)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div style="line-height: 1px; padding-top: 9px">
                    <span class="option-text point">תאום נסיעת
                      מבחן</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
                <div style="padding-right: 35px; padding-top: 20px;">
                  <input type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input input-option-text" style="height: 36px"
                    placeholder="שירות אחר" (focus)="setDeifferenctServiceFocus()" [(ngModel)]="otherService" />
                </div>
              </li>
            </ul>
          </div>
          <div *ngIf="hasCars" style="margin-top: 28px; text-align: center;"><span class="title">אילו רכבים מעניינים אותך?</span></div>
          <div *ngIf="hasCars" style="margin-top: 24px; margin-right: 30px;">
            <ul class="list-unstyled ul-fav-cars">
              <li *ngIf="currentCarModel !== null">
                <div>
                  <div class="Checkbox float-left point">
                    <input type="checkbox" checked (click)="addCars(1)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div style="line-height: 1px; padding-top: 9px">
                    <span class="option-text point">{{currentCarModel}}</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
              <li *ngIf="favouriteCarModels !== ''">
                <div>
                  <div class="Checkbox float-left point" style="padding-top: 5px">
                    <input type="checkbox" checked (click)="addCars(2)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div>
                    <span class="option-text point">{{favouriteCarModels}}</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
              <li *ngIf="comparisonCarModels !== ''">
                <div>
                  <div class="Checkbox float-left point" style="padding-top: 5px">
                    <input type="checkbox" checked (click)="addCars(3)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div>
                    <span class="option-text point">{{comparisonCarModels}}</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div class="Checkbox float-left point">
                    <input type="checkbox" (click)="addCars(4)" />
                    <div class="Checkbox-visible"></div>
                  </div>
                  <div style="line-height: 1px; padding-top: 9px">
                    <span class="option-text point">לא
                      החלטתי</span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 30px;"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="margin-top: 48px;">
            <button type="button" style="border-radius: 6px;" (click)="submit()" class="header-button point">
              סיום </button>
          </div>
        </div>

        <div *ngIf="isFinished === true">
          <div style="margin-top: 10px;"><span class="title" style="line-height: 2.5;">
              תודה!<br />
               מומחי הרכב שלנו יצרו<br />
              איתך קשר בהקדם.<br />
              רוצים לשוחח בחינם וללא התחייבות, עם מומחי הרכב
               שלנו כבר עכשיו?<br />
              <span class="d-none d-xl-inline">
                התקשר עכשיו <a (click)="openDialer()" data-dismiss="modal" href="tel:035245241">03-524-5241</a>
              </span>
              <span class="d-inlinr d-sm-none">
                התקשר עכשיו <a (click)="openDialer()" data-dismiss="modal" href="tel:035245241">03-524-5241</a>
               <br />
                <div class="d-flex align-items-center justify-content-center">
                  <button type="button" style="border-radius: 6px; width: 170px" data-dismiss="modal" (click)="openDialer()">
                    <span>
                      התקשרו עכשיו
                    </span>
                    <img src="assets/images/svg/ic-call-24-px.svg" />
                  </button>
                </div>
       
              </span>
            </span>
          </div>
          <br />
          <br />
        </div>

      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>