import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pollution-level-meter',
  templateUrl: './pollution-level-meter.component.html',
  styleUrls: ['./pollution-level-meter.component.css']
})
export class PollutionLevelMeterComponent implements OnInit {

  @Input()
  value: number;

  constructor() { }

  ngOnInit(): void {
  }

  getPointerLocation() {
    let position = 0;

    if (this.value > 0) {
      position = 24;
      if (this.value < 15) {
        position += ((15 - this.value) * 23);
      }
    }

    return `margin-right: ${position}px;`;
  }
}
