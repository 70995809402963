import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CarManufacturer } from './../../../../app/models/CarManufacturer';
import { HttpService } from './../../../../app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from './../../../../app/services/catalog.service';
import { DomSanitizer, Title, TransferState } from '@angular/platform-browser';
import { UtilsService } from './../../../../app/services/utils.service';
import { TagItem } from './../../../../app/models/tagItem';
import { ConfigurationInfo } from './../../../../app/models/configurationInfo';
import { BreadcrumbSchemeItem } from './../../../../app/models/breadcrumbSchemeItem';
import { MessageService } from './../../../../app/services/message.service';
import { environment } from './../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-car-manufacturer-view',
  templateUrl: './car-manufacturer-view.component.html',
  styleUrls: ['./car-manufacturer-view.component.css']
})
export class CarManufacturerViewComponent implements OnInit {

  manufacturerValue: string;
  modelValue: string;
  manufacturerInfo: CarManufacturer;
  manufacturerInfo21: CarManufacturer;
  isModelPage = false;
  isMobile = false;
  configurationId = 0;
  videoUrl: string;
  modelName: string;
  groupName: string;
  hebGroupName: string;
  year: number;
  hebManufacturerName: string;
  modelDescription: string;
  engModelName: string;
  lowestConfigutaionId: number;
  urlData: string[];
  title = '';
  isDataLoaded = false;

  constructor(public httpClient: HttpService
    , private router: Router
    , public catalogService: CatalogService
    , private titleService: Title
    , private utilsService: UtilsService
    , private route: ActivatedRoute
    , private messageService: MessageService
    , private sanitizer: DomSanitizer
    , private transferState: TransferState
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {
    this.year = 2022;
    // this.showInfo(this.year);

    if (this.utilsService.isUpper(this.router.url)) {
      this.utilsService.redirect(this.router.url.toLowerCase(), 301);
    }

    this.isMobile = this.utilsService.IsMobile();
    this.utilsService.createCanonicalURL();

    this.urlData = this.router.url.split('/');
    if (this.urlData[1].includes('%20')) {
      this.utilsService.redirect(this.router.url.replace('%20', '-'), 301);
    }

    this.manufacturerValue = this.urlData[1];
    if (this.manufacturerValue.includes('?')) {
      this.manufacturerValue = this.manufacturerValue.substring(0, this.manufacturerValue.indexOf('?'));
    }

    if (this.urlData.length === 3) {
      const value = this.urlData[2];
      if (+value > 2010) { //manf
        this.year = +value;
        this.showInfo(this.year);
        this.utilsService.setSessionValue('page', 'manf');
      } else { //model
        this.modelValue = this.urlData[2];
        this.isModelPage = true;
        this.utilsService.setSessionValue('page', 'model');
      }
    } else if (this.urlData.length === 4) {
      this.year = +this.urlData[3];
      this.showInfo(this.year);
      this.modelValue = this.urlData[2];
      this.isModelPage = true;
      this.utilsService.setSessionValue('page', 'model');
    } else {
      this.utilsService.setSessionValue('page', 'manf');
    }

    this.catalogService.generateFilter();

    this.GetInfo(this.manufacturerValue);

    let pageNumber = 0;
    this.route.queryParams
      .subscribe(params => {
        if (params.page !== undefined && !this.utilsService.IsHomePage()) {
          pageNumber = +params.page;
          this.catalogService.setPageNumber(pageNumber);

          const urlData = this.router.url.split('/');
          const isModelPage = urlData.length === 3 && (+urlData[2] < 2010);
          const isManufacturerPage = urlData.length === 2 || (urlData.length === 3 && (+urlData[2] > 2010));

          if (isManufacturerPage) {
            const manf = urlData[1].substring(0, urlData[1].indexOf('?'));
            this.catalogService.catalogFilter.Manufacturers = [];

            this.catalogService.catalogFilter.Manufacturers.push(manf);

            if (urlData.length === 3 && (+urlData[2] > 2010)) {
              this.catalogService.catalogFilter.years.push(+urlData[2]);
            }
          }

          this.messageService.sendMessage('refresh');
          return;
        } else {
          this.messageService.sendMessage('refresh');
        }
      });
  }

  ngAfterContentChecked() {
    this.getTitle();
  }

  GetInfo(name: string) {
    const breadcrumbPath: BreadcrumbSchemeItem[] = [];

    this.httpClient.getManufacturerInfo(name, 2022).subscribe((data: CarManufacturer) => {
      this.manufacturerInfo = data;
      this.isDataLoaded = true;

      if (this.manufacturerInfo.models === null) {
        let url = '';
        if (this.isModelPage) {
          url = `/${this.manufacturerValue}/${this.modelValue}`;;
        } else {
          url = `/${this.manufacturerValue}`;
        }

        // this.utilsService.redirect(url, 301);
      }

      const manufacturerImageName = this.manufacturerInfo.name.toLowerCase().replace(/\s/g, '');
      breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));

      if (this.isModelPage) {
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(this.manufacturerInfo.hebName, this.manufacturerInfo.name.toLowerCase()));
      } else {
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(this.manufacturerInfo.hebName, '', true));
      }

      let title = '';
      if (this.isModelPage) {
        let year = 0;
        if (this.urlData.length === 4) {
          year = +this.urlData[3];
        }

        this.httpClient.getConfigurationInfoByModel(this.manufacturerValue, this.modelValue, year).subscribe((data2: ConfigurationInfo) => {
          if (data2 === null ||data2.id == 0 ) {
            this.messageService.sendMessage('loading-stop');
            this.utilsService.redirect(`/${this.manufacturerValue}`, 301);
          }
          this.modelName = data2.hebParentModelName;
          this.engModelName = data2.engParentModelName;
          this.utilsService.setSessionValue('whatsappText', `${encodeURIComponent(this.manufacturerInfo.hebName)} ${encodeURIComponent(this.engModelName)}`);

          breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(this.groupName, '', true));
          this.utilsService.setBreadcrumbScheme(breadcrumbPath);

          this.videoUrl = data2.videoUrl;
          this.lowestConfigutaionId = data2.id;
          console.log(data2);
          title = `${data2.hebManufacturerName}${data2.hebParentModelName == null ? '' : ' ' + data2.hebParentModelName} ${data2.engManufacturerName} ${data2.engParentModelName} מחירון, מבצעים ומפרט`;
          this.utilsService.generatePageTitle(title);

          const description = `${data2.hebManufacturerName} ${data2.hebParentModelName} ${data2.engManufacturerName} ${data2.engParentModelName} במחיר המפורסם הזול בישראל. היכנסו לכל הגרסאות של ${data2.hebManufacturerName} ${data2.hebModelName} ${data2.engManufacturerName} ${data2.engModelName}, קבלו עסקאות משתלמות וצפו במפרט: צריכת דלק, סוג ונפח מנוע, טכנולוגיות חדשות, אבזור ועוד.`;
          this.utilsService.addMetaDescriptionTag(description);
          this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), title);

          this.groupName = data2.groupName;
          this.hebGroupName = data2.hebParentModelName;
        });
      } else {

        this.utilsService.setSessionValue('whatsappText', `${encodeURIComponent(this.manufacturerInfo.hebName)}`);

        title = `מחירון ${this.manufacturerInfo.name} ${this.manufacturerInfo.hebName} ועסקאות במחיר המפורסם הזול בישראל`;
        this.utilsService.generatePageTitle(title);

        this.httpClient.getConfigurationInfoByManufacturer(this.manufacturerValue).subscribe((data2: ConfigurationInfo) => {

          if (data == undefined)
            return;

          this.configurationId = data2.id;

          if (data.models.length > 0) {
            const modelsValues: string[] = [];
            const limiter = data.models.length > 3 ? 3 : data.models.length;
            for (let i = 0; i < limiter; i++) {
              const car = data.models[i];
              const value = `${car.hebManufacturerName} ${car.hebModelName}`;
              modelsValues.push(value);
            }


            this.hebManufacturerName = data.hebName;
            const description = `${data.hebName} ${data.name} יצרנית כלי רכב שבין דגמיה: ${modelsValues.join(', ')} ועוד. היכנסו למחירון הדגמים החדשים של ${data.hebName}, צפו במפרט כולל צריכת דלק והשוו רכבים >>`;
            this.utilsService.addMetaDescriptionTag(description);
            this.utilsService.setOGMeta(description, `${environment.cdnEndpointUrl}/general/manufacturers/${manufacturerImageName}.webp`, this.utilsService.getPageUrl(), title);
            this.utilsService.setBreadcrumbScheme(breadcrumbPath);
          }
        });
      }
    });

    this.httpClient.getManufacturerInfo(name, 2021).subscribe((data: CarManufacturer) => {
      this.manufacturerInfo21 = data;
    });
  }

  generateBreadcrumbs() {
    const tags: TagItem[] = [];

    if (this.manufacturerInfo !== undefined) {
      tags.push(this.utilsService.generateNewTag(this.manufacturerInfo.hebName, this.manufacturerInfo.name.toLowerCase(), false));
      if (this.isModelPage) {
        tags.push(this.utilsService.generateNewTag(this.getModelName(), '', false));
      }
    }

    return tags;
  }

  getModelName() {
    return this.modelName == null || this.modelName == undefined ? this.groupName : this.modelName;
  }

  getEngModelName() {
    return this.engModelName;
  }

  getLowestConfigutaionId() {
    return this.lowestConfigutaionId;
  }

  getPageFullHebrewName() {
    if (this.isModelPage && this.manufacturerInfo != undefined) {
      return `${this.manufacturerInfo.hebName} ${this.getModelGroupName()}`;

      // if (this.getModelName() !== undefined && this.getModelName() !== '') {
      //   return `${this.manufacturerInfo.hebName} ${this.getModelGroupName()}`;
      // } else if (this.getModelName() === '' && this.getEngModelName() !== undefined) {
      //   return `${this.manufacturerInfo.hebName} ${this.getModelGroupName()}`;
      // }
    } else {
      if (this.manufacturerInfo !== undefined && this.manufacturerInfo.hebName !== undefined) {
        return this.manufacturerInfo.hebName;
      }
    }
  }

  getModelGroupName() {
    return this.groupName == null ? '' : this.groupName;;
  }

  getModelHebGroupName() {
    return this.hebGroupName == null ? '' : this.hebGroupName;
  }

  getYear() {
    return this.year;
  }

  getYearUrl(year: number) {
    if (year == 2022) {
      if (this.isModelPage) {
        return `/${this.manufacturerValue}/${this.modelValue}`;
      } else {
        return `/${this.manufacturerValue}`;
      }
    }

    return `${this.router.url}/${year}`;
  }

  // getYearClass(year: number) {
  //   if (this.year === 2022 && year === 2022) {
  //     return 'year-active-link';
  //   } else {
  //     return this.router.url.includes(year.toString()) ? 'year-active-link' : 'year-not-active-link';
  //   }
  // }

  showInfo(year: number) {
    this.year = year;
    this.catalogService.toggleYear(year);
    this.messageService.sendMessage('refresh');

    // if (isPlatformBrowser(this.platformId)) {
    //   this.year = year;

    //   if (year == 2022) {
    //     $('#info2022').css('display', 'block');
    //     $('#info2021').css('display', 'none');
    //   } else {
    //     $('#info2022').css('display', 'none');
    //     $('#info2021').css('display', 'block');
    //   }
    // }
  }

  getActiveYearButton(year: number) {
    if (this.year == year) {
      return 'active-button point';
    }
    else {
      return 'header-button point';
    }
  }

  getYearClass(year: number) {
    return this.year == year ? 'year-active-link' : 'year-not-active-link';
  }

  getTitle() {
    if (this.isModelPage) {
      if (this.getModelHebGroupName() !== undefined && this.manufacturerInfo.hebName !== undefined  && this.manufacturerInfo.hebName !== null) {
        this.title = `${this.manufacturerInfo.hebName} ${this.getModelHebGroupName()} ${this.manufacturerInfo.name} ${this.getModelGroupName()}`;
      }
    } else {
      if (this.title === '' && this.manufacturerInfo.hebName !== undefined  && this.manufacturerInfo.hebName !== null) {
        this.title = `${this.manufacturerInfo.name} ${this.manufacturerInfo.hebName}`;
      }
    }

    if (this.year > 0) {
      this.title = `${this.title}`;
    }
  }
}
