<div class="container-fluid" style="text-align:justify; padding-top: 10px">
  <div class="row">
    <div class="col-md-6 col-12 p-0" style="line-height: 4;">
      <!-- <a *ngFor="let manufacturer of firstManufacturerList; let i=index;"   href="{{getUrl(manufacturer)}}">
            <img loading="lazy" alt="{{getCapitalizeString(manufacturer)}}" title="{{getCapitalizeString(manufacturer)}}"
             src="{{getImage(manufacturer)}}" />
        </a> -->
        <span *ngFor="let manufacturer of firstManufacturerList; let i=index;">
          <a href="{{getUrl(manufacturer.toLowerCase())}}">{{manufacturer}}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      
    </div>
    <!-- <div class="col-md-6 col-12 p-0">
      <a *ngFor="let manufacturer of secondManufacturerList; let i=index;"   href="{{getUrl(manufacturer)}}">
            <img loading="lazy" alt="{{getCapitalizeString(manufacturer)}}" title="{{getCapitalizeString(manufacturer)}}"
            src="{{getImage(manufacturer)}}" />
        </a>

      <a *ngFor="let manufacturer of secondManufacturerList; let i=index;" href="{{getUrl(manufacturer)}}">
        {{manufacturer}}
      </a>
    </div> -->
  </div>
</div>