export class CatalogFilter {
    MainSearchKeyword: string;
    SecondarySearchKeyword: string;
    Categories: string[] = [];
    BodyTypes: string[] = [];
    DealType: string;
    Engines: string[] = [];
    Transmissions: string[] = [];
    Drivetrains: string[] = [];
    Seats: string[] = [];
    Manufacturers: string[] = [];
    SubModels: number[] = [];
    years: number[] = [];
    MinPrice: number;
    MaxPrice: number;
    MinPaymentPrice: number;
    MaxPaymentPrice: number;
    MinKML: number;
    MaxKML: number;
    MinBootCapacity: number;
    MaxBootCapacity: number;
    MinLength: number;
    MaxLength: number;
    MinSeats: number;
    MaxSeats: number;
    MinBHP: number;
    MaxBHP: number;
    SortBy: string;
    ModelName: string;
    SortDirection: number;
    PageNumber: number;
    PageItemsCount: number;
    groupName: string;
    PaymentAdvance: number;
    PaymentBalloon: number;
}
