import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { CarGroupInfo } from '../models/CarGroupInfo';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { TransferState } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { CarFindDealerLeadTicket } from '../models/CarFindDealerLeadTicket';
import { Dealers01Query } from '../models/dealers01Query';
import { UtilsService } from './utils.service';
import { CarFindDealer01DashboardSearchResult, CarFindDealer01DashboardView } from '../models/CarFindDealer01DashboardView';

@Injectable({ providedIn: 'root' })
export class DealersService {

    isAuthenticated = false;
    userId = 0;
    allCarResult: CarFindDealer01DashboardView[];
    similarVersions: CarFindDealer01DashboardView[];
    sortByProperty: (c: CarFindDealer01DashboardView) => {};
    sortByDirection: string;

    constructor(
        public httpClient: HttpService
        , private transferState: TransferState
        , public utilsService: UtilsService
        , @Inject(PLATFORM_ID) private platformId: Object) {
    }

    isLoggedIn(): boolean {
        return this.isAuthenticated;
    }

    login(userName, password) {
        if (userName == 'CarFind' && password == 'DSS2@22!') {
            this.isAuthenticated = true;
            this.userId = 1;
        }

        if (this.isAuthenticated) {
            this.utilsService.setLocalValueWithExpiry('IsDealerLoggedIn', '1', 86400000);
        }

        return this.isAuthenticated;
    }

    getDealerLeadTickets() {
        return this.httpClient.getDealerLeadTickets().pipe(
            map(cars => cars)
        );
    }

    search01Deals(query: Dealers01Query) {
        return this.httpClient.search01Deals(query).pipe(
            map(cars => cars)
        );
    }

    getLeadTicketHiddenInfo(leadId: number) {
        return this.httpClient.getDealerLeadTicketHiddenInfo(leadId).pipe(
            map(dats => dats)
        );
    }

    ignoreLeadTicket(leadId: number) {
        return this.httpClient.setDealerLeadTicketStatus(leadId, 3).pipe(
            map(dats => dats)
        );
    }

    leadTicketCarSold(leadId: number) {
        return this.httpClient.setDealerLeadTicketStatus(leadId, 4).pipe(
            map(dats => dats)
        );
    }

    setLeadTicketRemark(lead: CarFindDealerLeadTicket) {
        return this.httpClient.setDealerLeadTicketRemark(lead).pipe(
            map(dats => dats)
        );
    }

    sort<T>(prop: (c: CarFindDealer01DashboardView) => T, order: string): void {
        this.sortCars(prop, order, this.allCarResult);
        this.sortCars(prop, order, this.similarVersions);
    }

    sortCars<T>(prop: (c: CarFindDealer01DashboardView) => T, order: string, cars: CarFindDealer01DashboardView[]): void {

        this.sortByProperty = prop;
        this.sortByDirection = order;

        if (order == 'ASC') {
            cars.sort((a, b) => {
                if (prop(a) < prop(b))
                    return -1;
                if (prop(a) > prop(b))
                    return 1;
                return 0;
            });
        } else {
            cars.sort((a, b) => {
                if (prop(a) > prop(b))
                    return -1;
                if (prop(a) < prop(b))
                    return 1;
                return 0;
            });
        }
    }
}