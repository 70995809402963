<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper">

    <div class="container-fluid page-content-wrapper" style="">
      <div class="row" style="padding-top:200px">
        <div class="col-md-12 col-12" *ngIf="isHandled === false; else isHandledTemplate">
          <div class="row">
            <span class="title">לקוח מעוניין לרכוש מכונית <span>{{leadTicket.CarFindCarVersionName}}</span> במערכת CarFind </span>
          </div>
          <div class="row">
            <ul class="list-group ul-items" style="padding-top: 30px;">
              <li>
                <span><strong>רכב: </strong>{{leadTicket.CarFindCarVersionName}}</span>
              </li>
              <li *ngIf="leadTicket.requestedPrice > 0">
                <span><strong>מחיר: </strong>{{leadTicket.requestedPrice.toLocaleString()}} ש"ח</span>
              </li>
              <li>
                <span><strong>שם: </strong>{{leadTicket.customerInfo.fullName}}</span>
              </li>
              <li *ngIf="leadTicket.customerInfo.cityName !== ''">
                <span><strong>איזור: </strong>{{leadTicket.customerInfo.cityName}}</span>
              </li>
              <li>
                <span><strong>טלפון: </strong>{{leadTicket.customerInfo.phoneNumber}}</span>
              </li>
            </ul>
          </div>
          <div class="row">
            <span class="text" style="padding-top: 30px">
              אנא צרו בהקדם קשר עם הלקוח ולאחר השיחה, אנא בחרו באחת מהאפשרויות הבאות:
            </span>
          </div>
          <div class="row">
            <ul class="ul-options p-0">
              <li>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optradio" (click)="soldCheckd()">
                    <span class="radio-button-text">
                      מכרתי ללקוח רכב (גם אם הרכב אינו זה שהלקוח התעניין בו מלכתחילה)
                    </span>
                  </label>
                  <div *ngIf="showSoldComment" class="row" style="padding-top:30px">
                    <input type="text" [(ngModel)]="soldComment" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom text-input-comment"
                      style="height: 36px;" id="message" name="first_name"
                      [(ngModel)]="leadTicket.comment" placeholder="{{commentPlaceholder}}" />
                  </div>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optradio" (click)="needMoreTimeCheckd()">
                    <span class="radio-button-text">
                      אזדקק לזמן נוסף כדי למכור ללקוח רכב
                    </span>
                  </label>
                  <div *ngIf="showMoreTimeComment" class="row" style="padding-top:30px">
                    <input type="text" [(ngModel)]="needMoreTimeComment" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom text-input-comment"
                      style="height: 36px; width: 1050px" id="message" name="first_name"
                      [(ngModel)]="leadTicket.comment" placeholder="{{commentPlaceholder}}" />
                  </div>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="optradio" (click)="noSuccessCheckd()">
                    <span class="radio-button-text">
                      כנראה לא אצליח למכור ללקוח רכב
                    </span>
                  </label>
                  <div *ngIf="showNoSuccessComment" class="row" style="padding-top:30px">
                    <input [(ngModel)]="noSuccessComment" type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom text-input-comment"
                      style="height: 36px; width: 500px;" id="message" name="first_name"
                      [(ngModel)]="leadTicket.comment" placeholder="אנא ציין את הסיבה לכך" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="row" style="padding-top: 50px">
            <button class="button-submit" (click)="updateResponse()">
              <span>שליחה</span>
            </button>
          </div>
          <div class="row">
            <span class="text" style="padding-top: 50px">
              בכל שאלה, נא צור איתנו קשר במיידי בטלפון <strong>03-524-5241</strong> או במייל support@carfind.co.il
              תודה ובהצלחה.
              <br />
              <br />
              <strong>
                צוות CarFind
              </strong>
            </span>
          </div>
        </div>
        <ng-template #isHandledTemplate>
          <div class="row">
            <span class="title">
              בקשה זו כבר טופלה, לכל שאלה נא צרו קשר עם קארפיינד בטלפון 03-524-5241
            </span>
            <span style="padding-top: 150px"></span>
          </div>
        </ng-template>
      </div>

    </div> 

  </div>
  <app-footer></app-footer>

</div>