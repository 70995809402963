export class CarFindCarVersion {
    name: string;
    lowestZeroKMPrice: number;
    lowestPrice: number;
    dealerDiscountPrice: number;
    categoryId: number;
    zeroKMPricesCount: number;
    officialDealersCount: number;
    parallelDealersCount: number;
    id: number;
    parentModelId: number;
    configurationId: number;
    basePrice: number;
    usedBasePrice: number;
    hebManufacturerName: string;
    engManufacturerName: string;
    engModelName: string;
    engParentModelName: string;
    hebModelName: string;
    engSubModelName: string;
    hebSubModelName: string;
    bodyType: string;
    yearOfManufacture: number;
    yearOfSale: number;
    licensingGroup: number;
    degreeOfPollution: number;
    engineType: string;
    engineCapacity: number;
    roundedEngineCapacity: string;
    bHP: number;
    drivetrain: string;
    drivetrainType: string;
    height: number;
    totalWeight: number;
    doors: number;
    seats: number;
    powerWindowsNumber: number;
    isManual: boolean;
    hasSunroof: boolean;
    hasAlloyWheels: boolean;
    safetyEquipmentLevel: number;
    safetyScore: number;
    adaptiveCruiseControl: boolean;
    airbagsNumber: number;
    automaticLightingOnDriving: boolean;
    tPMS: boolean;
    reverseCamera: boolean;
    laneDepartureControl: boolean;
    frontDistanceMonitoring: boolean;
    deadSpaceDetection: boolean;
    pedestrianDetection: boolean;
    trafficSignsDetection: boolean;
    failureToMaintainDistanceDetection: boolean;
    categories: number[];
    showDealerDiscountPrice: boolean;
    showZeroKMPrice: boolean;
    hasMainImage: boolean;
    configurationVideoUrl: string;
    hasSpecialDeals: boolean;
    monthlyPayment: number;
    hasZeroKMPrice: boolean;
    showDoorsInfo: boolean;
    mainImageFileName: string;
}