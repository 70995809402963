import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CatalogService } from '../../../services/catalog.service';
import { MessageService } from '../../../services/message.service';
import { UtilsService } from '../../../services/utils.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { TransferState } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-car-model-treeview',
  templateUrl: './car-model-treeview.component.html',
  styleUrls: ['./car-model-treeview.component.css']
})
export class CarModelTreeviewComponent implements OnInit {

  public carModels: any[] = [];
  public firstColumnCarModels: any[] = [];
  public secondColumnCarModels: any[] = [];
  public allModelsFilterItemClass;

  constructor(public httpClient: HttpService
    , public catalogService: CatalogService
    , private messageService: MessageService
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private transferState: TransferState
    , public googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.httpClient.getCarModelsCategoryList().subscribe((data: any) => {
      this.carModels = data;
      this.firstColumnCarModels = this.carModels.splice(0, Math.ceil(this.carModels.length / 2));
      this.secondColumnCarModels = this.carModels;
    });
  }

  toggleModelCategoryFilterItem(key: string) {
    key = key.toLowerCase().replace(/ /g, '-');
    const isClicked = this.catalogService.toggleManufacturerCategory(key);
    this.googleAnalyticsService.eventEmitter('Filter', 'Click', 'Manufacturer', 1);
    this.catalogService.navigateToFilterPage();
    this.utilsService.scrolltoTop();
    return false;
  }

  getFilterItemCount(key: string) {
    return this.catalogService.getFilterItemCount(key);
  }

  toggleModelCategoryFilterItemClass(key: string) {
    key = key.toLowerCase().replace(/ /g, '-');

    if (+this.getFilterItemCount(key.toLowerCase()) === 0) {
      return 'row disabled';
    }

    const isClicked = this.catalogService.isModelCategoryFilterExist(key);

    const hasModels = this.catalogService.isAnyModelCategoryFilterExist();
    if (hasModels) {
      this.allModelsFilterItemClass = "p-0 point";
    } else {
      this.allModelsFilterItemClass = "p-0 selected-mfilter-item point";
    }


    if (this.utilsService.IsMobile()) {
      if (isClicked) {
        return "row selected-model-item";
      } else {
        return "row selected-mfilter-item";
      }
    } else {
      if (isClicked) {
        return "row point selected-model-item";
      } else {
        return "row point selected-mfilter-item";
      }
    }
  }

  getUrl (value) {
    return `${environment.baseUrl}/${value}`.toLowerCase();
    ;
  }

  getCapitalizeString(m: string) {
    return this.utilsService.capitalizeString(m.replace('-', ' '));
  }

  getImage(imageName) {
    const url = `${environment.cdnEndpointUrl}/general/manufacturers/${imageName.toLowerCase().replace(/-/g,'')}.webp`;
    return url;
  }
}
