import { Component, OnInit, OnDestroy } from '@angular/core';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { ComparisonService } from '../../../../app/services/comparison.service';
import { MessageService } from '../../../../app/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../../app/services/utils.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Title, Meta } from '@angular/platform-browser';
import { TagItem } from '../../../../app/models/tagItem';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';

@Component({
  selector: 'app-car-comparison-view',
  templateUrl: './car-comparison-view.component.html',
  styleUrls: ['./car-comparison-view.component.css']
})
export class CarComparisonViewComponent implements OnInit, OnDestroy {
  carInfo: CarGroupInfo[];
  blankCarModelsCount: number[];
  private sub: any;
  subscription: Subscription;
  messages: any[] = [];
  viewTitle = 'השוואת רכבים'; //TODO: 2023
  pageTitle: string;
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(
    public carComparisonService: ComparisonService
    , private messageService: MessageService
    , private route: ActivatedRoute
    , private router: Router
    , private utilsService: UtilsService
    , private titleService: Title
    , private meta: Meta
    , public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'comparison-page');
    });

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-compare-refresh') {
        this.compare();
      } else {
        this.messages = [];
      }
    });

    this.pageTitle = 'השוואת רכבים חדשים או 0 ק"מ בין כלל הסוכנויות והעסקאות';
    this.utilsService.generatePageTitle(this.pageTitle);
    const description = `בצעו השוואת רכבים בקלות - בחרו יצרן, גרסה ומאפיינים נוספים ותופיע לפניכם השוואה בין רכבים הנבחרים עם כל המפרט. לא קונים רכב חדש או 0 ק"מ לפני שמשווים בין כלל הסוכנויות והעסקאות.`;
    this.utilsService.addMetaDescriptionTag(description);
    this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), this.pageTitle);

    this.sub = this.route.params.subscribe(params => {
      const carIds = params['ids'].split('-');

      if (carIds !== undefined && carIds.length > 0) {
        this.utilsService.addNoIndexMeta(true);
      }

      carIds.forEach(element => {
        this.carComparisonService.AddCar(+element);
      });
      this.utilsService.generatePageTitle('השוואת רכבים וגרסאות של מגוון יצרנים');
    });
  }

  ngOnInit() {
    this.utilsService.setSessionValue('page', 'compare');

    if (this.utilsService.IsMobile()) {
      this.viewTitle = `השוואת רכבים - בחרו יצרן, דגם וגרסה כדי לבצע השוואה בין רכבים`;
    }

    this.utilsService.createCanonicalURL();
    this.compare();
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  compare() {
    this.messageService.sendMessage('loading-start');
    
    this.carComparisonService.comapre().subscribe((data: any) => {
      this.carInfo = data;
      // this.carComparisonService.Clear();

      const carNames = [];
      this.carInfo.forEach(car => {
        carNames.push(`${car.manufacturerName} ${car.modelName}`)
      });

      this.utilsService.setSessionValue('whatsappText', `${encodeURIComponent(carNames.join(', '))}`);

      const breadcrumbPath: BreadcrumbSchemeItem[] = [];
      breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));

      if (this.carInfo.length > 0) {
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('השוואת רכבים', 'compare-cars'));
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem(`נבחרו ${this.carInfo.length} רכבים להשוואה`, '', true));
      } else {
        breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('השוואת רכבים', '', true));
      }

      this.utilsService.setBreadcrumbScheme(breadcrumbPath);

      let limit = 0;
      const isMobile = this.utilsService.IsMobile();

      if (isMobile) {
        limit = 2;
      } else {
        limit = this.carInfo.length > 2 ? 4 : 3;
      }

      if (isMobile) {
        if (this.carInfo === undefined || this.carInfo.length <= 2) {
          this.blankCarModelsCount = Array(limit - 0).fill(0).map((x, i) => i);
        } else {
          this.blankCarModelsCount = [];
        }
      } else {
        this.blankCarModelsCount = Array(limit - this.carInfo.length).fill(0).map((x, i) => i);
      }

      let firstCar = '';
      let secondCar = '';
      let thirdCar = '';
      if (this.carInfo.length >= 2) {
        firstCar = `${this.carInfo[0].manufacturerName} ${this.carInfo[0].modelName} ${this.carInfo[0].subModelName}`;
        secondCar = `${this.carInfo[1].manufacturerName} ${this.carInfo[1].modelName} ${this.carInfo[1].subModelName}`;
      }
      if (this.carInfo.length >= 3) {
        thirdCar = `ו-${this.carInfo[2].manufacturerName} ${this.carInfo[2].modelName} ${this.carInfo[2].subModelName}`;
      }

      if (this.carInfo.length > 0) {
        const description = `בצעו השוואת רכבים בקלות בין ${firstCar} ל-${secondCar} ${thirdCar}. כל המידע על מגוון הדגמים של מיטב יצרני הרכב במקום אחד.`;
        this.utilsService.addMetaDescriptionTag(description);
        this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), this.pageTitle);
      }

      this.messageService.sendMessage('comparison-loading-data-done');
      this.messageService.sendMessage('loading-stop');
    });
  }

  getItemStyle() {
    if (this.carInfo != undefined && this.carInfo.length === 2) {
      return 'col-md-4 col-6 p-0';
    } else {
      return 'col-md-3 col-6 p-0';
    }
  }

  generateBreadcrumbs() {
    const tags: TagItem[] = [];
    tags.push(this.utilsService.generateNewTag('השוואת רכבים', 'compare-cars', false));

    if (this.carInfo != undefined && this.carInfo.length > 0) {
      tags.push(this.utilsService.generateNewTag(`נבחרו ${this.carInfo.length} רכבים להשוואה`, '', false));
    }

    return tags;
  }
}
