<div style="line-height: 1.75">
    <div style="text-align: justify" *ngIf="carDescription !== ''">
        <span class="car-expert-opinion-title p-0">חוות דעת ביחס לרכבים דומים&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0"> <span class="car-expert-opinion p-0">{{carDescription}}</span></span>
    </div>
    <br />
    <div style="text-align: justify">
        <span class="car-expert-opinion-title p-0">יתרונות&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0">{{getPositivevFeaturesSummary(carInfo?.positiveFeatures)}}</span>
    </div>
    <br />
    <div style="text-align: justify">
        <span class="car-expert-opinion-title p-0">חסרונות&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0">{{getNegativeFeaturesSummary(carInfo?.negativeFeatures)}}</span>
    </div>
</div>