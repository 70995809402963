import { Component, OnInit, Input } from '@angular/core';
import { TagItem } from '../../../../app/models/tagItem';

@Component({
  selector: 'app-view-breadcrumbs',
  templateUrl: './view-breadcrumbs.component.html',
  styleUrls: ['./view-breadcrumbs.component.css']
})
export class ViewBreadcrumbsComponent implements OnInit {

  @Input() items: TagItem[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  generateUrl(index: number, value: string) {
    if (index > 0) {
      return `/${this.items[0].value}/${value}`.toLowerCase();
    } else {
      return `/${this.items[0].value}`.toLowerCase();
    }
  }
}
