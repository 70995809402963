import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '../../../../app/services/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  title: string;
  content: string;
  subscription: Subscription;
  messages: any[] = [];

  constructor(
    private messageService: MessageService
    ) {
      this.subscription = this.messageService.getMessage().subscribe(message => {
        if (message.text === 'show-comparison-limit-alert') {
          this.title = 'השוואת רכבים';
          this.content = 'לא ניתן להשוות יותר מ 4 דגמים';
        } else if (message.text === 'show-comparison-no-items-alert') {
          this.title = 'השוואת רכבים';
          this.content = 'בחרו לפחות דגם אחד להשוואה';
        } else if (message.text === 'show-nofav-alert') {
          this.title = 'מועדפים';
          this.content = 'לחיצה על כפתור ה-♥ ליד רכב - תשמור את הרכב במועדפים';
        } else {
          this.messages = [];
        }
      });
     }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
