import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CityInfo } from '../models/cityInfo';
import { TagItem } from '../models/tagItem';
import { Meta, Title } from '@angular/platform-browser';
import { BreadcrumbSchemeItem } from '../models/breadcrumbSchemeItem';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SharedDataService } from './shared-data.service';
import { Response } from 'express';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SrvRecord } from 'dns';
import { memoize } from 'lodash-decorators';
import { ImageTypeSchemeItem } from '../models/imageTypeSchemeItem';
declare var $: any;

@Injectable({ providedIn: 'root' })
export class UtilsService {

    constructor(
        public httpClient: HttpService,
        private deviceService: DeviceDetectorService,
        private meta: Meta,
        private titleService: Title,
        private router: Router,
        @Inject(DOCUMENT) private dom,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Optional() @Inject(REQUEST) private request: Request,
        @Optional() @Inject(RESPONSE) private response: Response
    ) {
    }

    IsTablet(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTabletCheck = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTabletCheck;
    }

    IsMobile(): boolean {
        return (this.deviceService.isMobile() && !this.IsTablet());
    }

    IsDesktop(): boolean {
        return (!this.IsTablet() && this.deviceService.isDesktop());
    }

    IsHomePage(): boolean {
        if (isPlatformServer(this.platformId)) {
            const url = this.request.url;
            if (url === '/') {
                return true;
            }

            SharedDataService.DealTypes.forEach(deal => {
                if (url.includes(deal)) {
                    return true;
                }
            });
            return false;
        } else {
            const pathData = window.location.pathname.split('/');
            return window.location.pathname === '/' || SharedDataService.DealTypes.includes(pathData[1]);
        }
    }

    getYeshuvimList(): Observable<CityInfo[]> {
        return this.httpClient.getYeshuvimList().pipe(
            map(data => data)
        );
    }

    engineValueConverter(value: string): string {
        switch (value) {
            case "בנזין":
                return "benzin";
            case "חשמל":
                return "electric";
            case "דיזל":
                return "diesel";
            case "חשמל/דיזל":
                return "electric-diesel";
            case "היברידי":
                return "hybrid";
            case "חשמל/בנזין":
                return "electric-benzin";
            default:
                {
                    console.log(value);
                }
        }
    }

    bodyTypeValueConverter(value: string): string {
        switch (value) {
            case "סדאן":
                return "sedan";
            case "תא כפול":
                return "double-cab";
            case "פנאי-שטח":
                return "suv";
            case "קופה":
                return "coupe";
            case "הצ'בק":
                return "hatchback";
            case "קבריולט":
                return "cabriolet";
            case "MPV":
                return "mpv";
            case "משא אחוד":
                return "integral-commercial";
            case "ואן/נוסעים":
                return "van";
            case "סגור/משלוח":
                return "pickup";
            case "קומבי":
                return "combie";
            case "סטיישן":
                return "station";
            case "תא בודד":
                return "single-cab";
            default:
                {
                    console.log(value);
                }
        }
    }

    bodyTypeCustomValueConverter(value: string): string {
        switch (value) {
            case "פנאי-שטח":
                return "פנאי";
            default:
                return value;
        }
    }

    generateNewTag(title: string, value: string, isRoot: boolean) {
        const tag = new TagItem();
        tag.title = title;
        tag.value = value;
        tag.isRoot = isRoot;
        return tag;
    }

    addNoIndexMeta(toFollow: boolean) {
        const value = toFollow ? 'noindex, follow' : 'noindex, nofollow';

        this.meta.updateTag(
            { name: 'robots', content: value },
        );

        console.log('noindex, nofollow');
    }

    addMetaDescriptionTag(description: string) {
        if (description !== '' && description !== undefined) {
            console.log('Meta description: ' + description);
            this.meta.updateTag({ name: 'description', content: description });
        }
    }

    generatePageTitle(title: string) {
        title = `${title} - CarFind`;
        this.titleService.setTitle(title);
        console.log('Page title: ' + title);
    }

    capitalizeString(value: string) {
        return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    encodeBase64string(str) {
        return btoa(str);
    }

    encryptLinks(container) {
        $(`#${container}`).find('a').each((i, item) => {
            const href = $(item).attr('href');
            if (href !== undefined) {
                $(item).removeAttr('href');
                $(item).attr('data-encoded-href', this.encodeBase64string(href));
            }
        });
    }

    generateBreadcrumbSchemeItem(title: string, url: string, isLast = false): BreadcrumbSchemeItem {
        if (title !== undefined) {
            const item = new BreadcrumbSchemeItem();
            item.title = title.replace('"', '&quot;');
            item.url = isLast ? undefined : `https://www.carfind.co.il/${url}`;
            return item;
        } else {
            return new BreadcrumbSchemeItem();
        }
    }

    setImageScheme(images: ImageTypeSchemeItem[]) {
        if (isPlatformBrowser(this.platformId)) {

            const items: string[] = [];
            for (let i = 0; i < images.length; i++) {
                const image = images[i];
                items.push(`{
                    "@type": "ImageObject",
                    "@id": "https://www.carfind.co.il/#/schema/image/${image.fileName}",
                    "url": "${image.url}",
                    "contentUrl": "${image.url}",
                    "caption": "${image.title}"
                }`);
            }

            const breadcrumbTemplate = `<script type="application/ld+json">
            {
                "@context": "https://schema.org",
                "@graph": [${items.join(',')}]
            }
        </script>`;

            $('head').append(breadcrumbTemplate);
        }
    }

    setBreadcrumbScheme(path: BreadcrumbSchemeItem[]) {
        if (isPlatformBrowser(this.platformId)) {
            const items: string[] = [];
            for (let i = 1; i <= path.length; i++) {
                const element = path[i - 1];

                if (element.url !== undefined) {
                    items.push(`{
                    "@type": "ListItem",
                    "position": ${i},
                    "name": "${element.title}",
                    "item": "${element.url}"
                  }`);
                } else {
                    items.push(`{
                    "@type": "ListItem",
                    "position": ${i},
                    "name": "${element.title}"
                  }`);
                }
            }

            const breadcrumbTemplate = `<script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [${items.join(',')}]
        }
        </script>`;

            $('head').append(breadcrumbTemplate);
        }
    }

    createCanonicalURL(url?: string) {
        const canURL = url === undefined ? this.dom.URL.replace('http://', 'https://') : url;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }

    setPaginationTag(url: string, direction: string) {
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', direction);
        this.dom.head.appendChild(link);
        link.setAttribute('href', url);
    }

    redirect(url: string, code?: number) {
        if (isPlatformServer(this.platformId)) {
            if (!!code) {
                this.response.status(code);
            }

            this.response.setHeader('Location', url);
        }
    }

    wwwredirect() {
        if (isPlatformServer(this.platformId)) {
            if (!this.request.headers['host'].includes('localhost') && !this.request.headers['host'].includes('carfind-app-staging')) {
                if (this.request.headers['host'].match(/^www/) == null) {
                    this.response.status(301);
                    this.response.setHeader('Location', 'https://www.' + this.request.headers['host'] + this.request.url);
                }
            }
        }
    }

    setOGMeta(description: string, image: string, url: string, title: string) {
        this.meta.updateTag(
            { property: 'fb:app_id', content: `790535161330236` }
        );
        this.meta.updateTag(
            { property: 'og:url', content: `${url}` }
        );
        this.meta.updateTag(
            { property: 'og:type', content: 'website' }
        );
        this.meta.updateTag(
            { property: 'og:title', content: title }
        );
        this.meta.updateTag(
            { property: 'og:description', content: description }
        );
        this.meta.updateTag(
            { property: 'og:site_name', content: 'CarFind' }
        );
        this.meta.updateTag(
            { property: 'og:image', content: image }
        );
    }

    getPageUrl() {
        if (isPlatformServer(this.platformId)) {
            return `https://www.carfind.co.il${this.request.url}`;
        }
        return 'client';
    }

    goToErrorPage(refererUrl: string) {
        this.router.navigate(['/404'], { queryParams: { url: refererUrl } });
    }

    setPaginationLinks(kw: string, pageNumber: number, isLastPage: boolean) {
        let baseUrl = this.router.url.toString().includes('?') ?
            this.router.url.toString().substr(0, this.router.url.toString().lastIndexOf('?'))
            : this.router.url;
        if (kw !== undefined) {
            baseUrl = `${baseUrl}?kw=${kw}`;
        }

        const prevUrl = baseUrl.includes('?') ? `${baseUrl}&page=${pageNumber - 2}` : `${baseUrl}?page=${pageNumber - 2}`;
        const nextUrl = baseUrl.includes('?') ? `${baseUrl}&page=${pageNumber}` : `${baseUrl}?page=${pageNumber}`;

        if (pageNumber === 2 && !isLastPage) {
            this.setPaginationTag(nextUrl, 'next');
        } else if (isLastPage) {
            this.setPaginationTag(prevUrl, 'prev');
        } else if (pageNumber > 1 && !isLastPage) {
            this.setPaginationTag(nextUrl, 'next');
            this.setPaginationTag(prevUrl, 'prev');
        }

        if (isPlatformBrowser(this.platformId)) {
            $('#side-menu-filter').ready(() => {
                if (pageNumber >= 5) {
                    this.encryptLinks('side-menu-filter');
                    this.encryptLinks('filter-tags');
                }
            });
        }
    }

    getDefaultImageUrl(bodyType) {
        const bodyTypeValue = this.bodyTypeValueConverter(bodyType).toLowerCase();
        let url = '';
        switch (bodyTypeValue) {
            case "hatchback":
                url = 'assets/images/wizard-hatchback.png';
                break;
            case "station":
                url = "assets/images/wizard-7plus.png";
                break;
            case "suv":
                url = "assets/images/wizard-suv.png";
                break;
            case "sedan":
                url = 'assets/images/wizard-sedan.png';
                break;
            case "double-cab":
                url = 'assets/images/wizard-van.png';
                break;
            case "coupe":
                url = 'assets/images/wizard-coupe.png';
                break;
            case "cabriolet":
                url = 'assets/images/wizard-coupe.png';
                break;
            case "mpv":
                url = "assets/images/wizard-suv.png";
                break;
            case "integral-commercial":
                url = 'assets/images/wizard-van.png';
                break;
            case "van":
                url = "assets/images/wizard-suv.png";
                break;
            case "pickup":
                url = 'assets/images/wizard-van.png';
                break;
            case "combie":
                url = 'assets/images/wizard-van.png';
                break;
            case "single-cab":
                url = 'assets/images/wizard-van.png';
                break;
        }

        return url;
    }

    getCarVersionImage(engManufacturerName: string, engParentModelName: string, engModelName: string, bodyType: string, engine: string, doors: number, direction: string, format: string) {
        // if (engine.includes('hybrid'))
        //     engine = engine.replace('hybrid', 'electric');

        let modelName = engParentModelName;
        if (engModelName != '') {
            modelName = `${engManufacturerName}_${engModelName}`;
        }

        const url = `${environment.cdnEndpointUrl}/cars/${direction.toUpperCase()}/${engManufacturerName}_${modelName}_${bodyType}_${engine}_${doors}-doors_${direction.toLowerCase()}.${format}`;
        return url;
    }

    getCarVersionMainImage(fileName: string) {
        const url = `${environment.cdnEndpointUrl}/cars/LF/${fileName}`;
        return url;
    }

    scrolltoTop() {
        if (isPlatformBrowser(this.platformId)) {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        }
    }

    isUpper(str) {
        return (/[A-Z]/.test(str));
    }

    setSessionValue(key, value) {
        if (isPlatformBrowser(this.platformId)) {
            sessionStorage.setItem(key, value);
        }
    }

    getSessionValue(key) {
        if (isPlatformBrowser(this.platformId)) {
            return sessionStorage.getItem(key);
        }
    }

    setLocalValue(key, value) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    setLocalValueWithExpiry(key, value, ttl) {
        if (isPlatformBrowser(this.platformId)) {
            const now = new Date()

            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            }
            localStorage.setItem(key, JSON.stringify(item))
        }
    }

    getLocalValueWithExpiry(key) {
        if (isPlatformBrowser(this.platformId)) {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }
            return item.value
        }
    }

    getLocalValue(key) {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(key);
        }
    }

    getVideoThumbnail(url: string) {
        if (url === null)
            return '';

        const urlInfo = url.split('/');
        if (url.includes('youtube')) {
            return `https://i3.ytimg.com/vi/${urlInfo[4]}/hqdefault.jpg`;
        } if (url.includes('vimeo')) {
            return `https://vumbnail.com/${urlInfo[4]}.jpg`;
        }
    }

    cleanStringNumbersOnly(value: string) {
        value = value.replace(/\D/g, '');
        return value;
    }

    isValidPhoneNumber(value: string) {
        value = this.cleanStringNumbersOnly(value);
        return value.length >= 9;
    }

    getPaymentsInfo(basePrice: number) {
        let paymentsPrice = basePrice - (basePrice * 0.30);
        if (basePrice >= 400000) {
            paymentsPrice = paymentsPrice - (basePrice * 0.30);
        }

        paymentsPrice = Math.ceil(paymentsPrice / 60);
        return paymentsPrice.toLocaleString();
    }

    isDealerSearchLoggedIn() {
        return this.getLocalValueWithExpiry('IsDealerLoggedIn') == 1 ? true : false;
    }

    getPaymentInfo() {
        const paymentInfo = [];
        if (isPlatformBrowser(this.platformId)) {
            const paymentBalloon = sessionStorage.getItem('BalloonPaymen')
            const maxPaymentPrice = sessionStorage.getItem('MaxPaymentPrice')
            const advancePaymentPrice = sessionStorage.getItem('AdvancePaymentPrice')
            if (paymentBalloon !== null) {
                paymentInfo.push(+paymentBalloon);
            } else {
                paymentInfo.push(0);
            }
            if (maxPaymentPrice !== null) {
                paymentInfo.push(+maxPaymentPrice);
            } else {
                paymentInfo.push(0);
            }
            if (advancePaymentPrice !== null) {
                paymentInfo.push(+advancePaymentPrice);
            } else {
                paymentInfo.push(0);
            }
        }

        return paymentInfo;
    }

    getCarFeaturesSummary(features) {
        let text = '';

        for (let index = 0; index < features.length; index++) {
            const element = features[index];

            if (index == features.length - 1 && features.length > 1) {
                text += ` ו${element}`;
            } else if (index < features.length - 2) {
                text += `${element}, `;
            } else {
                text += element;
            }
        }
        return `${text}.`;
    }

    getDealerTypeValue(type: number) {
        switch (type) {
            case 1:
                return 'יבוא רשמי';
            case 2:
                return 'יבוא מקביל';
            case 3:
                return 'רשמי ומקביל';
        }
    }
}
