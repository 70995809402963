<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>


  <div class="container-fluid">
    <div class="row" style="padding-top: 150px; padding-right: 135px;">
      <app-view-breadcrumbs [items]="generateBreadcrumbs()"></app-view-breadcrumbs>
    </div>
    <div class="row" style="padding-right: 135px; padding-top: 25px;">
      <app-view-title [items]="generateBreadcrumbs()"></app-view-title>
    </div>
    <div class="row" style="padding-top: 20px;">
      <div class="col-md-12 p-0">
        <div class="main-image">
          <span class="main-text">
            מצאו את הרכב המתאים לכם במחיר הזול ביותר,<br />
            באתר הגדול בישראל לעסקאות רכב חדש ואפס קילומטר
          </span>
        </div>
      </div>
    </div>

    <div class="row page-content-wrapper" style="padding-top: 30px; padding-bottom: 30px;">
      <div class="col-md-12 description-container-title p-0">
        <span class="description-title">מה מייחד את קארפיינד</span>
      </div>
    </div>

    <div class="row description-container page-content-wrapper">
      <div class="col-md-4 col-12 p-0 d-none d-xl-block">
        <div>
          <div class="float-left point">
            <img src="assets/images/svg/icon-target.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              אתר הרכב המקיף בישראל למציאת
              <br />
              הדגם המתאים ביותר עבורך
            </span>
          </div>
        </div>
        <div class="description-second-item-container">
          <div class="float-left point">
            <img src="assets/images/svg/icon-shield.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              קנייה בטוחה - עם אחריות של
              <br />
              יבואן רשמי על כל הרכבים
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12 p-0 d-none d-xl-block">
        <div>
          <div class="float-left point">
            <img style="width: 60px; height: 60px" src="assets/images/svg/icon-hand.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              מציאת המחיר המפורסם הכי זול
              <br />
              בישראל לכל רכב
            </span>
          </div>
        </div>
        <div class="description-second-item-container">
          <div class="float-left point">
            <img src="assets/images/svg/icon-search.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              חווית קנייה ייחודית: מנוע חיפוש
              <br />
              חדשני בשילוב עם "יועץ דיגיטלי"
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-0 d-none d-xl-block">
        <div>
          <div class="float-left gpointrab">
            <img src="assets/images/svg/icon-money-2.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              השוואת עסקאות לכל דגם מבין מגוון
              <br />
              סוכנויות: יבואנים וחברות 0 ק"מ
            </span>
          </div>
        </div>
        <div class="description-second-item-container">
          <div class="float-left point">
            <img src="assets/images/svg/icon-network.svg" class="description-image" />
          </div>
          <div>
            <span class="feature-text">
              שירות ויעוץ אוביקטיבי, מגוון ובחינם
              <br />
              בטלפון, בצאט ופורום לכל דגם
            </span>
          </div>
        </div>
      </div>

      <ul class="d-block d-sm-none list-inline" style="width: 100%">
        <li>
          <div>
            <div class="float-left point">
              <img src="assets/images/svg/icon-target.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                אתר הרכב המקיף בישראל למציאת
                <br />
                הדגם המתאים ביותר עבורך
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="float-left point">
              <img src="assets/images/svg/icon-shield.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                קנייה בטוחה - עם אחריות של
                <br />
                יבואן רשמי על כל הרכבים
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="float-left point">
              <img style="width: 60px; height: 60px" src="assets/images/svg/icon-hand.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                מציאת המחיר המפורסם הכי זול
                <br />
                בישראל לכל רכב
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="float-left point">
              <img src="assets/images/svg/icon-search.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                חווית קנייה ייחודית: מנוע חיפוש
                <br />
                חדשני בשילוב עם "יועץ דיגיטלי"
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="float-left point">
              <img src="assets/images/svg/icon-money-2.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                השוואת עסקאות לכל דגם מבין מגוון
                <br />
                סוכנויות: יבואנים וחברות 0 ק"מ
              </span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="float-left point">
              <img src="assets/images/svg/icon-network.svg" class="description-image" />
            </div>
            <div>
              <span class="feature-text">
                שירות ויעוץ אוביקטיבי, מגוון ובחינם
                <br />
                בטלפון, בצאט ופורום לכל דגם
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="row page-content-wrapper main-features-container">
      <div class="col-md-3 p-0">
        <ul class="list-unstyled  ul-menu-item ul-text point">
          <li class="ul-text-li">
            <span (click)="scrollToElement(c1)">בקארפינד תמצאו את המחיר המפורסם הזול ביותר בישראל לכל רכב חדש</span>
          </li>
          <li class="ul-text-li">
            <span (click)="scrollToElement(c2)">מהי עסקת רכישת רכב ב"אפס קילומטר"</span>
          </li>
          <li class="ul-text-li">
            <span (click)="scrollToElement(c3)">היתרון ברכישת רכב בעסקת "אפס קילומטר"</span>
          </li>
          <li class="ul-text-li">
            <span (click)="scrollToElement(c4)">קארפינד מציע 5 כלים לסיוע בבחירת הרכב המתאים ביותר</span>
          </li>
          <li class="ul-text-li">
            <span (click)="scrollToElement(c5)">מוכר רכבים חדשים ומעוניין להגדיל את מעגל הלקוחות?</span>
          </li>
        </ul>
      </div>
      <div class="col-md-9 p-0">
        <div #c1 class="text-title p-0">
          <span class="text-title">
            בקארפינד תמצאו את המחיר המפורסם הזול ביותר בישראל לכל רכב חדש
          </span>
        </div>
        <div class="text-content">
          <span class="text">
            קארפינד משווה את כל המחירים המפורסמים מכל יבואני הרכב, מחברות ליסינג ומסוכנויות רכב מובילות - לכם מובטח לקבל
            את המחיר הטוב ביותר המפורסם בישראל, לעיתים זול בעשרות אלפי ש"ח ביחס לעסקאות זהות עבור אותו רכב. בקארפינד
            תמצאו את כל דגמי הרכב החדשים הנמכרים בישראל ותוכלו להשוות בינם. השימוש באתר חינם.
          </span>
        </div>

        <div #c2 class="text-title">
          <span class="text-title">
            מהי עסקת רכישת רכב ב"אפס קילומטר"
          </span>
        </div>
        <div class="text-content">
          <span class="text">
            רכב חדש שיבואן מוכר לספק משנה (למשל חברת ליסינג), והספק מוכר את הרכב ללקוח הסופי - נקרא רכב "אפס ק"מ" מאחר
            והרכב לא צבר קילומטרז.
          </span>
        </div>

        <div #c3 class="text-title">
          <span class="text-title">
            היתרון ברכישת רכב בעסקת "אפס קילומטר"
          </span>
        </div>
        <div class="text-content">
          <span class="text">
            רכישת רכב חדש עם אחריות יבואן במחיר הזול בעד עשרות אלפי שקלים ביחס לרכישתו ישירות מהיבואן (עם או בלי מימון).
            במעמד מכירת רכב שקניתם בעסקת "אפס קילומטר" תדרשו לתת הנחה, בשל רישום הבעלות הקודמת של ספק המשנה. הנחה זו
            תהיה כמעט תמיד נמוכה מההנחה שקיבלתם בעת רכישת הרכב.
          </span>
        </div>

        <div #c4 class="text-title">
          <span class="text-title">
            קארפינד מציע 5 כלים לסיוע בבחירת הרכב המתאים ביותר:
          </span>
        </div>
        <div class="text-content">
          <span class="text">
            1. ייעוץ וירטואלי (ויזארד) בראש עמוד הבית
            <br />
            2. התכתבות עם יועץ רכב אנושי בצ'ט מצד ימין למטה
            <br />
            3. התייעצות עם קהילת הרכב שלנו בפייסבוק
            <br />
            4. התייעצות עם גולשים בפורום המוצע בכל עמוד רכב
            <br />
            5. שיחת טלפון חינם עם יועץ רכב <a (click)="callUs()" href="tel:035245241">03-524-5241</a>
          </span>
        </div>

        <div #c5 class="text-title">
          <span class="text-title">
            מוכר רכבים חדשים ומעוניין להגדיל את קהל הלקוחות?
          </span>
        </div>
        <div class="text-content">
          <span class="text">
            <span class="d-none d-xl-inline">
              צור איתנו קשר בטלפון <a (click)="callUs()" href="tel:035245241">03-524-5241</a> או <a
                (click)="goToContactUsPage()" href="/contact">כאן</a>
            </span>
            <span class="d-block d-sm-none">
              צור איתנו קשר בטלפון
              <button type="button" class="header-button point" style="border-radius: 6px;" (click)="openDialer()">
                <span style="color: #ff2800">
                  התקשרו עכשיו
                </span>
                <img src="assets/images/svg/ic-call-24-px.svg" />
              </button>
              או <a (click)="goToContactUsPage()" href="/contact">כאן</a>
            </span>

            <br />
            לקריאת תנאי השימוש ומדיניות הפרטיות באתר <a (click)="showTerms()" class="regular-link" href="/terms">לחצו
              כאן</a>
          </span>
        </div>
      </div>
    </div>
  </div>

</div> 

<app-footer></app-footer>