export class CarGroupInfo {
  id: number;
  name: string;
  manufacturerName: string;
  modelName: string;
  subModelName: string;
  engManufacturerName: string;
  engParentModelName: string;
  engModelName: string;
  engSubModelName: string;
  engine: string;
  doors: number;
  lowestMonthlyPayment: number;
  bodyType: string;
  groupName: string;
  degreeOfPollution: number;
  safetyScore: number;
  description: string;
  videourl: string;
  marketingStopped: boolean;
  categoryName: string;
  priceIsAboveGroupAvg: boolean;
  positiveFeatures?: (string)[] | null;
  negativeFeatures?: (string)[] | null;
  subModels?: (SubModelInfo)[] | null;
  hasImages: boolean;
  hasMainImage: boolean;
  images: (string)[] | null;
  hasSpecialDeals: boolean;
  hasVersionWithZeroKMPrice: boolean;
  years: string[];
}
export class SubModelInfo {
  subModelId: number;
  configurationId: number;
  name: string;
  videoUrl: string;
  officalDealerPrice: number;
  discountDealerPrice: number;
  lowerZeroKMPrice: number;
  zeroKMPriceCount: number;
  status: number;
  dealerType: number;
  hasMainImage: boolean;
  hideBasePrice: boolean;
  zeroKMPriceDealers?: (null)[] | null;
  features?: (CarFeatureInfo)[] | null;
  differentFeatures?: (DifferentFeaturesEntity)[] | null;
  monthlyPayment: number;
  hasZeroKMPrice: boolean;
  hasSpecialDeals: boolean;
}
export class CarFeatureInfo {
  key: string;
  title: string;
  value: number | string | number | string;
  rawValue: number | string | number | string;
}
export class DifferentFeaturesEntity {
  key: string;
  title: string;
  value: number | string | number | string;
  isHighest: boolean;
}
