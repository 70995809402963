<app-header></app-header>

<div id="wrapper">
  <app-sidebar-menu></app-sidebar-menu>

  <div id="page-content-wrapper">

    <div class="container-fluid page-content-wrapper">
      <div class="row" style="padding-top:200px">
        <div class="col-md-12 col-12">
          <div class="row">
            <span class="title">
              תודה על פנייתך,<br />
              מומחי הרכב שלנו יצרו איתך קשר תוך יום עסקים אחד.<br />
              מעוניינים לשוחח איתם כבר עכשיו?<br />
              התקשרו <a (click)="openDialer()" href="tel:035245241">03-524-5241</a><br />
              <button (click)="goToHomePage()" type="button" class="header-button point"
              style="width: 136px;border-radius: 6px; line-height: normal;">
              סיום
              </button>
            </span>
          </div>
          <div class="row">
            <span style="padding-top: 150px"></span>
          </div>
        </div>
      </div>

    </div> 

    <app-footer></app-footer>
  </div>

</div>