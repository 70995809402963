<nav class="fixed-top p-0 header-container" style="background-color: white">

    <nav class="navbar navbar-expand navbar-dark header-stripe">
        <span href="#menu-toggle" (click)="toggleMenu()" id="menu-toggle" class="navbar-brand d-block d-sm-none">
            <img style="width: 18px; height: 12px" src="assets/images/svg/mobile-menu-toggle.svg" />
        </span>

        <div class="d-none d-xl-block">
            <ul class="navbar-nav">
                <li class="nav-item header-text d-none d-xl-block">
                    <span style="color: #ff2800; font-size: 18px;">
                        יעוץ חינם&nbsp;&nbsp;<span style="font-size: 16px;"><a (click)="callUs()"
                                href="tel:035245241">03-524-5241</a></span>
                    </span>
                    <img style="padding-right: 5px" src="assets/images/svg/ic-call-24-px.svg" />
                    <span *ngIf="showFavorites" (click)="showFavoritesList($event)" class="point"
                        style="padding-right: 30px; font-size: 18px;">מועדפים (<span
                            style="color: #ff2800;">{{favoritesCount}}</span>)</span>
                </li>


            </ul>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto" style="width: 100%;">
                <li style="width: 100%; text-align: center;" class="d-none d-xl-block">
                    <div style="display: inline-block; padding-top: 5px;">
                        <form class="form-inline float-right" (keydown.enter)="$event.preventDefault()">
                            <div id="main-search-container" class="form-group has-search">
                                <img id="search-icon" aria-haspopup="false" aria-expanded="false" data-toggle="dropdown"
                                    class="form-control-feedback"
                                    style="width: 18px; height: 18px; position: relative; right: 30px;"
                                    src="assets/images/svg/search.svg" />
                                <input id="mainsearch" #mainsearch type="text" (keyup)="searchKeyUp($event)"
                                    (focus)="setSeatchFocus()" (keydown)="searchKeyDown($event)"
                                    (keydown.enter)="Search($event)" class="form-control header-search"
                                    style="padding-right: 40px; border-radius: 6px; height: 36px; width: 400px; border: white; color: #ff0000"
                                    placeholder="מצאו את הרכב המתאים לכם במחיר הזול בישראל" />

                            </div>

                            <div id="search-auto-complete" class="dropdown-menu">
                                <a *ngFor="let car of searchSuggestions; let i=index;"
                                    (click)="navigateToCarPage($event, car)"
                                    class="dropdown-item point">{{car.value}}</a>
                            </div>
                        </form>
                    </div>
                </li>

                <li style="width: 100%; text-align: center;" class="d-inline d-sm-none">
                    <div style="display: inline-block; padding-top: 5px;">
                        <form class="form-inline float-right" (keydown.enter)="$event.preventDefault()">
                            <div class="has-search" style="position: fixed; left: 120px; top: 10px;">
                                <span class="form-control-feedback" style="padding-right: 0px"
                                    aria-haspopup="false" aria-expanded="false" data-toggle="dropdown">
                                    <img  src="assets/images/svg/search.svg" class="form-control-feedback" style="width: 15px; height: 15px; top: 12px; right: 7px">
                                </span>
                                <input #mainsearchmobile id="mainsearchmobile" type="text" (keyup)="searchKeyUp($event)"
                                    (focus)="setSeatchFocus()" (keydown)="searchKeyDown($event)"
                                    (keydown.enter)="Search($event)" class="form-control"
                                    style="padding-right: 30px; width: 235px; height: 36px; font-size: 13px; border: 0; padding-left: 0;"
                                    placeholder="מצאו רכב חדש במחיר הזול בישראל">
                            </div>
                            <div id="search-auto-complete-mobile" class="dropdown-menu">
                                <a *ngFor="let car of searchSuggestions; let i=index;"
                                    (click)="navigateToCarPage($event, car)"
                                    class="dropdown-item point">{{car.value}}</a>
                            </div>
                        </form>
                    </div>
                </li>


            </ul>

            <ul class="navbar-nav">
                <li class="nav-item d-none d-xl-inline" style="width: 465px; text-align: end;">
                    <h1 *ngIf="!isCatalogFiltered && isMainPage" class="header-text" style="display: inline;">
                        {{desktopHeaderLogoText}}</h1>
                    <h2 *ngIf="isCatalogFiltered || !isMainPage" class="header-text" style="display: inline;">
                        {{desktopHeaderLogoText}}</h2>
                </li>
                <li class="nav-item d-inline d-sm-none">



                </li>
                <li class="nav-item d-none d-xl-block point">
                    <a (click)="logoClicked()"><img (click)="scrollToTop()" style="width: 150px; padding-right: 10px"
                            src="assets/images/carfind-logo-desktop.png" /></a>
                </li>
                <li class="nav-item d-inline d-sm-none" (click)="scrollToTop()">
                    <a (click)="logoClicked()"><img src="assets/images/svg/logo.svg" /></a>
                </li>
            </ul>
        </div>
    </nav>

    <div class="container-fluid page-content-wrapper" style="background-color: white;">
        <div class="row">
            <div class="col-md-6 p-0 d-none d-xl-block" style="margin-top: 20px">
                <form class="form-inline">
                    <input (focus)="setNameFocus()" type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input align-bottom"
                        style="height: 36px; width: 110px" id="name" name="first_name" placeholder="שם"
                        [(ngModel)]="contactForm.name" />
                    <input (focus)="setPhoneFocus()" type="text" class="mr-sm-2 mb-2 mb-sm-0 text-input" id="phone"
                        style="height: 36px; width: 110px" name="last_name" placeholder="טלפון"
                        [(ngModel)]="contactForm.phone" />
                    <button (click)="contactWithMe($event, 'CarFind Lead')" type="button" class="header-button point"
                        style="margin-right: 20px; width: 136px;border-radius: 6px">
                        צרו איתי קשר
                    </button>
                </form>
            </div>



            <div *ngIf="showSecondRow" class="col-md-6 p-0 d-inline d-sm-none">


                <ul class="list-inline ul-header-buttons"
                style="padding-top: 15px; padding-right:5px; padding-left: 5px;">
                <li>
                        <button (click)="openDialer()" type="button" class="header-button contact-us-button point"
                            style="width: 115px;height: 36px;  border: solid 1px #ff2800; border-radius: 6px;font-size: 14px;">
                            <span>
                                יעוץ חינם
                            </span>
                            <img style="margin-right: 5px;" src="assets/images/svg/ic-call-24-px-white.svg" />
                        </button>
                    </li>
                    <li>
                        <button (click)="contactWithMe($event, 'CarFind Lead City')" type="button"
                            class="header-button contact-us-button point"
                            style="width: 125px;height: 36px;  border: solid 1px #ff2800; border-radius: 6px;font-size: 14px;">
                            <span>
                                צרו איתי קשר
                            </span>
                        </button>
                    </li>
                    <li>
                        <button (click)="openThirdButtonCommand($event)" type="button"
                            class="header-button contact-us-button point"
                            style="width: 100px;height: 36px; border: solid 1px #ff2800; border-radius: 6px;font-size: 14px;">
                            <div *ngIf="isMainPage">
                                <span>
                                    סינון
                                </span>
                                <img style="width: 15px; margin-right: 5px;" src="assets/images/filter-icon.png" />
                            </div>
                            <div *ngIf="!isMainPage">
                                <span>
                                    נסיעת מבחן
                                </span>
                            </div>
                        </button>
                    </li>
                </ul>

            </div>

            <div class="col-md-6 align-items-start p-0 d-none d-xl-block" style="margin-top: 20px">
                <div class="container-fluid p-0">
                    <div class="form-inline justify-content-end">
                        <button (click)="getCheapDeals($event, 'CarFind Lead City')" type="button"
                            style="width: 134px; border-radius: 6px" class="header-button point">
                            <span>
                                הצעות מוזלות
                            </span>
                        </button>
                        <button (click)="getDealAlert($event, 'CarFind Lead City')" type="button"
                            class="header-button point" style="margin-right: 20px; width: 212px; border-radius: 6px">
                            התראת מבצעים והשקות
                        </button>
                        <button (click)="setTestDrive($event, 'CarFind Lead City')" type="button" class="header-button point"
                            style="margin-right: 20px; width: 120px; border-radius: 6px">
                            נסיעת מבחן
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<app-cars-services-dialog></app-cars-services-dialog>
<app-cars-contact-dialog></app-cars-contact-dialog>
<app-cars-deal-contact-dialog></app-cars-deal-contact-dialog>
<app-alert></app-alert>