<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>

    <div id="page-content-wrapper">
        <div class="container-fluid page-content-wrapper">
            <div class="row car-list-wrapper content-wrapper">
                <div style="text-align: right; width: 50%;">
                    <app-view-breadcrumbs [items]="generateBreadcrumbs()"></app-view-breadcrumbs>
                </div>
                <div class="d-block d-sm-none" style="text-align: left; width: 50%;" *ngIf="isDataLoaded">
                    <div>
                        <span [ngClass]="getYearClass(2022)"><a (click)="showInfo(2022)">2022</a></span><span style="font-size: 14px; vertical-align: super;">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span [ngClass]="getYearClass(2021)"><a (click)="showInfo(2021)">2021</a></span>    
                    </div>
                </div>
            </div>
            
            <div class="row" style="padding-top: 20px;">
                <div class="col-md-4 d-none d-xl-block">
                    <app-car-manufacturer-logo *ngIf="!isMobile" [EngManufacturerName]="manufacturerInfo?.name"
                        [HebManufacturerName]="manufacturerInfo?.hebName" [HebModelName]="getModelName()"
                        [Year]="getYear()" [EngModelName]="getEngModelName()" [GroupName]="getModelHebGroupName()">
                    </app-car-manufacturer-logo>
                    <div style="padding-top: 20px;" class="d-flex align-items-center justify-content-center">
                        <app-generic-contact-form [subTitle]="getPageFullHebrewName()" style="width: 85%;">
                        </app-generic-contact-form>
                    </div>
                    <!-- <app-car-community-info [showVideo]="isModelPage" [configurationId]="getLowestConfigutaionId()"
                        [configurationVideoUrl]="videoUrl"></app-car-community-info> -->
                </div>



                <div class="col-md-8 col-12">
                    <div style="margin-right: -15px;">
                        <ul class="navigation-list">
                            <li>
                                <h1>{{title}}</h1>
                            </li>
                            <li class="d-none d-xl-block">
                                <!-- <button type="button" (click)="showInfo(2022)" [ngClass]="getActiveYearButton(2022)"
                                    style="width: 100px;border-radius: 6px; margin-right: 20px;">
                                    2022
                                </button> -->
                                <!-- <span [ngClass]="getYearClass(2022)"><a (click)="showInfo(2022)">2022</a><span> | </span></span> -->

                            </li>
                            <li class="d-none d-xl-block">
                                <!-- <button type="button" (click)="showInfo(2021)" [ngClass]="getActiveYearButton(2021)"
                                    style="width: 100px;border-radius: 6px; margin-right: 20px;">
                                    2021
                                </button> -->
                                <!-- <span [ngClass]="getYearClass(2021)"><a (click)="showInfo(2021)">2021</a></span> -->

                            </li>
                        </ul>

                        <div class="d-none d-xl-block" *ngIf="isDataLoaded">
                            <span [ngClass]="getYearClass(2022)"><a class="point"  (click)="showInfo(2022)">2022</a></span><span style="font-size: 16px; vertical-align: super;">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            <span [ngClass]="getYearClass(2021)"><a class="point"  (click)="showInfo(2021)">2021</a></span>    
                        </div>
    

                        <!-- <button type="button" (click)="showInfo(2022)" [ngClass]="getActiveYearButton(2022)"
                            style="width: 100px;border-radius: 6px; margin-right: 20px;">
                            2022
                        </button>
                        <button type="button" (click)="showInfo(2021)" [ngClass]="getActiveYearButton(2021)"
                            style="width: 100px;border-radius: 6px; margin-right: 20px;">
                            2021
                        </button> -->
                    </div>
                    <div>
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-md-12 col-12 p-0">

                                    <div class="d-inline d-sm-none d-flex align-items-center justify-content-center" style="padding-top: 20px; padding-bottom: 20px">
                                        <app-car-manufacturer-logo *ngIf="isMobile"
                                            [EngManufacturerName]="manufacturerInfo?.name" [Year]="getYear()"
                                            [HebManufacturerName]="manufacturerInfo?.hebName"
                                            [HebModelName]="getModelName()" [EngModelName]="getEngModelName()"
                                            [GroupName]="getModelHebGroupName()"></app-car-manufacturer-logo>
                                    </div>

                                    <div>
                                        <input id="ch" type="checkbox">
                                        <!-- <label id="filterDescriptionShowMore" for="ch"></label> -->
                                        <div class="p0 manufacturer-info-container">
                                            <app-car-manufacturer-info *ngIf="modelDescription !== null"
                                                [ManufacturerDescription]="isModelPage ? modelDescription : manufacturerInfo?.description">
                                            </app-car-manufacturer-info>
                                        </div>
                                    </div>

                                    <div id="model-info" *ngIf="isModelPage">
                                        <app-car-model-info [ConfigurationId]="lowestConfigutaionId">
                                        </app-car-model-info>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 10px;" *ngIf="isModelPage == false">
                            <app-car-manufacturer-models [ManufacturerName]="manufacturerValue"
                                [HebManufacturerName]="manufacturerInfo.hebName"
                                [ManufacturerModels]="manufacturerInfo?.models"></app-car-manufacturer-models>
                        </div>
                        <div style="padding-top: 45px;">
                            <app-car-list [IsFiltered]="true"></app-car-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-footer></app-footer>
    </div>

</div>