import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Router } from '@angular/router';
import { DialogService } from '../../../app/services/dialog.service';
import { FavoritesService } from '../../../app/services/favorites.service';
import { GoogleAnalyticsService } from '../../../app/services/google-analytics.service';
import { HttpService } from '../../../app/services/http.service';
import { BasicContactForm } from '../../../app/models/basicContactForm';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
declare var $: any;

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css']
})
export class SidebarMenuComponent implements OnInit, AfterViewInit {

  contactForm: BasicContactForm;

  constructor(private ngNavigatorShareService: NgNavigatorShareService
    , private router: Router
    , private dialogService: DialogService
    , public httpClient: HttpService
    , public utilsService: UtilsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , private favoritesService: FavoritesService) { }

  ngOnInit() {
    this.contactForm = new BasicContactForm();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#sidebar-close-button').click(() => {
        $('#wrapper').toggleClass('toggled');
      });
    }
  }

  share() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Share', 1);
    this.ngNavigatorShareService.share({
      title: 'Share from CarFind',
      text: 'מצאתי אתר שמשווה בין כל עסקאות הרכב בישראל למציאת העסקה הזולה ביותר',
      url: this.router.url
    }).then((response) => {
    })
      .catch((error) => {
        console.log(error);
      });
  }

  goToPage(path) {
    this.router.navigate(['/' + path]);
  }

  showFavoritesList(event) {
    $('#wrapper').removeClass('toggled');
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Favorites', 1);

    const target = event.target || event.srcElement || event.currentTarget;
    if (this.favoritesService.carIds.length === 0) {
      this.dialogService.showAlert(target, 'show-nofav-alert');
    } else {
      this.dialogService.removeAlert(target);

      this.router.navigate([`/favorites/${this.favoritesService.carIds.join('-')}`]);
    }
  }

  goToLink(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }

  openDialog(event, subject) {
    $('#wrapper').removeClass('toggled');
    const target = event.target || event.srcElement || event.currentTarget;
    this.contactForm.subject = subject;
    
    const paymentInfo = this.utilsService.getPaymentInfo();
    this.contactForm.paymentBalloon = paymentInfo[0];
    this.contactForm.maxPaymentPrice = paymentInfo[1];
    this.contactForm.advancePaymentPrice = paymentInfo[2];

    this.dialogService.setDialog(target, this.contactForm, this.router.url);

    if (this.contactForm !== null && this.contactForm.phone !== undefined && this.contactForm.phone !== '' && this.utilsService.isValidPhoneNumber(this.contactForm.phone)) {
      this.httpClient.SendBasicContactForm(this.contactForm).subscribe((result: any) => {
        console.log('sent!');
        this.googleAnalyticsService.SendCompletedConversionEvent();
      });
    }
  }

  goToDealersPage() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Agencies', 1);
    this.goToPage('car-agencies');
  }

  goToAboutPage() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'About', 1);
    this.goToPage('about');
  }

  goToCademy() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Cademy', 1);
    this.goToLink('https://www.cademy.co.il');
  }

  goToFacebook() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Facebook', 1);
    this.goToLink('https://www.facebook.com/%D7%A7%D7%A8%D7%A4%D7%99%D7%99%D7%A0%D7%93-%D7%9E%D7%A6%D7%99%D7%90%D7%AA-%D7%A2%D7%A1%D7%A7%D7%AA-%D7%94%D7%A8%D7%9B%D7%91-%D7%94%D7%9E%D7%A9%D7%AA%D7%9C%D7%9E%D7%AA-%D7%91%D7%99%D7%95%D7%AA%D7%A8-CarFind-2008097506152222/');
  }

  goToInstagram() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Instagram', 1);
    this.goToLink('https://www.instagram.com/carfind_il/');
  }

  goToContactUsPage() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'ContactUs', 1);
    this.goToPage('contact');
  }

  goToAlertPage(event) {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Alert', 1);
    this.openDialog(event, 'התראת מבצעים');
  }

  goToDealsPage(event) {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Hazaot', 1);
    this.openDialog(event, 'הצעות מוזלות');
  }

  goToTestDrivePage(event) {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Nesiat', 1);
    this.openDialog(event, 'נסיעת מבחן');
  }

  goToJoinAsDealerPage() {
    this.googleAnalyticsService.eventEmitter('Menu', 'Click', 'Join', 1);
    this.goToPage('contact');
  }
}
