<router-outlet (activate)="onActivate($event)"></router-outlet>
<ngx-spinner
bdColor = ""
size = "medium"
color = "#ff2800"
type = "ball-atom">
</ngx-spinner>

<nav class="whatsapp-fab point" *ngIf="showWhatsAppIcon">
    <a id="whatsapp-button-base-icon" class="whatsapp-button whatsapp-button-base" (click)="clickToChat()" target="_blank" [href]="getMessageText()"></a>
</nav>
<nav class="whatsapp-fab point" *ngIf="showWhatsAppIcon">
    <a id="whatsapp-button-unread-icon" class="whatsapp-button whatsapp-button-unread" (click)="clickToChat()" target="_blank" style="display: none" [href]="getMessageText()"></a>
</nav>

<nav class="fab d-block d-sm-none" *ngIf="showCallIcon">
    <a class="buttons" id="open-modal" (click)="openDialer()"></a>
</nav>

<div *ngIf="showWhatsAppBubble" (click)="openWhatsApp()" type="floating" class="Window__WindowComponent-sc-17wvysh-1" style="width: 250px; direction: rtl; z-index: 1726;">
    <div color="rgb(255, 255, 255)" (click)="hideWhatsAppBubble()" role="button" tabindex="0" class="Close__Component-sc-1l05yq5-0 drfHxL"></div>
    <div class="Header__Component-sc-1y135nm-0">
        <div size="52" class="UserImage__Component-sc-1x4ogkw-0 eZEgcx">
            <div class="UserImage__ImageContainer-sc-1x4ogkw-1 izlSME">
                <div class="UserImage__Image-sc-1x4ogkw-2 lngbRu"></div>
            </div>
        </div>
        <div class="Header__Info-sc-1y135nm-1 hhASjW">
            <div class="Header__AnswerTime-sc-1y135nm-3 ioFWLq">אדם</div>
        </div>
    </div>
    <div class="WhatsappChat__Component-sc-1wqac52-0">
        <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 cWUfUj">
            <div class="WhatsappDots__Component-pks5bf-0 iNguXd" style="opacity: 0;">
                <div class="WhatsappDots__ComponentInner-pks5bf-1 kYdave">
                    <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 eMFEyG"></div>
                    <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 jAqeVd"></div>
                    <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 CPQqS"></div>
                </div>
            </div>
            <div class="WhatsappChat__Message-sc-1wqac52-4 dSUAOZ" style="opacity: 1;">
                <div class="WhatsappChat__Author-sc-1wqac52-3 acKCA">אדם</div>
                <div class="WhatsappChat__Text-sc-1wqac52-2 hOnFlx">היי, איזה רכב אתם מחפשים?</div>
            </div>
        </div>
    </div><a role="button" (click)="openWhatsApp()" title="WhatsApp"
        class="DefaultButton__DefaultButtonComponent-ulobej-0 jwwAjt">
        <span class="DefaultButton__DefaultButtonText-ulobej-1">המשך לצ׳אט</span></a>
</div>
