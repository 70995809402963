<div class="row">
  <div class="col-md-5 description-container">

    <div *ngIf="isMobile" style="padding-bottom: 10px;">
      <img class="point" (error)="getDefaultImageUrl($event)"
        style="max-height: 100%; max-width: 100%; padding-left: 5%; padding-right: 5%" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" [src]="getImageUrl(this.carInfo, 'lf')" />
    </div>

    <h1 style="line-height: 0.7;">
      <div style="display: flex;">
        <div style="float: right;">
          <span class="car-name p-0">{{title}} </span>
          <span class="car-name p-0" style="display: inline-block">{{titleYear}}</span>
        </div>
        <div>
          <span *ngIf="hasOtherYear()" [ngClass]="getYearClass(getOtherYear())"
            style="color: #000000">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <a *ngIf="hasOtherYear()" [ngClass]="getYearClass(getOtherYear())"
            [routerLink]="getYearUrl(getOtherYear())">{{getOtherYear()}}</a>
          <span class="car-status" *ngIf="carInfo?.marketingStopped"> (משווק שלא ע״י היבואן)</span><a
            [href]="search01Price()" target="_blank"><img *ngIf="isDealerSearchLoggedIn"
              src="assets/images/white-dot.png" class="point" style="height: 20px; padding-right: 10px;"></a>
        </div><br />
      </div>
      <div><span class="car-description p-0">
          {{utilsService.bodyTypeCustomValueConverter(carInfo?.bodyType)}}, {{carInfo?.engine}}
        </span></div>
      <!-- <div>
        <span *ngFor="let yearInfo of carInfo?.years; let i=index;" class="car-description"><a [ngClass]="getYearClass(getYearInfo(yearInfo)[0])" [routerLink]="getYearInfo(yearInfo)[1]">{{getYearInfo(yearInfo)[0]}}</a><span *ngIf="i < carInfo?.years.length - 1"> | </span></span>
      </div>   -->
    </h1>
    <div style="padding-top: 30px; line-height: 1.75">
      <div style="text-align: justify;">
        <span class="car-expert-opinion-title p-0">חוות דעת ביחס לרכבי
          {{utilsService.bodyTypeCustomValueConverter(carInfo?.bodyType)}} {{carInfo?.engine}}&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0"> <span class="car-expert-opinion p-0">{{carDescription}} במחיר
            {{carInfo?.priceIsAboveGroupAvg ? 'מעל' : 'מתחת'}} לממוצע.</span></span>
      </div>
      <br />
      <div style="text-align: justify">
        <span class="car-expert-opinion-title p-0">יתרונות&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0">{{getPositivevFeaturesSummary(carInfo?.positiveFeatures)}}</span>
      </div>
      <br />
      <div style="text-align: justify">
        <span class="car-expert-opinion-title p-0">חסרונות&nbsp;&nbsp;&nbsp;</span>
        <span class="car-expert-opinion p-0">{{getNegativeFeaturesSummary(carInfo?.negativeFeatures)}}</span>
      </div>
    </div>

    <ul class="list-inline d-none d-xl-block" style="padding-top: 10px;">
      <li class="list-inline-item">
        <div class="p-0 d-none d-xl-block">
          &nbsp;&nbsp;<span class="text">השוואה</span>
          <div class="Checkbox float-left" style="padding-top: 3px;">
            <input type="checkbox" (change)="checkValue($event, carInfo?.subModels[0]?.subModelId)"
              [checked]="isCheckedForComparison(carInfo?.subModels[0]?.subModelId)" />
            <div class="Checkbox-visible"></div>
          </div>
        </div>
      </li>
      <li class="list-inline-item" style="padding-right: 30px">
        <div class="p-0 d-none d-xl-inline">
          <img (click)="setFavourite($event, carInfo?.subModels[0]?.subModelId, carInfo?.subModels[0])" class="point"
            [src]="favImageSource" />
        </div>
        <span class="text" style="padding-right: 10px;">הוספה</span>
      </li>
      <li class="list-inline-item" style="padding-right: 30px">
        <div class="p-0 d-none d-xl-inline">
          <img (click)="shareOnFacebook()" class="point"
            style="padding-bottom: 5px; width: 26px; height: 26px"
            src="assets/images/svg/facebook.svg" />
          <img (click)="shareOnMail()" class="point d-block d-sm-none"
            style="padding-bottom: 5px; width: 26px; height: 26px"
            src="assets/images/svg/mail.svg" />
        </div>
        <span class="text" style="padding-right: 5px;">שיתוף</span>
      </li>
     
      
      <li class="list-inline-item" style="padding-right: 25px;">
        <!-- <div class="p-0 d-none d-xl-block" *ngIf="showSafeyLevel">
          <span class="text">הגנה מריסוק</span>
          <img *ngFor="let star of blankSafetyLevel; let i=index;" style="padding-right: 9px"
            src="assets/images/svg/star-blank.svg" />
          <img *ngFor="let star of fullSafetyLevel; let i=index;" style="padding-right: 9px"
            src="assets/images/svg/star-full.svg" />
        </div> -->
      </li>
    </ul>

  </div>
  <div class="col-12 d-block d-sm-none" style="padding-right: 15px; padding-top: 20px">
    <span class="text" style="padding-right: 12px">השוואה</span>
    <div class="Checkbox float-left" style="padding-top: 3px">
      <input type="checkbox" (change)="checkValue($event, carInfo?.subModels[0]?.subModelId)"
        [checked]="isCheckedForComparison(carInfo?.subModels[0]?.subModelId)" />
      <div class="Checkbox-visible"></div>
    </div>
    <img style="padding-right: 50px" (click)="share()" src="assets/images/svg/share.svg" />
    <span class="text" (click)="share()" style="padding-right: 12px">שיתוף</span>
    <!-- <span class="text" style="padding-right: 12px">הוספה</span>
    <img (click)="setFavourite($event, carInfo.subModels[0].subModelId, carInfo.subModels[0])"
      style="padding-right: 9px" [src]="favImageSource" /> -->
    

    <!-- <span class="text" style="padding-right: 12px" *ngIf="showSafeyLevel">הגנה מריסוק</span>
        <img *ngFor="let star of blankSafetyLevel; let i=index;" style="padding-right: 3px"
          src="assets/images/svg/star-blank.svg" />
        <img *ngFor="let star of fullSafetyLevel; let i=index;" style="padding-right: 3px"
          src="assets/images/svg/star-full.svg" /> -->


  </div>
  <div *ngIf="!isMobile"
    class="col-md-6 car-image-container d-flex align-items-center justify-content-centerd-flex align-items-center justify-content-center">
    <img #carMainImage (click)="mainImageClick()" class="point" (error)="getDefaultImageUrl($event)"
      style="max-height: 100%; max-width: 100%; padding-left: 5%; padding-right: 5%" alt="{{getImageAlt(carInfo)}}"
      title="{{getImageTitle(carInfo)}}" [src]="carImageUrl" />

  </div>
  <div class="col-md-1 d-none d-xl-block">
    <div class="=car-thumbnail-image-container"><img onError="this.src='assets/images/pixel.png';"
        (click)="setImage($event)" class="car-thumbnail-image float-right point" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" src="{{getImageUrl(carInfo, 'in')}}" /></div>
    <div class="=car-thumbnail-image-container"><img onError="this.src='assets/images/pixel.png';"
        (click)="setImage($event)" class="car-thumbnail-image float-right point" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" src="{{getImageUrl(carInfo, 'f')}}" /></div>
    <div class="=car-thumbnail-image-container"><img onError="this.src='assets/images/pixel.png';"
        (click)="setImage($event)" class="car-thumbnail-image float-right point" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" src="{{getImageUrl(carInfo, 'b')}}" /></div>
    <div class="=car-thumbnail-image-container"><img onError="this.src='assets/images/pixel.png';"
        (click)="setImage($event)" class="car-thumbnail-image float-right point" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" src="{{getImageUrl(carInfo, 'l')}}" /></div>
    <div class="=car-thumbnail-image-container"><img onError="this.src='assets/images/pixel.png';"
        (click)="setImage($event)" class="car-thumbnail-image float-right point" alt="{{getImageAlt(carInfo)}}"
        title="{{getImageTitle(carInfo)}}" src="{{getImageUrl(carInfo, 'r')}}" /></div>
  </div>

  <div class="col-md-1 d-block d-sm-none">

  </div>
</div>