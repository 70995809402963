export class LeadTicket {
    id: string;
    customerInfo: CustomerInfo;
    companyId: number;
    leadType: number;
    requestedPrice: number;
    CarFindCarVersionId: number;
    CarFindCarVersionName: string;
    dealType: number;
    comment?: string;
    timestamp: string;
    step: number;
    response: number;
    isHandled: boolean;
    isMonthlyPayment: boolean;
    paymentBalloon: number;
    maxPaymentPrice: number;
    advancePaymentPrice: number;
  }

export class CustomerInfo {
    id: number;
    phoneNumber: string;
    emailAddress: string;
    fullName: string;
    cityId: number;
    cityName: string;
}
