<div *ngIf="!isFinished">
  <!-- <div class="d-inline d-sm-none">
    <ul class="list-inline ul-wizard-menu" style="padding-top: 10px;">
      <li><span [ngClass]="setStatusMobile(1)" (click)="GoTo(1)">מחיר</span></li>
      <li><span [ngClass]="setStatusMobile(2)" (click)="GoTo(2)">קטגוריה</span></li>
      <li><span [ngClass]="setStatusMobile(3)" (click)="GoTo(3)">הטענה</span></li>
    </ul>
  </div> -->

  <div style="overflow: hidden;">
    <ul class="ul-wizard-arrows-menu">
      <li (click)="GoTo(1)" class="first-tab">
        <table style="width: 100%;">
          <tr>
            <td [ngClass]="step1HeaderClass3" style="width: 1px;"></td>
            <td [ngClass]="step1HeaderClass1" style="width: 100%;">מחיר</td>
            <td [ngClass]="step1HeaderClass2" style="width: 16px;">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </table>
      </li>
      <li (click)="GoTo(2)" style="width: 100%;">
        <table style="width: 100%;">
          <tr>
            <td [ngClass]="step2HeaderClass1" style="width: 100%;">קטגוריה</td>
            <td [ngClass]="step2HeaderClass2" style="width: 16px;">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </table>
      </li>
      <li (click)="GoTo(3)" style="width: 100%;">
        <table style="width: 100%;">
          <tr>
            <td [ngClass]="step3HeaderClass1" style="width: 100%;">הטענה</td>
            <td [ngClass]="step4HeaderClass2" style="width: 16px;">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </table>
      </li>
      <!-- <li (click)="GoTo(4)" style="width: 100%;">
        <table style="width: 100%;">
          <tr>
            <td [ngClass]="step4HeaderClass1" style="width: 100%;">מתקדם</td>
            <td [ngClass]="step4HeaderClass2" style="width: 16px;">&nbsp;&nbsp;&nbsp;&nbsp;</td>
          </tr>
        </table>
      </li> -->
    </ul>
  </div>

  <div class="wizard-container">
    <aw-wizard [awNavigationMode] navigateBackward="allow" navigateForward="allow" #wizard>
      <aw-wizard-step>
        <div>
          <div class="block d-flex align-items-center justify-content-centerd-flex align-items-center justify-content-center">
            <div class="wizard-price-container" style="width:80%; padding-top: 30px;">
              <div>
                <div class="float-left wizard-price-text wizard-price-text-right">
                  <span class="car-price-value">₪</span>{{priceValue[0].toLocaleString()}}
                </div>
                <div class="float-right wizard-price-text wizard-price-text-left">
                  <span *ngIf="priceMaxSliderLabel != 'ללא הגבלה'" class="car-price-value">₪</span>{{priceMaxSliderLabel}}
                </div>
              </div>
              <div class="slider-wrapper slider-danger slider-strips">
                <mv-slider *ngIf="showPriceSlider" (slideStop)="priceSlideStop($event)" (change)="priceSlideChange($event)"
                  class="grab d-flex align-items-center justify-content-centerd-flex align-items-center justify-content-center"
                  [range]="true" [(value)]="priceValue" step="100" [min]="minPrice" [max]="maxPrice" [tooltip]="true"
                  style="width: 100%; margin-top: 5px">
                </mv-slider>
              </div>
            </div>
          </div>
          <div>
            <ul class="list-inline ul-wizard-options">
              <li class="wizard-payment-price-padding-right">
                <div>
                  <span class="price-text">מקדמה</span>
                  <span class="point" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>&nbsp;&nbsp;▼</span> <span class="price-payment-value point">{{defaultPaymentAdvanceValue}}</span>
                  </span>
                
                  <div class="dropdown-menu dropdown-menu-right item-hover wizard-price-values-list" style="direction:rtl">
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(0)">ללא</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(10000)">10,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(15000)">15,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(20000)">20,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(25000)">25,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(30000)">30,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(35000)">35,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(40000)">40,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(45000)">45,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(50000)">50,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(55000)">55,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(60000)">60,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(65000)">65,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(70000)">70,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(75000)">75,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(80000)">80,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(85000)">85,000</div>
                    <div class="dropdown-item" (click)="setAdvancePaymentPrice(90000)">90,000</div>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <span class="price-text">בלון</span>
                  <span class="point" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>&nbsp;&nbsp;▼</span> <span class="price-payment-value point">{{defaultPaymentBalloonValue}}</span>
                  </span>
                
                  <div class="dropdown-menu dropdown-menu-right wizard-price-values-list" style="direction:rtl">
                    <div class="dropdown-item" (click)="setBalloonPaymentPrice(0)">ללא</div>
                    <div class="dropdown-item" (click)="setBalloonPaymentPrice(10)">10%</div>
                    <div class="dropdown-item" (click)="setBalloonPaymentPrice(20)">20%</div>
                    <div class="dropdown-item" (click)="setBalloonPaymentPrice(30)">30%</div>
                  </div>
                </div>
              </li>
              <li class="wizard-payment-price-padding-left">
                <div>
                  <span class="price-text">תשלום חודשי</span>
                  <span class="point" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>&nbsp;&nbsp;▼</span> <span class="price-payment-value point">{{defaultMaxPaymentValue}}</span>
                  </span>
                
                  <div class="dropdown-menu dropdown-menu-right wizard-price-values-list" style="direction:rtl">
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(0)">עד סכום</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(500)">500</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(1000)">1,000</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(1500)">1,500</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(2000)">2,000</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(2500)">2,500</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(3000)">3,000</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(3500)">3,500</div>
                    <div class="dropdown-item" (click)="setMaxPaymentPrice(4000)">4,000</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </aw-wizard-step>

      <aw-wizard-step>
        <ul class="list-inline wizard-ul-category">

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-3" loading="lazy" (click)="SelectCategoryImage('small')"
              src="assets/images/svg/wizard-hatchback.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 8%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-3" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-3', 'wizard-category-checkbox-3', null,'small')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">קטן והאצ'בק</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span class="description">
                3 או 5 דלתות 
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-3" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-3','wizard-category-checkbox-3', 'wizard-category-txtm-3', 'small')">
                קטן + האצ'בק
                <div class="description-text">3 או 5 דלתות</div>
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-4" loading="lazy" (click)="SelectCategoryImage('sedan')"
              src="assets/images/svg/wizard-sedan.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 2%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-4" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-4', 'wizard-category-checkbox-4', null,'sedan')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">סדאן משפחתי</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span class="description">
                4 דלתות
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-4" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-4','wizard-category-checkbox-4', 'wizard-category-txtm-4', 'sedan')">
                סדאן משפחתי
                <div class="description-text">4 דלתות</div>
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-5" loading="lazy" (click)="SelectCategoryImage('suv')"
              src="assets/images/svg/wizard-suv.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 1%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-5" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-5', 'wizard-category-checkbox-5', null,'suv')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">פנאי SUV</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span class="description">
                ג'יפ ג'יפון
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-5" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-5','wizard-category-checkbox-5', 'wizard-category-txtm-5', 'suv')">
                פנאי SUV
                <br />
                <br />
                <div class="description-text">ג'יפ ג'יפון</div>
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-6" loading="lazy" (click)="SelectCategoryImage('7plus')"
              src="assets/images/svg/wizard-7plus.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 1%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-6" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-6', 'wizard-category-checkbox-6', null,'7plus')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">7+ מושבים</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span class="description">
                סטיישן ואן מיני-ואן
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-6" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-6','wizard-category-checkbox-6', 'wizard-category-txtm-6', '7plus')">
                7+ מושבים
                <div class="description-text">סטיישן ואן</div>
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-2" loading="lazy" (click)="SelectCategoryImage('pickup')"
              src="assets/images/svg/wizard-van.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 1%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-2" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-2', 'wizard-category-checkbox-2', null,'pickup')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">מסחרי + טנדר</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span class="description">
                תא מטען גדול
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-2" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-2','wizard-category-checkbox-2', 'wizard-category-txtm-2', 'pickup')">
                מסחרי + טנדר
                <div class="description-text">תא מטען גדול</div>
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="wizard-category-image-container">
            <img id="wizard-category-image-1" loading="lazy" (click)="SelectCategoryImage('coupe-converible')"
              src="assets/images/svg/wizard-coupe.svg" />
            </div>
            <div class="d-none d-xl-block" style="padding-right: 8%; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-category-checkbox-1" type="checkbox"
                  (click)="SelectCategory('wizard-category-image-1', 'wizard-category-checkbox-1', null,'coupe-converible')" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 10px; width: max-content;">קופה + קבריולה</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 15px">
              <span id="wizard-category-text-1" class="description">
                גג נפתח / כיפתי
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-category-txtm-1" class="wizard-category-item-font"
                (click)="SelectCategory('wizard-category-image-1','wizard-category-checkbox-1', 'wizard-category-txtm-1', 'coupe-converible')">
                קופה + קבריולה
                <div class="description-text">נפתח / כיפתי</div>
              </span>
            </div>
          </li>

        </ul>
      </aw-wizard-step>

      <aw-wizard-step>
        <ul
          class="list-inline wizard-ul-category ul-wizard-submenu wizard-transmission-slider-container advanced-container">
          
          <li class="list-inline-item">
            <img id="wizard-category-engine-1" class="wizard-icon-engine-type" loading="lazy" (click)="SelectFueltypeImage(1)"
              src="assets/images/svg/wizard-benzin-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 30px; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-fuelType-checkbox-1" type="checkbox"
                  (click)="SelectFueltype('wizard-fuelType-image-1', 'wizard-fuelType-checkbox-1', null, 1)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 30px">בנזין</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                <!-- רק בנזין -->
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-fuelType-txtm-1" class="wizard-item-font" style="font-size: 13px;"
                (click)="SelectFueltype('wizard-fuelType-image-1','wizard-fuelType-checkbox-1', 'wizard-fuelType-txtm-1',1)">
                בנזין
                <!-- <div class="description-text">רק בנזין</div> -->
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <img id="wizard-category-engine-5" class="wizard-icon-engine-type" loading="lazy" (click)="SelectFueltypeImage(5)"
              src="assets/images/svg/wizard-electric-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 25px; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-fuelType-checkbox-5" type="checkbox"
                  (click)="SelectFueltype('wizard-fuelType-image-5','wizard-fuelType-image-5', null,5)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 25px">חשמל</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                <!-- רק חשמל -->
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-fuelType-txtm-5" class="wizard-item-font" style="font-size: 13px;"
                (click)="SelectFueltype('wizard-fuelType-image-5','wizard-fuelType-checkbox-5', 'wizard-fuelType-txtm-5',5)">
                חשמל
                <!-- <div class="description-text">רק חשמל</div> -->
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <img id="wizard-category-engine-3" class="wizard-icon-engine-type" loading="lazy" (click)="SelectFueltypeImage(3)"
              src="assets/images/svg/wizard-hybrid-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 20px; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-fuelType-checkbox-3" type="checkbox"
                  (click)="SelectFueltype('wizard-fuelType-image-3','wizard-fuelType-checkbox-3', null,3)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 25px; width: max-content;">היברידי</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                בלי שקע חשמל
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-fuelType-txtm-3" class="wizard-item-font" style="font-size: 13px; width: max-content;"
                (click)="SelectFueltype('wizard-fuelType-image-3','wizard-fuelType-checkbox-3', 'wizard-fuelType-txtm-3',3)">
                היברידי
                <div class="description-text">בלי שקע חשמל</div>
              </span>
            </div>
          </li>


          <li class="list-inline-item">
            <img id="wizard-category-engine-4" class="wizard-icon-engine-plugin-type" loading="lazy" (click)="SelectFueltypeImage(4)"
              src="assets/images/svg/wizard-plugin-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 10px; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-fuelType-checkbox-4" type="checkbox"
                  (click)="SelectFueltype('wizard-fuelType-image-4','wizard-fuelType-image-4', null,4)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 25px; width: max-content;">בנזין (פלאגאין)</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px;">
              <span class="description">
                עם שקע חשמל
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-fuelType-txtm-4" class="wizard-item-font" style="font-size: 13px;"
                (click)="SelectFueltype('wizard-fuelType-image-4','wizard-fuelType-checkbox-4', 'wizard-fuelType-txtm-4',4)">
                עם שקע חשמל
                <!-- <div class="description-text">עם הטענת חשמל</div> -->
              </span>
            </div>
          </li>

         
          <li class="list-inline-item">
            <img id="wizard-category-engine-2" class="wizard-icon-engine-type" loading="lazy" (click)="SelectFueltypeImage(2)"
              src="assets/images/svg/wizard-diesel-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 40px; padding-top: 20px">
              <div class="Checkbox">
                <input id="wizard-fuelType-checkbox-2" type="checkbox"
                  (click)="SelectFueltype('wizard-fuelType-image-2','wizard-fuelType-checkbox-2', null,2)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 25px">דיזל</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                עם / בלי חשמל
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-fuelType-txtm-2" class="wizard-item-font" style="font-size: 13px;"
                (click)="SelectFueltype('wizard-fuelType-image-2','wizard-fuelType-checkbox-2', 'wizard-fuelType-txtm-2',2)">
                דיזל
                <div class="description-text">עם / בלי חשמל</div>
              </span>
            </div>
          </li>
        </ul>
      </aw-wizard-step>

      <aw-wizard-step>
        <ul
          class="list-inline wizard-ul-category ul-wizard-submenu wizard-transmission-slider-container advanced-container">

          <li class="list-inline-item">
            <img id="wizard-advanced-image-2" loading="lazy" class="wizard-transmission-image" (click)="SelectAdvancedOptionImage(1)"
              src="assets/images/wizard-deals-icon.png" />
            <div class="d-none d-xl-block" style="padding-right: 10%; padding-top: 20px;">
              <div class="Checkbox">
                <input id="wizard-advanced-checkbox-2" type="checkbox"
                  (click)="SelectDealtype('wizard-advanced-image-2','wizard-advanced-checkbox-2', null,1)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 15px; width: max-content;">רק מבצעים</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                מבצעי יבואן ואפס ק״מ
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-advanced-txtm-2" class="wizard-item-font" style="padding-top: 10px; width: max-content;"
                (click)="SelectDealtype('wizard-advanced-image-2','wizard-advanced-checkbox-2', null,1)">
                רק מבצעים
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <img id="wizard-advanced-image-4" loading="lazy" class="wizard-manual-drivetrain-image"
              (click)="SelectAdvancedOptionImage(4)" src="assets/images/svg/wizard-manual-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 20%; padding-top: 20px;">
              <div class="Checkbox">
                <input id="wizard-advanced-checkbox-4" type="checkbox"
                  (click)="SelectTransmissiontype('wizard-advanced-image-4','wizard-advanced-checkbox-4', null,1)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 15px; width: max-content;">רק ידני</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                תיבת הילוכים ידנית
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-advanced-txtm-4" class="wizard-item-font" style="padding-top: 13px;"
                (click)="SelectTransmissiontype('wizard-advanced-image-4','wizard-advanced-checkbox-4', null,1)">
                רק ידני
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <img id="wizard-advanced-image-1" loading="lazy" class="wizard-transmission-image" (click)="SelectAdvancedOptionImage(2)"
              src="assets/images/svg/wizard-4on4-icon.svg" />
            <div class="d-none d-xl-block" style="padding-top: 20px; padding-right: 20%; ">
              <div class="Checkbox">
                <input id="wizard-advanced-checkbox-1" type="checkbox"
                  (click)="SelectTransmissiontype('wizard-advanced-image-1','wizard-advanced-checkbox-1', null,4)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 15px; width: max-content;">רק 4x4</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                הנעה כפולה
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-advanced-txtm-1" class="wizard-item-font" style="padding-top: 10px;"
                (click)="SelectTransmissiontype('wizard-advanced-image-1','wizard-advanced-checkbox-1', null,4)">
                רק 4x4
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <img id="wizard-advanced-image-3" loading="lazy" class="wizard-seats3p-image" (click)="SelectAdvancedOptionImage(3)"
              src="assets/images/svg/wizard-seat3plus-icon.svg" />
            <div class="d-none d-xl-block" style="padding-right: 10%; padding-top: 20px;">
              <div class="Checkbox">
                <input id="wizard-advanced-checkbox-3" type="checkbox"
                  (click)="SelectSeats('wizard-advanced-image-3','wizard-advanced-checkbox-3', null,1)" />
                <div class="Checkbox-visible"></div>
                <span class="wizard-item-font" style="padding-right: 15px; width: max-content;">3 שורות ויותר</span>
              </div>
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span class="description">
                סטיישן, מיניואן, ואן...
              </span>
            </div>
            <div class="d-block d-sm-none">
              <span id="wizard-advanced-txtm-3" class="wizard-item-font" style="padding-top: 10px;"
                (click)="SelectSeats('wizard-advanced-image-3','wizard-advanced-checkbox-3', null,1)">
                3+ שורות
              </span>
            </div>
          </li>

          <li class="list-inline-item">
            <div class="d-inline d-sm-none" style="padding-right: 10px;">
              <button (click)="wizardIsFinished()" type="button"
                style="width: 40px;height: 25px;  border: solid 1px #ff2800; border-radius: 6px;font-size: 12px; margin-top: 37px;">
                סיום
              </button>
            </div>
            <div class="d-none d-xl-block" style="padding-right: 10%; padding-top: 20px;">
            </div>
            <div class="d-none d-xl-block" style="padding-top: 16px">
              <span style="padding-right: 5px;">
                <button (click)="wizardIsFinished()" type="button"
                  style="width: 70px;height: 36px;  border: solid 1px #ff2800; border-radius: 6px;font-size: 14px;">
                  סיום
                </button>
              </span>
            </div>
            <div class="d-block d-sm-none">

            </div>
          </li>
        </ul>
      </aw-wizard-step>
    </aw-wizard>
  </div>
</div>

<div *ngIf="isFinished" class="d-flex align-items-center justify-content-center finish-info">
  <div>
    <div class="d-none d-xl-block finish-text">
      <span> נמצאו {{resultCount}} דגמים שמתאימים לדרישותיך. ליעוץ חינם ממומחה רכב</span>
      <br />
      <span>התקשרו עכשיו <a (click)="callUs()" href="tel:035245241">03-524-5241</a> או בחרו אחת מהאפשרויות הבאות:</span>
    </div>
    <div class="d-block d-sm-none finish-text">
      <span> נמצאו {{resultCount}} דגמים שמתאימים לדרישותיך.</span>
      <br />
      <span class="d-inline d-sm-none finish-text">
        ליעוץ חינם ממומחה רכב:
      </span>
    </div>
    <div class="d-none d-xl-block">
      <ul class="list-inline ul-finish-action-list">
        <li class="list-inline-item">
          <button (click)="contactUs($event, 'צרו איתי קשר')" type="button"
            class="wizard-button wizard-advanced-button1 point">
            צרו איתי קשר
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="setTestdrive($event, 'תאמו לי נסיעת מבחן')" type="button"
            class="wizard-button wizard-advanced-button2 point">
            <span class="d-none d-xl-block">
              תאמו לי נסיעת מבחן
            </span>
            <span class="d-block d-sm-none">
              תאמו לי
              <br />
              נסיעת מבחן
            </span>
          </button>
        </li>
        <li class="list-inline-item">
          <button (click)="newSearch()" type="button" class="wizard-button wizard-advanced-button3 point">
            חיפוש חדש
          </button>
        </li>
      </ul>
    </div>
    <div class="d-inline d-sm-none">
      <br />
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 p-0">
            <button type="button" class="header-button point" style="border-radius: 6px;" (click)="openDialer()">
              <span>
                התקשרו עכשיו
              </span>
              <img loading="lazy" src="assets/images/svg/ic-call-24-px.svg" />
            </button>
          </div>
          <div class="col-6">
            <button (click)="contactUs($event, 'צרו איתי קשר')" type="button" class="header-button point"
              style="border-radius: 6px;">
              צרו איתי קשר
            </button>
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-6 p-0">
            <button (click)="setTestdrive($event, 'תאמו לי נסיעת מבחן')" type="button" class="header-button point"
              style="border-radius: 6px;">
              <span class="d-none d-xl-block">
                תאמו לי נסיעת מבחן
              </span>
              <span class="d-block d-sm-none">
                נסיעת מבחן
              </span>
            </button>
          </div>
          <div class="col-6">
            <button (click)="newSearch()" type="button" class="header-button point" style="border-radius: 6px;">
              חיפוש חדש
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>