<app-header></app-header>

<div id="wrapper" style="text-align: center;">
    <app-sidebar-menu></app-sidebar-menu>

    <div id="page-content-wrapper">

        <div class="container-fluid view-container">
            <div class="row data-container">
                <div class="col-md-12 col-12">
                    <div class="rows">
                        <div>
                            <div style="text-align: right">
                                <div style="width: max-content;">
                                    <div style="text-align: right;">
                                        <h1>
                                            חדשות רכב {{now}}
                                        </h1>
                                    </div>
                                </div>
                            </div>

                            <ul>
                                <li *ngFor="let item of newsItems; let i=index;">
                                    <a target="_blank" [href]="item.url">{{item.title}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <span style="padding-top: 150px"></span>
                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>

</div>