import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { CarGroupInfo } from '../models/CarGroupInfo';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class ComparisonService {
    carIds: number[] = [];
    carGroupInfos: CarGroupInfo[];

    constructor(
        public httpClient: HttpService
        , public utilsService: UtilsService
        , @Inject(PLATFORM_ID) private platformId: Object) {
        this.carGroupInfos = [];
    }

    GetCarCount(): number {
        this.restore();
        return this.carIds.length;
    }

    save() {
        this.utilsService.setSessionValue('compare-car-ids', this.carIds.join(','));
    }

    restore() {
        const data = this.utilsService.getSessionValue('compare-car-ids');
        if (data !== '' && data !== 'NaN'&& data !== null) {
            this.carIds = data.split(',').map((item) => {
                return parseInt(item, 10);
            });
        }
    }

    AddCar(id: number): void {
        if (!this.IsChecked(id) && id > 0) {
            this.carIds.push(id);
            this.save();
        }
    }

    GetCars(): number[] {
        return this.carIds;
    }

    RemoveCar(id: number): void {
        this.carIds.splice(this.carIds.indexOf(id), 1);
        this.save();
    }

    Clear(): void {
        this.carIds = [];
        this.save();
    }

    getData(): CarGroupInfo[] {
        return this.carGroupInfos;
    }

    getModelNames(): string {
        const data: string[] = [];
        this.carGroupInfos.forEach(car => {
            data.push(car.name);
        });

        const result = data.join(', ');
        return result;
    }

    comapre(): Observable<CarGroupInfo[]> {
        return this.httpClient.compare(this.carIds).pipe(
            map(cars => this.carGroupInfos = cars)
        );
    }

    getCompareUrl() {
        return this.carIds.join('-');
    }

    IsChecked(carId: number): boolean {
        return (this.carIds.indexOf(carId) > -1);
    }
}