<div *ngIf="showView" class="col-12">
    <div class="row header-row sticky-car-list">
        <div class="col-md-3 p-0 my-auto d-none d-xl-block">
            <span *ngIf="isResultFiltered" (click)="ResetResult()"
                class="car-comparison-header point">&#x2715;&nbsp;&nbsp;</span>
            <span (click)="ResetResult()" class="car-comparison-header point">{{resultLabel}}</span>
        </div>
        <a [routerLink]="compareCars()" class="col-md-3 my-auto d-none d-xl-block item-hover">
            <span *ngIf="comparisonService.GetCarCount() === 0" class="car-comparison-header point" style="color: #ff2800">
                {{comparisonTitle}}
            </span>

            <button *ngIf="comparisonService.GetCarCount() > 0" type="button" class="header-button more-button point"
                style="border-radius: 6px; height: 26px; width: 130px;">
                <span class="car-comparison-header point">
                    {{comparisonTitle}} <span *ngIf="comparisonService.GetCarCount() > 0">(<span
                            class="text-style-1">{{GetComparisonCarCount()}}</span>)</span>
                </span>
            </button>
        </a>
        <div id="header-comparison" class="col-md-2 col-6 my-auto p-0">
            <div class="dropdown item-hover point">
                <div id="sortDropDown" *ngIf="utilsService.IsHomePage()" class="dropdown-menu" style="direction:rtl"
                    (mouseover)="showSortMenu($event)" (mouseleave)="closeSortPopup()">
                    <a class="dropdown-item" (click)="Sort('height', $event)">גובה</a>
                    <a class="dropdown-item" (click)="Sort('bhp', $event)">הספק מרבי</a>
                    <a class="dropdown-item" (click)="Sort('pricezerokm', $event)">מחיר עסקה</a>
                    <a class="dropdown-item" (click)="Sort('price', $event)">מחיר יבואן</a>
                    <a class="dropdown-item" (click)="Sort('weight', $event)">משקל</a>
                    <a class="dropdown-item" (click)="Sort('safetyScore', $event)">ניקוד מבחן ריסוק</a>
                    <a class="dropdown-item" (click)="Sort('enginecapacity', $event)">נפח מנוע</a>
                    <a class="dropdown-item" (click)="Sort('safetyEquipmentLevel', $event)">רמת אבזור בטיחותי</a>
                    <a class="dropdown-item" (click)="Sort('name', $event)">שם דגם</a>
                    <a class="dropdown-item" (click)="Sort('year', $event)">שנת השקה</a>
                    <a class="dropdown-item" (click)="Sort('monthlypayment', $event)">תשלום חודשי</a>
                </div>

                <span *ngIf="utilsService.IsHomePage()" (click)="showSortMenu($event)"
                    (mouseover)="showSortMenu($event)" (mouseleave)="closeSortPopup()"
                    class="car-comparison-header sort-header-title">{{sortByLabel}}</span>
                <span *ngIf="utilsService.IsHomePage()"
                    class="car-comparison-count d-inline d-sm-none">&nbsp;({{catalogService.GetTotalCarCount()}})</span>
                <span *ngIf="isSorted" class="point" [innerHTML]="sortImageDirection" (click)="refreshSort()"></span>
            </div>
        </div>
        <div class="col-md-2 col-3 my-auto p-0 item-hover">
            <span (click)="SortByMonthlyPayment()" class="car-comparison-header baseprice-header-title point">
                {{fromDealerText}}
            </span>

            <span *ngIf="isFilteredByMonthlyPayment" (click)="SortByMonthlyPayment()" class="point"
                [innerHTML]="monthlyPaymentSortImageDirection"></span>
        </div>
        <div class="col-md-2 col-3 my-auto p-0 item-hover">
            <div class="dealer-price-column">
                <span (click)="SortByBasePrice()" class="car-comparison-header zerokmprice-header-title point"
                    data-toggle="popover" data-placement="top"
                    data-content="רכב חדש שלקוח קונה מחברת ליסינג נקרא ''רכב 0 ק''מ'' מאחר ולרכב אין קילומטראז. היתרון בעסקת 0 ק''מ (עם או בלי מימון): רכישת רכב חדש עם אחריות מהיבואן, במחיר הזול בהרבה ביחס לרכישתו ישירות מהיבואן.">{{fromZero0KmText}}</span>

                <span *ngIf="isFilteredByBasePrice" (click)="SortByBasePrice()" class="point"
                    [innerHTML]="basePriceSortImageDirection"></span>
            </div>

        </div>
    </div>
    <div class="d-none d-xl-block"></div>
    <div class="row car-row-item car-item-hover" *ngFor="let car of carList; let i=index;">
        <div class="col-md-3 d-none d-xl-block p-0 point" (click)="navigateToCarPage(i)"
            style="height: 145px;padding-top: 30px;" [attr.cf-data]="car.configurationId">
            <a [routerLink]="getConfigurationPageUrl(i)">
                <img *ngIf="(car.hasMainImage); else showVideoImage"
                    onload="this.className='car-model-image point car-model-image-fade'"
                    [loading]="getImageLoadingState(i)" src="{{getImageUrl(car)}}" alt="{{getImageAlt(car)}}"
                    title="{{getImageTitle(car)}}" class="car-model-image point car-model-image-fade-start"
                    style="padding-top: 23px;padding-bottom: 29px;" />

                <ng-template #showVideoImage>
                    <div class="ytImgWrapper">
                        <div class="ytImgThumbBox"
                            *ngIf="(!car.hasMainImage && getVideoThumbnail(car.configurationVideoUrl) != ''); else showDefaultImage"
                            id="videoWrapper" style="width: 100%; height: 223px;">

                            <img class="ytImgThumbImg" frameBorder="0" style="border-radius: 6px; height: 65%;;"
                                [src]="getVideoThumbnail(car.configurationVideoUrl)"
                                [loading[]="getImageLoadingState(i)" />

                            <div id="videoOverlay"></div>
                        </div>
                    </div>

                    <ng-template #showDefaultImage>
                        <div style="width: 100%; height: 223px;">

                            <img frameBorder="0" style="border-radius: 6px; width: 80%; padding-top: 25px;"
                                [src]="getVideoThumbnail(car.configurationVideoUrl)"
                                (error)="getDefaultImageUrl($event,car.bodyType)" />

                            <div id="videoOverlay"></div>
                        </div>
                    </ng-template>
                </ng-template>
            </a>

        </div>
        <div class="col-md-3 col-6 carname-content">
            <div>
                <div class="Checkbox float-left">
                    <input [checked]="isCheckedForComparison(car.id)" type="checkbox"
                        (change)="checkValue($event, car.id)" />
                    <div class="Checkbox-visible"></div>
                </div>
                <div class="car-name-container point" (click)="navigateToCarPage(i)">
                    <a [routerLink]="getConfigurationPageUrl(i)" class="car-name point"
                        (click)="navigateToCarPage(i)">{{getCarName(i)}}
                        <span *ngIf="isMobile && car.showDoorsInfo" class="carNameDesc">
                            <span style="padding-top: 8px;">{{car.doors}} דלתות</span>
                        </span>
                    </a>
                    <span *ngIf="car.engSubModelName !== null && isHomePage" class="carNameDesc d-none d-xl-block"
                        style="padding-right: 25px; margin-top: 9px;">
                        <span style="color: #ff2800;">{{getCarVersionName(i)}}<br /></span>
                    </span>
                    <span class="carNameDesc d-none d-xl-block" style="padding-right: 25px; margin-top: 9px;">
                        <span style="padding-top: 8px;">{{utilsService.bodyTypeCustomValueConverter(car.bodyType)}}, {{car.engineType}}{{getDoorsText(car.showDoorsInfo,car.doors)}}</span>
                    </span>
                </div>
                <div class="d-block d-sm-none point" (click)="navigateToCarPage(i)">

                    <img *ngIf="(car.hasMainImage); else showMobileVideoImage"
                        onload="this.className='car-model-image car-model-image-fade'"
                        [loading]="getImageLoadingState(i)" src="{{getImageUrl(car)}}"
                        (error)="getDefaultImageUrl($event,car.bodyType)" alt="{{getImageAlt(car)}}"
                        title="{{getImageTitle(car)}}" class="car-model-image car-model-image-fade-start"
                        style="padding: 5%;" (click)="navigateToCarPage(i)" />

                    <ng-template #showMobileVideoImage>
                        <div *ngIf="(!car.hasMainImage && getVideoThumbnail(car.configurationVideoUrl) != ''); else showMobileDefaultImage"
                            id="videoWrapper" style="height: 150px; padding-top: 15px;">
                            <img frameBorder="0" style="border-radius: 6px; height: 65%;"
                                [src]="getVideoThumbnail(car.configurationVideoUrl)" [loading]="getImageLoadingState(i)"
                                (error)="getDefaultImageUrl($event,car.bodyType)" />

                            <div id="videoOverlay"></div>
                        </div>

                        <ng-template #showMobileDefaultImage>
                            <div style="width: 100%; height: 150px; padding-top: 15px;">
                                <img frameBorder="0" style="width: 80%; padding-top: 5px;"
                                    [src]="getVideoThumbnail(car.configurationVideoUrl)"
                                    (error)="getDefaultImageUrl($event,car.bodyType)" />

                                <div id="videoOverlay"></div>
                            </div>
                        </ng-template>
                    </ng-template>

                </div>
            </div>
            <div>
                <div [ngClass]="toggleFavoriteStyle(car.id)" (click)="setFavorite(car)"
                    style="position:absolute; bottom:33px; right: 14px">
                </div>
            </div>
            <div *ngIf="isDealerSearchLoggedIn && !isMobile" class="point"
                style="position:absolute; bottom: 0; bottom:33px; right: 40px;">
                <a target="_blank" [href]="search01Price(i)">
                    <img src="assets/images/white-dot.png" style="height: 20px;">
                </a>
            </div>
        </div>
        <div class="col-md-2 d-none d-xl-block p-0 point" (click)="navigateToCarPage(i)" style="height: 136px;">
            <ul class="list-group ul-features-info" style="padding-top: 33px; line-height: 1px">
                <li style="padding-bottom: 21px">
                    <span>{{getDrivingRangeText(car.bhp)}}</span>
                </li>
                <li style="padding-bottom: 21px">
                    <span>דרגת זיהום: {{getCarPropertyValue(car.degreeOfPollution)}}</span>
                </li>
                <li style="padding-bottom: 21px">
                    <span>רמת בטיחות: {{getCarPropertyValue(car.safetyEquipmentLevel)}}</span>
                </li>

            </ul>

        </div>
        <div class="col-md-2 col-6 point" style="height: 136px;" (click)="navigateToCarPage(i)">
            <div class="row baseprice-content">
                <div class="col-md-12 col-6 p-0 point">
                    <ul class="list-group ul-features baseprice-content-ul dealer-price-container">
                        <li>
                            <a [routerLink]="getConfigurationPageUrl(i)"
                                class="car-price-main">{{car.monthlyPayment.toLocaleString()}} ₪</a>
                        </li>

                        <li *ngIf="isMobile && !isMainDealType" style="padding-top: 5px;">
                            <span class="car-price">{{getCarVersionName(i)}}</span>
                        </li>
                        <li *ngIf="isMobile">
                            <span class="car-price">{{car.bodyType}}, {{car.engineType}}</span>
                        </li>
                        <li *ngIf="isMobile && isMainDealType">
                            <span class="car-price">דרגת זיהום {{getCarPropertyValue(car.degreeOfPollution)}}</span>
                        </li>
                        <li *ngIf="isMobile">
                            <span class="car-price">ציון בטיחות
                                {{getCarPropertyValue(car.safetyEquipmentLevel)}}</span>
                        </li>

                    </ul>
                </div>
                <div class="col-6 d-block d-sm-none col-md-12 p-0 point ng-star-inserted">
                    <div class="dealer-price-column" (click)="navigateToCarPage(i)">
                        <ul class="list-group ul-features zerokm-price-container">
                            <li *ngIf="car.dealerDiscountPrice > 0" class="dealer-discount-price">
                                <span class="dealer-discount-price" style="text-decoration: line-through;">
                                    {{hideBasePriceDiscountLabel(car.basePrice,car.dealerDiscountPrice)}}
                                </span>
                            </li>
                            <li *ngIf="car.dealerDiscountPrice > 0">
                                <a [routerLink]="getConfigurationPageUrl(i)"
                                    class="car-price-main">{{car.dealerDiscountPrice.toLocaleString()}} ₪</a>
                            </li>
                            <li *ngIf="car.dealerDiscountPrice == 0">
                                <a [routerLink]="getConfigurationPageUrl(i)"
                                    class="car-price-main">{{car.basePrice.toLocaleString()}} ₪</a>
                            </li>
                            <li *ngIf="car.zeroKMPricesCount > 1 || car.parallelDealersCount > 0 || car.officialDealersCount > 0" style="padding-top: 15px;">
                                <span class="car-price" *ngIf="car.officialDealersCount > 0"> {{car.officialDealersCount}} סוכנויות ישיר </span>
                                <span class="car-price" *ngIf="car.parallelDealersCount > 0"> {{car.parallelDealersCount}} ייבוא מקביל </span>
                            </li>
                            <!-- <li *ngIf="car.zeroKMPricesCount == 0" style="padding-top: 15px;">
                                <span class="car-price"> חדש 0 ק״מ </span>
                            </li> -->
                            <li *ngIf="!car.hasSpecialDeals && car.zeroKMPricesCount > 0 && car.lowestZeroKMPrice > 0">
                                <a [routerLink]="getConfigurationPageUrl(i)"
                                    class="car-price-main">{{car.lowestZeroKMPrice.toLocaleString()}} ₪</a>
                            </li>
                            <li *ngIf="car.hasSpecialDeals">
                                <a [routerLink]="getConfigurationPageUrl(i)" class="car-price-main"
                                    style="line-height: normal;">מבצע מיוחד</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ng-template #noLowestZeroKMPriceMobileTemplate>
                    <div *ngIf="car.hasSpecialDeals && car.lowestZeroKMPrice == 0" class="d-block d-sm-none"
                        (click)="navigateToCarPage(i)">
                        מבצע מיוחד</div>
                </ng-template>
            </div>
            <div class="row" style="padding-top: 13px">

                <div class="col-2 d-block d-sm-none p-0">



                    <div class="dropdown-menu" style="background-color: #eeeeee"
                        aria-labelledby="favoriteDropdownMenuButton">
                        <span class="dropdown-item context-dropdown-item"
                            (click)="setFavoriteMobile($event, car)">{{favoritesActionContextMenuText}}</span>
                        <span class="dropdown-item context-dropdown-item" (click)="showFavoritesList($event)">הצג</span>
                    </div>
                </div>
                <div class="col-2 d-block d-sm-none p-0" style="z-index: 1726;">
                    <a *ngIf="isDealerSearchLoggedIn && isMobile" style="padding-top: 0;" target="_blank"
                        [href]="search01Price(i)">
                        <img src="assets/images/white-dot.png" style="height: 20px;">
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-2 d-none d-xl-block p-0 point" style="height: 136px;" (click)="navigateToCarPage(i)">
            <ul class="list-group ul-features" style="padding-right: 20px; padding-top: 21px;">
                <li *ngIf="car.dealerDiscountPrice > 0" class="dealer-discount-price">
                    <span style="text-decoration: line-through; font-size: 17px; color: #757575;">
                        {{hideBasePriceDiscountLabel(car.basePrice,car.dealerDiscountPrice)}}
                    </span>
                </li>
                <li *ngIf="car.dealerDiscountPrice > 0">
                    <a [routerLink]="getConfigurationPageUrl(i)"
                        class="car-price-main">{{car.dealerDiscountPrice.toLocaleString()}} ₪</a>
                </li>
                <li *ngIf="car.dealerDiscountPrice == 0">
                    <a [routerLink]="getConfigurationPageUrl(i)"
                        class="car-price-main">{{car.basePrice.toLocaleString()}} ₪</a>
                </li>
                <li *ngIf="car.zeroKMPricesCount > 1 || car.parallelDealersCount > 0 || car.officialDealersCount > 0" style="padding-top: 13px">
                    <span *ngIf="car.officialDealersCount > 0" class="car-price" style="color: #9e9e9e;"> {{car.officialDealersCount}} סוכנויות ישיר </span>
                    <span *ngIf="car.parallelDealersCount > 0" class="car-price" style="color: #9e9e9e;"> {{car.parallelDealersCount}} ייבוא מקביל </span>
                </li>
                <!-- <li *ngIf="car.zeroKMPricesCount == 1" style="padding-top: 13px">
                    <span class="car-price"> חדש 0 ק״מ </span>
                </li> -->
                <li *ngIf="!car.hasSpecialDeals && car.zeroKMPricesCount > 0 && car.lowestZeroKMPrice > 0"
                    style="padding-top: 1px">
                    <a [routerLink]="getConfigurationPageUrl(i)"
                        class="car-price-main">{{car.lowestZeroKMPrice.toLocaleString()}} ₪</a>
                </li>
                <li *ngIf="car.hasSpecialDeals">
                    <a [routerLink]="getConfigurationPageUrl(i)" class="car-price-main"
                        style="line-height: normal;">מבצע מיוחד</a>
                </li>
            </ul>
        </div>
        <ng-template #noDealerDiscountPriceTemplate>
            <ul class="list-group ul-features point" (click)="navigateToCarPage(i)" style="padding-right: 10px;">
                <li>
                    <a [routerLink]="getConfigurationPageUrl(i)"
                        class="car-price-main">{{car.basePrice.toLocaleString()}}
                    </a>
                </li>
                <li>
                    <a [routerLink]="getConfigurationPageUrl(i)" class="additional-dealer-deals">לחצו למבצעי יבואן
                    </a>
                </li>

                <li style="padding-top: 21px">
                    <span class="car-price"></span>
                </li>
            </ul>
        </ng-template>
    </div>
</div>
<div *ngIf="showView" id="more-button-container" class="row justify-content-center more-cars-button">
    <button type="button" *ngIf="isMoreCarsButtonVisible" class="header-button more-button point"
        style="margin-right: 20px; border-radius: 6px;">
        <a [href]="getMoreUrl()" (click)="getMore()">הצג דגמים נוספים</a>
    </button>
</div>


<div class="modal fade" id="noResultsInSearchAlert" style="z-index: 888888" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body no-search-results-container">
                <div>
                    <div style="font-size: 20px;">לא נמצאו תוצאות עבור המילה "{{mainSearchKeyword}}"</div>
                    <div style="padding-top: 15px; text-align: justify;">
                        לפיכך החיפוש יבוטל. נסו לאתר את הרכב הרצוי באמצעות:

                        <div class="text" style="padding-top: 15px;">
                            <p>
                                <span class="feature-item">1.&nbsp; חיפוש שם היצרן או הדגם בעברית או
                                    באנגלית<br><br></span>
                                <span class="feature-item">2.&nbsp; או לחילופין על-ידי שימוש במנגנון
                                    הסינון<br><br></span>
                                <span class="feature-item">3.&nbsp; או התקשרו בחינם למומחי הרכב שלנו
                                    03-524-5241</span>
                            <p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button" class="header-button point" style="border-radius: 6px; width: 70px;"
                            (click)="resetAllFilterFromDialog()">הבנתי</button>
                        <button type="button" class="header-button point d-block d-sm-none"
                            style="border-radius: 6px; width: 170px; margin-right: 10px;" (click)="openDialer()">שיחה עם
                            מומחה רכב</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="noResultsInFiltersAlert" style="z-index: 888888" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body no-filter-results-container">
                <div>
                    <div style="font-size: 20px;">לא נמצאו תוצאות עבור הסינון שבצעת</div>
                    <div style="padding-top: 15px; text-align: justify;">
                        לפיכך יבוטל הסינון האחרון שביצעת.<br />
                        נסו לאתר את הרכב הרצוי באמצעות:

                        <div class="text" style="padding-top: 15px;">
                            <p>
                                <span class="feature-item">1.&nbsp; הסרת חלק ממאפייני הסינון שבחרתם<br><br></span>
                                <span class="feature-item">2.&nbsp; חיפוש שם היצרן או הדגם בעברית או
                                    באנגלית<br><br></span>
                                <span class="feature-item">3.&nbsp; או התקשרו בחינם למומחי הרכב שלנו
                                    03-524-5241</span>
                            <p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button" class="header-button point" style="border-radius: 6px; width: 70px;"
                            (click)="cancelLastFilter()">הבנתי</button>
                        <button type="button" class="header-button point d-block d-sm-none"
                            style="border-radius: 6px; width: 170px; margin-right: 10px;" (click)="openDialer()">שיחה עם
                            מומחה רכב</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="favInfoAlert" style="z-index: 888888" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body no-filter-results-container">
                <div>
                    <div style="font-size: 20px;">לצפייה בכל הרכבים שאהבתם - לחצו על כפתור המועדפים בראש העמוד</div>

                    <div class="d-flex align-items-center justify-content-center">
                        <button type="button" class="header-button point" style="border-radius: 6px; width: 70px;"
                            (click)="confirmFavInfoAlert()">הבנתי</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>