<app-header></app-header>

<div id="wrapper">
    <app-sidebar-menu></app-sidebar-menu>

    <div id="page-content-wrapper">
        <div class="container-fluid page-content-wrapper">
            <div class="row welcome-container d-none d-xl-block">
                <div class="col-md-8 p-0">
                    <app-welcome-dialog *ngIf="!isMobile"></app-welcome-dialog>
                </div>
            </div>
            <div *ngIf="(showViewTitle || isCatalogFiltered) && !isMobile" class="row d-none d-xl-block">
                <app-view-title [title]="viewTitle" [items]="generateTitle()"></app-view-title>
            </div>
            <div class="row wizard-wrapper">
                <div class="col-md-8 col-12 p-0">
                    <div class="d-block d-sm-none">
                        <app-welcome-dialog *ngIf="isMobile"></app-welcome-dialog>
                    </div>

                    <app-car-wizard></app-car-wizard>
                </div>
                <div class="col-md-4 d-none d-xl-block" style="padding-left: 0">
                    <app-car-news-feed></app-car-news-feed>
                </div>
            </div>

            <div *ngIf="isCatalogFiltered" class="row filter-tags">
                <app-view-tags [items]="generateTags()" [onTagRemove]="onFilterTagRemove.bind(this)"></app-view-tags>
            </div>

            <div class="row car-list-wrapper">
                <div class="col-md-2 d-none d-xl-block p-0">
                    <app-side-menu [isMobileMode]="false"></app-side-menu>
                </div>

                <div class="col-md-10 col-12">
                    <app-car-list></app-car-list>

                    <div class="d-block d-sm-none" style="padding-top: 25px;">
                        <span style="font-size: 16px;">כניסה לעמוד יצרן</span>
                        <app-manufacturer-image-list></app-manufacturer-image-list>
                    </div>

                    <div *ngIf="(showViewTitle || isCatalogFiltered) && isMobile" class="row d-block d-sm-none"
                        style="margin-left: 0; padding-top: 25px; padding-right: 10px">
                        <app-view-title [title]="viewTitle" [items]="generateTitle()"></app-view-title>
                    </div>

                    <div *ngIf="showDealerImages" class="container-fluid">
                        <span *ngIf="!isMobile"
                            class="d-flex align-items-center justify-content-center companies-list-title">
                            קארפיינד משווה למעלה ממאה סוכנויות למציאת המחיר הזול בישראל
                        </span>
                        <div class="row companies-list-item companies-list">
                            <div class="col-md-3 col-4 p-0 company-logo-1">
                                <img loading="lazy" alt="Avis" title="Avis" src="assets/images/avis.png" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-2">
                                <img loading="lazy" alt="ר.צ ליסינג" title="ר.צ ליסינג"
                                    src="assets/images/rz-leasing.png" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-3">
                                <img loading="lazy" alt="אוטופלוס" title="אוטופלוס" src="assets/images/autoplus.png" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-4">
                                <img loading="lazy" alt="אוטו סנטר" title="אוטו סנטר"
                                    src="assets/images/autocenter.png" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-5">
                                <img loading="lazy" alt="אלבר" title="אלבר" src="assets/images/elbar.png" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-6">
                                <img loading="lazy" alt="גלי ליסינג" title="גלי ליסינג"
                                    src="assets/images/galileasing.gif" />
                            </div>
                            <div class="col-md-3 col-4 p-0 company-logo-7">
                                <img loading="lazy" alt="שיא ליסינג" title="שיא ליסינג"
                                    src="assets/images/c-leasing.jpg" />
                            </div>
                            <div class="col-md-3 col-4 p-0 d-block d-sm-none company-logo-9">
                                <img loading="lazy" style="width: 100px;" src="assets/images/eurodrive.png" />
                            </div>
                            <div class="col-md-3 col-4 other-companies-text-container p-0">
                                <br />
                                <button type="button" class="header-button other-companies-text point"
                                    (click)="goToLeasingPage()" style="border-radius: 6px;">
                                    <span class="d-none d-xl-block">
                                        סוכנויות רכב נוספות
                                    </span>
                                    <span class="d-block d-sm-none">
                                        סוכנויות רכב <br />
                                        נוספות
                                    </span>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div class="d-none d-xl-block" style="padding-top: 15px;">
                        <span>
                            {{filterDescription}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-block d-sm-none filter-description-container" style="padding: 10px;">
            {{filterDescription}}
        </div>
        <app-footer></app-footer>
    </div>

</div>

<div id="filter-modal" #filtersModal class="filter-modal" style="overflow-y: scroll;">
    <nav class="fixed-top">
        <div class="container-fluid" style="background-color:white">
            <div class="row" style="padding-top: 23px">
                <div class="col-9 p-0">
                    <!-- <span #filtersModalShowResult id="show-result" (click)="hideFiltersModal()" class="popup-menu-text"
                        style="padding-right: 23px;">הצג {{resultLabel}} דגמים</span> -->

                    <button type="button" style="margin-right: 23px; width:120px; background-color: #ff2800; color: white; border-radius: 6px;"
                        (click)="hideFiltersModal()">
                        הצג {{resultLabel}} דגמים
                    </button>
                </div>
                <div class="col-3 p-0">
                    <!-- <span #filtersModalClear id="clear-filters" (click)="resetFilters()" class="popup-menu-text">
                        אפס
                        סינון
                    </span> -->
                    <button type="button" style="width:80px; background-color: #ff2800; color: white; border-radius: 6px;"
                        (click)="resetFilters()">
                        אפס סינון
                    </button>
                </div>
            </div>
        </div>
    </nav>

    <div class="container-fluid">
        <div class="row" style="padding-top: 64px">
            <div class="col-12 p-0">
                <app-side-menu [isMobileMode]="true"></app-side-menu>
            </div>
        </div>
        <div class="row" style="padding-top: 20px">

        </div>
    </div>
</div>