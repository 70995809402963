import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { flatMap } from 'rxjs/operators';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

    sendAnalytics: boolean;

    constructor(private router: Router
        ,       @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.sendAnalytics = !(window['ga-disable-UA-149877936-1']);
            console.log('sendAnalytics: ' + this.sendAnalytics);
        }
    }

    public eventEmitter(eventCategory: string,
                        eventAction: string,
                        eventLabel: string = null,
                        eventValue: number = null) {

        if (this.sendAnalytics) {
            gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_action': eventAction,
                'event_label': eventLabel,
                'value': eventValue
            });
        }

        console.log(`ga: event >> category: '${eventCategory}', action: '${eventAction}', label: '${eventLabel}'`);

    }

    public registerPageNavigation(event: any, pageTitle) {
        if (event instanceof NavigationEnd) {
            if (this.sendAnalytics) {
                gtag('config', 'UA-149877936-1', {
                    'page_title': pageTitle,
                    'page_path': event.urlAfterRedirects
                });
            }

            console.log(`ga: page view >> title: '${pageTitle}', path: '${event.urlAfterRedirects}'`);

        }
    }

    public AddPageNavigation(pageTitle) {
        if (this.sendAnalytics) {
            gtag('config', 'UA-149877936-1', {
                'page_title': pageTitle,
                'page_path': pageTitle
            });
        }

        console.log(`ga: page view >> title: '${pageTitle}''`);


    }

    public SendCompletedConversionEvent() {
        if (this.sendAnalytics) {
            gtag('event', 'conversion', {
                'send_to': 'AW-608661243/kUKICK_9ndkBEPvdnaIC'
            });
        }

        console.log(`gads: SendCompletedConversionEvent`);
    }

    public SendPhoneEvent() {
        if (this.sendAnalytics) {
            gtag('event', 'conversion', {
                'send_to': 'AW-608661243/wt6JCLaC-fACEPvdnaIC'
            });
        }

        console.log(`gads: SendPhoneEvent`);
    }

    public SendWhatsAppEvent() {
        if (this.sendAnalytics) {
            gtag('event', 'conversion', {
                'send_to': 'AW-608661243/eMjxCOK_jvYCEPvdnaIC'
            });
        }

        console.log(`gads: SendWhatsAppEvent`);
    }
}