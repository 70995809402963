import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '../../../../app/services/http.service';
import { DealerInfo } from '../../../../app/models/dealerinfo';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TagItem } from '../../../../app/models/tagItem';
import { UtilsService } from '../../../../app/services/utils.service';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-dealers-info',
  templateUrl: './dealers-info.component.html',
  styleUrls: ['./dealers-info.component.css']
})
export class DealersInfoComponent implements OnInit, OnDestroy {
  public dealersInfo: DealerInfo[] = [];
  private eventsSubscriptionHandler = Subscription.EMPTY;
  title: string;

  constructor(public httpClient: HttpService
    , public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
    , private titleService: Title
    , private utilsService: UtilsService
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.title = 'סוכנויות רכב, חברות ליסינג ומגרשי מכוניות - כל המידע';
      this.utilsService.generatePageTitle(this.title);
      this.utilsService.createCanonicalURL();
      this.googleAnalyticsService.registerPageNavigation(event, 'agencies-page');
    });
  }

  ngOnInit(): void {
    this.httpClient.getDealersInfo().subscribe((result: any) => {
      this.dealersInfo = result;
    });

    const description = `רוצים לקנות רכב מחברת ליסינג? היכנסו לכל פרטי ההתקשרות עם חברות ליסינג וסוכנויות רכב שונות בישראל. בנוסף לפרטים על סוכנויות ומגרשי מכוניות תמצאו רכבים חדשים במחיר המפורסם הזול בישראל!`;
    this.utilsService.addMetaDescriptionTag(description);
    this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), this.title);

    const breadcrumbPath: BreadcrumbSchemeItem[] = [];
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('סוכנויות רכב', '', true));
    this.utilsService.setBreadcrumbScheme(breadcrumbPath);

  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  generateBreadcrumbs() {
    const tags: TagItem[] = [];
    tags.push(this.utilsService.generateNewTag('סוכנויות רכב', '', false));
    return tags;
  }

  getDealerImage(imageName) {
    const url = `${environment.cdnEndpointUrl}/general/car-agencies/webp/${imageName}.webp`;
    return url;
  }

  getTitle(title: string) {
    return `${title.substring(0,20)}`;
  }
}
