import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-car-manufacturer-info',
  templateUrl: './car-manufacturer-info.component.html',
  styleUrls: ['./car-manufacturer-info.component.css']
})
export class CarManufacturerInfoComponent implements OnInit {

  @Input()
  ManufacturerDescription: string;

  constructor() { }

  ngOnInit(): void {
  }

}
