import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { UtilsService } from '../../../../app/services/utils.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  private eventsSubscriptionHandler = Subscription.EMPTY;
  constructor(public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
    , private meta: Meta
    , private utilsService: UtilsService
    , private titleService: Title
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'terms-page');
    });

    this.utilsService.generatePageTitle('מדיניות הפרטיות ותנאי השימוש');
    this.utilsService.createCanonicalURL();
  }

  ngOnInit() {
    this.meta.updateTag({ name: 'description', content: 'אתר הרכב המקיף בישראל למציאת המכונית המתאימה ביותר עבורך' });
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

}
