import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CarGroupInfo, SubModelInfo } from '../models/CarGroupInfo';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { TransferState } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class CarDetailsService {

    carGroupInfo: CarGroupInfo;
    currentPage = 0;
    isFirstPage = false;
    isLastPage = false;
    itemsPerPage = 0;

    constructor(
        public httpClient: HttpService
        , private utilsService: UtilsService
        , private transferState: TransferState
        , @Inject(PLATFORM_ID) private platformId: Object) {
        this.carGroupInfo = new CarGroupInfo();
        this.setItemsperPage();
    }

    setItemsperPage() {
        const isMobile = this.utilsService.IsMobile();
        if (isMobile) {
            this.itemsPerPage = 2;
        } else {
            this.itemsPerPage = 4;
        }
    }

    getData(): CarGroupInfo {
        return this.carGroupInfo;
    }

    reset(): SubModelInfo[] {
        this.currentPage = 1;
        this.isFirstPage = false;
        this.isLastPage = false;
        this.setItemsperPage();
        return this.getSubModels();
    }

    resetSubModels(): SubModelInfo[] {
        this.currentPage = 1;
        return this.getSubModels();
    }

    getNextSubModels(): SubModelInfo[] {
        this.currentPage++;
        return this.getSubModels();
    }

    getPrevSubModels(): SubModelInfo[] {
        this.currentPage--;
        return this.getSubModels();
    }

    getSubModels(): SubModelInfo[] {
        return this.paginator(this.carGroupInfo.subModels, this.currentPage, this.itemsPerPage);
    }

    getCarDetails(id: number): Observable<CarGroupInfo> {
        return this.httpClient.getCarFindCarVersions(id).pipe(
            map(info => this.carGroupInfo = info)
        );
    }

    getCarDetailsData(manufacturer: string, pmodel: string, model: string, bodyType: string, engine: string, doors: number, year: number): Observable<CarGroupInfo> {


        // engine = this.utilsService.engineValueConvertBack(engine);
        // bodyType = this.utilsService.bodyTypeValueConvertBack(bodyType);

        if (model === 'undefined') {
            model = '-';
        }

        return this.httpClient.getCarFindCarVersionsDataEx(manufacturer, pmodel, model, bodyType, engine, doors, year).pipe(
            map(info => this.carGroupInfo = info)
        );
    }

    paginator(items: SubModelInfo[], page: number, perPage: number) {
        const offset = page - 1;
        const paginatedItems = items.slice(offset).slice(0, perPage);

        this.isLastPage = paginatedItems[paginatedItems.length - 1] === items[items.length - 1];
        this.isFirstPage = paginatedItems[0] === items[0];

        return paginatedItems;
    }
}