import { Component, OnInit, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../../app/services/http.service';
import { ContactForm } from '../../../../app/models/contactForm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { UtilsService } from '../../../../app/services/utils.service';
import { TagItem } from '../../../../app/models/tagItem';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy, AfterViewInit {

  contactForm: ContactForm;
  registerForm: FormGroup;
  submitted = false;
  title: string;
  phoneHasErros = false;
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(public httpClient: HttpService
    , private router: Router
    , private formBuilder: FormBuilder
    , private titleService: Title
    , private meta: Meta
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
    , public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'contact-us-page');
    });
    this.title = 'צרו קשר: ייעוץ רכב חינם ואובייקטיבי, מגוון ובחינם 03-524-5241 או בצאט';
    this.utilsService.generatePageTitle(this.title);
  }

  get f() { return this.registerForm.controls; }

  ngOnInit() {
    const description = `צריכים ייעוץ לגבי בחירת הרכב הבא שלכם? צוות CarFind אתר הרכב המקיף בישראל למציאת הדגם המתאים ביותר עבורך עומד לרשותך. יש לך הצעות לשיפור? מעוניין לשתף פעולה? צור איתנו קשר.`;
    this.utilsService.addMetaDescriptionTag(description);
    this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), this.title);
    this.utilsService.createCanonicalURL();
    this.contactForm = new ContactForm();

    this.registerForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    }, {});

    const breadcrumbPath: BreadcrumbSchemeItem[] = [];
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('יצירת קשר', '', true));
    this.utilsService.setBreadcrumbScheme(breadcrumbPath);
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  ngAfterViewInit() {
  }

  submit() {
    this.submitted = true;

    this.phoneHasErros = !(this.utilsService.isValidPhoneNumber(this.contactForm.phone));

    if (this.registerForm.invalid || this.phoneHasErros) {
      return;
    }

    this.contactForm.subject = 'פנייה מעמוד יצירת קשר';
    this.contactForm.channelId = 4;
    this.httpClient.SendContactForm(this.contactForm).subscribe((result: any) => {
      this.googleAnalyticsService.SendCompletedConversionEvent();
      this.googleAnalyticsService.eventEmitter('ContactUs', 'Click', 'Submit', 1);
      this.router.navigate(['/thanks']);
    });
  }

  setNameFocus() {
    this.googleAnalyticsService.eventEmitter('ContactUs', 'Focus', 'Name', 1);
  }

  setPhoneFocus() {
    this.googleAnalyticsService.eventEmitter('ContactUs', 'Focus', 'Phone', 1);
  }

  setEmailFocus() {
    this.googleAnalyticsService.eventEmitter('ContactUs', 'Focus', 'Email', 1);
  }

  setMessageFocus() {
    this.googleAnalyticsService.eventEmitter('ContactUs', 'Focus', 'Message', 1);
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('ContactUs', 'Click', 'Call', 1);
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.SendPhoneEvent();
      this.googleAnalyticsService.eventEmitter('ContactUs', 'Click', 'Call', 1);
      document.location.href = 'tel:035245241';
    }
  }

  generateBreadcrumbs() {
    const tags: TagItem[] = [];
    tags.push(this.utilsService.generateNewTag('יצירת קשר' , '', false));
    return tags;
  }
}
