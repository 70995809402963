<ul class="list-inline ul-links">
    <li>
        <a href="/">ראשי</a>
    </li>
    <li *ngFor="let item of items; let i=index;">
        >&nbsp;&nbsp;&nbsp;
        <a *ngIf="i < (items.length - 1); else noLink" [routerLink]="generateUrl(i, item.value)">
            {{item.title}}
        </a>
        <ng-template #noLink>
          <span>
            {{item.title}}
          </span>
          </ng-template>
    </li>
</ul>

