import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { TagItem } from '../../../../app/models/tagItem';
import { UtilsService } from '../../../../app/services/utils.service';
import { BreadcrumbSchemeItem } from '../../../../app/models/breadcrumbSchemeItem';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, OnDestroy {

  private eventsSubscriptionHandler = Subscription.EMPTY;
  title: string;

  constructor(public googleAnalyticsService: GoogleAnalyticsService
    , private router: Router
    , private meta: Meta
    , private titleService: Title
    , private utilsService: UtilsService
    , @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'about-page');
    });
    this.title = 'אודות אתר הרכב המקיף בישראל למציאת המכונית המתאימה ביותר עבורך';
    this.utilsService.generatePageTitle(this.title);
  }

  ngOnInit() {
    const description = `אתר הרכב המקיף בישראל עם מידע על גרסאות ודגמי רכבים, השוואת עסקאות מבין מגוון סוכנויות: יבואנים, חברות 0 ק"מ ועוד. מצאו את הרכב המתאים לכם במחיר הזול ביותר עם CarFind`;
    this.utilsService.addMetaDescriptionTag(description);
    this.utilsService.setOGMeta(description, 'https://www.carfind.co.il/assets/images/carfind-logo-desktop.png', this.utilsService.getPageUrl(), this.title);
    this.utilsService.createCanonicalURL();

    const breadcrumbPath: BreadcrumbSchemeItem[] = [];
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('ראשי', ''));
    breadcrumbPath.push(this.utilsService.generateBreadcrumbSchemeItem('אודות', '', true));
    this.utilsService.setBreadcrumbScheme(breadcrumbPath);

  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  showTerms() {
    this.googleAnalyticsService.eventEmitter('About', 'Click', 'TOS-Privacy', 1);
  }

  goToContactUsPage() {
    this.googleAnalyticsService.eventEmitter('About', 'Click', 'ContactUs', 1);
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('About', 'Click', 'callUs', 1);
  }

  openDialer() {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsService.SendPhoneEvent();
      this.googleAnalyticsService.eventEmitter('About', 'Click', 'callUs', 1);
      document.location.href = 'tel:035245241';
    }
  }

  generateBreadcrumbs() {
    const tags: TagItem[] = [];
    tags.push(this.utilsService.generateNewTag('אודות' , '', false));
    return tags;
  }
}
