import { Component, OnInit, Input, AfterViewInit, AfterContentChecked } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { CarGroupInfo } from './../../../../app/models/CarGroupInfo';
import { HttpService } from './../../../../app/services/http.service';

@Component({
  selector: 'app-car-model-info',
  templateUrl: './car-model-info.component.html',
  styleUrls: ['./car-model-info.component.css']
})
export class CarModelInfoComponent implements OnInit, AfterViewInit, AfterContentChecked {
  carInfo: CarGroupInfo;
  carDescription: string;

  @Input()
  ConfigurationId: number;

  constructor(public httpClient: HttpService, private utilsService: UtilsService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  ngAfterContentChecked() {
    if (this.ConfigurationId !== undefined && this.carInfo === undefined) {
      this.carInfo  = null;
      this.httpClient.getCarModelInfo(
        this.ConfigurationId).subscribe((data: CarGroupInfo) => {
          this.carInfo = data;
          this.carDescription = this.carInfo.description === null ?  '' : `${this.carInfo.description}.`;
        });
    }
  }

  getPositivevFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין יתרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getNegativeFeaturesSummary(features) {
    if (features === null || features.length === 0) {
      return 'אין חסרונות ביחס לקטגוריה';
    }
    return this.getFeaturesSummary(features);
  }

  getFeaturesSummary(features) {
    return this.utilsService.getCarFeaturesSummary(features);
  }
}
