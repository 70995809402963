<div id="sidebar-wrapper" class="d-block d-sm-none sticky" style="z-index: 10000000">
    <div id="sidebar-close-button" style="padding-top: 20px; padding-right: 200px;">
        <img style="width: 14px; height: 14px" src="assets/images/svg/ic-close-2-copy.svg" />
    </div>
    <div>

        <ul class="sidebar-nav" style="margin-top: 45px;">
            <!-- <li> <a (click)="showFavoritesList($event)">מועדפים</a> </li> -->
            <li> <a (click)="goToContactUsPage()">יצירת קשר</a> </li>
            <li> <a (click)="goToAlertPage($event)">התראת מבצעים</a> </li>
            <li> <a (click)="goToDealsPage($event)">הצעות מוזלות</a> </li>
            <li> <a (click)="goToTestDrivePage($event)">נסיעת מבחן</a> </li>
            <li> <a (click)="goToAboutPage()">אודות</a> </li>
            <li> <a (click)="goToDealersPage()">סוכנויות רכב</a> </li>
            <li> <a (click)="goToJoinAsDealerPage()">הצטרפו למאגר ספקי הרכב</a> </li>
            <!-- <li> <a (click)="goToCademy()">מועדון צרכנות לאקדמאיים</a> </li> -->
            <li> <a (click)="share()">שתפו עם חברים <img style="padding-right: 40px;" (click)="share()"
                        src="assets/images/svg/ic-share-24-px.svg" /></a> </li>
            <li> <a (click)="goToFacebook()">עקבו אחרינו <img (click)="goToFacebook()" style="padding-right: 15px;" src="assets/images/svg/fb.svg" /><img
                        style="padding-right: 45px;" (click)="goToInstagram()" src="assets/images/svg/inst.svg" /></a> </li>
        </ul>
    </div>

</div>