export class BasicContactForm {
    subject: string;
    name: string;
    phone: string;
    city: string;
    message: string;
    channelId: number;
    paymentBalloon: number;
    maxPaymentPrice: number;
    advancePaymentPrice: number;
}