import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasicContactForm } from '../../../../app/models/basicContactForm';
import { LeadTicket, CustomerInfo } from '../../../../app/models/leadTicket';
import { DialogService } from '../../../../app/services/dialog.service';
import { Router } from '@angular/router';
import { MessageService } from '../../../../app/services/message.service';
import { CarGroupInfo } from '../../../../app/models/CarGroupInfo';
import { Subscription } from 'rxjs';
import { CarDetailsService } from '../../../../app/services/car-details.service';
import { HttpService } from '../../../../app/services/http.service';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { UtilsService } from '../../../../app/services/utils.service';

@Component({
  selector: 'app-car-details-contact-form',
  templateUrl: './car-details-contact-form.component.html',
  styleUrls: ['./car-details-contact-form.component.css']
})
export class CarDetailsContactFormComponent implements OnInit, OnDestroy {

  leadTicket: LeadTicket;
  carInfo: CarGroupInfo;
  subscription: Subscription;
  messages: any[] = [];
  isModelPage: boolean;
  isManufacturerPage: boolean;
  hasErrors = false;

  constructor(private dialogService: DialogService
    , private router: Router
    , private messageService: MessageService
    , public carDetailsService: CarDetailsService
    , public googleAnalyticsService: GoogleAnalyticsService
    , public utilsService: UtilsService
    , public httpClient: HttpService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text === 'car-deailes-loading-data-done') {
        this.carInfo = carDetailsService.getData();
      } else {
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.leadTicket = new LeadTicket();
    this.leadTicket.customerInfo = new CustomerInfo();
    this.leadTicket.customerInfo.fullName = '';
    this.leadTicket.customerInfo.cityName = '';
    this.leadTicket.requestedPrice = 0;

    const urlData = this.router.url.split('/');
    this.isModelPage = urlData.length === 3;
    this.isManufacturerPage = urlData.length === 2;
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  submit(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const contactForm = new BasicContactForm();
    contactForm.name = this.leadTicket.customerInfo.fullName;
    contactForm.phone = this.leadTicket.customerInfo.phoneNumber;

    const isValid =  contactForm.name !== undefined && contactForm.phone !== undefined && this.utilsService.isValidPhoneNumber(contactForm.phone);
    // this.dialogService.setDialog(target, contactForm, this.router.url);

    if (isValid) {
      this.hasErrors = false;
      const modelType = 'm';
      this.leadTicket.CarFindCarVersionId = this.carInfo.id;
      this.leadTicket.CarFindCarVersionName = `${this.carInfo.name} ${this.carInfo.engine} ${this.carInfo.bodyType}`;
      const paymentInfo = this.utilsService.getPaymentInfo();
      this.leadTicket.paymentBalloon = paymentInfo[0];
      this.leadTicket.maxPaymentPrice = paymentInfo[1];
      this.leadTicket.advancePaymentPrice = paymentInfo[2];
      this.httpClient.SendLeadTicket(this.leadTicket, modelType).subscribe((result: any) => {
        console.log('sent!');
        this.googleAnalyticsService.SendCompletedConversionEvent();
        this.googleAnalyticsService.eventEmitter('ContactWidget', 'Click', 'Submit', 1);
        this.router.navigate(['/thanks']);
      });
    } else {
      this.hasErrors = true;
      this.googleAnalyticsService.eventEmitter('ContactWidget', 'Click', '!Submit', 1);
    }
  }

  callUs() {
    this.googleAnalyticsService.SendPhoneEvent();
    this.googleAnalyticsService.eventEmitter('ContactWidget', 'Click', 'Call', 1);
  }

  setNameFocus() {
    this.googleAnalyticsService.eventEmitter('Config', 'Focus', 'Name', 1);
  }

  setPhoneFocus() {
    this.googleAnalyticsService.eventEmitter('Config', 'Focus', 'Phone', 1);
  }
}
