<!-- <div class="d-flex align-items-center justify-content-center">
    <div>
        <h1>
            <span class="title">{{title}}</span>
        </h1>
    </div>

</div> -->
<div class="d-flex align-items-center justify-content-center">
    <img class="logo" alt="{{manufacturerImageName}} {{title}}" title="{{title}}"
     src="{{getImage(manufacturerImageName)}}">

</div>