<div>
    <div class="side-menu-wrapper">
        <div>
            <ul class="list-unstyled ul-menu-item" style="padding-top: 4px">
                <li>
                    <div class="form-check float-left point" style="padding-top: 4px;">
                        <input id="dealNewCarsFilterItem" #dealNewCarsFilterItem
                            [ngClass]="setDealTypeStatus('dealNewCarsFilterItem','new-cars')" type="radio"
                            name="main-catalog-group" id="dealNewCarsFilterItem" (click)="setDealType('new-cars')">
                    </div>
                    <label for="dealNewCarsFilterItem" style="padding-top: 5px;">
                        <a href="/new-cars" (click)="setDealTypeLink('new-cars')" [ngClass]="toggleDealTypeFilterItemClass('new-cars')">רכב חדש</a>
                    </label>
                    <span class="side-nav-item-count"
                        style="padding-top: 5px;">&nbsp;{{getFilterItemCountText('new-cars')}}</span>
                </li>
                <li>
                    <div class="form-check float-left point" style="padding-top: 5px;">
                        <input id="dealCarDealsFilterItem" #dealCarDealsFilterItem
                            [ngClass]="setDealTypeStatus('dealCarDealsFilterItem','car-deals')" type="radio"
                            name="main-catalog-group" id="dealCarDealsFilterItem" (click)="setDealType('car-deals')">
                    </div>
                    <label for="dealCarDealsFilterItem" style="padding-top: 6px;">
                        <a href="/car-deals" (click)="setDealTypeLink('car-deals')" [ngClass]="toggleDealTypeFilterItemClass('car-deals')">מבצעי רכב</a>
                    </label>
                    <span class="side-nav-item-count"
                        style="padding-top: 6px;">&nbsp;{{getFilterItemCountText('car-deals')}}</span>

                </li>
                <li>
                    <div class="form-check float-left point" style="padding-top: 5px;">
                        <input id="dealLeasing0KmFilterItem" #dealLeasing0KmFilterItem
                            [ngClass]="setDealTypeStatus('dealLeasing0KmFilterItem','leasing-0-km')" type="radio"
                            name="main-catalog-group" id="dealLeasing0KmFilterItem"
                            (click)="setDealType('leasing-0-km')">
                    </div>
                    <label for="dealLeasing0KmFilterItem" style="padding-top: 6px;">
                        <a href="/leasing-0-km" (click)="setDealTypeLink('leasing-0-km')" [ngClass]="toggleDealTypeFilterItemClass('leasing-0-km')">רכב 0 ק"מ</a>
                    </label>
                    <span class="side-nav-item-count"
                        style="padding-top: 6px;">&nbsp;{{getFilterItemCountText('leasing-0-km')}}</span>

                </li>
                <li>
                    <div class="form-check float-left point" style="padding-top: 4px;">
                        <input id="dealUsedCars2021FilterItem" #dealUsedCars2021FilterItem
                            [ngClass]="setDealTypeStatus('dealUsedCars2021FilterItem','used-cars')" type="radio"
                            name="main-catalog-group" id="dealUsedCars2021FilterItem" (click)="setDealType('used-cars')">
                    </div>
                    <label for="dealUsedCars2021FilterItem" style="padding-top: 5px;">
                        <a href="/used-cars/2021" (click)="setDealTypeLink('used-cars')" [ngClass]="toggleDealTypeFilterItemClass('used-cars')">רכבי 2021</a>
                    </label>
                    <span class="side-nav-item-count"
                    style="padding-top: 6px;">&nbsp;{{getFilterItemCountText('used-cars')}}</span>
                </li>
            </ul>
        </div>

        <div id="side-menu-filter">
            <div>
                <span (click)="clearAllCategoryFilterItem()" [ngClass]="toggleCategoryTitleClass()">כל
                    הקטגוריות</span>
                <div>
                    <ul class="list-unstyled ul-nav-category ul-menu-item">
                        <li>
                            <a id="categoryPremiumFilterItem" #categoryPremiumFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('small-hatchback')"
                                href="{{getFilterItemUrl('categoryPremiumFilterItem','small-hatchback', 6)}}"
                                (click)="toggleCategoryFilterItem('small-hatchback')">קטן + האצ'בק
                            </a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('small-hatchback')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">3 או 5 דלתות</span>
                            </div>
                        </li>

                        <li>
                            <a id="categorySuvFilterItem" #categorySuvFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('sedan')"
                                href="{{getFilterItemUrl('categorySuvFilterItem','sedan', 6)}}"
                                (click)="toggleCategoryFilterItem('sedan')">סדאן משפחתי
                            </a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('sedan')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">4 דלתות</span>
                            </div>
                        </li>

                        <li>
                            <a id="categoryTrunkFilterItem" #categoryTrunkFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('suv')"
                                href="{{getFilterItemUrl('categoryTrunkFilterItem','suv', 6)}}"
                                (click)="toggleCategoryFilterItem('suv')">פנאי SUV
                            </a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('suv')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">ג'יפ ג'יפון</span>
                            </div>
                        </li>

                        <li>
                            <a id="category7PlusFilterItem" #category7PlusFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('7plus-seats')"
                                href="{{getFilterItemUrl('category7PlusFilterItem','7plus-seats', 6)}}"
                                (click)="toggleCategoryFilterItem('7plus-seats')">7+ מושבים
                            </a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('7plus-seats')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">סטיישן ואן מיני-ואן</span>
                            </div>
                        </li>

                        <li>
                            <a id="categoryFamilyFilterItem" #categoryFamilyFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('pickup-van')"
                                href="{{getFilterItemUrl('categoryFamilyFilterItem','pickup-van', 6)}}"
                                (click)="toggleCategoryFilterItem('pickup-van')">מסחרי + טנדר
                            </a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('pickup-van')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">תא מטען גדול</span>
                            </div>
                        </li>

                        <li>
                            <a id="categorySmallFilterItem" #categorySmallFilterItem
                                [ngClass]="toggleCategoryFilterItemClass('coupe-converible')"
                                href="{{getFilterItemUrl('categorySmallFilterItem','coupe-converible', 6)}}"
                                (click)="toggleCategoryFilterItem('coupe-converible')">קבריולה + קופה
                            </a>
                            <span
                                class="side-nav-item-count">&nbsp;{{getFilterItemCountText('coupe-converible')}}</span>

                            <div class="float-right d-block d-sm-none">
                                <span class="side-nav-item-description">גג נפתח / כיפתי</span>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="category-container">
                <span (click)="clearAllEngineCategoryFilterItem()" [ngClass]="toggleEnginesTitleClass()">כל סוגי
                    ההטענה</span>
                <div>
                    <ul class="list-unstyled ul-nav-category ul-menu-item">
                        <li>
                            <a id="ftBenzinFilterItem" #ftBenzinFilterItem
                                href="{{getFilterItemUrl('ftBenzinFilterItem','benzin', 1)}}"
                                (click)="toggleEngineFilterItem('benzin')"
                                [ngClass]="toggleEngineFilterItemClass('benzin')" for="employment_all">בנזין</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('benzin')}}</span>

                        </li>
                        <li>
                            <a id="ftElectricFilterItem" #ftElectricFilterItem
                                href="{{getFilterItemUrl('ftElectricFilterItem','electric', 1)}}"
                                (click)="toggleEngineFilterItem('electric')"
                                [ngClass]="toggleEngineFilterItemClass('electric')" for="employment_all">חשמל</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('electric')}}</span>

                        </li>
                        <li>
                            <a id="ftPluginFilterItem" #ftPluginFilterItem
                                href="{{getFilterItemUrl('ftPluginFilterItem','plug-in', 1)}}"
                                (click)="toggleEngineFilterItem('plug-in')"
                                [ngClass]="toggleEngineFilterItemClass('plug-in')" for="employment_all">בנזין עם שקע חשמל</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('plug-in')}}</span>

                        </li>
                        <li>
                            <a id="ftHybridFilterItem" #ftHybridFilterItem
                                href="{{getFilterItemUrl('ftHybridFilterItem','hybrid', 1)}}"
                                (click)="toggleEngineFilterItem('hybrid')"
                                [ngClass]="toggleEngineFilterItemClass('hybrid')" for="employment_all">היברידי בלי שקע חשמל</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('hybrid')}}</span>

                        </li>
                        
                        
                        <li>
                            <a id="ftDieselFilterItem" #ftDieselFilterItem
                                href="{{getFilterItemUrl('ftDieselFilterItem','diesel', 1)}}"
                                (click)="toggleEngineFilterItem('diesel')"
                                [ngClass]="toggleEngineFilterItemClass('diesel')" for="employment_all">דיזל עם / בלי חשמל</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('diesel')}}</span>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div class="category-container">
                <span (click)="clearAllTransmissionCategoryFilterItem()" [ngClass]="toggleTransmissionTitleClass()">כל
                    ההילוכים</span>
                <div>
                    <ul class="list-unstyled ul-nav-category ul-menu-item">
                        <li>
                            <a id="transmissionManualFilterItem" #transmissionManualFilterItem
                                href="{{getFilterItemUrl('transmissionManualFilterItem','manual', 2)}}"
                                (click)="toggleTransmissionFilterItem('manual')"
                                [ngClass]="toggleTransmissionFilterItemClass('manual')" for="employment_all">ידני</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('manual')}}</span>
                        </li>
                        <li>
                            <a id="transmissionAutoFilterItem" #transmissionAutoFilterItem
                                href="{{getFilterItemUrl('transmissionAutoFilterItem','automatic', 2)}}"
                                (click)="toggleTransmissionFilterItem('automatic')"
                                [ngClass]="toggleTransmissionFilterItemClass('automatic')"
                                for="employment_all">אוטומטי</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('automatic')}}</span>
                        </li>
                        <li>
                            <a id="transmissionFourFilterItem" #transmissionFourFilterItem
                                href="{{getFilterItemUrl('transmissionFourFilterItem','4x4', 3)}}"
                                (click)="toggleDrivetrainFilterItem('4x4')"
                                [ngClass]="toggleDrivetrainFilterItemClass('4x4')" for="employment_all">4x4</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('4x4')}}</span>
                        </li>
                        <li>
                            <a id="transmissionTwoFilterItem" #transmissionTwoFilterItem
                                href="{{getFilterItemUrl('transmissionTwoFilterItem','2x4', 3)}}"
                                (click)="toggleDrivetrainFilterItem('2x4')"
                                [ngClass]="toggleDrivetrainFilterItemClass('2x4')" for="employment_all">2x4</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('2x4')}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="category-container">
                <span (click)="clearAllSeatsCategoryFilterItem()" [ngClass]="toggleSeatsTitleClass()">כל אפשרויות
                    המושבים</span>
                <div>
                    <ul class="list-unstyled ul-nav-category ul-menu-item">
                        <li>
                            <a id="1rowFilterItem" href="{{getFilterItemUrl('1rowFilterItem','1-row', 4)}}"
                                [ngClass]="toggleSeatsFilterItemClass('1-row')" (click)="toggleSeatsFilterItem('1-row')"
                                for="employment_all">שורה אחת</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('1-row')}}</span>
                        </li>
                        <li>
                            <a id="2-rowsFilterItem" href="{{getFilterItemUrl('2-rowsFilterItem','2-rows', 4)}}"
                                [ngClass]="toggleSeatsFilterItemClass('2-rows')"
                                (click)="toggleSeatsFilterItem('2-rows')" for="employment_all">שתי שורות</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('2-rows')}}</span>
                        </li>
                        <li>
                            <a id="3-rows-plusFilterItem"
                                href="{{getFilterItemUrl('3-rows-plusFilterItem','3-plus-rows', 4)}}"
                                #seatsThreePlusFilterItem [ngClass]="toggleSeatsFilterItemClass('3-plus-rows')"
                                (click)="toggleSeatsFilterItem('3-plus-rows')" for="employment_all">שלוש שורות ויותר</a>
                            <span class="side-nav-item-count">&nbsp;{{getFilterItemCountText('3-plus-rows')}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="feature-slider-category">
                <span (click)="clearAllModelCategoryFilterItem()" [ngClass]="toggleModelsTitleClass()">
                    <div *ngIf="!isModelsSelected()" class="d-none d-xl-block">לחצו על: שם-יצרן לסינון</div>
                    <div *ngIf="!isModelsSelected()" class="d-none d-xl-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;לוגו לעמוד-יצרן</div>
                    <div *ngIf="isModelsSelected()" class="d-none d-xl-block">הצג את כל היצרנים</div>
                </span>
                <div style="padding-top: 5px">
                    <app-car-model-treeview></app-car-model-treeview>
                </div>
            </div>

        </div>
    </div>
</div>