import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadTicket } from '../../../models/leadTicket';
import { GoogleAnalyticsService } from '../../../../app/services/google-analytics.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})
export class LeadComponent implements OnInit, OnDestroy {
  id: string;
  private sub: any;
  leadTicket: LeadTicket;
  showSoldComment = false;
  showMoreTimeComment = false;
  showNoSuccessComment = false;
  commentPlaceholder: string = null;
  isHandled = false;
  soldComment = '';
  needMoreTimeComment = '';
  noSuccessComment = '';
  private eventsSubscriptionHandler = Subscription.EMPTY;

  constructor(public httpClient: HttpService
    , private route: ActivatedRoute
    , private router: Router
    , @Inject(PLATFORM_ID) private platformId: Object
    , public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.eventsSubscriptionHandler = this.router.events.subscribe((event) => {
      this.googleAnalyticsService.registerPageNavigation(event, 'lead-page');
    });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getLeadTicketInfo();
    });

    this.commentPlaceholder = 'נתקלת בקשיים, יש לך הערות, הצעות לשיפור, נשמח שתכתוב לנו אותם ואם תצרף את הטלפון שלך (לא חובה) נוכל ליצור אף ליצור איתך קשר לסיוע והבהרות';
  }

  ngOnDestroy() {
    this.eventsSubscriptionHandler.unsubscribe();
  }

  soldCheckd() {
    this.showSoldComment = true;
    this.showMoreTimeComment = false;
    this.showNoSuccessComment = false;
    this.leadTicket.response = 2;
    this.leadTicket.comment = this.soldComment;
  }

  needMoreTimeCheckd() {
    this.showSoldComment = false;
    this.showMoreTimeComment = true;
    this.showNoSuccessComment = false;
    this.leadTicket.response = 3;
    this.leadTicket.comment = this.needMoreTimeComment;
  }

  noSuccessCheckd() {
    this.showSoldComment = false;
    this.showMoreTimeComment = false;
    this.showNoSuccessComment = true;
    this.leadTicket.response = 4;
    this.leadTicket.comment = this.noSuccessComment;
  }

  getLeadTicketInfo() {
    this.httpClient.getLeadTicketInfo(this.id).subscribe((data: any) => {
      this.leadTicket = data;
      this.isHandled = this.leadTicket.isHandled;
    });
  }

  updateResponse() {
    this.httpClient.updateLeadTicketResponse(this.leadTicket).subscribe((data: any) => {
      this.router.navigate(['/thanks']);
    });
  }
}
